<div class="flex account-page">
  <div class="flex flex-column container">
    <div class="flex position-center page-title hide">Your Orders</div>
    <div class="flex w-100">
      <!-- account-side-menu -->
      <app-account-sidemenu class="flex account-panel"></app-account-sidemenu>
      <!-- ./account-side-menu -->

      <div class="flex right profile-details" style="position: relative">
        <app-section-loader class=" {{(show_loader) ? 'show' :'hide'}} "></app-section-loader>

        <div class="flex flex-column w-100 profile-overview profile-card">
          <div class="w-100 head-section">All Orders</div>
          <div class="flex flex-column w-100 account-order-items" infiniteScroll [infiniteScrollDistance]="1"
            [infiniteScrollThrottle]="50" (scrolled)="onScroll()">

            <ng-container *ngFor="let product of orders; let indx=index;">
              <div class="flex flex-column order-item">
                <div class="flex w-100 allign-middle justidfy-content-between order-stat">
                  <div class="flex allign-middle stat-left">

                    <div class="flex allign-middle stat-left-item cancelled "
                      *ngIf="product.status=='CBU' || product.status=='CBS' || product.status=='R' ">
                      <div class="flex position-center stat-icon">
                        <svg viewBox="0 0 24 24">
                          <path fill="currentColor"
                            d="m12 10.586 6.293-6.293a1 1 0 0 1 1.414 1.414l-6.293 6.293 6.293 6.293a1 1 0 0 1 -1.414 1.414l-6.293-6.293-6.293 6.293a1 1 0 1 1 -1.414-1.414l6.293-6.293-6.293-6.293a1 1 0 0 1 1.414-1.414z">
                          </path>
                        </svg>
                      </div>
                      <div class="flex flex-column left-details">
                        <div>{{cancel_status(indx)}}
                          | <a href="" target="_blank">#{{product.order.key}}</a>
                          | <a routerLink="{{'/account/order/details/'+product.key}}"
                            [queryParams]="{okey: product.order.key}" target="_blank">View Details</a>
                        </div>
                        <span>On {{product.create_date | date:'E, dd MMM YYYY'}} as per your request</span>
                        <!-- <span>On Mon, 27 Jun as per your request</span> -->
                      </div>
                      <!-- <button class="cancel-order" (click)="show_cancel(indx)">CANCEL ITEM</button> -->
                    </div>

                    <div class="flex allign-middle stat-left-item placed" *ngIf="product.status=='P'">
                      <div class="flex position-center stat-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="32" d="M416 128L192 384l-96-96" />
                        </svg>
                      </div>
                      <div class="flex flex-column left-details">
                        <div>Order Placed
                          | <a href="" target="_blank">#{{product.order.key}}</a>
                          | <a routerLink="{{'/account/order/details/'+product.key}}"
                            [queryParams]="{okey: product.order.key}" target="_blank">View Details</a>
                        </div>
                        <span>On {{product.create_date | date:'E, dd MMM YYYY'}}</span>
                      </div>
                      <button class="cancel-order" (click)="show_cancel(indx)">CANCEL ITEM</button>
                    </div>

                    <div class="flex allign-middle stat-left-item processing " *ngIf="product.status=='PKD'">
                      <div class="flex position-center stat-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
                          <title>Checkmark Done</title>
                          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="32" d="M464 128L240 384l-96-96M144 384l-96-96M368 128L232 284" />
                        </svg>
                      </div>
                      <div class="flex flex-column left-details">
                        <div>Under Processing
                          | <a href="" target="_blank">#{{product.order.key}}</a>
                          | <a routerLink="{{'/account/order/details/'+product.key}}"
                            [queryParams]="{okey: product.order.key}" target="_blank">View Details</a>
                        </div>
                        <span>On {{product.create_date | date:'E, dd MMM YYYY'}} as per your request.</span>
                      </div>
                      <button class="cancel-order" (click)="show_cancel(indx)">CANCEL ITEM</button>
                    </div>

                    <div class="flex allign-middle stat-left-item despatched " *ngIf="product.status=='DSP'">
                      <div class="flex position-center stat-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
                          <title>Checkmark Done</title>
                          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="32" d="M464 128L240 384l-96-96M144 384l-96-96M368 128L232 284" />
                        </svg>
                      </div>
                      <div class="flex flex-column left-details">
                        <div>Despatched
                          | <a href="" target="_blank">#{{product.order.key}}</a>
                          | <a routerLink="{{'/account/order/details/'+product.key}}"
                            [queryParams]="{okey: product.order.key}" target="_blank">View Details</a>
                        </div>
                        <span>Through DTDC on {{product.create_date | date:'E, dd MMM YYYY'}}</span>
                      </div>
                      <button class="track-order">TRACK</button>
                    </div>

                    <div class="flex allign-middle stat-left-item despatched " *ngIf="product.status=='CMPLT'">
                      <div class="flex position-center stat-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
                          <title>Checkmark Done</title>
                          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="32" d="M464 128L240 384l-96-96M144 384l-96-96M368 128L232 284" />
                        </svg>
                      </div>
                      <div class="flex flex-column left-details">
                        <div>Delivered
                          | <a href="" target="_blank">#{{product.order.key}}</a>
                          | <a routerLink="{{'/account/order/details/'+product.key}}"
                            [queryParams]="{okey: product.order.key}" target="_blank">View Details</a>
                        </div>
                        <span>Through DTDC on {{product.create_date | date:'E, dd MMM YYYY'}}</span>
                      </div>
                      <button class="cancel-order" *ngIf="product.return_applicable=='Y' "
                        (click)="show_return(indx)">RETURN</button>
                      <button class="track-order" *ngIf="product.return_applicable=='N' ">COMPLETE</button>
                    </div>

                  </div>
                </div>

                <div class="flex w-100 order-item-container">
                  <div class="flex position-center order-item-left">
                    <img src="{{product.images[0].sm}}" alt="{{product.images[0].sm}}">
                  </div>
                  <div class="flex flex-column order-item-right">
                    <div class="item-name">{{product.name}}</div>
                    <div class="flex allign-middle item-size-Qty">
                      <a class="flex allign-middle item-size" *ngIf="product.is_variant_type== 'Y' ">
                        <span class="">Color: {{product.color}}</span>
                      </a>
                      <a class="flex allign-middle item-size">
                        <span class="">Size: {{product.size}}</span>
                      </a>
                      <a class="flex allign-middle item-quantity">
                        <span class="">Qty: {{product.qnty}}</span>
                      </a>
                    </div>
                    <div class="flex allign-middle item-price">
                      <div class="new-price">₹{{product.price_payable*product.qnty}}</div>
                      <div class="old-price">₹{{product.price*product.qnty}}</div>
                      <div class="flex discount-price">({{product.discount}}% OFF)</div>
                    </div>
                    <a class="flex allign-middle price-break" (click)="show_price_breakup(indx)">View breakup</a>
                  </div>
                </div>
              </div>
            </ng-container>

            <div class="flex flex-column order-item" *ngIf="0">
              <div class="flex w-100 allign-middle justidfy-content-between order-stat">
                <div class="flex allign-middle stat-left">
                  <div class="flex allign-middle stat-left-item cancelled hide">
                    <div class="flex position-center stat-icon">
                      <svg viewBox="0 0 24 24">
                        <path fill="currentColor"
                          d="m12 10.586 6.293-6.293a1 1 0 0 1 1.414 1.414l-6.293 6.293 6.293 6.293a1 1 0 0 1 -1.414 1.414l-6.293-6.293-6.293 6.293a1 1 0 1 1 -1.414-1.414l6.293-6.293-6.293-6.293a1 1 0 0 1 1.414-1.414z">
                        </path>
                      </svg>
                    </div>
                    <div class="flex flex-column left-details">
                      <b>Cancelled</b>
                      <span>On Mon, 27 Jun as per your request</span>
                    </div>
                    <button class="cancel-order">CANCEL ITEM</button>
                  </div>
                  <div class="flex allign-middle stat-left-item placed hide">
                    <div class="flex position-center stat-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="32" d="M416 128L192 384l-96-96" />
                      </svg>
                    </div>
                    <div class="flex flex-column left-details">
                      <b>Order Placed</b>
                      <span>On Mon, 27 Jun</span>
                    </div>
                    <button class="cancel-order">CANCEL ITEM</button>
                  </div>
                  <div class="flex allign-middle stat-left-item processing hide">
                    <div class="flex position-center stat-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
                        <title>Checkmark Done</title>
                        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="32" d="M464 128L240 384l-96-96M144 384l-96-96M368 128L232 284" />
                      </svg>
                    </div>
                    <div class="flex flex-column left-details">
                      <b>Under Processing</b>
                      <span>On Mon, 27 Jun as per your request.</span>
                    </div>
                    <button class="cancel-order">CANCEL ITEM</button>
                  </div>
                  <div class="flex allign-middle stat-left-item despatched">
                    <div class="flex position-center stat-icon">
                      <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
                        <title>Checkmark Done</title>
                        <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                          stroke-width="32" d="M464 128L240 384l-96-96M144 384l-96-96M368 128L232 284" />
                      </svg>
                    </div>
                    <div class="flex flex-column left-details">
                      <b>Despatched</b>
                      <span>Through DTDC on Mon, 27 Jun</span>
                    </div>
                    <button class="track-order">TRACK</button>
                  </div>
                </div>
              </div>
              <div class="flex w-100 order-item-container">

                <div class="flex position-center order-item-left">
                  <img src="{{product.images[0].sm}}" alt="">
                </div>
                <div class="flex flex-column order-item-right">
                  <div class="item-name">Men Beige & Black Typography Printed Sustainable T-shirt</div>
                  <div class="flex allign-middle item-size-Qty">
                    <a class="flex allign-middle item-size">
                      <span class="">Size: 32C</span>
                    </a>
                    <a class="flex allign-middle item-quantity">
                      <span class="">Qty: 1</span>
                    </a>
                  </div>
                  <div class="flex allign-middle item-price">
                    <div class="new-price">₹599</div>
                    <div class="old-price">₹1099</div>
                    <div class="flex discount-price">({{product.discount}})</div>
                  </div>
                  <a class="flex allign-middle price-break">View breakup</a>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

  </div>
</div>

<div class="flex position-center remove-order-item-dialog" *ngIf="show_cancel_popup">
  <div class="remove-order-item-backdrp"></div>
  <div class="flex flex-column remove-box">
    <div class="flex w-100 remove-box-top">
      <div class="flex allign-middle image">
        <img src="{{product.images[0].sm}}" alt="{{product.name}}">
      </div>
      <div class="flex flex-column details">
        <div class="head1">Cancel Order Item
          <div class="flex position-center dialog-close" (click)="hide_cancel()">
            <svg viewBox="0 0 24 24">
              <path fill="currentColor"
                d="m12 10.586 6.293-6.293a1 1 0 0 1 1.414 1.414l-6.293 6.293 6.293 6.293a1 1 0 0 1 -1.414 1.414l-6.293-6.293-6.293 6.293a1 1 0 1 1 -1.414-1.414l6.293-6.293-6.293-6.293a1 1 0 0 1 1.414-1.414z">
              </path>
            </svg>
          </div>
        </div>
        <div class="txt">Are you sure you want to cancel this item?</div>
      </div>
    </div>
    <div class="flex position-center justify-content-between remove-item-bottom">
      <div class="flex position-center remove"><a (click)="hide_cancel()">NO</a></div>
      <div class="flex position-center wish">
        <a routerLink="{{'/account/orders/cancel/'+product.key}}" [queryParams]="{okey: product.order.key}">YES</a>
      </div>
    </div>
  </div>
</div>

<div class="flex position-center remove-order-item-dialog" *ngIf="show_return_popup">
  <div class="remove-order-item-backdrp"></div>
  <div class="flex flex-column remove-box">
    <div class="flex w-100 remove-box-top">
      <div class="flex allign-middle image">
        <img src="{{product.images[0].sm}}" alt="{{product.name}}">
      </div>
      <div class="flex flex-column details">
        <div class="head1">Cancel Order Item
          <div class="flex position-center dialog-close" (click)="hide_cancel()">
            <svg viewBox="0 0 24 24">
              <path fill="currentColor"
                d="m12 10.586 6.293-6.293a1 1 0 0 1 1.414 1.414l-6.293 6.293 6.293 6.293a1 1 0 0 1 -1.414 1.414l-6.293-6.293-6.293 6.293a1 1 0 1 1 -1.414-1.414l6.293-6.293-6.293-6.293a1 1 0 0 1 1.414-1.414z">
              </path>
            </svg>
          </div>
        </div>
        <div class="txt">Are you sure you want to return this item?</div>
      </div>
    </div>
    <div class="flex position-center justify-content-between remove-item-bottom">
      <div class="flex position-center remove"><a (click)="hide_cancel()">NO</a></div>
      <div class="flex position-center wish">
        <a routerLink="{{'/account/orders/return/'+product.key}}" [queryParams]="{okey: product.order.key}">YES</a>
      </div>
    </div>
  </div>
</div>

<div class="flex position-center pricing-breakup-dialog" *ngIf="show_price_popup">
  <div class="dialog-backdrp"></div>
  <div class="flex flex-column pricing-breakup-box">
    <div class="flex allign-middle heading">
      Payment Information
      <div class="flex position-center dialog-close" (click)="hide_price_breakup()">
        <svg viewBox="0 0 24 24">
          <path fill="currentColor"
            d="m12 10.586 6.293-6.293a1 1 0 0 1 1.414 1.414l-6.293 6.293 6.293 6.293a1 1 0 0 1 -1.414 1.414l-6.293-6.293-6.293 6.293a1 1 0 1 1 -1.414-1.414l6.293-6.293-6.293-6.293a1 1 0 0 1 1.414-1.414z">
          </path>
        </svg>
      </div>
    </div>
    <div class="flex flex-column cart-price">
      <div class="flex justify-content-between price-breakup">
        <span>Total MRP</span>
        <span>₹{{product.price*product.qnty}}</span>
      </div>
      <div class="flex justify-content-between price-breakup">
        <span>Discount on MRP</span>
        <span class="green">-₹{{(product.price*product.qnty) - (product.price_payable*product.qnty)}}</span>
      </div>
      <div class="flex justify-content-between price-breakup hide">
        <span>Coupon Discount</span>
        <span class="green">-₹252</span>
      </div>
      <div class="flex justify-content-between price-breakup">
        <span>Delivery Fee</span>
        <span><span style="text-decoration: line-through;">₹99 </span><span class="green">FREE</span></span>
      </div>
    </div>
    <div class="flex allign-middle justify-content-between total-cart-amount">
      <span>Total Amount</span>
      <span>₹{{(product.price_payable*product.qnty)}}</span>
    </div>
    <div class="flex w-100 position-center payment-medium">
      <div class="flex w-100 position-center pay-box cod " *ngIf="product.status!=='CMPLT' && (product.order.paidby=='COD') ">
        <svg viewBox="0 0 752 752" xmlns="http://www.w3.org/2000/svg">
          <path fill="currentColor"
            d="m394.95 335.75h-17.996c-0.94531-3.3164-2.3672-6.6289-4.7344-9.4727l22.73 0.003906c3.7891 0 7.1055-3.3164 7.1055-7.1055s-3.3164-7.1055-7.1055-7.1055h-85.246c-3.7891 0-7.1055 3.3164-7.1055 7.1055s3.3164 7.1055 7.1055 7.1055h27.469c14.68 0 21.312 3.7891 24.625 9.4727l-52.094-0.003906c-3.7891 0-7.1055 3.3164-7.1055 7.1055 0 3.7891 3.3164 7.1055 7.1055 7.1055h54.461c-0.47266 9.9453-4.7344 18.941-26.992 18.941h-27.469c-2.8398 0-5.6836 1.8945-6.6289 4.2617-0.94531 2.8398-0.47266 5.6836 1.4219 7.5781l56.828 56.828c1.4219 1.4219 3.3164 1.8945 5.2109 1.8945s3.7891-0.47266 5.2109-1.8945c2.8398-2.8398 2.8398-7.1055 0-9.9453l-44.516-44.516h9.9453c34.098 0 40.727-19.891 41.203-33.152h16.57c3.7891 0 7.1055-3.3164 7.1055-7.1055-0.003906-3.7852-3.3164-7.1016-7.1055-7.1016z" />
          <path fill="currentColor"
            d="m582.01 533.23h-364.66c-3.7891 0-7.1055-3.3164-7.1055-7.1055s3.3164-7.1055 7.1055-7.1055l357.55 0.003907v-198.9c0-3.7891 3.3164-7.1055 7.1055-7.1055 3.7891 0 7.1055 3.3164 7.1055 7.1055v206.01c-0.003907 4.2617-3.3203 7.1016-7.1094 7.1016z" />
          <path fill="currentColor"
            d="m558.33 509.55h-364.66c-3.7891 0-7.1055-3.3164-7.1055-7.1055 0-3.7891 3.3164-7.1055 7.1055-7.1055l357.55 0.003906v-198.9c0-3.7891 3.3164-7.1055 7.1055-7.1055 3.7891 0 7.1055 3.3164 7.1055 7.1055v206.01c-0.003906 4.2617-3.3203 7.1016-7.1055 7.1016z" />
          <path fill="currentColor"
            d="m534.65 485.87h-364.66c-3.7891 0-7.1055-3.3164-7.1055-7.1055v-206c0-3.7891 3.3164-7.1055 7.1055-7.1055h364.66c3.7891 0 7.1055 3.3164 7.1055 7.1055v206.01c-0.003906 4.2617-3.3164 7.1016-7.1055 7.1016zm-357.55-14.207h350.45v-191.32h-350.45z" />
          <path fill="currentColor"
            d="m255.24 376c0-28.887 12.312-54.461 32.203-71.984h-79.562c-3.7891 0-7.1055 3.3164-7.1055 7.1055v130.23c0 3.7891 3.3164 7.1055 7.1055 7.1055h79.562c-19.891-17.996-32.203-43.57-32.203-72.461z" />
          <path fill="currentColor"
            d="m496.77 304.02h-79.562c19.891 17.996 32.203 43.57 32.203 71.984s-12.312 54.461-32.203 71.984h79.562c3.7891 0 7.1055-3.3164 7.1055-7.1055l-0.003906-130.23c0-3.7891-3.3125-6.6328-7.1016-6.6328z" />
        </svg>
        <span>Pay on delivery</span>
      </div>
      <div class="flex w-100 position-center pay-box card hide">
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
          <title>Card</title>
          <rect x="48" y="96" width="416" height="320" rx="56" ry="56" fill="none" stroke="currentColor"
            stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
          <path fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="60"
            d="M48 192h416M128 300h48v20h-48z" />
        </svg>
        <span>VISA - 4569</span>
      </div>
      <div class="flex w-100 position-center pay-box upi hide">
        <svg viewBox="0 0 120 60" fill-rule="evenodd">
          <path d="M95.678 42.9L110 29.835l-6.784-13.516z" fill="#097939" />
          <path d="M90.854 42.9l14.322-13.065-6.784-13.516z" fill="#ed752e" />
          <path
            d="M22.41 16.47l-6.03 21.475 21.407.15 5.88-21.625h5.427l-7.05 25.14c-.27.96-1.298 1.74-2.295 1.74H12.31c-1.664 0-2.65-1.3-2.2-2.9l6.724-23.98zm66.182-.15h5.427l-7.538 27.03h-5.58zM49.698 27.582l27.136-.15 1.81-5.707H51.054l1.658-5.256 29.4-.27c1.83-.017 2.92 1.4 2.438 3.167L81.78 29.49c-.483 1.766-2.36 3.197-4.19 3.197H53.316L50.454 43.8h-5.28z"
            fill="#747474" />
        </svg>
        <span>d**********a@okaxis</span>
      </div>
    </div>
  </div>
</div>
