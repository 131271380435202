<div class="flex flex-column position-center password-sent password3">

  <div class="registration-email " *ngIf="auth.lsv_action_type=='registration-email' ">
    <div class="flex allign-middle ls-heading">Hi There!</div>
    <!-- register -->
    <div class="password-sent-txt">
      You have been successfully registered.
      A verify email link has been sent to <span>{{auth.customer.email}}</span>
    </div>
    <!-- <button class="flex position-center ls-submit">Continue</button> -->
  </div>

  <!-- reset-password -->
  <div class="pass-reset hide">
    <div class="password-sent-txt hide">You have been successfully registered.
      Please check your inbox for Reset-Password link at <span>{{auth.customer.email}}</span>
    </div>
    <button class="flex position-center ls-submit hide">Continue</button>
  </div>

  <!-- reset-password-expired -->
  <div class="pass-expired">
    <div class="flex column password-sent-txt">
      <span>Token for password reset is expired.</span>
      <span class="hide">Didn't received varification mail?
        Click <a style="color: rgb(49, 130, 236);">HERE</a> to resend</span>
    </div>
    <button class="flex position-center ls-submit hide">Continue</button>
  </div>

  <!-- reset-password-invalid -->
  <div class="pass-invalid hide">
    <div class="flex column password-sent-txt">
      <span>Forgot password invalid link.</span>
      <a style="color: rgb(49, 130, 236);margin-top: 40px;">Go to Signin</a>
    </div>
  </div>


</div>
