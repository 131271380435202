import { Component, ViewEncapsulation, OnInit, PLATFORM_ID, Inject, ViewChild } from '@angular/core';
import { NgbModalConfig, NgbModal, NgbActiveModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

import { SwiperComponent } from "swiper/angular";
import SwiperCore, { FreeMode, SwiperOptions, Zoom, Navigation, Pagination, Lazy, Autoplay, Thumbs, Swiper, Virtual } from 'swiper';
SwiperCore.use([FreeMode, Zoom, Navigation, Pagination, Lazy, Autoplay, Thumbs, Virtual]);

//import { GalleryComponent } from '../../components/gallery/gallery.component';
import { SizeChartComponent } from '../../components/size-chart/size-chart.component';

import { ReviewPopupComponent } from '../../components/review-popup/review-popup.component';
import { WebModule } from 'src/app/modules/web/web.module';
import { HttpClient } from '@angular/common/http';
import { GlobalsService } from 'src/app/services/globals.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToastrService } from 'ngx-toastr';
import { isPlatformBrowser } from '@angular/common';
import { Observable, combineLatest } from 'rxjs/index';




@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbModalConfig, NgbModal]
})
export class ProductComponent implements OnInit {
  thumbsSwiper: any;
  url_slug = '';
  product_indx = '';
  product_vkey = '';
  product_details = [] as any;
  product_images = [] as any;
  show_loader = true;
  public search_results = [] as any;

  init_page_data = false;
  url_params = {} as any;
  url_query_params = {} as any;

  order = {
    name: '',
    slug: '',
    pkey: '',
    pindx: '',
    vkey: '',
    color: '',
    size: '',
    image: '',
    price_unit: '',
    price_payable: 0,
    price: 0,
    qnty: 1,
  } as any;
  cart_count = 0;
  coupons = [] as any;

  popup_details = {
    title: '',
    data: '',
  };

  latest_products = [] as any;
  related_products = [] as any;

  zipcode = '';
  zipcode_http_state = false;
  errors = {} as any;

  public webModule: WebModule = {} as any;

  toggle: boolean = true;
  galleryToggle: boolean = true;
  initialSlide = 0;

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  constructor(
    private modalService: NgbModal,
    private offcanvasService: NgbOffcanvas,
    private httpClient: HttpClient,
    public globals: GlobalsService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public meta: Meta,
    public titleService: Title,
    public toastr: ToastrService,
    public auth: AuthenticationService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  offerOpen() {
    this.toggle = !this.toggle;
  }

  toggleGallery() {
    this.galleryToggle = !this.galleryToggle;
  }

  openSizeChart() {
    const modalRe = this.modalService.open(SizeChartComponent, { centered: true, modalDialogClass: 'size-chart-modal' });
    modalRe.componentInstance.name = 'sizeChart';
  }

  openReviewPopup() {
    const modalRe = this.modalService.open(ReviewPopupComponent, { centered: true, modalDialogClass: 'review-modal' });
    modalRe.componentInstance.name = 'reviewPopup';
  }

  ngOnInit(): void {
    this.globals.cart_count_observer.subscribe(count => this.cart_count = count);
    this.globals.page_loader_observer.subscribe(state => this.show_loader = state);
    this.globals.web_observer.subscribe(web => this.webModule = web);
    this.globals.header_search_results_observer.subscribe((results) => {
      this.search_results = results;
      //console.log([this.search_results, Object.entries(this.search_results).length]);
    });

    this.offcanvasService.dismiss('close');
  }

  async ngAfterViewInit() {
    this.globals.set_page_loader(true);

    // viewChild is set after the view has been initialized
    //console.log('AfterViewInit');
    this.url_params = await this.activeRoute.params;
    this.url_query_params = await this.activeRoute.queryParams;

    // 2. Call combineLatest operator, inject multiple observables in array
    const joinStream = combineLatest([this.url_params, this.url_query_params]);


    // 3. Subscibe combineLatest observable
    const subscribe = await joinStream.subscribe(async ([params, queryParams]) => {
      //this.product_vkey = queryParams['vkey'];
      await this.extract_params(params, queryParams);

      /**
      * close the search bar if it's open. set it to falses
      */
      this.globals.set_suggestion_bar_state(false);
      this.globals.set_search_text('');

      this.search_results.show_results = false;
      this.globals.set_suggestion_bar_state(this.search_results);
      this.offcanvasService.dismiss('close');

      console.log({ params: params, queryParams: typeof queryParams });
    });


    //console.log('has_params_change', has_params_change);

  }

  ngAfterContentInit() {
    this.get_coupons_data();
    console.log('ngContentAfterInit');
  }

  async extract_params(params: any, queryParams: any) {
    this.url_slug = await params['slug'];
    let temp = this.url_slug.split('-');
    let indx = temp[temp.length - 1];

    this.product_indx = indx;
    this.product_vkey = await queryParams['vkey'];

    await this.init_page();

    console.log(params, queryParams);
  }

  init_page() {
    this.get_product_details();
    this.get_latest_products();
    this.get_related_products();

    setTimeout(() => {
      this.topScroll();
    }, 1 * 1000);
  }

  async get_product_details() {
    const postData = {
      action: 'products/details',
      slug: this.url_slug,
      indx: this.product_indx,
      vkey: this.product_vkey,
      customer_key: this.globals.get_customer_key(),
    }; //new FormData();
    this.globals.set_page_loader(true);

    // postData.append('action', 'products/details');
    // postData.append('slug', this.url_slug);
    // postData.append('indx', this.product_indx);
    // postData.append('vkey', this.product_vkey);
    // postData.append(this.globals.customer_key, this.globals.get_customer_key());


    const result = await this.httpClient.post<any>(
      this.globals.get_endpoint() + `/products/indx/${this.product_indx}/slug/${this.url_slug}`,
      postData
    ).subscribe((response) => {
      //console.log(response);
      if (response.msg == 'success') {
        this.product_details = response.data;
        this.get_images();

        //--meta data--
        this.titleService.setTitle(this.product_details.name + ' - Orange');
        this.meta.addTag({ 'title': this.product_details.name });
        this.meta.addTag({ 'description': this.product_details.seo_desc });
        this.meta.addTag({ 'keywords': this.product_details.seo_desc });

        //console.log([this.product_details, (this.product_details.price_unit == 'P')]);
        if (this.product_details.price_unit == 'A') {
          this.order.qnty = '1.0';
        } else {
          this.order.qnty = '1';
        }

        if (this.product_details.variant.color != undefined && this.product_details.variant.color.length > 0) {
          this.order.color = this.product_details.variant.color;
          this.order.price = this.product_details.price;
          this.order.price_payable = this.product_details.price_payable;
        }

        setTimeout(() => {
          this.globals.set_page_loader(false);
          console.log('setTimeout');
        }, 0.5 * 1000);
      }

      this.init_page_data = false;
    });
  }

  async get_latest_products() {
    const postData = {
      action: 'products/latest',
      results_per_page: 7,
      slug: this.url_slug,
      indx: this.product_indx,
      vkey: this.product_vkey,
      customer_key: this.globals.get_customer_key(),
    }; //new FormData();

    // postData.append('action', 'products/latest');
    // postData.append('results_per_page', '7');
    // postData.append('slug', this.url_slug);
    // postData.append('indx', this.product_indx);
    // postData.append('vkey', this.product_vkey);

    const result = await this.httpClient.post<any>(this.globals.get_endpoint() + '/products/latest', postData).subscribe((response) => {
      //console.log(response);
      if (response.msg == 'success') {
        this.latest_products = response.data.products;
      }

      this.init_page_data = false;
    });
  }

  async get_related_products() {
    const postData = {
      action: 'products/related',
      results_per_page: 7,
      slug: this.url_slug,
      indx: this.product_indx,
      vkey: this.product_vkey,
      customer_key: this.globals.get_customer_key(),
    }; //new FormData();

    // postData.append('action', 'products/related');
    // postData.append('results_per_page', '7');
    // postData.append('slug', this.url_slug);
    // postData.append('indx', this.product_indx);
    // postData.append('vkey', this.product_vkey);

    const result = await this.httpClient.post<any>(this.globals.get_endpoint() + '/products/related', postData).subscribe((response) => {
      //console.log(response);
      if (response.msg == 'success') {
        this.related_products = (response.data.products).slice(1, 5);
      }

      this.init_page_data = false;
    });

  }


  get_data() {
    const postData = {
      action: 'search',
      results_per_page: 7,
      slug: this.url_slug,
      indx: this.product_indx,
      vkey: this.product_vkey,
      customer_key: this.globals.get_customer_key(),
    }; //new FormData();

    this.globals.set_page_loader(true);

    const result = this.httpClient.post<any>(this.globals.get_endpoint() + '/search', postData).subscribe((response) => {
      //console.log(response);
      if (response.msg == 'success') {
        this.product_details = response.data;
        setTimeout(() => {
          this.globals.set_page_loader(false);
          console.log('setTimeout');
        }, 2 * 1000);
      }
    });
  }

  // When the user clicks on the button, scroll to the top of the document
  topScroll() {
    if (isPlatformBrowser(this.platformId)) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  }

  get_images(): any {
    this.product_images = this.product_details.images;

    //console.log('variant.length', this.product_details.variant);

    if (this.product_details.is_variant == 'Y')
      if (this.product_details.variant != undefined) {
        for (let kk = 0; kk < Object.entries(this.product_details.color_variants).length; kk++) {
          //console.log('variant.color', this.product_details.color_variants[kk].color, (this.product_details.variant.color == this.product_details.color_variants[kk].color));
          if (this.product_details.variant.color == this.product_details.color_variants[kk].color) {
            this.product_images = this.product_details.color_variants[kk].images;
            console.log(this.product_images);
          }
        }
      }

    //console.log(this.product_images);
  }

  on_size_change(value: string) {
    this.order.size = value;
    console.log(this.order);
  }

  on_color_change(value: string) {
    this.order.color = value;

    for (let kk = 0; kk < Object.entries(this.product_details.color_variants).length; kk++) {
      if (this.order.color == this.product_details.color_variants[kk].color) {
        this.product_images = this.product_details.color_variants[kk].images;
        this.order.vkey = this.product_details.color_variants[kk].vindx;
        this.order.image = this.product_images[0];
        //console.log(this.product_images);
      }
    }

    this.router.navigate(
      [`/product/${this.product_details.slug}-${this.product_details.indx}`],
      {
        queryParams: { vkey: this.order.vkey, color: value },
      }
    );

    //console.log(this.order);
  }

  async add_to_cart(type: string): Promise<any> {
    const customer_key = await this.globals.get_customer_key();

    this.order.name = this.product_details.name;
    this.order.slug = this.product_details.slug;
    this.order.pindx = this.product_details.price;
    this.order.price = this.product_details.price;
    this.order.price_payable = this.product_details.price_payable;
    this.order.image = this.product_images[0];

    if (this.product_details.is_variant == 'Y' && this.order.color.length == 0) {
      //alert('Please select a colour!');
      this.toastr.clear();
      this.toastr.warning('Please select a colour!', '', { positionClass: 'toast-bottom-center' });
      return false;
    }

    if (this.order.size.length == 0) {
      //alert('Please select a size!');
      this.toastr.clear();
      this.toastr.warning('Please select a size!', '', { positionClass: 'toast-bottom-center' });
      return false;
    }

    this.order.pkey = this.product_details.key;
    this.order.pindx = this.product_details.indx;

    if (this.product_vkey != undefined && this.product_vkey.length > 0) {
      this.order.vkey = this.product_vkey;
    } else {
      this.order.vkey = this.product_details.variant.key;
    }

    if (customer_key != null && customer_key.length > 0) { // for logged in user
      const result = (await this.globals.add_to_cart(this.order)).subscribe((response) => {
        if (response.msg == 'success') {
          let count = this.cart_count + 1;
          this.globals.cart_update_count();

          if (type == 'buy_now') {
            this.router.navigate(['/checkout-address']);
          }

          if (type == 'add_to_cart') {
            this.globals.open_side_cart();
            //this.router.navigate(['/cart']);
          }

          this.toastr.clear();
          this.toastr.success(response.data, '', { positionClass: 'toast-bottom-center' });

        } else {
          this.toastr.clear();
          this.toastr.error(response.data, '', { positionClass: 'toast-bottom-center' });

          switch (response.code.toString()) {
            case '4': {
              this.router.navigate([this.get_redirect_url(type)]);
              break;
            }
          }
        }
      });
    } else { // for not logged in user
      if (isPlatformBrowser(this.platformId)) {
        let localCart = [] as any;
        let found = false;
        this.toastr.clear();

        localCart = localStorage.getItem('cart');
        localCart = (localCart != null) ? JSON.parse(localCart) : [];
        //localCart.push(this.order);

        for (let kk = 0; kk < localCart.length; kk++) {
          if (localCart[kk].key == this.order.key && localCart[kk].vkey == this.order.vkey && localCart[kk].size == this.order.size) {
            found = true;
            //localCart[kk].qnty = parseInt(localCart[kk].qnty) + 1;
            this.toastr.warning('Item already added in cart', '', { positionClass: 'toast-bottom-center' });
          }
        }

        if (!found) {
          localCart.push(this.order);
          //this.toastr.success('Item added in cart', '', { positionClass: 'toast-bottom-center' });
        }

        localStorage.setItem('cart', JSON.stringify(localCart));
        this.globals.cart_update_count();
        await this.save_in_cart();

        //this.router.navigate(['/cart']);
        this.globals.open_side_cart();
      }
    }
  }

  async save_in_cart() {
    if (isPlatformBrowser(this.platformId)) {
      const postData = new FormData();

      postData.append('action', 'cart/save');
      postData.append('product_key', this.order.pkey);
      postData.append('vkey', this.order.vkey);
      postData.append('color', this.order.color);
      postData.append('size', this.order.size);
      postData.append('qnty', this.order.qnty);

      const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/cart/save`, postData)
        .subscribe((response) => {
          if (response.msg == 'success') {
            console.log('Item saved');
          }
        });
    }
  }

  async add_to_cart2(data: any): Promise<any> {
    let cart = {
      pkey: data.key,
      vkey: data.vkey,
      color: data.color,
      size: data.size,
      qnty: data.qnty,
    } as any;

    const result = (await this.globals.add_to_cart(cart)).subscribe((response) => {
      if (response.msg == 'success') {
        let count = this.cart_count + 1;
        this.globals.set_cart_count(count);

        this.toastr.clear();
        this.toastr.success(response.data, '', { positionClass: 'toast-bottom-center' });

        this.router.navigate([this.get_redirect_url('add_to_cart')]);
      } else {
        this.toastr.clear();
        this.toastr.error(response.data, '', { positionClass: 'toast-bottom-center' });

        switch (response.code.toString()) {
          case '4': {
            this.router.navigate([this.get_redirect_url('add_to_cart')]);
            break;
          }
        }

      }
    });

  }

  async add_to_wishlist(): Promise<any> {
    let customer_key = await this.globals.get_customer_key();
    let wishlist = {
      pkey: this.product_details.key,
      vkey: this.product_vkey,
      color: this.order.color,
      size: this.order.size,
      qnty: 1,
    } as any;

    if (customer_key == null || customer_key.length == 0) {
      this.auth.openLSV('common');
      return false;
    }

    if (this.product_details.is_variant == 'Y' && this.order.color.length == 0) {
      //alert('Please select a colour!');
      this.toastr.clear();
      this.toastr.warning('Please select a colour!', '', { positionClass: 'toast-bottom-center' });
      return false;
    }

    if (this.order.size.length == 0) {
      //alert('Please select a size!');
      this.toastr.clear();
      //this.toastr.warning('Please select a size!', '', { positionClass: 'toast-bottom-center' });
      //return false;
    }

    const result = (await this.globals.add_to_wishlist(wishlist)).subscribe((response) => {
      if (response.msg == 'success') {
        let count = this.cart_count + 1;
        this.globals.set_cart_count(count);

        this.toastr.clear();
        this.toastr.success(response.data, '', { positionClass: 'toast-bottom-center' });

        this.router.navigate(['/account/wishlist']);
      } else {
        this.toastr.clear();
        this.toastr.error(response.data, '', { positionClass: 'toast-bottom-center' });

        switch (response.code.toString()) {
          case '3': {
            this.auth.openLSV('common');
            break;
          }
          case '4': {
            this.router.navigate([this.get_redirect_url('add_to_cart')]);
            break;
          }
        }

      }
    });

  }

  async wishlist_remove() {
    let product = this.product_details;
    let customer_key = await this.globals.get_customer_key();
    this.globals.set_page_loader(true);

    if (customer_key !== null && customer_key.length > 0) { // if logged in
      let postData = new FormData();

      postData.append('action', 'cart/remove');
      postData.append('key', product.key);

      const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/cart/${atob(customer_key)}/remove/${product.key}`, postData).subscribe((response) => {
        if (response.msg == 'success') {
          //console.log(temp);
          this.toastr.clear();
          this.toastr.success(response.data, '', { positionClass: 'toast-bottom-center' });
          this.globals.set_page_loader(false);
        } else {
          this.globals.set_page_loader(false);
        }
      });
    }
  }

  get_redirect_url(type: string): string {
    switch (type) {
      case 'buy_now': { return '/checkout-address'; }
      case 'add_to_cart': { return '/checkout/cart'; }
    }

    return '';
  }

  openProductGallery(indx: number) {
    this.initialSlide = indx;
    this.swiper?.swiperRef.slideTo(indx + 1);
    console.log(indx);
  }

  openReviewPhoto() {
    //const modalRe = this.modalService.open(ReviewPhotoPopupComponent, { centered: true });
    //modalRe.componentInstance.name = 'RWorld';
  }

  async check_pincode(): Promise<any> {
    if (this.zipcode.length == 0) {
      this.errors.zipcode = "Please enter a Pincode";
      this.toastr.clear();
      this.toastr.warning(this.errors.zipcode, '', { positionClass: 'toast-bottom-center' });
      return false;
    }

    if (this.zipcode.length > 0 && (this.zipcode.length.toString() !== '6')) {
      this.errors.zipcode = "Please enter a valid Pincode";
      this.toastr.clear();
      this.toastr.warning(this.errors.zipcode, '', { positionClass: 'toast-bottom-center' });
      return false;
    }

    let postData = {
      action: 'check/zipcode',
      zipcode: this.zipcode,
    };
    //this.globals.set_page_loader(true);
    this.zipcode_http_state = true;

    let results = await this.httpClient.post<any>(this.globals.get_endpoint() + `/check-zipcode`, postData)
      .subscribe((response) => {
        if (response.msg == 'success') {
          this.toastr.clear();
          this.toastr.success(`Delivery by ${response.data.delivery_date}`, '', { positionClass: 'toast-bottom-center' });

          this.product_details.expected_delivery = response.data.delivery_date;
          this.product_details.expected_delivery2 = response.data.delivery_date2;

          //this.globals.set_page_loader(false);
          this.zipcode_http_state = false;
        } else {
          this.toastr.clear();
          this.toastr.warning(response.data, '', { positionClass: 'toast-bottom-center' });

          //this.globals.set_page_loader(false);
          this.zipcode_http_state = false;
        }
      });
  }

  remove_error(name: string) {
    let err = this.errors;
    delete err[name];

    var preg = /^[0-9 ]+$/;
    var regx = /[^0-9]+/g;

    if (name == 'zipcode') {
      let zipcode = this.zipcode;

      if (this.zipcode.length > 0 && !preg.test(this.zipcode)) {
        err.zipcode = 'Invalid contact number';
        zipcode = zipcode.replace(regx, '');
        this.zipcode = zipcode;
        ////console.log([phone, this.address]);
      }
      if (zipcode.length > 6) {
        zipcode = zipcode.substring(0, 10);
        this.zipcode = zipcode;
      }
    }

    this.errors = err;
  }

  change_qnty(type: string) {
    switch (type) {
      case 'P': {
        this.order.qnty = parseInt(this.order.qnty) + 1;

        if (this.order.qnty > 10)
          this.order.qnty = 10;

        break;
      }
      case 'M': {
        this.order.qnty -= 1;

        if (this.order.qnty < 1)
          this.order.qnty = 1;

        break;
      }
    }
  }

  get_coupons_data() {
    const postData = {
      action: 'coupons/list',
      results_per_page: 7,
      customer_key: this.globals.get_customer_key(),
    }; //new FormData();
    this.globals.set_page_loader(true);

    const result = this.httpClient.post<any>(this.globals.get_endpoint() + '/coupons', postData).subscribe((response) => {
      //console.log(response);
      if (response.msg == 'success') {
        this.coupons = response.data;

        setTimeout(() => {
          this.globals.set_page_loader(false);
          console.log('setTimeout');
        }, 2 * 1000);
      }
    });
  }

  wishlist_class() {
    let className = '00';

    if (this.product_details.check_wishlist != undefined && this.product_details.check_wishlist == 'Y') {
      className = 'wishlisted';
    }

    return className;
  }

  soldout_class() {
    let className = '00';

    if (this.product_details.is_variant == 'N') {
      if (this.product_details.qnty == 0)
        className = 'sold-out';
    }

    if (this.product_details.is_variant == 'Y') {
      if (this.product_details.variant != undefined && this.product_details.variant.qnty != undefined) {
        if (this.product_details.variant.qnty == 0)
          className = 'sold-out';
      }
    }


    return className;
  }

  make_sizes_array(data: any) {
    if (data.options != undefined) {
      for (let kk = 0; kk < data.options.length; kk++) {
        if (data.options[kk].name == 'size') {
          //console.log(data.varients[kk].value);
          return (data.options[kk].value).slice(0, 5);
        }
      }
    }

    return [];
  }


}


