<div class="flex flex-column ">
  <div class="flex position-center ls-heading">Verify your Mobile number</div>
  <div class="flex flex-column allign-middle ls-input">
    <!-- this is for email signup auth.customer.mobile.length==0-->
    <div class="single-input" *ngIf="1">
      <input class="floating-input" type="text" name="mobile" [(ngModel)]="auth.customer.mobile"
        [style.border]=" (auth.errors.mobile != undefined) ? '1px solid red' :'' "
        (change)="auth.remove_error('mobile')"
        (keyup)="auth.remove_error('mobile');auth.check_enter_pressed('verify-google-mob', $event);" placeholder=""
        value="" [disabled]="(auth.signup_type=='mobile')" />
      <label class="floating-label">Mobile*</label>
      <span class="ls-warning error text-danger" *ngIf="auth.errors.mobile!==undefined"
        style="display: block !important;">{{auth.errors.mobile}}</span>
    </div>

    <button class="flex position-center ls-submit" (click)="auth.verify_google_mob()">Verify Mobile</button>
  </div>
  <div class="flex flex-column position-center forget-pass" *ngIf="0">
    <a (click)="auth.skip_personal_info()">Skip</a>
  </div>
</div>
