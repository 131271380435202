import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

/////// Layout ////////
import { LayoutHomeComponent } from './layouts/layout-home/layout-home.component';
import { LayoutInnerComponent } from './layouts/layout-inner/layout-inner.component';
import { LayoutOtherComponent } from './layouts/layout-other/layout-other.component';

/////// Pages ////////
import { HomeComponent } from './pages/home/home.component';
import { SearchResultComponent } from './pages/search-result/search-result.component';
import { ProductComponent } from './pages/product/product.component';
import { CartComponent } from './pages/cart/cart.component';
import { AccountProfileComponent } from './pages/account-profile/account-profile.component';
import { AccountOrdersComponent } from './pages/account-orders/account-orders.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { ReviewAllComponent } from './pages/review-all/review-all.component';
import { ReviewSingleComponent } from './pages/review-single/review-single.component';
import { TrackOrderComponent } from './pages/track-order/track-order.component';
import { ReturnExchangeComponent } from './pages/return-exchange/return-exchange.component';
import { OtherPrivacyPolicyComponent } from './pages/other-privacy-policy/other-privacy-policy.component';
import { OtherCookiePolicyComponent } from './pages/other-cookie-policy/other-cookie-policy.component';
import { OtherReturnPolicyComponent } from './pages/other-return-policy/other-return-policy.component';
import { CheckoutStatusComponent } from './pages/checkout-status/checkout-status.component';
//import { OtherRefundPolicyComponent } from './pages/other-refund-policy/other-refund-policy.component';
//import { OtherCancellationPolicyComponent } from './pages/other-cancellation-policy/other-cancellation-policy.component';
import { OtherShipppingPolicyComponent } from './pages/other-shippping-policy/other-shippping-policy.component';
import { OtherTermsComponent } from './pages/other-terms/other-terms.component';
import { OtherAboutusComponent } from './pages/other-aboutus/other-aboutus.component';
import { OtherFaqComponent } from './pages/other-faq/other-faq.component';
import { ReviewCreateComponent } from './components/review-create/review-create.component';
import { AccountOrderDetailsComponent } from './pages/account-order-details/account-order-details.component';
import { AccountWishlistComponent } from './pages/account-wishlist/account-wishlist.component';
import { AccountAddressesComponent } from './pages/account-addresses/account-addresses.component';
import { CheckoutAddressComponent } from './pages/checkout-address/checkout-address.component';
import { CheckoutSummaryComponent } from './pages/checkout-summary/checkout-summary.component';
import { WholesaleComponent } from './pages/wholesale/wholesale.component';
import { AccountOrderCancelComponent } from './pages/account-order-cancel/account-order-cancel.component';

import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
/////// Components ////////



const routes: Routes = [
  //{path: '', component: ComingSoonComponent},
  {
    path: '', component: LayoutHomeComponent, children: [
      { path: '', component: HomeComponent },
      //{ path: 'home', component: HomeComponent }
    ]
  },
  {
    path: '', component: LayoutInnerComponent, children: [
      { path: 'product/:slug', component: ProductComponent },
      { path: 'category/:category', component: SearchResultComponent },
      { path: 'category/:category/:subcategory', component: SearchResultComponent },
      { path: 'contact-us', component: ContactUsComponent },

      //-----------------
      { path: 'search', component: SearchResultComponent },
      { path: 'product', component: ProductComponent },
      { path: 'cart', component: CartComponent },
      { path: 'checkout/cart', component: CartComponent },
      { path: 'checkout/address', component: CheckoutAddressComponent },
      { path: 'checkout/payment', component: CheckoutSummaryComponent },
      { path: 'checkout/success/:okey', component: CheckoutStatusComponent },
      { path: 'checkout/error/:okey', component: CheckoutStatusComponent },
      // { path: 'checkout/error', component: CheckoutStatusComponent },

      { path: 'contact-us', component: ContactUsComponent },
      { path: 'privacy-policy', component: OtherPrivacyPolicyComponent },
      { path: 'about-us', component: OtherAboutusComponent },
      { path: 'cookie-policy', component: OtherCookiePolicyComponent },
      { path: 'order-payment-policy', component: OtherReturnPolicyComponent },
      { path: 'shipping-return-policy', component: OtherShipppingPolicyComponent },
      { path: 'terms-conditions', component: OtherTermsComponent },
      { path: 'faq', component: OtherFaqComponent },
      { path: 'product/reviews', component: ReviewAllComponent },
      { path: 'review', component: ReviewSingleComponent },
      { path: 'wholesale', component: WholesaleComponent },
    ]
  },
  {
    path: 'account', component: LayoutInnerComponent, children: [
      { path: 'profile', component: AccountProfileComponent },

      { path: 'orders', component: AccountOrdersComponent },
      { path: 'order/:key', component: AccountOrderDetailsComponent },
      { path: 'orders/cancel/:key', component: AccountOrderCancelComponent },
      //{ path: 'orders/return/:key', component: AccountOrderReturnComponent },
      { path: 'order/details/:key', component: AccountOrderDetailsComponent },

      { path: 'wishlist', component: AccountWishlistComponent },
      { path: 'addresses', component: AccountAddressesComponent },
    ]
  },
  {
    path: '', component: LayoutOtherComponent, children: [
      { path: 'track-order', component: TrackOrderComponent },
      { path: 'return-exchange/requests/create', component: ReturnExchangeComponent },
      { path: 'review/create', component: ReviewCreateComponent }
    ]
  },
  { path: '**', redirectTo: '/404', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
