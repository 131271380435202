import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ReviewPopupComponent } from '../../components/review-popup/review-popup.component';

@Component({
  selector: 'app-review-single',
  templateUrl: './review-single.component.html',
  styleUrls: ['./review-single.component.scss']
})
export class ReviewSingleComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }
  openReviewPopup() {
    const modalRe = this.modalService.open(ReviewPopupComponent, { centered: true, modalDialogClass: 'review-modal' });
    modalRe.componentInstance.name = 'reviewPopup';
  }
}
