<div class="payment-success" *ngIf="order_status=='success' ">
  <div class="flex column position-center contact-box payment-success-box">
    <div class="flex position-center success-icon">
      <svg viewBox="0 0 24 24" stroke-width=".5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
        <path d="M9 12l2 2l4 -4"></path>
     </svg>
    </div>
    <div class="flex position-center addressing">Thank you !</div>
    <div class="order-id">Order has been placed. You'll receive a confirmation email shortly.</div>

  </div>
</div>

<div class="payment-error" *ngIf="order_status=='error' ">
  <div class="flex column position-center contact-box payment-success-box">
    <div class="flex position-center success-icon">
      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-exclamation-circle" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M12 9v4" /><path d="M12 16v.01" /></svg>
    </div>
    <div class="flex position-center addressing">There is an error !</div>
    <div class="order-id">Order not placed due to some error. please try again later</div>

  </div>
</div>
