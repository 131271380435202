<div class="flex position-center announcement-bar"
  *ngIf="webModule.announcement!==undefined && webModule.announcement.show && announcement_state">
  <div class="flex position-center container">
    <swiper [navigation]="false" [pagination]="false" [loop]="false" [autoplay]="{
            delay: 5000, disableOnInteraction: false}" class="flex position-center">
      <ng-container>
        <ng-template swiperSlide>
          <a class="flex position-center announcement" style="cursor: pointer !important;"
            [innerHTML]="webModule.announcement.data.text" (click)="goto(webModule.announcement.data.link)">
            <!-- <span class="text1"></span> -->
            <!-- <span class="text2">USE CODE: CHEERS30 on purchase of ₹6999/-</span> -->
          </a>
        </ng-template>
      </ng-container>
    </swiper>
  </div>

  <svg viewBox="0 0 512 512" (click)="hide_announcement()">
    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
      d="M368 368L144 144M368 144L144 368" />
  </svg>
</div>