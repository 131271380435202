<app-header class="header-home {{headerClass}}"></app-header>
<div class="flex flex-column home-page">
  <div #hfeat></div>
  <section class="home-slider m-0" style="order:1;">
    <div class="home-slider-container">
      <!--<swiper [navigation]="true" [pagination]="false" [loop]="true" [lazy]="true" [autoplay]="{
                  delay: 10000,
                  disableOnInteraction: false
              }" class="flex position-center">-->
      <swiper [navigation]="true" [pagination]="false" [loop]="true" [lazy]="true" [autoplay]="false" class="flex position-center">
        <ng-container>
          <ng-template swiperSlide>
            <a href="/product/heartfelt-self-love-t-shirt-30622?vkey=159" class="slider-item">
              <img class="" src="../assets/images/ban1.jpg" alt="www.orangefashion.co">
            </a>
            <div class="flex column position-center description">
              <div class="txt">Heartfelt Self-Love T-Shirt</div>
              <a href="/product/heartfelt-self-love-t-shirt-30622?vkey=159" class="flex position-center shop-now">Shop now</a>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <a href="/product/alyx-the-dark-enigma-t-shirt-30594?vkey=132" class="slider-item">
              <img class="" src="../assets/images/ban2.jpg" alt="www.orangefashion.co">
            </a>
           
            <div class="flex column position-center description">
              <div class="txt">ALYX - The Dark Enigma T-Shirt</div>
              <a href="/product/alyx-the-dark-enigma-t-shirt-30594?vkey=132" class="flex position-center shop-now">Shop now</a>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <a href="/product/contrasting-harmony-t-shirt-30618?vkey=155" class="slider-item">
              <img class="" src="../assets/images/ban3.jpg" alt="www.orangefashion.co">
            </a>
            <div class="flex column position-center description">
              <div class="txt">Contrasting Harmony T-Shirt</div>
              <a href="/product/contrasting-harmony-t-shirt-30618?vkey=155" class="flex position-center shop-now">Shop now</a>
            </div>
          </ng-template>
        </ng-container>
        <!--<ng-container *ngFor="let item of home.banners">
          <ng-template swiperSlide>
            <a class="slider-item" href="{{item.title_link}}">
              <img class="" src="{{this.globals.get_server_assets_url()+item.image.data}}" alt="www.orangefashion.co">
            </a>
            <div class="flex position-center shop-now">Shop now</div>
          </ng-template>
        </ng-container>-->
        <ng-container *ngIf="false">
          <ng-template swiperSlide>
            <img class="" src="../assets/images/upss-sorry.jpg" alt="www.orangefashion.co">
          </ng-template>
        </ng-container>
      </swiper>
    </div>
  </section>




  <div class="container" style="order:2;">
    <section class="flex design-block hightlight-1 right">
      <div class="flex position-center left">
          <div class="flex column position-center content">
              <div class="heading">COLOURED PATCHES</div>
              <div class="text">Dive into a kaleidoscope of color with our vibrant tie-dye t-shirts.</div>
              <div class="flex gap-2 men-women-shop">
                  <a href="/category/coloured-patches" class="shop">SHOP NOW</a>
              </div>
          </div>
      </div>
      <div class="flex position-center right">
          <img src="../assets/images/d3.jpg">
      </div>
    </section>
    <section class="flex design-block hightlight-1 left">
        <div class="flex position-center right">
            <img src="../assets/images/d6.jpg">
        </div>
        <div class="flex position-center left">
            <div class="flex column position-center content">
                <div class="heading">SHAPES OF THOUGHTS</div>
                <div class="text">Unleash your personality in motion with our statement-making graphic tees..</div>
                <div class="flex gap-2 men-women-shop">
                  <a href="/category/shapes-of-thoughts" class="shop">SHOP NOW</a>
                </div>
            </div>
        </div>
    </section>
    <section class="flex design-block hightlight-1 right">
        <div class="flex position-center left">
            <div class="flex column position-center content">
                <div class="heading">CANVA DRIP</div>
                <div class="text">Step into a world of style with our must-have fashion tees.</div>
                <div class="flex gap-2 men-women-shop">
                  <a href="/category/canva-drip" class="shop">SHOP NOW</a>
                </div>
            </div>
        </div>
        <div class="flex position-center right">
            <img src="../assets/images/d2.jpg">
        </div>
    </section>
    <section class="flex design-block hightlight-2 container left" style="background: #f0f1eb;">
      <div class="flex position-center right">
          <img src="../assets/images/d5.jpg">
      </div>
      <div class="flex position-center left">
          <div class="flex column position-center content">
              <div class="heading">Ready. SET. Go.</div>
              <div class="text">Experience our year-round, 100% Awesome best sellers like never before in our virtual store.</div>
              <div class="flex gap-2 men-women-shop">
                  <a href="/search" class="shop">Explore NOW</a>
              </div>
          </div>
      </div>
    </section>
  </div>














  <div class="container hide" style="order:2;">
    <section class="home-featured-section hide" style="order:2;" *ngIf="home.section2!=undefined">
      <div class="container">
        <div class="flex grid-featured">
          <div class="flex position-center feat-column">
            <a class="flex allign-middle img-item" href="{{home.section2.left.top.link}}">
              <img src="{{this.globals.get_server_assets_url()+home.section2.left.top.image.data}}" alt="">
              <span>SHAPES OF<br />THOUGHTS</span>
            </a>
            <a class="flex allign-middle img-item" href="{{home.section2.right.bottom.link}}">
              <img src="{{this.globals.get_server_assets_url()+home.section2.left.bottom.image.data}}" alt="">
            </a>
          </div>

          <div class="flex column position-center feat-column center-column">
            <a class="flex allign-middle img-item" href="{{home.section2.title_link}}">
              <img src="{{home.section2.image.data}}" alt="">
            </a>
          </div>

          <div class="flex position-center feat-column">
            <a class="flex allign-middle img-item" href="{{home.section2.right.top.link}}">
              <img src="{{this.globals.get_server_assets_url()+home.section2.right.top.image.data}}" alt="">
            </a>
            <a class="flex allign-middle img-item" href="{{home.section2.right.bottom.link}}">
              <img src="{{this.globals.get_server_assets_url()+home.section2.right.bottom.image.data}}" alt="">
              <span>ARBITRARY<br />HUES</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
  <section class="flex w-100 home-mixed-section left-type hide" style="order:3;">
    <div class="container">
      <div class="flex position-center feat-collection">
        <div class="feat-item">
          <a class="flex allign-middle img-item" href="{{home.section3.banner.title_link}}">
            <img src="{{this.globals.get_server_assets_url()+home.section3.banner.image.data}}" alt="">
            <div class="flex column collection-title" [routerLink]="home.section3.banner.title_link">
              <span class="txt1">{{home.section3.banner.title}}</span>
              <span class="txt2">{{home.section3.banner.title2}}</span>
            </div>
          </a>
        </div>
        <div class="product-slider">
          <div class="flex column position-center heading">
            <div class="main">New items</div>
            <div class="sub">2023 Collections</div>
          </div>
          <div class="flex product-carosul" *ngIf="home.section3.items.length>0">
            <swiper [navigation]="false" [pagination]="{clickable: true}" [loop]="true" [lazy]="true" [autoplay]="false"
              class=" flex position-center product-swiper">

              <ng-container *ngFor="let item of home.section3.items">
                <ng-template swiperSlide>
                  <div class="flex product" *ngFor="let product of item">
                    <a class="flex column product-content"
                      href="{{this.globals.get_product_url(product.slug,product.indx)}}">
                      <div class="flex image">
                        <img class="flex main" *ngIf="product.images[0]!=undefined" src="{{product.images[0].md}}">
                        <img class="flex alternative" *ngIf="product.images[1]!=undefined"
                          src="{{product.images[1].md}}">
                      </div>
                      <div class="flex position-center w-100 name">
                        <span>{{product.name}}</span>
                      </div>
                      <div class="flex position-center price">
                        <p class="new-price">Rs. <span>{{product.price_compare_at}}</span></p>
                        <p class="old-price">Rs. <span>{{product.price}}</span></p>
                        <p class="discount-price">(<span>{{product.discount}}%</span> OFF)</p>
                      </div>
                      <div class="flex size">
                        <ng-container *ngFor="let size of make_sizes_array(product)">
                          <a class="flex position-center size-chip size-{{size.name.toLowerCase()}}">{{size.name}}</a>
                        </ng-container>
                      </div>
                    </a>
                  </div>
                </ng-template>
              </ng-container>

            </swiper>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="flex w-100 home-mixed-section right-type hide" style="order:4;">
    <div class="container">
      <div class="flex position-center feat-collection">
        <div class="feat-item">
          <a class="flex allign-middle img-item" href="{{home.section4.banner.title_link}}">
            <img src="{{this.globals.get_server_assets_url()+home.section4.banner.image.data}}" alt=""
              [routerLink]="[home.section4.banner.title_link]" alt="">
            <div class="flex column collection-title">
              <span class="txt1">{{home.section4.banner.title}}</span>
              <span class="txt2">{{home.section4.banner.title2}}</span>
            </div>
          </a>
        </div>

        <div class="product-slider">
          <div class="flex column position-center heading">
            <div class="main">New items</div>
            <div class="sub">2023 Collections</div>
          </div>
          <div class="flex product-carosul" *ngIf="home.section4.items.length>0">
            <swiper [navigation]="false" [pagination]="{clickable: true}" [loop]="true" [lazy]="true" [autoplay]="false"
              class="flex position-center product-swiper">

              <ng-container *ngFor="let item of home.section4.items">
                <ng-template swiperSlide>
                  <div class="flex product" *ngFor="let product of item">
                    <a class="flex column product-content"
                      href="{{this.globals.get_product_url(product.slug,product.indx)}}">
                      <div class="flex image">
                        <img class="flex main" *ngIf="product.images[0]!=undefined" src="{{product.images[0].md}}">
                        <img class="flex alternative" *ngIf="product.images[1]!=undefined"
                          src="{{product.images[1].md}}">
                      </div>
                      <div class="flex position-center w-100 name">
                        <span>{{product.name}}</span>
                      </div>
                      <div class="flex position-center price">
                        <p class="new-price">Rs. <span>{{product.price_compare_at}}</span></p>
                        <p class="old-price">Rs. <span>{{product.price}}</span></p>
                        <p class="discount-price">(<span>{{product.discount}}%</span> OFF)</p>
                      </div>
                      <div class="flex size">
                        <ng-container *ngFor="let size of make_sizes_array(product)">
                          <a class="flex position-center size-chip size-{{size.name.toLowerCase()}}">{{size.name}}</a>
                        </ng-container>
                      </div>
                    </a>
                  </div>
                </ng-template>
              </ng-container>

            </swiper>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="flex w-100 home-collection one-items full hide" style="order:4;">
    <div class="container">
      <a href="{{home.section6.banner.title_link}}" style="width: 100%;">
        <img class="w-100" src="{{this.globals.get_server_assets_url()+ home.section6.banner.image.data}}" alt="">
      </a>
    </div>
  </section>

  <section class="home-products-section hide" style="order:5;">
    <div class="container">
      <div class="flex position-center section-heading">New Arrivals</div>
      <div class="flex flex-wrap products-box">

        <ng-container *ngFor="let item of home.section5.items">
          <div class="flex product">
            <a class="flex column product-content" href="{{this.globals.get_product_url(item.slug, item.indx)}}">
              <div class="flex image">
                <img class="flex main" *ngIf="item.images[0]!=undefined" src="{{item.images[0].md}}">
                <img class="flex alternative" *ngIf="item.images[1]!=undefined" src="{{item.images[1].md}}">
              </div>
              <div class="flex position-center w-100 name">
                <span>{{item.name}}</span>
              </div>
              <div class="flex position-center price">
                <p class="new-price">Rs. <span>{{item.price_compare_at}}</span></p>
                <p class="old-price">Rs. <span>{{item.price}}</span></p>
                <p class="discount-price">(<span>{{item.discount}}%</span> OFF)</p>
              </div>
              <div class="flex size">
                <ng-container *ngFor="let size of make_sizes_array(item)">
                  <a class="flex position-center size-chip size-{{size.name.toLowerCase()}}">{{size.name}}</a>
                </ng-container>
              </div>
            </a>
          </div>
        </ng-container>

      </div>
      <div class="flex position-center section-more">
        <button type="button" [routerLink]="['/search']">View All</button>
      </div>
    </div>
  </section>


  <section class="flex w-100 home-collection one-items hide" style="order:7;">
    <div class="flex column container">
      <div class="flex position-center section-heading">New Arrivals</div>
      <div class="flex w-100 collection-box item-row">
        <div class="flex item item1">
          <a href="#" class="flex w-100 position-center item-link">
            <img src="../assets/images/home-banner.jpg" alt="">
          </a>
        </div>
      </div>
    </div>
  </section>

  <section class="flex w-100 home-collection two-items hide" style="order:8;">
    <div class="flex column container">
      <div class="flex position-center section-heading">New Arrivals</div>
      <div class="flex w-100 collection-box item-row">
        <div class="flex item item1">
          <a href="#" class="flex w-100 position-center item-link">
            <img src="../assets/images/colll4.jpg">
          </a>
        </div>
        <div class="flex item item2">
          <a href="#" class="flex w-100 position-center ">
            <img src="../assets/images/colll5.jpg">
          </a>
        </div>
      </div>
    </div>
  </section>

  <section class="flex w-100 home-collection three-items hide" style="order:9;">
    <div class="flex column container">
      <div class="flex position-center section-heading">New Arrivals</div>
      <div class="flex w-100 collection-box item-row">
        <div class="flex item item1">
          <a href="#" class="flex w-100 position-center item-link">
            <img src="../assets/images/colll3.jpg">
          </a>
        </div>
        <div class="flex item item2">
          <a href="#" class="flex w-100 position-center ">
            <img src="../assets/images/colll2.jpg">
          </a>
        </div>
        <div class="flex item item2">
          <a href="#" class="flex w-100 position-center ">
            <img src="../assets/images/colll1.jpg">
          </a>
        </div>
      </div>
    </div>
  </section>

  <section class="flex w-100 home-collection four-items" style="order:10;">
    <div class="flex column container">
      <div class="flex position-center section-heading">New Arrivals</div>
      <div class="flex w-100 collection-box item-row">
        <div class="flex item item1">
          <a href="/product/ocean-patches-t-shirt-30621?vkey=158" class="flex w-100 position-center item-link">
            <img src="../assets/images/sm2.jpg">
          </a>
        </div>
        <div class="flex item item2">
          <a href="/product/smile-with-vibe-t-shirt-30601?vkey=138" class="flex w-100 position-center ">
            <img src="../assets/images/sm1.jpg">
          </a>
        </div>
        <div class="flex item item3">
          <a href="/product/harmony-in-hues-t-shirt-blue-white-yellow-30620?vkey=157" class="flex w-100 position-center ">
            <img src="../assets/images/sm3.jpg">
          </a>
        </div>
        <div class="flex item item4">
          <a href="/product/triangle-of-harmony-t-shirt-30617?vkey=154" class="flex w-100 position-center item-link">
            <img src="../assets/images/sm4.jpg">
          </a>
        </div>
      </div>
    </div>
  </section>

  <section class="flex w-100 home-collection five-items hide" style="order:11;">
    <div class="flex column container">
      <div class="flex position-center section-heading">New Arrivals</div>
      <div class="flex w-100 collection-box item-row">
        <div class="flex item item1">
          <a href="#" class="flex w-100 position-center item-link">
            <img src="../assets/images/colll5.jpg">
          </a>
        </div>
        <div class="flex item item2">
          <a href="#" class="flex w-100 position-center item-link">
            <img src="../assets/images/colll4.jpg">
          </a>
        </div>
        <div class="flex item item3">
          <a href="#" class="flex w-100 position-center item-link">
            <img src="../assets/images/colll3.jpg">
          </a>
        </div>
        <div class="flex item item4">
          <a href="#" class="flex w-100 position-center ">
            <img src="../assets/images/colll2.jpg">
          </a>
        </div>
        <div class="flex item item5">
          <a href="#" class="flex w-100 position-center ">
            <img src="../assets/images/colll1.jpg">
          </a>
        </div>
      </div>
    </div>
  </section>

  <section class="flex w-100 home-collection six-items hide" style="order:12;" >
    <div class="flex column container">
      <div class="flex position-center section-heading">New Arrivals</div>
      <div class="flex w-100 collection-box item-row">
        <div class="flex item item1">
          <a href="#" class="flex w-100 position-center item-link">
            <img src="../assets/images/colll1.jpg">
          </a>
        </div>
        <div class="flex item item2">
          <a href="#" class="flex w-100 position-center item-link">
            <img src="../assets/images/colll2.jpg">
          </a>
        </div>
        <div class="flex item item3">
          <a href="#" class="flex w-100 position-center item-link">
            <img src="../assets/images/colll3.jpg">
          </a>
        </div>
        <div class="flex item item4">
          <a href="#" class="flex w-100 position-center item-link">
            <img src="../assets/images/colll4.jpg">
          </a>
        </div>
        <div class="flex item item5">
          <a href="#" class="flex w-100 position-center ">
            <img src="../assets/images/colll5.jpg">
          </a>
        </div>
        <div class="flex item item6">
          <a href="#" class="flex w-100 position-center ">
            <img src="../assets/images/colll2.jpg">
          </a>
        </div>
      </div>
    </div>
  </section>

  <section class="flex w-100 web-promise hide" style="order:13;">
    <div class="flex column position-center container">
      <img class="" src="../assets/images/guarenty.png">
    </div>
  </section>
</div>
