<div class="flex flex-column customer-service">
  <div class="flex allign-middle cc-container page-heading">Customer Service</div>
  <div class="flex w-100 cctop">
    <div class="flex flex-column cc-container">
      <div class="flex allign-middle cctop-head">How can we help?</div>
      <div class="flex flex-column allign-middle cctop-content">
        <div class="flex allign-middle w-100 quick-service">Quick Service</div>
        <div class="flex w-100 justify-content-between quick-service-box">

          <ng-container *ngIf="customer_key.length>0">
            <a class="flex position-center ccquick ccordr" routerLink="/account/orders">Your Orders</a>
            <a class="flex position-center ccquick cctrack" routerLink="/account/orders">Track Order</a>
            <!--<a class="flex position-center ccquick ccreturn">Start a Return</a>-->
            <a class="flex position-center ccquick ccmsg" (click)="show_popup('feedback')">Send Feedback</a>
          </ng-container>

          <ng-container *ngIf="customer_key.length==0">
            <a class="flex position-center ccquick ccordr">Your Orders</a>
            <a class="flex position-center ccquick cctrack" routerLink="/track-order">Track Order</a>
            <!--<a class="flex position-center ccquick ccreturn">Start a Return</a>-->
            <a class="flex position-center ccquick ccmsg" (click)="show_popup('feedback')">Send Feedback</a>
          </ng-container>

          <a class="flex position-center ccquick ccmsg" (click)="show_popup('contact')">Wholesale Enquiry</a>

        </div>
      </div>
    </div>
  </div>
  <div class="flex w-100 ccmiddle">
    <div class="flex flex-column cc-container">
      <div class="flex allign-middle ccmiddle-head">Frequently Asked Questions</div>
      <div class="flex w-100 justify-content-between faq-box">
        <a class="flex flex-column faq-single" routerLink="/faq">
          <div class="flex position-center iconn">
            <svg viewBox="0 0 505 511.9" style="enable-background:new 0 0 505 511.9;" xml:space="preserve">
              <path fill="currentColor"
                d="M505,280.9l-0.5-228.8v0c0-5.2-3.5-9.4-8.6-10l-308.2-42c-1.7-0.3-3.7,0-5.3,0.8L5.7,81
                            C1.9,83,0.3,85.8,0,89.9v332.5c0,5.2,4.1,9.5,9.3,9.7l197.9,25.6c-3.4-6.6-6.3-13.5-8.8-20.6l-179-23.1v-313l88.5,12l-6.1,148.9
                            l51.5-29.1l51.5,35l0.1-141.7l145,19.7v58.8c6.5-0.5,12.9-0.6,19.4-0.4v-59.8l115.8-74.9v183.6C489.4,260.6,499.3,272.4,505,280.9z
                            M123.3,95.5L44.2,84.8l143.7-65.2l47.6,6.5L123.3,95.5z M356.1,127.2l-135.4-18.4l115.4-69l131.3,17.9L356.1,127.2z" />
              <path fill="#000" d="M362.8,244.9c-73.7,0-133.5,59.8-133.5,133.5s59.8,133.5,133.5,133.5s133.5-59.8,133.5-133.5
                            S436.5,244.9,362.8,244.9z M304.5,329l2.8-2.2l34.9-27.5c4.3-3.3,8.7-4.3,8.7,3v11.2c0,2.2,0.3,3.3,2.2,3.3h29.6
                            c8.4,0.7,15.9,2.9,22.2,7.2c4.4,3,7.6,8.5,10,12.5c4.3,7,6.7,17,8,25.2c0.6,3.5,0.8,6.8,0.2,8.3c-0.3,0.7-0.7,1.2-1.3,1.4
                            c-3.2,1.3-6.6-3.1-8.3-5.2c-8.5-10-20.9-14.1-35.6-14.9h-25.6c-1.5,0.3-2.1,1.4-1.9,3.1v11.9c-0.1,4-2.1,5.3-6,4l-36.4-28.6
                            l-3.6-2.8l-0.9-0.7C300.6,335.5,301.8,331.1,304.5,329z M421.2,427.8l-2.8,2.2l-34.9,27.5c-4.3,3.4-8.7,4.3-8.7-3v-11.2
                            c0-2.2-0.3-3.3-2.2-3.3h-29.6c-8.4-0.7-15.9-2.9-22.2-7.2c-4.4-3-7.7-8.6-10.1-12.5c-4.4-7.2-7.3-18.8-8.3-27.2
                            c-0.3-2.7-0.4-5,0.1-6.2c0.3-0.7,0.7-1.2,1.3-1.4c3.2-1.3,6.5,3.1,8.3,5.2c8.5,10,21,14.1,35.6,14.9h25.6c1.5-0.3,2-1.4,1.9-3.1
                            v-11.9c0.1-4,2.1-5.3,6-4l36.4,28.6l3.6,2.8l0.9,0.7C425,421.3,423.8,425.7,421.2,427.8z" />
            </svg>
          </div>
          <div class="flex w-100 position-center txttt">Returns & Exchanges</div>
        </a>
        <a class="flex flex-column faq-single" routerLink="/faq">
          <div class="flex position-center iconn ship">
            <svg viewBox="-191 218.6 122.9 74.3" style="enable-background:new -191 218.6 122.9 74.3;"
              xml:space="preserve">
              <path fill="currentColor"
                d="M-69.9,261l-19.1-7.3l-7.6-16.5l-18-0.1v-12.1c0-1.8-0.7-3.4-1.9-4.6c-1.2-1.2-2.8-1.9-4.6-1.9h-49.8
                                c-1.8,0-3.4,0.7-4.6,1.9c-1.2,1.2-1.9,2.8-1.9,4.6c0,1,0.8,1.8,1.8,1.8s1.8-0.8,1.8-1.8c0-0.8,0.3-1.6,0.9-2.1
                                c0.5-0.5,1.3-0.9,2.1-0.9h49.8c0.8,0,1.6,0.3,2.1,0.9c0.5,0.5,0.9,1.3,0.9,2.1v55.4h-10c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8h11.8
                                c1,0,1.8-0.8,1.8-1.8v-2h10.7c0.8-18.4,27.3-21,30,0h5.8L-69.9,261z M-110.1,253.8v-11.5l8.9,0.1l6,11.4H-110.1z" />
              <path fill="currentColor" d="M-88.9,268.9c-6.6,0-12,5.4-12,12s5.4,12,12,12s12-5.4,12-12S-82.3,268.9-88.9,268.9z M-88.9,285.5c-2.5,0-4.6-2.1-4.6-4.6
                                c0-2.5,2.1-4.6,4.6-4.6c2.5,0,4.6,2.1,4.6,4.6S-86.4,285.5-88.9,285.5z" />
              <path fill="currentColor"
                d="M-146.5,268.9c-6.6,0-12,5.4-12,12s5.4,12,12,12s12-5.4,12-12C-134.6,274.3-139.9,268.9-146.5,268.9z M-146.5,285.5
                                c-2.6,0-4.6-2.1-4.6-4.6c0-2.5,2.1-4.6,4.6-4.6c2.5,0,4.6,2.1,4.6,4.6C-141.9,283.5-144,285.5-146.5,285.5z" />
              <path fill="currentColor"
                d="M-162.8,282.3c0,1-0.8,1.8-1.8,1.8h-6.4c-1.8,0-3.4-0.8-4.6-2c-1.2-1.2-1.9-2.9-1.9-4.7v-13c0-1,0.8-1.8,1.8-1.8
                                s1.8,0.8,1.8,1.8v13c0,0.9,0.3,1.7,0.9,2.3c0.5,0.6,1.3,0.9,2.1,0.9h6.4v0C-163.6,280.5-162.8,281.3-162.8,282.3z" />
              <path fill="#000" d="M-161.8,254.3c0,1-1.2,1.8-2.7,1.8h-23.9c-1.5,0-2.7-0.8-2.7-1.8c0-1,1.2-1.8,2.7-1.8h23.9
                                C-163,252.5-161.8,253.3-161.8,254.3z" />
              <path fill="#000" d="M-162.5,244.7c0,1-0.9,1.8-2,1.8h-18.4c-1.1,0-2-0.8-2-1.8c0-1,0.9-1.8,2-1.8h18.4
                                C-163.3,243-162.5,243.8-162.5,244.7z" />
              <path fill="#000" d="M-162.5,235.2c0,1-0.9,1.8-2,1.8h-15.9c-1.1,0-2-0.8-2-1.8c0-1,0.9-1.8,2-1.8h15.9
                                C-163.4,233.4-162.5,234.2-162.5,235.2z" />
            </svg>
          </div>
          <div class="flex w-100 position-center txttt">Shipping</div>
        </a>
        <a class="flex flex-column faq-single" routerLink="/faq">
          <div class="flex position-center iconn">
            <svg viewBox="247 -245.1 1000 1000" style="enable-background:new 247 -245.1 1000 1000;"
              xml:space="preserve">
              <path fill="currentColor" stroke="#fff" stroke-miterlimit="10" stroke-width="15"
                d="M747-235.1c-270.2,0-490,219.8-490,490s219.8,490,490,490s490-219.8,490-490S1017.2-235.1,747-235.1z
                                M747,683.7c-236.4,0-428.7-192.4-428.7-428.8S510.6-173.9,747-173.9s428.7,192.3,428.7,428.8C1175.7,491.3,983.4,683.7,747,683.7z" />
              <path fill="#000"
                d="M747,299.4c-82.3,0-149,66.2-149,147.8h-37.2c0-76.3,46.7-141.8,113.3-170C694.8,291.2,719.9,299.4,747,299.4z" />
              <path fill="#000"
                d="M933.3,447.2H896c0-81.6-66.7-147.8-149-147.8c27.1,0,52.3-8.2,73.1-22.2C886.7,305.4,933.3,370.9,933.3,447.2z" />
              <path fill="#000" d="M820.1,277.2c-20.8,14-46,22.2-73.1,22.2H747c-27,0-52.2-8.2-73-22.2c22.4-9.5,47.1-14.7,73.1-14.7
                                C773,262.4,797.7,267.7,820.1,277.2z" />
              <path fill="#000" d="M877.5,170c0,44.6-22.7,83.9-57.3,107.2c-22.4-9.5-47.1-14.8-73.1-14.8c51.5,0,93.1-41.4,93.1-92.4
                                c0-51.1-41.7-92.4-93.1-92.4c-51.5,0-93.1,41.4-93.1,92.4s41.7,92.4,93.1,92.4c-25.9,0-50.6,5.3-73.1,14.7
                                c-34.6-23.2-57.3-62.5-57.3-107.1C616.7,98.6,675,40.7,747,40.7S877.5,98.6,877.5,170z" />
            </svg>
          </div>
          <div class="flex w-100 position-center txttt">Account & Orders</div>
        </a>
        <a class="flex flex-column faq-single" routerLink="/faq">
          <div class="flex position-center iconn rupee">
            <svg viewBox="0 0 426.2 267.6" style="enable-background:new 0 0 426.2 267.6;" xml:space="preserve">
              <path fill="currentColor" d="M419,267.6H54.4c-3.8,0-7.1-3.3-7.1-7.1s3.3-7.1,7.1-7.1l357.6,0V54.5c0-3.8,3.3-7.1,7.1-7.1
                                s7.1,3.3,7.1,7.1v206C426.1,264.7,422.8,267.6,419,267.6L419,267.6z" />
              <path fill="currentColor" d="M395.3,243.9H30.7c-3.8,0-7.1-3.3-7.1-7.1s3.3-7.1,7.1-7.1l357.5,0V30.8c0-3.8,3.3-7.1,7.1-7.1
                                s7.1,3.3,7.1,7.1v206C402.4,241.1,399.1,243.9,395.3,243.9L395.3,243.9z" />
              <path fill="currentColor" d="M371.7,220.2H7c-3.8,0-7.1-3.3-7.1-7.1V7.1C-0.1,3.3,3.2,0,7,0h364.7c3.8,0,7.1,3.3,7.1,7.1v206
                                C378.8,217.4,375.4,220.2,371.7,220.2L371.7,220.2z M14.1,206h350.5V14.7H14.1V206z" />
              <path fill="currentColor" d="M92.2,110.3c0-28.9,12.3-54.5,32.2-72H44.9c-3.8,0-7.1,3.3-7.1,7.1v130.2c0,3.8,3.3,7.1,7.1,7.1h79.6
                                C104.6,164.8,92.2,139.2,92.2,110.3L92.2,110.3z" />
              <path fill="currentColor" d="M333.8,38.4h-79.6c19.9,18,32.2,43.6,32.2,72s-12.3,54.5-32.2,72h79.6c3.8,0,7.1-3.3,7.1-7.1l0-130.2
                                C340.9,41.2,337.6,38.4,333.8,38.4L333.8,38.4z" />
              <path fill="#000" d="M200.4,68.1H217l6.1-10.2h-61.5l-6.1,10.2H168c12.1,0,19.7,3,22.7,10.2h-29l-6.1,10.2h36.6
                            c-0.9,10.8-9.8,17.9-24.8,17.9h-8.2v9.7l36.3,47.2h16.4c-12.3-16-25.8-33-38-49.2c16-1.1,29.8-10.3,31.7-25.6H217l6.1-10.2h-17.9
                            C204.4,74.2,202.7,70.8,200.4,68.1" />
            </svg>
          </div>
          <div class="flex w-100 position-center txttt">Payments & Refunds</div>
        </a>
        <a class="flex flex-column faq-single" routerLink="/faq">
          <div class="flex position-center iconn">
            <svg version="1.1" text-rendering="geometricPrecision" image-rendering="optimizeQuality"
              shape-rendering="geometricPrecision" viewBox="3 5.9 512 501" style="enable-background:new 3 5.9 512 501;"
              xml:space="preserve">
              <path fill="currentColor" d="M181.1,496.1c-0.3,1.6-0.8,3.1-1.6,4.5c-1.7,2.9-4.5,4.9-7.7,5.7c-3.1,0.8-6.5,0.4-9.5-1.4l-52.1-31.1l-51.6,30.9
                                c-1.1,0.7-2.4,1.3-3.7,1.6l-0.9,0.2c-1.4,0.3-3,0.3-4.5,0l-0.5-0.1c-3.3-0.8-6.1-2.8-7.8-5.6c-1.7-2.8-2.3-6.1-1.7-9.2L53,432.2
                                L7.3,392.3c-1.2-1.1-2.2-2.4-2.9-3.8c-0.7-1.3-1.1-2.7-1.3-4.2l0-0.6c-0.2-3.3,0.9-6.5,2.9-8.9c2-2.4,4.9-4.1,8.2-4.4l60.7-5.4
                                l23.8-55.7c0.6-1.5,1.5-2.9,2.7-4c0.9-1,2.1-1.8,3.3-2.4c0.5-0.2,0.9-0.4,1.4-0.6c3-1.1,6.1-0.9,8.9,0.2c2.8,1.1,5.1,3.2,6.5,6
                                l18.8,44l-5.5,23.9c-0.4-0.5-0.7-1-1-1.6l-23.6-55.2l-23.6,55.2c-1.1,2.6-3.7,4.4-6.6,4.5l-59.3,5.3L66,424c2.1,1.8,2.9,4.6,2.3,7.2
                                l-13.4,58.5l51.5-30.8c2.4-1.4,5.3-1.3,7.5,0l51.5,30.8l-5.9-25.7c4.9,0.6,9.9,0.2,14.8-1.1l6.7,29.3
                                C181.3,493.5,181.3,494.8,181.1,496.1z" />
              <path fill="currentColor"
                d="M515,383.7l0,0.6c-0.2,1.5-0.6,2.9-1.3,4.2c-0.7,1.4-1.7,2.7-2.9,3.8L465,432.2l13.6,59.4c0.7,3.3,0,6.6-1.7,9.2
                                c-1.7,2.7-4.4,4.8-7.8,5.6l-0.5,0.1c-1.6,0.3-3.1,0.3-4.5,0l-0.9-0.2c-1.4-0.3-2.6-0.9-3.7-1.6l-51.6-30.9l-52.1,31.1
                                c-2.8,1.7-6.3,2.2-9.5,1.4c-3.1-0.8-5.9-2.8-7.7-5.7c-0.8-1.4-1.4-2.9-1.6-4.5l0-0.1c-0.2-1.3-0.2-2.5,0-3.8l6.7-29.4
                                c1.1,0.3,2.2,0.6,3.3,0.8c3.8,0.7,7.6,0.8,11.5,0.4l-5.9,25.7l51.5-30.8c2.3-1.3,5.1-1.4,7.5,0l51.5,30.8l-13.4-58.5
                                c-0.6-2.5,0.2-5.3,2.3-7.2l45.2-39.5l-59.3-5.3c-2.9-0.1-5.5-1.8-6.6-4.5l-23.6-55.2l-23.6,55.2c-0.3,0.6-0.6,1.2-1,1.7l-5.5-23.9
                                l18.8-44.1c1.4-2.8,3.8-4.9,6.5-6c2.8-1.1,5.9-1.3,8.9-0.2c0.5,0.2,1,0.4,1.4,0.6c1.3,0.6,2.4,1.4,3.3,2.4c1.2,1.2,2.1,2.5,2.7,4
                                l0.1,0.1l23.7,55.6l60.7,5.4c3.3,0.4,6.2,2,8.2,4.4C514.1,377.2,515.2,380.4,515,383.7z" />
              <path fill="currentColor" d="M474.1,157.9l-46.7,19.8c-5.7,2.4-12.3-0.3-14.7-6c-2.4-5.7,0.3-12.3,6-14.7l46.7-19.8c5.7-2.4,12.3,0.3,14.7,6
                                C482.4,148.9,479.8,155.5,474.1,157.9z" />
              <path fill="currentColor" d="M426.5,85.1l-35.7,36.1c-4.4,4.4-11.5,4.4-15.9,0.1c-4.4-4.4-4.4-11.5,0-15.9l35.7-36.1c4.4-4.4,11.5-4.4,15.8,0
                                S430.9,80.7,426.5,85.1z" />
              <path fill="currentColor" d="M354.9,35.7l-19.3,47c-2.4,5.7-8.9,8.5-14.6,6.1c-5.7-2.3-8.5-8.9-6.1-14.6l19.3-47c2.4-5.7,8.9-8.4,14.6-6.1
                                C354.5,23.4,357.2,30,354.9,35.7z" />
              <path fill="currentColor" d="M269.9,17.4v50.8c0,6.2-5,11.2-11.2,11.2c-6.2,0-11.2-5-11.2-11.2V17.4c0-6.2,5-11.2,11.2-11.2
                                C264.8,6.1,269.9,11.1,269.9,17.4z" />
              <path fill="currentColor" d="M197,88.8c-5.7,2.4-12.3-0.4-14.6-6.1l-19.3-47c-2.3-5.7,0.4-12.3,6.1-14.6c5.7-2.3,12.3,0.4,14.6,6.1l19.3,47
                                C205.5,79.9,202.8,86.4,197,88.8z" />
              <path fill="currentColor" d="M143,121.2c-4.4,4.4-11.5,4.3-15.9-0.1L91.5,85.1c-4.4-4.4-4.3-11.5,0-15.9s11.5-4.3,15.8,0l35.7,36.1
                                C147.4,109.7,147.4,116.8,143,121.2z" />
              <path fill="currentColor" d="M105.3,171.7c-2.4,5.7-9,8.4-14.7,6l-46.7-19.8c-5.7-2.4-8.4-9-6-14.7c2.4-5.7,9-8.4,14.7-6l46.7,19.8
                                C105.1,159.4,107.7,166,105.3,171.7z" />
              <path fill="#000" d="M411.5,248.7l0,0.5c-0.2,1.7-0.7,3.3-1.4,4.7l-0.2,0.4c-0.7,1.2-1.5,2.3-2.5,3.2l-69.7,60.8l20.5,89.8
                                c0.7,3.6,0,7.1-1.8,10c-4.1,6.5-12.7,8.3-19.1,4.2L259,375.6l-79,47.2c-3.1,1.8-6.7,2.3-10.2,1.4c-7.3-1.8-11.6-9.1-10-16.3
                                l20.4-89.5L111.2,258c-2.7-2.4-4.4-5.8-4.6-9.4c-0.2-3.5,0.9-7,3.4-9.9c1.3-1.4,2.7-2.5,4.3-3.3c1.6-0.8,3.4-1.3,5.3-1.4l90.9-8.1
                                l36.1-84.4c1.5-3.5,4.3-6,7.5-7.3c7-2.8,14.9,0.6,17.7,7.4l0,0.1l36,84.2l91.6,8.2c3.7,0.4,6.9,2.2,9.1,4.8l0.4,0.5
                                C410.7,242,411.7,245.3,411.5,248.7z" />
            </svg>
          </div>
          <div class="flex w-100 position-center txttt">Rewards</div>
        </a>
      </div>
    </div>
  </div>
  <div class="flex w-100 ccmiddle">
    <div class="flex flex-column cc-container">
      <div class="flex allign-middle ccmiddle-head">Contact Us</div>
      <div class="flex w-100 justify-content-between contact-box">
        <div class="flex position-center flex-column single-box">
          <svg viewBox="0 0 512 512">
            <path
              d="M451 374c-15.88-16-54.34-39.35-73-48.76-24.3-12.24-26.3-13.24-45.4.95-12.74 9.47-21.21 17.93-36.12 14.75s-47.31-21.11-75.68-49.39-47.34-61.62-50.53-76.48 5.41-23.23 14.79-36c13.22-18 12.22-21 .92-45.3-8.81-18.9-32.84-57-48.9-72.8C119.9 44 119.9 47 108.83 51.6A160.15 160.15 0 0083 65.37C67 76 58.12 84.83 51.91 98.1s-9 44.38 23.07 102.64 54.57 88.05 101.14 134.49S258.5 406.64 310.85 436c64.76 36.27 89.6 29.2 102.91 23s22.18-15 32.83-31a159.09 159.09 0 0013.8-25.8C465 391.17 468 391.17 451 374z"
              fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="20" />
          </svg>
          <span class="flex allign-middle main-txt">GIVE US A CALL</span>
          <span class="flex allign-middle sbtxt">Mon-Sat: 10AM-5PM IST</span>
          <span class="flex allign-middle sbtxt">+91-7602510752</span>
        </div>
        <div class="flex position-center flex-column single-box">
          <svg viewBox="0 0 512 512">
            <rect x="48" y="96" width="416" height="320" rx="40" ry="40" fill="none" stroke="currentColor"
              stroke-linecap="round" stroke-linejoin="round" stroke-width="20" />
            <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="20"
              d="M112 160l144 112 144-112" />
          </svg>
          <span class="flex allign-middle main-txt">Email Us</span>
          <span class="flex allign-middle sbtxt">
            <a (click)="show_popup('contact')" style="text-decoration: underline;">Click here</a> &nbsp; or email
          </span>
          <a href="mailto:contact@orangefashion.co" class="flex allign-middle sbtxt"
            style="margin-top: 3px;">contact@orangefashion.co</a>
        </div>
        <div class="flex position-center flex-column single-box letter">
          <svg class="write" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
            <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
              <path fill="currentColor"
                d="M606.4,5000.8c-17.3-6.5-49.8-32.5-69.3-58.5c-36.8-43.3-39-307.7-39-4435.3v-4389.8l56.3-58.5l58.5-56.3h450.7c442,0,450.7,0,509.2,49.8c80.2,71.5,80.2,175.5,0,247c-56.3,47.7-73.7,49.8-394.4,49.8H844.7V498.3v4149.3l2201.4,4.3l2201.4,6.5l52,60.7c65,78,65,147.3,0,225.3l-52,60.7l-2305.4,4.3C1676.8,5011.6,623.7,5007.3,606.4,5000.8z" />
              <path fill="currentColor"
                d="M5743.7,4851.3c-43.3-56.3-45.5-82.3-45.5-810.4v-751.9l56.3-58.5l58.5-56.3h745.4h745.3l52,54.2c39,36.8,54.2,75.8,54.2,134.3c0,78-30.3,110.5-732.4,812.5c-693.3,693.3-736.7,732.3-810.4,732.3C5806.5,4907.6,5776.2,4894.6,5743.7,4851.3z M6445.7,3910.9l390-390h-396.5h-394.4v390c0,214.5,2.2,390,6.5,390C6053.6,4300.9,6231.2,4125.4,6445.7,3910.9z" />
              <path fill="currentColor" d="M1538.1,3445.1v-184.2h1560h1560v184.2v184.2h-1560h-1560V3445.1z" />
              <path fill="currentColor"
                d="M7182.4,2909.9c-84.5-49.8-95.4-86.7-97.5-327.2c0-218.8,2.2-236.2,54.2-286c32.5-34.7,78-54.2,119.2-54.2c41.2,0,86.7,19.5,119.2,54.2c49.8,47.7,54.2,67.2,54.2,279.5c0,205.8-4.3,231.8-47.7,281.7C7325.4,2922.9,7240.9,2944.5,7182.4,2909.9z" />
              <path fill="currentColor"
                d="M8460.8,2912c-60.7-6.5-158.2-39-216.7-69.3c-82.3-43.3-780-728-3005.3-2955.4C3323.5-2032.4,2328.9-3042.1,2300.8-3098.4c-23.8-47.7-151.7-411.7-288.2-810.4c-229.7-680.3-242.7-725.9-212.3-780c17.3-30.3,58.5-69.3,91-88.8c54.2-30.3,91-21.7,832,225.4l775.7,255.7l2942.4,2940.2C9724,1921.9,9498.7,1674.8,9498.7,1993.4c0,247-56.3,355.3-329.3,624c-121.4,117-251.4,225.3-299,244.8C8757.6,2909.9,8595.1,2929.4,8460.8,2912z M8681.8,2565.4c71.5-26,407.3-348.8,452.8-435.5c39-73.7,39-192.8,0-283.8c-17.3-39-95.3-132.2-173.3-210.2l-143-138.7L8450,1865.5l-366.2,368.3l145.2,145.2C8437,2582.7,8530.1,2621.7,8681.8,2565.4z M5503.2-823.4L2935.6-3390.9l-119.2,119.2l-119.2,119.2L5264.9-585l2567.6,2567.6l119.2-119.2l119.2-119.2L5503.2-823.4z M5990.7-1310.9L3423.1-3878.5l-112.7,114.8L3195.6-3651l2567.6,2567.6l2567.6,2567.6l114.8-112.7l112.7-114.8L5990.7-1310.9z M2827.3-3781c190.7-190.7,268.7-283.8,247-292.5c-91-32.5-810.4-270.8-823.4-270.8c-8.7,0,47.7,190.7,125.7,422.5c75.8,231.8,147.3,422.5,156,422.5C2539.1-3499.3,2671.3-3625,2827.3-3781z" />
              <path fill="currentColor" d="M1538.1,2405v-184.2h1906.7h1906.7V2405v184.2H3444.8H1538.1V2405z" />
              <path fill="currentColor" d="M1538.1,1365v-184.2h2253.4h2253.4V1365v184.2H3791.5H1538.1V1365z" />
              <path fill="currentColor"
                d="M7180.3-901.4c-26-13-58.5-47.6-71.5-75.8c-17.3-39-23.8-446.3-23.8-1362.9V-3651H5752.4H4417.7l-52-54.2c-71.5-69.3-71.5-169,0-238.3l52-54.2h1449.5h1449.5l58.5,56.3l56.3,58.5v1436.5v1436.5l-47.7,54.2C7325.4-890.6,7245.3-871,7180.3-901.4z" />
            </g>
          </svg>
          <span class="flex allign-middle main-txt">WRITE US</span>
          <span class="flex allign-middle sbtxt">ORANGE ESSENTIAL ENTERPRISE</span>
          <span class="flex allign-middle sbtxt">Panshila, Deulia, Kolaghat,</span>
          <span class="flex allign-middle sbtxt text-center">Purba Medinipur, West Bengal,India, 721154</span>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="flex position-center contact-popup" *ngIf="popup_state">
  <div class="contact-bkdrp"></div>
  <div class="flex flex-column contact-form-box">
    <div class="flex w-100 position-center contact-head">
      Send Us &nbsp; <span *ngIf="popup_type=='contact' ">a Message!</span>
      <span class="" *ngIf="popup_type=='feedback' ">Your Feedback!</span>
    </div>
    <div class="flex position-center popup-close" (click)="hide_popup()">
      <svg viewBox="0 0 24 24">
        <path fill="currentColor"
          d="m12 10.586 6.293-6.293a1 1 0 0 1 1.414 1.414l-6.293 6.293 6.293 6.293a1 1 0 0 1 -1.414 1.414l-6.293-6.293-6.293 6.293a1 1 0 1 1 -1.414-1.414l6.293-6.293-6.293-6.293a1 1 0 0 1 1.414-1.414z">
        </path>
      </svg>
    </div>
    <div class="flex flex-column w-100 position-center contact-form">
      <div class="flex allign-middle w-100 line-row">
        <div class="single-input">
          <input class="floating-input" type="text" placeholder=" " name="name" [(ngModel)]="wholesale.name"
            [style.border]=" (errors.name != undefined) ? '1px solid red' :'' " (change)="remove_error('name')"
            (keyup)="remove_error('name');check_enter_pressed($event)">
          <label class="floating-label">Name*</label>
        </div>
      </div>
      <div class="flex allign-middle w-100 line-row">
        <div class="single-input" style="margin-right: 15px;">
          <input class="floating-input" type="text" placeholder=" " name="email" [(ngModel)]="wholesale.email"
            [style.border]=" (errors.email != undefined) ? '1px solid red' :'' " (change)="remove_error('email')"
            (keyup)="remove_error('email');check_enter_pressed($event)" />
          <label class="floating-label">Email*</label>
        </div>
        <div class="single-input">
          <input class="floating-input" type="text" placeholder=" " name="phone" [(ngModel)]="wholesale.phone"
            [style.border]=" (errors.phone != undefined) ? '1px solid red' :'' " (change)="remove_error('phone')"
            (keyup)="remove_error('phone');check_enter_pressed($event)">
          <label class="floating-label">Phone*</label>
        </div>
      </div>
      <div class="flex allign-middle w-100 line-row hide">
        <div class="single-input">
          <select class="floating-select" onclick="this.setAttribute('value', this.value);"
            onchange="this.setAttribute('value', this.value);" value="">
            <option value=""></option>
            <option value="1">Alabama</option>
            <option value="2">Boston</option>
            <option value="3">Ohaio</option>
            <option value="4">New York</option>
            <option value="5">Washington</option>
          </select>
          <label class="floating-label">Topic*</label>
        </div>
      </div>
      <div class="flex allign-middle w-100 line-row">
        <div class="single-input">
          <textarea rows="4" placeholder="Your message.." class="w-100 cr-comments" name="text"
            [(ngModel)]="wholesale.text" [style.border]=" (errors.text != undefined) ? '1px solid red' :'' "
            (change)="remove_error('text')" (keyup)="remove_error('text');check_enter_pressed($event)"></textarea>
        </div>
      </div>

      <div class="flex allign-middle w-100 line-row">
        <ngx-recaptcha2 #captchaElem [siteKey]="siteKey" (reset)="handleReset()" (expire)="handleExpire()"
          (load)="handleLoad()" (success)="handleSuccess($event)" [useGlobalDomain]="false" name="captcha"
          [ngModel]="wholesale.captcha" [ngModelOptions]="{ standalone: true }" style="margin: 0 auto;">
        </ngx-recaptcha2>

        <span>{{ (errors.captcha!==undefined) ? errors.captcha : '' }}</span>
      </div>

      <div class="flex allign-middle w-100 line-row m-0">
        <button class="flex position-center w-100 submit-msg" (click)="save()">SEND MESSAGE</button>
      </div>
    </div>
  </div>
</div>