import { Component, ViewEncapsulation,HostListener, ViewChild, OnInit, ElementRef, PLATFORM_ID, Inject } from '@angular/core';
import { SwiperComponent } from "swiper/angular";
import SwiperCore, { SwiperOptions, Navigation, Pagination, Lazy, Autoplay, Thumbs } from 'swiper';
import { GlobalsService } from 'src/app/services/globals.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


SwiperCore.use([Navigation, Pagination, Lazy, Autoplay, Thumbs]);

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {







  @ViewChild("hfeat") componentDiv: any;
  headerClass = '';
  // /catbarDistance: number;



    @HostListener('window:scroll', [])
    onWindowScroll() {
      const element = this.componentDiv.nativeElement;
      const viewportOffset = element.getBoundingClientRect();
      const top = viewportOffset.top;
      if (top <= 0) {
          // Do something
          console.log("success"); 
          this.headerClass = 'scrolled';
        } else {
          this.headerClass = '';
        }
    }



    

  










  home = {
    banners: [
      {
        image: {
          name: '',
          ext: '',
          type: '',
          data: '',
        },
        title: '',
        title_link: '',
        title2: '',
        title2_link: '',
        btn_text: '',
        btn_link: '',
      }
    ],
    mobile_banners: [
      {
        image: {
          name: '',
          ext: '',
          type: '',
          data: '',
        },
        title: '',
        title_link: '',
        title2: '',
        title2_link: '',
        btn_text: '',
        btn_link: '',
      },
    ],
    section2: {
      title: "",
      title_link: '',
      title2: "",
      title2_link: '',
      btn_text: '',
      btn_link: '',
      image: {
        name: '',
        ext: '',
        type: '',
        data: '',
      },
      left: {
        top: {
          link: "",
          image: {
            name: '',
            ext: '',
            type: '',
            data: '',
          },
        },
        bottom: {
          ink: "",
          image: {
            name: '',
            ext: '',
            type: '',
            data: '',
          },
        },
      },
      right: {
        top: {
          link: "",
          image: {
            name: '',
            ext: '',
            type: '',
            data: '',
          },
        },
        bottom: {
          ink: "",
          image: {
            name: '',
            ext: '',
            type: '',
            data: '',
          },
        },
      }
    },
    section3: {
      banner: {
        title: "",
        title_link: '',
        title2: "",
        title2_link: '',
        btn_text: '',
        btn_link: '',
        image: {
          name: '',
          ext: '',
          type: '',
          data: '',
        },
      },
      items: [],
    },
    section4: {
      banner: {
        title: "",
        title_link: '',
        title2: "",
        title2_link: '',
        btn_text: '',
        btn_link: '',
        image: {
          name: '',
          ext: '',
          type: '',
          data: '',
        },
      },
      items: [],
    },
    section5: {
      items: [],
    },
    section6: {
      banner: {
        title: "",
        title_link: '',
        title2: "",
        title2_link: '',
        btn_text: '',
        btn_link: '',
        image: {
          name: '',
          ext: '',
          type: '',
          data: '',
        },
      },
    },
  } as any;

  constructor(
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {

  }

  async ngAfterContentInit() {
    let tempData = localStorage.getItem('home');
    //console.log(tempData);

    if (tempData != null)
      this.home = JSON.parse(tempData);

    await this.get_data();
  }

  async get_data() {
    let postData = {
      action: 'home/page',
    };

    let result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/home/page`, postData)
      .subscribe((response) => {
        if (response.msg == 'success') {
          this.set_homepage_data(response.data);

          //console.log(this.home);
        }
      });
  }

  set_homepage_data(data: any) {
    if (data.banners != undefined && (data.banners != null))
      this.home.banners = data.banners;

    if (data.banners != undefined && (data.banners != null))
      this.home.mobile_banners = data.mobile_banners;

    if (data.section2 != undefined && (data.section2 != null))
      this.home.section2 = data.section2;

    if (data.section3 != undefined && (data.section3 != null)) {
      this.home.section3 = data.section3;

      if (this.home.section3.items != undefined && this.home.section3.items.length > 0)
        this.home.section3.items = this.make_carousel_array(this.home.section3.items);

      //console.log(this.home.section3.items);
    }

    if (data.section4 != undefined && (data.section4 != null)) {
      this.home.section4 = data.section4;

      if (this.home.section4.items != undefined && this.home.section4.items.length > 0)
        this.home.section4.items = this.make_carousel_array(this.home.section4.items);

      //console.log(this.home.section4.items);
    }

    if (data.section5 != undefined && (data.section5 != null))
      this.home.section5 = data.section5;

    if (data.section6 != undefined && (data.section6 != null))
      this.home.section6 = data.section6;

    localStorage.setItem('home', JSON.stringify(this.home));
  }

  make_carousel_array(data: any) {
    let temp = [] as any;

    for (let kk = 0; kk < data.length; kk = (kk + 2)) {
      let temp1 = [] as any;

      for (let ind = 0; ind < 2; ind++) {
        if (data[(kk + ind)] != undefined)
          temp1.push(data[(kk + ind)]);
      }

      temp.push(temp1);
    }

    //console.log([temp, data, data.length]);
    return temp;
  }

  make_sizes_array(data: any) {
    if (data.varients != undefined) {
      for (let kk = 0; kk < data.varients.length; kk++) {
        if (data.varients[kk].name == 'size') {
          //console.log(data.varients[kk].value);
          return (data.varients[kk].value).slice(0, 5);
        }
      }
    }

    return [];
  }


  goto(url: string) {
    console.log(url);
    this.router.navigate([url]);
  }

}
