import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lsv-social-verify',
  templateUrl: './lsv-social-verify.component.html',
  styleUrls: ['./lsv-social-verify.component.scss']
})
export class LsvSocialVerifyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
