import { Component, Renderer2, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { NgbModalConfig, NgbModal, NgbActiveModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { MenuSideComponent } from '../../components/menu-side/menu-side.component';
import { HeaderSearchComponent } from '../../components/header-search/header-search.component';
import { CartComponent } from '../../pages/cart/cart.component';
import { LsvComponent } from '../../components/lsv/lsv.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalsService } from 'src/app/services/globals.service';
import { isPlatformBrowser } from '@angular/common';
import { WebModule } from 'src/app/modules/web/web.module';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class HeaderComponent implements OnInit {

  public search_text = '';
  public customer_key = '';
  public customer_fname = '';
  public search_results = [] as any;

  public webModule: WebModule = {} as any;

  public scroll: any;

  cart_count = 0;
  wishlist_count = 0;

  constructor(
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private platformLocation: PlatformLocation,
    private offcanvasService: NgbOffcanvas,
    private modalService: NgbModal,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    platformLocation.onPopState(() => this.modalService.dismissAll());
  }

  async ngAfterViewInit() {

  }

  async ngAfterContentInit() {
    //console.log('ngAfterViewInit');
    this.globals.search_text_observer.subscribe(text => this.search_text = text);
    this.globals.web_observer.subscribe(web => this.webModule = web);
    this.globals.cart_count_observer.subscribe(count => this.cart_count = count);
    this.globals.wishlist_count_observer.subscribe(count => this.wishlist_count = count);
    this.globals.header_search_results_observer.subscribe((results) => {
      this.search_results = results;
      //console.log([this.search_results, Object.entries(this.search_results).length]);
    });

    if (isPlatformBrowser(this.globals.platformId)) {
      let tempMenu = localStorage.getItem('menu');

      if (tempMenu != null && tempMenu.length > 0) {
        this.webModule.menus = JSON.parse(tempMenu);
      }
    }

    this.customer_key = await this.globals.get_customer_key();
    this.get_search_results();
    this.get_menus();

    if (isPlatformBrowser(this.globals.platformId)) {
      if (this.customer_key.length > 0) {
        this.customer_fname = this.globals.get_localstore('fname');
      }
    }
  }

  get_search_results() {
    const postData = {
      action: 'products/suggestions',
      search: this.search_text,
    }; //new FormData();

    //postData.append('action', 'products/suggestions');
    //postData.append('search', this.search_text);

    const result = this.httpClient.post<any>(this.globals.get_endpoint() + '/products/suggestions', postData).subscribe((response) => {
      ////console.log(response);
      if (response.msg == 'success') {
        this.search_results.products = response.data.products;
        this.search_results.categories = response.data.categories;
        this.globals.set_header_search_result(this.search_results);
      }
    });
  }

  openMenu() {
    const offcanvasRef = this.offcanvasService.open(MenuSideComponent, { position: 'start' });
    offcanvasRef.componentInstance.name = 'sideMenu';
  }

  openSearch() {
    const offcanvasRef = this.offcanvasService.open(HeaderSearchComponent, { position: 'top' });
    offcanvasRef.componentInstance.name = 'headerSearch';
    offcanvasRef.componentInstance.modal = offcanvasRef;
    offcanvasRef.componentInstance.search_results = this.search_results;
    this.get_search_results();
  }

  openCart() {
    if (isPlatformBrowser(this.globals.platformId)) {
      if (window.location.pathname !== '/checkout/cart') {
        const offcanvasRef = this.offcanvasService.open(CartComponent, { panelClass: 'cart-panel', position: 'end' });
        offcanvasRef.componentInstance.name = 'cart';
      }
    }
    //console.log(window.location);
  }

  openLsv() {
    const modalRe = this.modalService.open(LsvComponent, { centered: true, modalDialogClass: 'lsv-modal' });
    modalRe.componentInstance.name = 'userLsv';
  }

  ngOnInit() {
  }

  is_logged_in(): boolean {
    if (isPlatformBrowser(this.globals.platformId)) {
      return this.globals.is_logged_in();
    }

    return false;
  }

  logout() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(this.globals.customer_key);
      localStorage.removeItem('name');
      localStorage.removeItem('fname');
      window.location.reload();
    }
  }

  async get_menus() {
    const postData = {
      action: 'home/menus',
    }; //new FormData();

    //postData.append('action', 'home/menus');

    const result = this.httpClient.post<any>(this.globals.get_endpoint() + '/home/menus', postData).subscribe((response) => {
      ////console.log(response);
      if (response.msg == 'success') {
        this.webModule.menus = response.data.menus;
        this.webModule.announcement = response.announcement;
        this.globals.set_web(this.webModule);

        localStorage.setItem('menu', JSON.stringify(this.webModule.menus));
        //console.log('header', this.webModule);
      }
    });
  }

}
