import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AddressServiceService } from 'src/app/services/address-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-checkout-summary',
  templateUrl: './checkout-summary.component.html',
  styleUrls: ['./checkout-summary.component.scss']
})
export class CheckoutSummaryComponent implements OnInit {

  public order_sum: boolean = false;
  billing_address_type = 'same' as any;
  pay_type = 'COD' as string; // COD | RZPY
  isPay2Checked: boolean = false;
  isPay2online: boolean = false;

  rows = [] as any;
  cart = {
    total: 0,
    price: 0,
    price_payable: 0,
    discount_amount: 0,
    item_qnty: 0,
    shipping: 0,
    coupon_code: '',
    coupon: {} as any,
    maximum_saving: 0,
  } as any;
  customer_key = '';
  cart_count = 0;
  address = {
    key: '',
    email: '',
    name: '',
    fname: '',
    lname: '',
    address1: '',
    address2: '',
    locality: '',
    landmark: '',
    city: '',
    country: '',
    state: '',
    state_name: '',
    pincode: '',
    mobile: '',
    new_updates: true,
    set_default: false,
    type: 'home',
    satday_open: 'N',
    sunday_open: 'N',
    billing_address: '',
  } as any;
  billing_address = {
    key: '',
    email: '',
    name: '',
    fname: '',
    lname: '',
    address1: '',
    address2: '',
    locality: '',
    landmark: '',
    city: '',
    country: '',
    state: '',
    state_name: '',
    pincode: '',
    mobile: '',
    new_updates: true,
    set_default: false,
    type: 'home',
    satday_open: 'N',
    sunday_open: 'N',
  } as any;
  payTm = {
    ACTION: '',
    ORDER_ID: '',
    CUST_ID: '',
    INDUSTRY_TYPE_ID: '',
    CHANNEL_ID: '',
    TXN_AMOUNT: '',
  } as any;
  countries = [] as any;
  states = [] as any;
  errors = {} as any;
  order_key = '' as string;
  razorpay_url = '' as string;
  giftwrap = false;

  //------
  show_popup = false;

  //--coupons popup--
  coupon_code = '';


  constructor(
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public toastr: ToastrService,
    private auth: AuthenticationService,
    public addressService: AddressServiceService,
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    //console.log("cart:ngAfterViewInit");
    this.customer_key = atob(await this.globals.get_customer_key());
    this.globals.cart_count_observer.subscribe(x => this.cart_count = x);
    //this.globals.set_checkout_active('checkout-payment');

    if (!this.globals.is_logged_in()) {
      this.globals.set_page_loader(false);
      this.router.navigate(['/']);
    }

    this.get_cart_data();

    if (isPlatformBrowser(this.globals.platformId)) {
      let shipping_address = JSON.parse(await localStorage.getItem('shipping_address') as any);
      let billing_address_type = JSON.parse(await localStorage.getItem('billing_address_type') as any);
      let billing_address = JSON.parse(await localStorage.getItem('billing_address') as any);
      let giftwrap = await localStorage.getItem('giftwrap') as string;
      //let checkout_cart = JSON.parse(await localStorage.getItem('checkout_cart') as any);

      this.address = (shipping_address != null) ? shipping_address : this.address;
      this.billing_address = (billing_address != null) ? billing_address : this.billing_address;
      this.billing_address_type = (billing_address_type != null) ? billing_address_type : this.billing_address_type;
      this.giftwrap = (giftwrap == 'true') ? true : false;

      //--coupons--
      this.coupon_code = await localStorage.getItem('coupon_code') as string;

      if (this.coupon_code != null && this.coupon_code.length > 0)
        await this.get_coupon_details();
    }



    /**
    * close the search bar if it's open. set it to falses
    */
    this.globals.set_suggestion_bar_state(false);
  }


  async get_cart_data() {
    let postData = new FormData();

    postData.append('action', 'cart/list');
    this.globals.set_page_loader(true);

    const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${this.customer_key}/cart`, postData).subscribe((response) => {
      //console.log(response);
      //this.rows = response.data;
      this.cart = response.cart;

      setTimeout(() => {
        this.globals.set_page_loader(false);
        //this.globals.set_cart_count(this.item_count());
        //console.log('setTimeout');
      }, 0.2 * 1000);
    });
  }

  validate(): boolean {
    this.errors = {};

    if (this.billing_address_type == 'diff') {
      /*if (this.billing_address.fname.length == 0) {
        this.errors.fname = "Please enter your First name";
      }
      if (this.billing_address.lname.length == 0) {
        this.errors.lname = "Please enter your Last name";
      }
      if (this.billing_address.address1.length == 0) {
        this.errors.address1 = "Please enter your Address";
      }
      if (this.billing_address.city.length == 0) {
        this.errors.city = "Please enter City";
      }
      if (this.billing_address.country.length == 0) {
        this.errors.country = "Please enter Country";
      }
      if (this.billing_address.state.length == 0) {
        this.errors.state = "Please enter State";
      }
      if (this.billing_address.pincode.length == 0) {
        this.errors.pincode = "Please enter pincode";
      }
      if (this.billing_address.pincode.length > 0 && this.billing_address.pincode.length < 6) {
        this.errors.pincode = "Please enter a valid pincode";
      }
      if (this.billing_address.phone.length == 0) {
        this.errors.phone = "Please enter Contact number";
      }
      if (this.billing_address.phone.length > 0 && this.billing_address.phone.length < 10) {
        this.errors.phone = "Please enter a valid Contact number";
      }*/

      if (this.address.billing_address.length == 0) {
        this.errors.billing_address = "Please enter your billing address";
      }
    }

    //console.log(this.errors);
    return Object.entries(this.errors).length == 0 ? true : false;
  }

  async save() {
    let postData = new FormData();

    postData.append('action', 'checkout/payment');

    postData.append('cart_total', this.cart.total);
    postData.append('total_payable', this.total_payable()); //
    postData.append('shipping', this.cart.shipping);

    postData.append('new_updates', this.address.new_updates);
    postData.append('set_default', this.address.set_default);
    postData.append('email', this.address.email);

    let tempName1 = this.address.name.split(' ');

    if (tempName1.length > 0) {
      postData.append('shipping_address[fname]', tempName1[0]);
    }
    if (tempName1.length >= 2) {
      let lname = tempName1.slice(1, tempName1.length).join(' ');
      postData.append('shipping_address[lname]', lname);
    }
    postData.append('shipping_address[email]', this.address.email);
    postData.append('shipping_address[mobile]', this.address.mobile);
    postData.append('shipping_address[address1]', this.address.address1);
    postData.append('shipping_address[address2]', this.address.address2);
    postData.append('shipping_address[country]', this.address.country);
    postData.append('shipping_address[state]', this.address.state);
    postData.append('shipping_address[city]', this.address.city);
    postData.append('shipping_address[landmark]', this.address.landmark);
    postData.append('shipping_address[locality]', this.address.locality);
    postData.append('shipping_address[pincode]', this.address.pincode);
    postData.append('shipping_address[set_default]', this.address.set_default);
    postData.append('shipping_address[type]', this.address.type);
    postData.append('shipping_address[satday_open]', this.address.satday_open);
    postData.append('shipping_address[sunday_open]', this.address.sunday_open);
    postData.append('shipping_address[billing_address]', this.address.billing_address);


    let tempName2 = this.billing_address.name.split(' ');

    if (tempName2.length > 0) {
      postData.append('shipping_address[fname]', tempName1[0]);
    }
    if (tempName2.length >= 2) {
      let lname = tempName2.slice(1, tempName2.length).join(' ');
      postData.append('billing_address[lname]', lname);
    }
    postData.append('billing_address[email]', this.billing_address.email);
    postData.append('billing_address[mobile]', this.billing_address.mobile);
    postData.append('billing_address[address1]', this.billing_address.address1);
    postData.append('billing_address[address2]', this.billing_address.address2);
    postData.append('billing_address[country]', this.billing_address.country);
    postData.append('billing_address[state]', this.billing_address.state);
    postData.append('billing_address[city]', this.billing_address.city);
    postData.append('billing_address[landmark]', this.billing_address.landmark);
    postData.append('billing_address[locality]', this.billing_address.locality);
    postData.append('billing_address[pincode]', this.billing_address.pincode);
    postData.append('billing_address[set_default]', this.billing_address.set_default);
    postData.append('billing_address[type]', this.billing_address.type);
    postData.append('billing_address[satday_open]', this.billing_address.satday_open);
    postData.append('billing_address[sunday_open]', this.billing_address.sunday_open);

    postData.append('billing_address_type', this.billing_address_type);
    postData.append('pay_type', this.pay_type);

    postData.append('giftwrap', this.giftwrap.toString());

    if (this.coupon_code != null && this.coupon_code.length > 0) {
      postData.append('coupon_code', this.cart.coupon_code.toString());
      postData.append('coupon_key', this.cart.coupon.key.toString());
    }

    if (this.validate()) {
      const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${this.customer_key}/checkout/payment`, postData).subscribe((response) => {
        //console.log(response);
        if (response.msg == 'success') {


          switch (this.pay_type) {
            case "COD": {
              if (isPlatformBrowser(this.globals.platformId)) {
                localStorage.removeItem('billing_address');
                localStorage.removeItem('billing_address_type');
                localStorage.removeItem('shipping_address');
                localStorage.removeItem('giftwrap');
              }

              this.router.navigate([`/checkout/success/${response.order_key}`]);
              break;
            }
            case "RZPY": {
              this.order_key = response.order_key;
              this.razorpay_url = this.globals.get_server_payment_url() + `/account/${btoa(this.customer_key)}/checkout/payment/razorpay/${response.order_key}`;
              window.location.href = this.razorpay_url;
              break;
            }
            case "PYTM": {
              this.order_key = response.order_key;
              let paytm_url = this.globals.get_server_payment_url() + `/account/${(this.customer_key)}/checkout/payment/paytm/${response.order_key}`;

              this.payTm.ACTION = paytm_url;
              this.payTm.ORDER_ID = btoa(response.data.order_key);
              this.payTm.CUST_ID = btoa(response.data.customer_key);
              this.payTm.INDUSTRY_TYPE_ID = 'Retail';
              this.payTm.CHANNEL_ID = 'WEB';
              this.payTm.TXN_AMOUNT = response.data.total_payable;

              window.location.href = paytm_url;

              if (isPlatformBrowser(this.globals.platformId)) {
                let form = document.getElementById('paytm') as any;
                setTimeout(() => {
                  //form.submit();
                }, 0.5 * 1000);
              }
              //window.location.href = paytm_url;
              break;
            }
          }

          //localStorage.setItem('billing_address', JSON.stringify(this.billing_address));
          //localStorage.setItem('billing_address_type', JSON.stringify(this.billing_address_type));

        }
      });
    }

  }

  remove_error(name: string) {
    let err = this.errors;
    delete err[name];

    var preg = /^[0-9 ]+$/;
    var regx = /[^0-9]+/g;

    if (name == 'phone') {
      if (this.billing_address.phone.length > 0 && !preg.test(this.billing_address.phone)) {
        err.phone = 'Invalid contact number';
        let phone = this.billing_address.phone;
        phone = phone.replace(regx, '');
        this.billing_address.phone = phone;
        ////console.log([phone, this.address]);
      }
      if (this.billing_address.phone.length > 10) {
        let phone = this.billing_address.phone as string;
        phone = phone.substring(0, 10);
        this.billing_address.phone = phone;
      }
    }
    if (name == 'pincode') {
      if (this.billing_address.pincode.length > 0 && !preg.test(this.billing_address.pincode)) {
        err.pincode = 'Invalid Pincode number';
        let pincode = this.billing_address.pincode;
        pincode = pincode.replace(regx, '');
        this.billing_address.pincode = pincode;
        //console.log([pincode, this.billing_address]);
      }
      if (this.billing_address.pincode.length > 6) {
        let pincode = this.billing_address.pincode as string;
        pincode = pincode.substring(0, 6);
        this.billing_address.pincode = pincode;
      }
    }


    this.errors = err;
  }

  show_address(): string {
    let addrs = '';
    addrs += (this.address.address1.length > 0) ? this.address.address1 : '';
    addrs += (this.address.locality.length > 0) ? ', ' + this.address.locality : '';
    addrs += (this.address.city.length > 0) ? ', ' + this.address.city : '';
    addrs += (this.address.state_name.length > 0) ? ', ' + this.address.state_name : '';
    addrs += (this.address.pincode.length > 0) ? ', ' + this.address.pincode : '';

    return addrs;
  }

  get_sate_name(key: string): string {
    ////console.log(this.states.length);

    for (let kk = 0; kk < this.states.length; kk++) {
      ////console.log((this.states[kk].key).toString());
      if (key == (this.states[kk].key).toString())
        return this.states[kk].name;
    }

    return '';
  }

  reset_billing_address() {
    if (this.billing_address_type == 'same') {
      this.billing_address = {
        key: '',
        email: '',
        name: '',
        fname: '',
        lname: '',
        address1: '',
        address2: '',
        locality: '',
        landmark: '',
        city: '',
        country: '',
        state: '',
        pincode: '',
        mobile: '',
        new_updates: true,
        set_default: false,
        satday_open: 'N',
        sunday_open: 'N',
      } as any;
    }
    if (this.billing_address_type == 'diff') {
      this.address.billing_address = '';
    }
  }

  hide_address_popup(state: boolean) {
    this.show_popup = state;
    this.billing_address_type = 'same';
    //this.get_data();
  }

  round(num: number) {
    return Math.round(num);
  }

  total_payable() {
    return (this.cart.price_payable + this.cart.shipping + ((this.giftwrap) ? 25 : 0))
  }

  /*/---COUPONS----*/
  async get_coupon_details() {
    let postData = {
      action: 'account/coupons/view',
      coupon_code: this.coupon_code,
    };
    this.globals.set_page_loader(true);

    if (this.customer_key !== null && this.customer_key.length > 0) {
      const result = await this.httpClient.post<any>(
        this.globals.get_endpoint() + `/account/customer/${btoa(this.customer_key)}/coupons/${this.coupon_code}`
        , postData
      ).subscribe((response) => {
        if (response.msg == 'success') {
          this.cart.coupon = response.data;
          this.cart.coupon_code = response.data.code;

          this.price_payable();


          setTimeout(() => {
            this.globals.set_page_loader(false);
          }, 0.5 * 1000);
        } else {
          this.globals.set_page_loader(false);
        }
      });
    } else {
      this.globals.set_page_loader(false);
    }
  }

  price_payable() {
    let total = 0;

    if (Object.entries(this.cart).length > 0) {
      total = this.cart.total;

      if (this.cart.coupon != undefined && Object.entries(this.cart.coupon).length > 0) {
        let discount = 0;

        if (this.cart.coupon.type == 'flat') {
          discount = this.cart.coupon.discount;
        }

        if (this.cart.coupon.type == 'percent') {
          discount = Math.round(Math.abs((this.cart.total * this.cart.coupon.discount) / 100));
        }

        if (this.cart.coupon.has_min_limit == 'Y' && (this.cart.total >= this.cart.coupon.min_purchase)) {
          total = total - discount;
        } else {
          total = total - discount;
        }

        this.cart.maximum_saving = discount;
        console.log(discount);
      }

      //console.log(this.giftwrap);
      if (this.giftwrap)
        total = total + 25;

      this.cart.price_payable = total + this.cart.shipping;
    }

    return total;
  }

}
