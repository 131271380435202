import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AddEditAddressComponent } from 'src/app/components/add-edit-address/add-edit-address.component';
import { AddressServiceService } from 'src/app/services/address-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-checkout-address',
  templateUrl: './checkout-address.component.html',
  styleUrls: ['./checkout-address.component.scss']
})
export class CheckoutAddressComponent implements OnInit {

  public order_sum: boolean = false;

  rows = [] as any;
  cart = {
    total: 0,
    price: 0,
    price_payable: 0,
    discount_amount: 0,
    item_qnty: 0,
    shipping: 0,
    coupon_code: '',
    coupon: {} as any,
    maximum_saving: 0,
  } as any;
  customer_key = '';
  cart_count = 0;

  address = {
    key: '',
    email: '',
    name: '',
    fname: '',
    lname: '',
    address1: '',
    address2: '',
    locality: '',
    landmark: '',
    city: '',
    country: '',
    state: '',
    pincode: '',
    mobile: '',
    new_updates: true,
    set_default: false,
    satday_open: 'N',
    sunday_open: 'N',
  } as any;
  countries = [] as any;
  states = [] as any;
  errors = {} as any;
  giftwrap = false;

  //------
  show_popup = false;

  //--coupons popup--
  coupon_code = '';

  constructor(
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public toastr: ToastrService,
    private auth: AuthenticationService,
    public addressService: AddressServiceService,
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    //console.log("cart:ngAfterViewInit");
    this.customer_key = atob(await this.globals.get_customer_key()) as string;
    this.globals.cart_count_observer.subscribe(x => this.cart_count = x);
    this.addressService.rows_observer.subscribe((rows) => this.rows = rows);
    this.addressService.address_observer.subscribe((address) => this.addressService.address = address);
    this.addressService.mode_observer.subscribe((value) => this.addressService.mode = value);
    this.addressService.address_type_observer.subscribe((value) => this.addressService.address_type = value);
    this.addressService.set_address_type('shipping');
    this.globals.set_page_loader(true);
    this.globals.set_checkout_active('checkout-address');

    if (!this.globals.is_logged_in()) {
      this.globals.set_page_loader(false);
      this.router.navigate(['/']);
    }

    this.address = {} as any;
    await this.get_data();
    await this.get_cart_data();

    if (isPlatformBrowser(this.globals.platformId)) {
      let giftwrap = await localStorage.getItem('giftwrap') as string;
      this.giftwrap = (giftwrap == 'true') ? true : false;

      this.coupon_code = await localStorage.getItem('coupon_code') as string;

      if (this.coupon_code != null && this.coupon_code.length > 0)
        await this.get_coupon_details();
    }

    /**
     * close the search bar if it's open. set it to falses
     */
    this.globals.set_suggestion_bar_state(false);
  }

  async get_data() {
    const postData = new FormData();
    this.globals.set_page_loader(true);

    postData.append('action', 'account/addresses');
    postData.append(this.globals.customer_key, this.customer_key);

    const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${this.customer_key}/addresses`, postData)
      .subscribe((response) => {
        ////console.log(response);
        if (response.msg == 'success') {
          let data = response.data;

          this.rows = response.data;
          //this.states = response.states;
          //this.countries = response.countries;
          this.globals.set_page_loader(false);
        } else {
          this.globals.set_page_loader(false);
        }
      });
  }

  async get_cart_data() {
    let postData = new FormData();

    postData.append('action', 'cart/list');
    this.globals.set_page_loader(true);

    const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${this.customer_key}/cart`, postData).subscribe((response) => {
      //console.log(response);
      //this.rows = response.data;
      this.cart = response.cart;

      setTimeout(() => {
        this.globals.set_page_loader(false);
        //this.globals.set_cart_count(this.item_count());
        //console.log('setTimeout');
      }, 0.2 * 1000);
    });
  }

  validate() {
    let customer = this.auth.customer;
    this.errors = {} as any;

    if (!this.check_default_address()) {
      this.errors.set_default = "Please select a shipping address";
      this.toastr.success('Please select a shipping address!', '', { positionClass: 'toast-bottom-center' });
    }

    //console.log([this.errors, this.auth.customer]);
    return (Object.entries(this.errors).length == 0) ? true : false;
  }
  async save() {
    if (this.validate()) {
      let shipping = {} as any;

      for (let kk = 0; kk < this.rows.length; kk++) {
        if (this.rows[kk].set_default) {
          shipping = this.rows[kk];
        }
        //console.log(kk);
      }

      if (this.addressService.address_type = 'shipping')
        localStorage.setItem('shipping_address', JSON.stringify(shipping));

      localStorage.setItem('giftwrap', this.giftwrap.toString());

      //await localStorage.setItem('shipping_address', JSON.stringify(this.addressService.address));
      //await localStorage.setItem('checkout_cart', JSON.stringify(this.cart));
      //await localStorage.setItem('checkout_rows', JSON.stringify(this.ca));
      //await localStorage.setItem('countries', JSON.stringify(this.countries));
      //await localStorage.setItem('states', JSON.stringify(this.states));

      this.router.navigate(['/checkout/payment']);
    }
    //console.log(this.address);
  }

  check_default_address(): boolean {
    let found = false;

    for (let kk = 0; kk < this.rows.length; kk++) {
      if (this.rows[kk].set_default) {
        found = true;
      }
    }

    return found;
  }

  show_update_address_popup(indx: any) {
    //this.addressService.address = this.rows[indx];
    this.addressService.set_address(this.rows[indx]);
    this.addressService.set_mode('edit');
    this.show_address_popup();
  }

  select_address(indx: any) {
    for (let kk = 0; kk < this.rows.length; kk++) {
      if (kk.toString() == indx.toString()) {
        this.rows[kk].set_default = true;
        this.address = this.rows[kk];

        if (isPlatformBrowser(this.globals.platformId)) {
          //localStorage.setItem('shipping_address', JSON.stringify(this.address));
        }
      } else {
        this.rows[kk].set_default = false;
        this.address = {} as any;
      }
    }
  }

  show_address_popup() {
    this.show_popup = true;
  }

  hide_address_popup(state: boolean) {
    this.show_popup = state;
    this.get_data();
  }

  /*/---COUPONS----*/
  async get_coupon_details() {
    let postData = {
      action: 'account/coupons/view',
      coupon_code: this.coupon_code,
    };
    this.globals.set_page_loader(true);

    if (this.customer_key !== null && this.customer_key.length > 0) {
      const result = await this.httpClient.post<any>(
        this.globals.get_endpoint() + `/account/customer/${btoa(this.customer_key)}/coupons/${this.coupon_code}`
        , postData
      ).subscribe((response) => {
        if (response.msg == 'success') {
          this.cart.coupon = response.data;
          this.cart.coupon_code = response.data.code;

          this.price_payable();


          setTimeout(() => {
            this.globals.set_page_loader(false);
          }, 0.5 * 1000);
        } else {
          this.globals.set_page_loader(false);
        }
      });
    } else {
      this.globals.set_page_loader(false);
    }
  }

  price_payable() {
    let total = 0;

    if (Object.entries(this.cart).length > 0) {
      total = this.cart.total;

      if (this.cart.coupon != undefined && Object.entries(this.cart.coupon).length > 0) {
        let discount = 0;

        if (this.cart.coupon.type == 'flat') {
          discount = this.cart.coupon.discount;
        }

        if (this.cart.coupon.type == 'percent') {
          discount = Math.round(Math.abs((this.cart.total * this.cart.coupon.discount) / 100));
        }

        if (this.cart.coupon.has_min_limit == 'Y' && (this.cart.total >= this.cart.coupon.min_purchase)) {
          total = total - discount;
        } else {
          total = total - discount;
        }

        this.cart.maximum_saving = discount;
        console.log(discount);
      }

      //console.log(this.giftwrap);
      if (this.giftwrap)
        total = total + 25;

      this.cart.price_payable = total + this.cart.shipping;
    }

    return total;
  }

}
