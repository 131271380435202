<header class="flex site-header other-header">
    <div class="flex position-center w-100 logo">
        <svg viewBox="0 0 1908.000000 272.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,272.000000) scale(0.100000,-0.100000)"
            fill="currentColor" stroke="none">
            <path d="M7816 2619 c-24 -52 -299 -627 -611 -1279 -312 -652 -569 -1193 -572
            -1202 -5 -17 13 -18 274 -18 l279 0 128 280 129 280 417 0 417 0 128 -280 129
            -280 284 0 c156 0 281 3 279 8 -2 4 -280 585 -617 1292 -337 707 -614 1287
            -616 1289 -2 2 -24 -38 -48 -90z m339 -1530 c6 -18 -2 -19 -294 -19 -166 0
            -301 2 -301 4 0 3 61 146 136 318 75 172 142 329 150 348 l15 35 144 -333 c79
            -184 147 -342 150 -353z"/>
            <path d="M10232 1408 l3 -1283 240 0 240 0 3 758 2 758 818 -772 c449 -425
            838 -793 865 -818 l47 -45 0 1287 0 1287 -237 -2 -238 -3 -5 -756 -5 -756
            -860 813 c-473 448 -863 814 -868 814 -4 0 -6 -577 -5 -1282z"/>
            <path d="M1090 2605 c-185 -27 -363 -91 -517 -187 -114 -70 -281 -234 -357
            -351 -98 -149 -172 -341 -195 -511 -16 -115 -14 -347 5 -457 55 -324 224 -604
            481 -798 80 -61 257 -151 364 -185 263 -84 630 -80 882 11 426 153 711 487
            808 946 29 134 31 388 5 522 -87 456 -374 794 -804 949 -181 65 -469 91 -672
            61z m455 -488 c285 -93 469 -337 505 -670 47 -437 -196 -811 -580 -892 -92
            -19 -250 -19 -348 1 -282 57 -514 302 -573 604 -16 84 -16 286 0 370 58 298
            288 543 566 604 112 25 327 16 430 -17z"/>
            <path d="M14850 2609 c-427 -48 -792 -283 -979 -631 -104 -192 -141 -337 -148
            -584 -6 -208 8 -322 59 -484 140 -439 509 -754 978 -834 287 -49 574 -17 797
            89 340 161 566 456 653 854 21 96 47 369 37 395 -6 15 -60 16 -607 16 l-600 0
            0 -200 0 -200 350 0 c193 0 350 -2 350 -5 0 -2 -11 -34 -25 -71 -80 -213 -249
            -368 -462 -426 -105 -28 -316 -30 -418 -4 -226 58 -416 216 -516 429 -95 205
            -104 516 -20 732 77 198 250 367 447 436 238 83 515 59 698 -60 71 -46 164
            -137 208 -202 16 -24 34 -43 40 -41 9 2 340 194 395 228 l23 14 -37 53 c-63
            89 -222 240 -317 302 -255 166 -566 232 -906 194z"/>
            <path d="M3840 1345 l0 -1225 250 0 250 0 2 443 3 442 109 0 109 0 285 -442
            284 -443 295 0 294 0 -60 87 c-33 49 -181 261 -329 473 l-268 384 95 48 c210
            103 334 254 391 473 28 104 30 287 5 395 -73 319 -336 533 -710 579 -54 6
            -275 11 -547 11 l-458 0 0 -1225z m943 780 c116 -30 200 -95 247 -190 22 -44
            25 -63 25 -160 0 -97 -3 -116 -25 -160 -48 -98 -146 -168 -268 -194 -29 -6
            -136 -11 -237 -11 l-185 0 0 365 0 365 193 0 c139 0 209 -4 250 -15z"/>
            <path d="M17520 1345 l0 -1225 780 0 780 0 0 210 0 210 -535 0 -535 0 0 335 0
            335 500 0 500 0 0 210 0 210 -500 0 -500 0 0 260 0 260 535 0 535 0 0 210 0
            210 -780 0 -780 0 0 -1225z"/>
            </g>
          </svg>
    </div>
</header>
