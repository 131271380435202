<div class="other-page">
    <div class="flex column container" style="max-width: 1000px">
       <div class="flex position-center other-page-heading">ABOUT US</div>
       <div class="content">
        <p>Hello and welcome to ORANGE, the place to find the best fashion products for every taste and occasion. We thoroughly check the quality of our goods, working only with reliable suppliers so that you only receive the best quality product. We at orange believe in high quality and exceptional customer service. But most importantly, we believe shopping is a right, not a luxury, so we strive to deliver the best products at the most affordable prices, and ship them to you regardless of where you are located.</p>
       
        <p>&nbsp;</p>

        <p>Warm Regards,<br />
        Team ORANGE<br />
        www.orangefashion.co</p>
    </div>
    </div>
</div>