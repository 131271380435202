<div class="flex review-popup-box">
    <a title="Close" class="flex position-center modal-close"></a>
    <div class="flex position-center popup-review-pic-container">
        <swiper [navigation]="true" [pagination]="false" [loop]="false" [lazy]="true" [autoplay]="false" class="flex position-center">     
            <ng-container>
                <ng-template swiperSlide>
                  <img class=""
                    src="../assets/images/home-slider1.jpg"
                    alt="">
                </ng-template>
              </ng-container>
              <ng-container>
                <ng-template swiperSlide>
                  <img class=""
                    src="../assets/images/home-slider2.jpg"
                    alt="">
                </ng-template>
              </ng-container>
        </swiper>

    </div>



    <div class="popup-user-review">
        <div class="flex flex-column single-review rating-3">
            <div class="flex single-review-details">
                <div class="flex allign-middle single-r-r">
                    <span>3</span>
                    <span class="star">★</span>
                </div>
                <div class="flex allign-middle single-review-user">
                    <div class="name">Debapriyo Maity</div>
                    <div class="date">13.05.2022</div>
                </div>
            </div>
            <div class="flex flex-column single-r-comments">
                <div class="text">
                  It’s pretty! It doesn’t have any buttons so you need to make sure the knots are tied properly and
                  tight. I thought the colours would be more poppy and saturated. Was a little disappointed with
                  that. Overall it’s a cute top. Will go well on brown / beige pants 🤎
                </div>
            </div> 
        </div>
    </div>
</div>