import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalConfig, NgbModal, NgbActiveModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { WebModule } from 'src/app/modules/web/web.module';
import { GlobalsService } from 'src/app/services/globals.service';
import { isPlatformBrowser } from '@angular/common';
import { LsvComponent } from '../../components/lsv/lsv.component';
import { CartComponent } from 'src/app/pages/cart/cart.component';

@Component({
  selector: 'app-menu-side',
  templateUrl: './menu-side.component.html',
  styleUrls: ['./menu-side.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class MenuSideComponent implements OnInit {

  toggle: boolean = true;
  selectedCategory = '';
  public webModule: WebModule;

  constructor(
    private modalService: NgbModal,
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private offcanvasService: NgbOffcanvas,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  catToggle(name: string) {
    this.toggle = !this.toggle;
    this.selectedCategory = name;
  }

  categoryHide() {
    this.toggle = !this.toggle;
    this.selectedCategory = '';
    //this.close_menu();
    console.log(this.offcanvasService.hasOpenOffcanvas());
  }

  close_menu() {
    const offcanvasRef = this.modalService.dismissAll('close modal');
    console.log(this.offcanvasService.activeInstance);
    this.offcanvasService.dismiss('Canvas closed');
    //this.modal.dismissAll('close')
    //console.log(this.modal);
  }

  ngOnInit(): void {
  }

  ngAfterContentInit() {
    this.globals.web_observer.subscribe(web => this.webModule = web);

    let elements = document.querySelectorAll('.side-menu a') as any;
    console.log(elements);
  }

  logout() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(this.globals.customer_key);
      localStorage.removeItem('name');
      localStorage.removeItem('fname');
      window.location.reload();
    }
  }

  openLsv() {
    this.close_menu();

    setTimeout(() => {
      const modalRe = this.modalService.open(LsvComponent, { centered: true, modalDialogClass: 'lsv-modal' });
      modalRe.componentInstance.name = 'userLsv';
    }, 0.5 * 1000);
  }

  openCart() {
    this.close_menu();

    setTimeout(() => {
      const offcanvasRef = this.offcanvasService.open(CartComponent, { panelClass: 'cart-panel', position: 'end' });
      offcanvasRef.componentInstance.name = 'cart';
    }, 0.5 * 1000);
  }

}
