import { Component, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialAuthService } from 'angularx-social-login';
import { NgOtpInputComponent, NgOtpInputConfig } from 'ng-otp-input';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';


@Component({
  selector: 'app-lsv-otp',
  templateUrl: './lsv-otp.component.html',
  styleUrls: ['./lsv-otp.component.scss']
})
export class LsvOtpComponent implements OnInit {

  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;

  config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '48px',
      'font-size': '26px'
    }
  };

  constructor(
    public globals: GlobalsService,
    public toastr: ToastrService,
    public auth: AuthenticationService,
    public modalService: NgbModal,
    private authService: SocialAuthService,
  ) { }

  ngOnInit(): void {

  }

  async ngAfterViewInit() {
    console.log(this.auth);
    /**
     * close the search bar if it's open. set it to falses
     */
    this.globals.set_suggestion_bar_state(false);
  }

  onOtpChange(otp: any) {
    this.auth.otp = otp;
    //console.log(event);
  }

}
