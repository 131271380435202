<div class="search-result-page">
  <div class="container">
    <div class="flex column result-box">
      <div class="flex top-section">
        <div class="sort-backdrop" (click)="sortToggle()" [ngClass]="{'sort-closed': toggle2, 'sort-opened': !toggle2}">
        </div>
        <!-- filter button -->
        <div class="top-item flex left">
          <div class="flex allign-middle filter" (click)="change()">
            <svg xmlns="http://www.w3.org/2000/svg" [ngClass]="{'filter-closed': toggle, 'filter-opened': !toggle}"
              viewBox="0 0 512 512">
              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                d="M368 128h80M64 128h240M368 384h80M64 384h240M208 256h240M64 256h80" />
              <circle cx="336" cy="128" r="32" fill="none" stroke="currentColor" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="32" />
              <circle cx="176" cy="256" r="32" fill="none" stroke="currentColor" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="32" />
              <circle cx="336" cy="384" r="32" fill="none" stroke="currentColor" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="32" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" [ngClass]="{'not-active': toggle, 'active': !toggle}"
              viewBox="0 0 512 512">
              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                d="M368 368L144 144M368 144L144 368" />
            </svg>
            <span class="title">Filter</span>
          </div>
        </div>
        <!-- ./filter button -->

        <div class="flex position-center top-item middle">
          <div class="flex position-center search-heading">
            <span>
              SHOWING <span style="font-weight: 700;">{{rows.length}}</span> of <span
                style="font-weight: 700;">{{count}}</span>
              RESULTS FOR "<span>{{search_title}}</span>"
            </span>
          </div>
        </div>

        <!-- sort by -->
        <div class="flex top-item right">
          <div class="flex flex-column position-center sort-sortBy"
            [ngClass]="{'sort-closed': toggle2, 'sort-opened': !toggle2}">
            <div class="flex allgn-middle text" (click)="sortToggle()">
              <!-- Sort by : -->
              <span>Sort by</span>
            </div>
            <ul class="flex flex-column sort-list" [ngClass]="{'sort-closed': toggle2, 'sort-opened': !toggle2}">
              <li (click)="set_sortby('new')">
                <label class="sort-label ">
                  <input type="radio" value="new">
                  What's New
                </label>
              </li>
              <li (click)="set_sortby('popular')">
                <label class="sort-label">
                  <input type="radio" value="popularity">Popularity
                </label>
              </li>
              <li (click)="set_sortby('discounts')">
                <label class="sort-label">
                  <input type="radio" value="discount">
                  Better Discount
                </label>
              </li>
              <li (click)="set_sortby('h2l')">
                <label class="sort-label">
                  <input type="radio" value="price_desc">
                  Price: High to Low
                </label>
              </li>
              <li (click)="set_sortby('l2h')">
                <label class="sort-label">
                  <input type="radio" value="price_asc">
                  Price: Low to High
                </label>
              </li>
              <li (click)="set_sortby('rating')">
                <label class="sort-label ">
                  <input type="radio" value="Customer Rating">
                  Customer Rating
                </label>
              </li>
            </ul>
          </div>
        </div>
        <!-- ./sort by -->

      </div>

      <div class="flex position-relative bottom-section">

        <!-- filter section -->
        <section class="flex flex-column filter-container" [ngClass]="{'filter-hide': toggle, 'filter-show': !toggle}">
          <div class="backdrop" (click)="change()"></div>
          <!-- <div class="flex position-center filter-head">Filters</div> -->
          <div class="flex flex-column allign-middle bottom-options">
            <div class="flex flex-column w-100 different-filters">
              <div class="flex allign-middle title">Percent Sale</div>
              <ul class="categories-list">
                <li *ngFor="let discount of discounts; let indx=index;">
                  <label class="flex allign-middle custom-checkbox">
                    <input type="checkbox" value="Track Pants" value="{{discount.name}}"
                      [(ngModel)]="discounts[indx].checked" (change)="changeColor('discount',$event)">{{discount.name}}
                    <!--<span class="count">(11)</span>-->
                    <div class="checkbox-indicator"></div>
                  </label>
                </li>
              </ul>
            </div>

            <div class="flex flex-column w-100 different-filters hide">
              <div class="flex allign-middle title">Price</div>
              <ul class="categories-list">
                <li>
                  <label class="flex allign-middle custom-checkbox">
                    <input type="checkbox" value="299">Under Rs. 299
                    <!-- <span class="count">(11)</span> -->
                    <div class="checkbox-indicator"></div>
                  </label>
                </li>
                <li>
                  <label class="flex allign-middle custom-checkbox">
                    <input type="checkbox" value="499">Under Rs. 499
                    <!-- <span class="count">(11)</span> -->
                    <div class="checkbox-indicator"></div>
                  </label>
                </li>
                <li>
                  <label class="flex allign-middle custom-checkbox">
                    <input type="checkbox" value="799">Under Rs. 799
                    <!-- <span class="count">(11)</span> -->
                    <div class="checkbox-indicator"></div>
                  </label>
                </li>
                <li>
                  <label class="flex allign-middle custom-checkbox">
                    <input type="checkbox" value="999">Under Rs. 999
                    <!-- <span class="count">(11)</span> -->
                    <div class="checkbox-indicator"></div>
                  </label>
                </li>
                <li>
                  <label class="flex allign-middle custom-checkbox">
                    <input type="checkbox" value="1599">Under Rs. 1599
                    <!-- <span class="count">(11)</span> -->
                    <div class="checkbox-indicator"></div>
                  </label>
                </li>
              </ul>
            </div>

            <div class="flex flex-column w-100 different-filters" *ngIf="sizes_count() > 0">
              <div class="flex allign-middle title">Sizes</div>
              <ul class="categories-list">
                <ng-container *ngFor="let size of sizes; let indx=index;">
                  <li *ngIf="size!=='default'">
                    <label class="flex allign-middle custom-checkbox">
                      <input type="checkbox" value="Track Pants" value="{{size.name}}" [(ngModel)]="sizes[indx].checked"
                        (change)="changeColor('size',$event)">{{size.name}}
                      <!-- <span class="count">(1)</span> -->
                      <div class="checkbox-indicator"></div>
                    </label>
                  </li>
                </ng-container>
              </ul>
            </div>

            <div class="flex flex-column w-100 different-filters" *ngIf="colors_count() > 0">
              <div class="flex allign-middle title">Colors</div>
              <ul class="categories-list">
                <ng-container *ngFor="let color of colors; let indx=index;">
                  <li *ngIf="color.name!=null">
                    <label class="flex allign-middle custom-checkbox">
                      <input type="checkbox" class="discount-input" name="discount-product" value="{{color.name}}"
                        [(ngModel)]="colors[indx].checked" (change)="changeColor('color',$event)">
                      {{color.name}}
                      <!-- <span class="count">(5)</span> -->
                      <div class="checkbox-indicator"></div>
                    </label>
                  </li>
                </ng-container>
              </ul>
            </div>

            <div class="flex flex-column w-100 different-filters">
              <div class="flex allign-middle title">Categories</div>
              <ul class="categories-list">
                <li *ngFor="let category of categories; let indx=index;">
                  <label class="flex allign-middle custom-checkbox">
                    <input type="checkbox" value="Track Pants" value="{{category.name}}"
                      [(ngModel)]="categories[indx].checked" (change)="changeColor('category',$event)">{{category.name}}
                    <!-- <span class="count">(11)</span> -->
                    <div class="checkbox-indicator"></div>
                  </label>
                </li>
              </ul>
            </div>
            <div class="flex flex-column w-100 different-filters" *ngIf="0">
              <div class="flex allign-middle title">Product Type</div>
              <ul class="categories-list">
                <li>
                  <label class="flex allign-middle custom-checkbox">
                    <input type="checkbox" value="Track Pants">T-Shirts
                    <!-- <span class="count">(11)</span> -->
                    <div class="checkbox-indicator"></div>
                  </label>
                </li>
                <li>
                  <label class="flex allign-middle custom-checkbox">
                    <input type="checkbox" value="Track Pants">Hot Pants
                    <!-- <span class="count">(9)</span> -->
                    <div class="checkbox-indicator"></div>
                  </label>
                </li>
              </ul>
            </div>
          </div>
        </section>
        <!-- ./filter section -->

        <!-- products -->
        <div class="flex flex-wrap product-container" infiniteScroll [infiniteScrollDistance]="1"
          [infiniteScrollThrottle]="50" (scrolled)="onScroll()">

          <app-section-loader *ngIf="loading"></app-section-loader>

          <div class="flex product {{soldout_class(product)}}" *ngFor="let product of rows; let indx=index;">
            <div class="flex column product-content"
              routerLink="{{this.globals.get_product_url(product.slug, product.indx)}}"
              [queryParams]="{vkey: product.vindx}">
              <div class="flex image">
                <img class="flex main"
                  src="{{ (product.images[0] != undefined) ? this.globals.get_server_assets_url()+ '/uploads/products/medium/'+product.images[0] :'' }}"
                  alt="{{product.name}}">
                <img class="flex alternative"
                  src="{{ (product.images[1] != undefined) ? this.globals.get_server_assets_url()+ '/uploads/products/medium/'+ product.images[1] :'' }}"
                  alt="{{product.name}},{{product.vindx}}">

                <div class="flex sold-out-banner animated" *ngIf="product.count <= 0">
                  <div class="flex banner-container">
                    <div class="flex allign-middle text-message">
                      <p>SOLD OUT !</p>
                      <p>SOLD OUT !</p>
                      <p>SOLD OUT !</p>
                      <p>SOLD OUT !</p>
                    </div>
                    <div class="flex allign-middle text-message">
                      <p>SOLD OUT !</p>
                      <p>SOLD OUT !</p>
                      <p>SOLD OUT !</p>
                      <p>SOLD OUT !</p>
                    </div>
                  </div>
                </div>

                <div class="product-banner animated " *ngIf="(product.count > 0) && (product.count <=4)">
                  <div class="product-banner-cointainer">
                    <marquee behavior="scroll" direction="left" class="flex">
                      Only {{product.count}} left
                    </marquee>
                  </div>
                </div>
              </div>
              <div class="flex position-center w-100 name">
                <span>{{(product.name.length>40) ? product.name.substring(0,40)+'..' : product.name}}</span>
              </div>
              <div class="flex position-center w-100 price">
                <p class="new-price">Rs. <span>{{product.price_compare_at}}</span></p>
                <p class="old-price">Rs. <span>{{product.price}}</span></p>
                <p class="discount-price">(<span>{{product.discount}}%</span> OFF)</p>
              </div>
              <div class="flex flex-wrap position-center size" *ngIf="false">
                <ng-container *ngFor="let size of make_sizes_array(product)">
                  <a class="flex position-center size-chip size-{{size.name.toLowerCase()}}">{{size.name}}</a>
                </ng-container>
              </div>
            </div>
          </div>

          <ng-container *ngIf="0">
            <div class="flex product">
              <div class="flex column product-content">
                <div class="flex image">
                  <img class="flex main" src="../assets/images/p2.jpg">
                  <img class="flex alternative hide" src="../assets/images/p21.jpg">
                  <div class="flex product-banner animated">
                    <div class="flex product-banner-container">
                      <section class="flex allign-middle text-message">
                        <p>Only 4 left</p>
                        <p>Only 4 left</p>
                        <p>Only 4 left</p>
                        <p>Only 4 left</p>
                      </section>
                      <section class="flex allign-middle text-message">
                        <p>Only 4 left</p>
                        <p>Only 4 left</p>
                        <p>Only 4 left</p>
                        <p>Only 4 left</p>
                      </section>
                    </div>
                  </div>
                </div>
                <div class="flex position-center w-100 name">
                  <span>Griff Black Puffer Jacket</span>
                </div>
                <div class="flex position-center w-100 price">
                  <p class="new-price">Rs. <span>1,999</span></p>
                  <p class="old-price">Rs. <span>1,999</span></p>
                  <p class="discount-price">(<span>50%</span> OFF)</p>
                </div>
                <div class="flex flex-wrap position-center size">
                  <a class="flex position-center size-chip size-s">S</a>
                  <a class="flex position-center size-chip size-m">M</a>
                  <a class="flex position-center size-chip size-l stock-out">L</a>
                  <a class="flex position-center size-chip size-xl stock-out">XL</a>
                  <a class="flex position-center size-chip size-xxl stock-out">XXL</a>
                </div>
              </div>
            </div>
            <div class="flex product">
              <div class="flex column product-content">
                <div class="flex image">
                  <img class="flex main" src="../assets/images/p3.jpg">
                  <img class="flex alternative hide" src="../assets/images/p31.jpg">
                </div>
                <div class="flex position-center w-100 name">
                  <span>Griff Black Puffer Jacket</span>
                </div>
                <div class="flex position-center w-100 price">
                  <p class="new-price">Rs. <span>1,999</span></p>
                  <p class="old-price">Rs. <span>1,999</span></p>
                  <p class="discount-price">(<span>50%</span> OFF)</p>
                </div>
                <div class="flex flex-wrap position-center size">
                  <a class="flex position-center size-chip size-s stock-out">S</a>
                  <a class="flex position-center size-chip size-m stock-out">M</a>
                  <a class="flex position-center size-chip size-l">L</a>
                  <a class="flex position-center size-chip size-xl">XL</a>
                  <a class="flex position-center size-chip size-xxl">XXL</a>
                </div>
              </div>
            </div>
            <div class="flex product sold-out">
              <div class="flex column product-content">
                <div class="flex image">
                  <img class="flex main" src="../assets/images/p4.jpg">
                  <img class="flex alternative hide" src="../assets/images/p41.jpg">
                  <div class="flex product-banner animated">
                    <div class="flex product-banner-container">
                      <section class="flex allign-middle text-message">
                        <p>SOLD OUT !</p>
                        <p>SOLD OUT !</p>
                        <p>SOLD OUT !</p>
                        <p>SOLD OUT !</p>
                      </section>
                      <section class="flex allign-middle text-message">
                        <p>SOLD OUT !</p>
                        <p>SOLD OUT !</p>
                        <p>SOLD OUT !</p>
                        <p>SOLD OUT !</p>
                      </section>
                    </div>
                  </div>
                </div>
                <div class="flex position-center w-100 name">
                  <span>Griff Black Puffer Jacket</span>
                </div>
                <div class="flex position-center w-100 price">
                  <p class="new-price">Rs. <span>1,999</span></p>
                  <p class="old-price">Rs. <span>1,999</span></p>
                  <p class="discount-price">(<span>50%</span> OFF)</p>
                </div>
                <div class="flex flex-wrap position-center size">
                  <a class="flex position-center size-chip size-s stock-out">S</a>
                  <a class="flex position-center size-chip size-m">M</a>
                  <a class="flex position-center size-chip size-l">L</a>
                  <a class="flex position-center size-chip size-xl stock-out">XL</a>
                </div>
              </div>
            </div>
            <div class="flex product">
              <div class="flex column product-content">
                <div class="flex image">
                  <img class="flex main" src="../assets/images/p5.jpg">
                  <img class="flex alternative hide" src="../assets/images/p51.jpg">
                </div>
                <div class="flex position-center w-100 name">
                  <span>Griff Black Puffer Jacket</span>
                </div>
                <div class="flex position-center w-100 price">
                  <p class="new-price">Rs. <span>1,999</span></p>
                  <p class="old-price">Rs. <span>1,999</span></p>
                  <p class="discount-price">(<span>50%</span> OFF)</p>
                </div>
                <div class="flex flex-wrap position-center size">
                  <a class="flex position-center size-chip size-s">S</a>
                  <a class="flex position-center size-chip size-m stock-out">M</a>
                  <a class="flex position-center size-chip size-l stock-out">L</a>
                  <a class="flex position-center size-chip size-xl">XL</a>
                  <a class="flex position-center size-chip size-xxl">XXL</a>
                </div>
              </div>
            </div>
            <div class="flex product">
              <div class="flex column product-content">
                <div class="flex image">
                  <img class="flex main" src="../assets/images/p6.jpg">
                  <img class="flex alternative hide" src="../assets/images/p61.jpg">
                </div>
                <div class="flex position-center w-100 name">
                  <span>Griff Black Puffer Jacket</span>
                </div>
                <div class="flex position-center w-100 price">
                  <p class="new-price">Rs. <span>1,999</span></p>
                  <p class="old-price">Rs. <span>1,999</span></p>
                  <p class="discount-price">(<span>50%</span> OFF)</p>
                </div>
                <div class="flex flex-wrap position-center size">
                  <a class="flex position-center size-chip size-s">S</a>
                  <a class="flex position-center size-chip size-m">M</a>
                  <a class="flex position-center size-chip size-l stock-out">L</a>
                  <a class="flex position-center size-chip size-xl">XL</a>
                </div>
              </div>
            </div>
            <div class="flex product">
              <div class="flex column product-content">
                <div class="flex image">
                  <img class="flex main" src="../assets/images/p7.jpg">
                  <img class="flex alternative hide" src="../assets/images/p71.jpg">
                  <div class="flex product-banner animated">
                    <div class="flex product-banner-container">
                      <section class="flex allign-middle text-message">
                        <p>Only 2 left</p>
                        <p>Only 2 left</p>
                        <p>Only 2 left</p>
                        <p>Only 2 left</p>
                      </section>
                      <section class="flex allign-middle text-message">
                        <p>Only 2 left</p>
                        <p>Only 2 left</p>
                        <p>Only 2 left</p>
                        <p>Only 2 left</p>
                      </section>
                    </div>
                  </div>
                </div>
                <div class="flex position-center w-100 name">
                  <span>Griff Black Puffer Jacket</span>
                </div>
                <div class="flex position-center w-100 price">
                  <p class="new-price">Rs. <span>1,999</span></p>
                  <p class="old-price">Rs. <span>1,999</span></p>
                  <p class="discount-price">(<span>50%</span> OFF)</p>
                </div>
                <div class="flex flex-wrap position-center size">
                  <a class="flex position-center size-chip size-s">S</a>
                  <a class="flex position-center size-chip size-m stock-out">M</a>
                  <a class="flex position-center size-chip size-l">L</a>
                  <a class="flex position-center size-chip size-xl stock-out">XL</a>
                </div>
              </div>
            </div>
            <div class="flex product">
              <div class="flex column product-content">
                <div class="flex image">
                  <img class="flex main" src="../assets/images/p8.jpg">
                  <img class="flex alternative hide" src="../assets/images/p81.jpg">
                </div>
                <div class="flex position-center w-100 name">
                  <span>Griff Black Puffer Jacket</span>
                </div>
                <div class="flex position-center w-100 price">
                  <p class="new-price">Rs. <span>1,999</span></p>
                  <p class="old-price">Rs. <span>1,999</span></p>
                  <p class="discount-price">(<span>50%</span> OFF)</p>
                </div>
                <div class="flex flex-wrap position-center size">
                  <a class="flex position-center size-chip size-s stock-out">S</a>
                  <a class="flex position-center size-chip size-m stock-out">M</a>
                  <a class="flex position-center size-chip size-l">L</a>
                  <a class="flex position-center size-chip size-xl">XL</a>
                  <a class="flex position-center size-chip size-xxl">XXL</a>
                </div>
              </div>
            </div>
            <div class="flex product">
              <div class="flex column product-content">
                <div class="flex image">
                  <img class="flex main" src="../assets/images/p9.jpg">
                  <img class="flex alternative hide" src="../assets/images/p91.jpg">
                  <div class="flex product-banner animated">
                    <div class="flex product-banner-container">
                      <section class="flex allign-middle text-message">
                        <p>Only 4 left</p>
                        <p>Only 4 left</p>
                        <p>Only 4 left</p>
                        <p>Only 4 left</p>
                      </section>
                      <section class="flex allign-middle text-message">
                        <p>Only 4 left</p>
                        <p>Only 4 left</p>
                        <p>Only 4 left</p>
                        <p>Only 4 left</p>
                      </section>
                    </div>
                  </div>
                </div>
                <div class="flex position-center w-100 name">
                  <span>Griff Black Puffer Jacket</span>
                </div>
                <div class="flex position-center w-100 price">
                  <p class="new-price">Rs. <span>1,999</span></p>
                  <p class="old-price">Rs. <span>1,999</span></p>
                  <p class="discount-price">(<span>50%</span> OFF)</p>
                </div>
                <div class="flex flex-wrap position-center size">
                  <a class="flex position-center size-chip size-s">S</a>
                  <a class="flex position-center size-chip size-m">M</a>
                  <a class="flex position-center size-chip size-l stock-out">L</a>
                  <a class="flex position-center size-chip size-xl stock-out">XL</a>
                  <a class="flex position-center size-chip size-xxl stock-out">XXL</a>
                </div>
              </div>
            </div>
            <div class="flex product">
              <div class="flex column product-content">
                <div class="flex image">
                  <img class="flex main" src="../assets/images/p10.jpg">
                  <img class="flex alternative hide" src="../assets/images/p101.jpg">
                </div>
                <div class="flex position-center w-100 name">
                  <span>Griff Black Puffer Jacket</span>
                </div>
                <div class="flex position-center w-100 price">
                  <p class="new-price">Rs. <span>1,999</span></p>
                  <p class="old-price">Rs. <span>1,999</span></p>
                  <p class="discount-price">(<span>50%</span> OFF)</p>
                </div>
                <div class="flex flex-wrap position-center size">
                  <a class="flex position-center size-chip size-s stock-out">S</a>
                  <a class="flex position-center size-chip size-m stock-out">M</a>
                  <a class="flex position-center size-chip size-l">L</a>
                  <a class="flex position-center size-chip size-xl">XL</a>
                  <a class="flex position-center size-chip size-xxl">XXL</a>
                </div>
              </div>
            </div>
            <div class="flex product">
              <div class="flex column product-content">
                <div class="flex image">
                  <img class="flex main" src="../assets/images/p1111.jpg">
                  <img class="flex alternative hide" src="../assets/images/p111.jpg">
                </div>
                <div class="flex position-center w-100 name">
                  <span>Griff Black Puffer Jacket</span>
                </div>
                <div class="flex position-center w-100 price">
                  <p class="new-price">Rs. <span>1,999</span></p>
                  <p class="old-price">Rs. <span>1,999</span></p>
                  <p class="discount-price">(<span>50%</span> OFF)</p>
                </div>
                <div class="flex flex-wrap position-center size">
                  <a class="flex position-center size-chip size-s stock-out">S</a>
                  <a class="flex position-center size-chip size-m">M</a>
                  <a class="flex position-center size-chip size-l">L</a>
                  <a class="flex position-center size-chip size-xl stock-out">XL</a>
                </div>
              </div>
            </div>
            <div class="flex product">
              <div class="flex column product-content">
                <div class="flex image">
                  <img class="flex main" src="../assets/images/p121.jpg">
                  <img class="flex alternative hide" src="../assets/images/p12.jpg">
                </div>
                <div class="flex position-center w-100 name">
                  <span>Griff Black Puffer Jacket</span>
                </div>
                <div class="flex position-center w-100 price">
                  <p class="new-price">Rs. <span>1,999</span></p>
                  <p class="old-price">Rs. <span>1,999</span></p>
                  <p class="discount-price">(<span>50%</span> OFF)</p>
                </div>
                <div class="flex flex-wrap position-center size">
                  <a class="flex position-center size-chip size-s">S</a>
                  <a class="flex position-center size-chip size-m stock-out">M</a>
                  <a class="flex position-center size-chip size-l stock-out">L</a>
                  <a class="flex position-center size-chip size-xl">XL</a>
                  <a class="flex position-center size-chip size-xxl">XXL</a>
                </div>
              </div>
            </div>
            <div class="flex product">
              <div class="flex column product-content">
                <div class="flex image">
                  <img class="flex main" src="../assets/images/p13.jpg">
                  <img class="flex alternative hide" src="../assets/images/p131.jpg">
                </div>
                <div class="flex position-center w-100 name">
                  <span>Griff Black Puffer Jacket</span>
                </div>
                <div class="flex position-center w-100 price">
                  <p class="new-price">Rs. <span>1,999</span></p>
                  <p class="old-price">Rs. <span>1,999</span></p>
                  <p class="discount-price">(<span>50%</span> OFF)</p>
                </div>
                <div class="flex flex-wrap position-center size">
                  <a class="flex position-center size-chip size-s">S</a>
                  <a class="flex position-center size-chip size-m">M</a>
                  <a class="flex position-center size-chip size-l stock-out">L</a>
                  <a class="flex position-center size-chip size-xl">XL</a>
                </div>
              </div>
            </div>
            <div class="flex product">
              <div class="flex column product-content">
                <div class="flex image">
                  <img class="flex main" src="../assets/images/p141.jpg">
                  <img class="flex alternative hide" src="../assets/images/p14.jpg">
                  <div class="flex product-banner animated">
                    <div class="flex product-banner-container">
                      <section class="flex allign-middle text-message">
                        <p>Only 2 left</p>
                        <p>Only 2 left</p>
                        <p>Only 2 left</p>
                        <p>Only 2 left</p>
                      </section>
                      <section class="flex allign-middle text-message">
                        <p>Only 2 left</p>
                        <p>Only 2 left</p>
                        <p>Only 2 left</p>
                        <p>Only 2 left</p>
                      </section>
                    </div>
                  </div>
                </div>
                <div class="flex position-center w-100 name">
                  <span>Griff Black Puffer Jacket</span>
                </div>
                <div class="flex position-center w-100 price">
                  <p class="new-price">Rs. <span>1,999</span></p>
                  <p class="old-price">Rs. <span>1,999</span></p>
                  <p class="discount-price">(<span>50%</span> OFF)</p>
                </div>
                <div class="flex flex-wrap position-center size">
                  <a class="flex position-center size-chip size-s">S</a>
                  <a class="flex position-center size-chip size-m stock-out">M</a>
                  <a class="flex position-center size-chip size-l">L</a>
                  <a class="flex position-center size-chip size-xl stock-out">XL</a>
                </div>
              </div>
            </div>
            <div class="flex product">
              <div class="flex column product-content">
                <div class="flex image">
                  <img class="flex main" src="../assets/images/p15.jpg">
                  <img class="flex alternative hide" src="../assets/images/p151.jpg">
                </div>
                <div class="flex position-center w-100 name">
                  <span>Griff Black Puffer Jacket</span>
                </div>
                <div class="flex position-center w-100 price">
                  <p class="new-price">Rs. <span>1,999</span></p>
                  <p class="old-price">Rs. <span>1,999</span></p>
                  <p class="discount-price">(<span>50%</span> OFF)</p>
                </div>
                <div class="flex flex-wrap position-center size">
                  <a class="flex position-center size-chip size-s stock-out">S</a>
                  <a class="flex position-center size-chip size-m stock-out">M</a>
                  <a class="flex position-center size-chip size-l">L</a>
                  <a class="flex position-center size-chip size-xl">XL</a>
                  <a class="flex position-center size-chip size-xxl">XXL</a>
                </div>
              </div>
            </div>
            <div class="flex product">
              <div class="flex column product-content">
                <div class="flex image">
                  <img class="flex main" src="../assets/images/p16.jpg">
                  <img class="flex alternative hide" src="../assets/images/p161.jpg">
                </div>
                <div class="flex position-center w-100 name">
                  <span>Griff Black Puffer Jacket</span>
                </div>
                <div class="flex position-center w-100 price">
                  <p class="new-price">Rs. <span>1,999</span></p>
                  <p class="old-price">Rs. <span>1,999</span></p>
                  <p class="discount-price">(<span>50%</span> OFF)</p>
                </div>
                <div class="flex flex-wrap position-center size">
                  <a class="flex position-center size-chip size-s stock-out">S</a>
                  <a class="flex position-center size-chip size-m stock-out">M</a>
                  <a class="flex position-center size-chip size-l">L</a>
                  <a class="flex position-center size-chip size-xl">XL</a>
                  <a class="flex position-center size-chip size-xxl">XXL</a>
                </div>
              </div>
            </div>
          </ng-container>

        </div>
        <!-- ./products -->

      </div>

    </div>
  </div>
</div>
