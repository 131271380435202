import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-other-cookie-policy',
  templateUrl: './other-cookie-policy.component.html',
  styleUrls: ['./other-cookie-policy.component.scss']
})
export class OtherCookiePolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
