import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { WebModule } from 'src/app/modules/web/web.module';
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-header-announcement',
  templateUrl: './header-announcement.component.html',
  styleUrls: ['./header-announcement.component.scss']
})
export class HeaderAnnouncementComponent implements OnInit {

  public webModule: WebModule = {} as any;
  announcement_state = true;

  constructor(
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public cookies: CookieService,
  ) { }

  ngOnInit(): void {

  }

  async ngAfterViewInit() {
    this.globals.web_observer.subscribe(web => this.webModule = web);

    if (isPlatformBrowser(this.globals.platformId)) {
      if (this.cookies.check('anmt')) {
        //let st = this.cookies.get('anmt') as string;  //(localStorage.getItem('announcement_state') as string);
        this.announcement_state = false;
      }
    }

  }

  hide_announcement() {
    this.webModule.announcement.show = false;
    this.globals.set_web(this.webModule);

    if (isPlatformBrowser(this.globals.platformId)) {
      //localStorage.setItem('announcement_state', 'false');
      //this.cookies.set('anmt', 'false', { expires: 1, });
    }
  }

  goto(link: string) {
    this.router.navigateByUrl(link);
  }

}
