<div class="flex column account-pages cart">
  <div class="flex w-100 position-center column page-title">
    <span class="main">CART</span>
    <span class="sub">Continue shopping</span>
  </div>

  <div class="flex container" style="max-width:1000px">

    <!-- cart sidebar -->
    <div class="flex column w-100 cart-box">
      <div class="flex w-100 top">
        <div class="flex allign-middle w-100 heading">
          <span>CART</span>
          <a class="flex position-center cart-close" title="Close" (click)="close_side_cart()"></a>
        </div>
      </div>

      <div class="flex w-100 cart-inner">
        <div class="flex w-100 column cart-items-container">
          <ng-container *ngFor=" let item of rows; let indx=index;">
            <div class="flex cart-item">
              <a class="flex position-center item-remove" (click)="show_remove_popup(indx)">
                <svg viewBox="0 0 512 512">
                  <path d="M112 112l20 320c.95 18.49 14.4 32 32 32h184c17.67 0 30.87-13.51 32-32l20-320" fill="none"
                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
                  <path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"
                    d="M80 112h352" />
                  <path
                    d="M192 112V72h0a23.93 23.93 0 0124-24h80a23.93 23.93 0 0124 24h0v40M256 176v224M184 176l8 224M328 176l-8 224"
                    fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                    stroke-width="32" />
                </svg>
              </a>
              <div class="flex allign-middle cart-image">
                <img src="{{item.images[0].sm}}" alt="{{item.images[0].sm}}">
              </div>
              <div class="flex cart-item-details">
                <a class="flex cart-item-title" routerLink="{{this.globals.get_product_url(item.slug,item.indx)}}"
                  [queryParams]="{vkey:item.variant.indx}">
                  <span>{{item.name}}</span>
                </a>
                <div class="flex allign-middle w-100 cart-item-info">
                  <div class="flex column left">
                    <div class="flex cart-item-color">
                      <span>Color:</span>{{rows[indx].color}}
                    </div>
                    <div class="flex allign-middle cart-item-size">
                      <span>Size:</span>{{rows[indx].size}}
                    </div>
                  </div>
                  <div class="flex w-100 right">
                    <div class="flex cart-item-price">
                      ₹ {{item.price_payable*item.qnty}}
                    </div>
                    <!-- If a coupon is applied then show the below section. Else not.-->
                    <div class="flex allign-middle coupon-dis hide"
                      *ngIf="(cart.coupon!==undefined) && (cart.coupon.key==undefined)">
                      Coupon Discount: ₹ <span>{{item_price_coupon_discount(indx)}} 1</span>
                    </div>
                    <!-- If a coupon is applied then show the above section. Else not.-->
                  </div>

                </div>
                <div class="cart-item-qty">
                  <div class="flex position-relative allign-middle qty-entry">
                    <input class="" [(ngModel)]="rows[indx].qnty" />
                    <button class="flex position-center entry-pi entry-minus" (click)="change_qnty('M',indx)">
                      <svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-minus"
                        viewBox="0 0 20 20">
                        <path fill="#444"
                          d="M17.543 11.029H2.1A1.032 1.032 0 0 1 1.071 10c0-.566.463-1.029 1.029-1.029h15.443c.566 0 1.029.463 1.029 1.029 0 .566-.463 1.029-1.029 1.029z">
                        </path>
                      </svg>
                    </button>
                    <button class="flex position-center entry-pi entry-plus" (click)="change_qnty('P',indx)">
                      <svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-plus"
                        viewBox="0 0 20 20">
                        <path fill="#444"
                          d="M17.409 8.929h-6.695V2.258c0-.566-.506-1.029-1.071-1.029s-1.071.463-1.071 1.029v6.671H1.967C1.401 8.929.938 9.435.938 10s.463 1.071 1.029 1.071h6.605V17.7c0 .566.506 1.029 1.071 1.029s1.071-.463 1.071-1.029v-6.629h6.695c.566 0 1.029-.506 1.029-1.071s-.463-1.071-1.029-1.071z">
                        </path>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="flex position-center column cart-panel-chkout" *ngIf="rows.length>0">
        <div class="flex justify-content-between allign-middle w-100 cart-subtotal">
          <span class="txt">SUBTOTAL</span>
          <span>₹{{ price_payable() }}</span>
        </div>
        <div class="flex w-100 position-center shippinng-text">
          Shipping, taxes & discount will be calculated at checkout.
        </div>
        <button class="flex position-center w-100 cart-pay" routerLink="/checkout/cart" (click)="close_menu()">
          <div class="flex column text">
            <span class="main">PROCEED TO CHECKOUT</span>
            <span class="sub">Free delivery on prepaid orders</span>
          </div>
          <div class="flex hide">
            <img class="cart-ck-img" src="../assets/images/upi-icons.svg">
            <img class="cart-ck-go" src="../assets/images/right-arrow.svg">
          </div>
        </button>
      </div>

      <!-- If cart is Empty, then show this b elow section-->
      <div class="flex position-center empty-cart " *ngIf="rows.length==0">
        <div class="flex column position-center h-100 content">
          <img src="../assets/images/empty-cart.png">
          <div class="txt11">Your cart is empty.</div>
        </div>
      </div>
    </div>
    <!-- ./cart sidebar -->

    <!-- cart page -->
    <div class="flex checkout-box" *ngIf="rows.length>0">
      <div class="flex flex-column checkout-box-inner">
        <div class="flex flex-column cart-coupon">
          <div class="flex allign-middle coupon-heading">COUPONS</div>
          <div class="flex allign-middle justify-content-between apply-coupon">
            <div class="flex allign-middle coupon-summery">
              <svg viewBox="0 0 18 18">
                <g fill="none" fill-rule="evenodd" transform="rotate(45 6.086 5.293)">
                  <path stroke="#000"
                    d="M17.5 10V1a1 1 0 0 0-1-1H5.495a1 1 0 0 0-.737.323l-4.136 4.5a1 1 0 0 0 0 1.354l4.136 4.5a1 1 0 0 0 .737.323H16.5a1 1 0 0 0 1-1z">
                  </path>
                  <circle cx="5.35" cy="5.35" r="1.35" fill="#000" fill-rule="nonzero"></circle>
                </g>
              </svg>
              <div class="flex before-apply" *ngIf="cart.coupon_code==undefined">Apply Coupons</div>
              <div class="flex flex-column after-apply " *ngIf="cart.coupon_code!==undefined"
                (click)="show_coupon_popup()">
                <span>1 Coupon applied</span>
                <span class="save">You saved additional ₹<span>{{cart.maximum_saving}}</span></span>
              </div>
            </div>
            <button class="flex position-center coupon-change" *ngIf="cart.coupon_code==undefined"
              (click)="show_coupon_popup()">
              APPLY
              <!-- If coupon applied then text will be "EDIT"-->
            </button>
            <button class="flex position-center coupon-change" *ngIf="cart.coupon_code!==undefined"
              (click)="show_coupon_popup()">
              EDIT
              <!-- If coupon applied then text will be "EDIT"-->
            </button>
          </div>
        </div>

        <div class="flex allign-middle flex-column gift-wrap hide">
          <div class="flex allign-middle gift-heading">GIFTING & PERSONALISATION</div>
          <label class="flex allign-middle custom-checkbox">
            <input type="checkbox" value="Track Pants" [value]="true" [(ngModel)]="giftwrap"
              (change)="giftwrap_change($event)" />
            <svg viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
              <g>
                <path
                  d="M922,300.7H617c39.8-22.5,78.2-46.5,85.4-50.9c0.9-0.6,1.7-1.2,2.5-1.9c27.7-23.9,42.5-49.7,44-76.5c1.4-24.6-8.7-49.3-29.1-71.4c-13.1-14.2-36.4-22.4-49.1-26c-16.7-4.8-54.4-0.5-65.9,4.7c-54,24.3-85.5,84.3-103.5,137.1C482,164.9,452,116.4,430.6,85.2c-14.2-20.8-47.2-50.5-59.6-57.4c-34.3-19.1-65.7-22.9-93.4-11.1c-46.5,19.8-61.9,76.6-62.8,80.6c-16.9,95.1,84.7,164.6,163.1,203.4H78c-11.3,0-20.5,9.2-20.5,20.5v201.6c0,11.3,9.2,20.5,20.5,20.5h33.8v426.3c0,11.3,9.2,20.5,20.5,20.5h735.4c11.3,0,20.5-9.2,20.5-20.5V543.2H922c11.3,0,20.5-9.2,20.5-20.5V321.2C942.5,309.9,933.3,300.7,922,300.7L922,300.7z M621.2,116.3c4.9-1.8,20.5-3.4,30.7-3.4c3.3,0,6,0.2,7.5,0.6c15.8,4.5,26.7,10.6,30.2,14.4c12.9,14,19.1,27.9,18.3,41.3c-0.8,15-10.5,30.8-28.7,46.8c-33.9,21.2-122.2,74.4-153.9,84.8h-3.2C530.7,248.6,559.4,144.1,621.2,116.3L621.2,116.3z M254.7,106c0.1-0.4,11.1-39.8,38.9-51.7c5.2-2.2,11-3.4,17.1-3.4c11.9,0,25.4,4.2,40.3,12.5c6.7,3.8,34.9,28.8,45.8,44.8c24.4,35.7,79.1,122.6,82.6,190.5c-1.3-0.7-2.6-1.4-4.1-1.8C408.4,275.4,238.3,198.7,254.7,106L254.7,106z M98.5,341.7h381v160.6h-381V341.7L98.5,341.7z M152.8,543.2h326.7V949H152.8V543.2L152.8,543.2z M847.2,949H520.5V543.2h326.7V949L847.2,949z M901.5,502.3h-381V341.7h381V502.3L901.5,502.3z" />
              </g>
            </svg> Gift Wrap (₹25)
            <div class="checkbox-indicator"></div>
          </label>
        </div>

        <div class="flex flex-column cart-price">
          <div class="flex allign-middle cart-price-heading">
            PRICE DETAILS (<span>{{item_count()}}</span>&nbsp;{{(item_count()>1) ?'Items' : 'Item' }})
          </div>
          <div class="flex justify-content-between price-breakup">
            <span>Total MRP</span>
            <span>₹{{cart.price}}</span>
          </div>
          <div class="flex justify-content-between price-breakup">
            <span>Discount on MRP</span>
            <span class="green">-₹{{cart.discount_amount}}</span>
          </div>
          <div class="flex justify-content-between price-breakup"
            *ngIf="(cart.coupon !== undefined) && (cart.coupon.key !== undefined)">
            <span>Coupon Discount</span>
            <span class="green">-₹{{cart.maximum_saving}}</span>
          </div>
          <div class="flex justify-content-between price-breakup" *ngIf="giftwrap">
            <span>Gift Wrap</span>
            <span>₹25</span>
          </div>
          <div class="flex justify-content-between price-breakup">
            <span>Delivery Fee</span>
            <span>
              <span style="text-decoration: line-through;">₹{{cart.shipping}}</span>
              <span class="green" style="margin-left: 5px;">FREE</span>
            </span>
          </div>
        </div>

        <div class="flex allign-middle justify-content-between total-cart-amount">
          <span>Total Amount</span>
          <span>₹{{ price_payable() }}</span>
        </div>

        <div class="flex position-center column cart-chkout">
          <button class="flex position-center w-100 cart-pay" (click)="place_order()">
            <div class="flex column text">
              <span class="main">PLACE ORDER</span>
              <span class="sub">Free Delivery on prepaid orders</span>
            </div>
            <div class="flex hide">
              <img class="cart-ck-img" src="../assets/images/upi-icons.svg">
              <img class="cart-ck-go" src="../assets/images/right-arrow.svg">
            </div>
          </button>
        </div>
      </div>
    </div>
    <!-- ./cart page -->

  </div>
</div>

<!-- coupon popup -->

<div class="flex position-center all-coupon-dialog" *ngIf="show_apply_coupon">
  <div class="dialog-backdrop"></div>
  <div class="flex flex-column position-center all-coupon-box">
    <div class="flex allign-middle heading">
      Apply Coupon
      <div class="flex position-center dialog-close" (click)="hide_coupon_popup()">
        <svg viewBox="0 0 24 24">
          <path fill="currentColor"
            d="m12 10.586 6.293-6.293a1 1 0 0 1 1.414 1.414l-6.293 6.293 6.293 6.293a1 1 0 0 1 -1.414 1.414l-6.293-6.293-6.293 6.293a1 1 0 1 1 -1.414-1.414l6.293-6.293-6.293-6.293a1 1 0 0 1 1.414-1.414z">
          </path>
        </svg>
      </div>
    </div>
    <div class="flex flex-column coupon-body">

      <div class="flex w-100 enter-coupon">
        <div class="flex column allign-middle w-100 input-container">
          <input placeholder="Enter coupon code" [(ngModel)]="coupon_code">
          <a class="flex position-center coupons-input-chk" (click)="check_coupon()">CHECK</a>
        </div>
      </div>

      <div class="flex w-100 flex-column select-coupon">
        <ng-container *ngFor="let item of coupons.rows; let indx=index;">
          <div class="flex flex-column single-coupon">
            <div class="flex checkbox-coupon">
              <label class="flex allign-middle custom-radio">
                <input type="radio" name="coupon" [(ngModel)]="coupons.code" value="{{item.code}}"
                  (change)="on_change_coupon(indx)" [disabled]="has_min_limit(item)" />
                <div class="flex allign-middle coupon-code">{{item.code}}</div>
                <div class="radio-indicator"></div>
              </label>
            </div>
            <div class="flex flex-column coupon-details">
              <div class="txt1">Save ₹{{coupon_save_amount(indx)}}</div>
              <div class="txt2">
                <ng-container *ngIf="item.type=='flat' "> ₹{{item.discount}}</ng-container>
                <ng-container *ngIf="item.type=='percent' "> {{item.discount}}%</ng-container>
                off on minimum purchase of ₹{{item.min_purchase}}.
              </div>
              <div class="txt3">{{item.active_upto|date:'d MMMM YYYY'}} | {{item.active_upto|date:'h:m aaa'}}</div>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="coupons.rows.length==0">
          <p class="flex flex-column single-coupon">Sorry! Currently we don't have any coupons for you.</p>
        </ng-container>

        <div class="flex flex-column single-coupon" *ngIf="0">
          <div class="flex checkbox-coupon">
            <label class="flex allign-middle custom-radio">
              <input type="radio" name="coupon" value="Track Pants">
              <div class="flex allign-middle coupon-code">MYNTRA250</div>
              <div class="radio-indicator"></div>
            </label>
          </div>
          <div class="flex flex-column coupon-details">
            <div class="txt1">Save ₹599</div>
            <div class="txt2"> ₹ 250 off on minimum purchase of ₹ 1599 .</div>
            <div class="txt3">31st December 2022 | 11:59pm</div>
          </div>
        </div>

      </div>
    </div>
    <div class="flex allign-middle justify-content-between all-coupon-footer">
      <!--<div class="flex flex-column saving-txt">
        <b>Maximum savings:</b>
        <span *ngIf="coupons.code.length==0">₹0</span>
        <span *ngIf="coupons.code.length>0">₹{{coupons.maximum_saving}}</span>
      </div>-->
      <button class="flex position-center coupon-action" (click)="apply_coupon()">APPLY</button>
    </div>
  </div>
</div>
<!-- ./coupon popup -->

<!-- remove item alert/prompt -->
<div class="flex position-center remove-order-item-dialog" *ngIf="show_remove_item">
  <div class="remove-order-item-backdrp"></div>
  <div class="flex flex-column remove-box">
    <div class="flex w-100 remove-box-top">
      <div class="flex allign-middle image">
        <img src="{{selected_item.images[0].data || ''}}" alt="" style="width:64px; height: 64px;">
      </div>
      <div class="flex flex-column details">
        <div class="head1">
          Remove from Bag
          <div class="flex position-center dialog-close" (click)="hide_remove_popup()">
            <svg viewBox="0 0 24 24">
              <path fill="currentColor"
                d="m12 10.586 6.293-6.293a1 1 0 0 1 1.414 1.414l-6.293 6.293 6.293 6.293a1 1 0 0 1 -1.414 1.414l-6.293-6.293-6.293 6.293a1 1 0 1 1 -1.414-1.414l6.293-6.293-6.293-6.293a1 1 0 0 1 1.414-1.414z">
              </path>
            </svg>
          </div>
        </div>
        <div class="txt">Are you sure you want to move this item from bag?</div>
      </div>
    </div>
    <div class="flex position-center justify-content-between remove-item-bottom">
      <div class="flex position-center remove" (click)="remove()"><a>REMOVE</a></div>
      <div class="flex position-center wish" (click)="move_to_wishlist()"><a>MOVE TO WISHLIST</a></div>
    </div>
  </div>
</div>
<!-- ./remove item alert/prompt -->
