import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AddressServiceService } from 'src/app/services/address-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { HttpClient } from '@angular/common/http';
import { WebModule } from 'src/app/modules/web/web.module';

@Component({
  selector: 'app-account-addresses',
  templateUrl: './account-addresses.component.html',
  styleUrls: ['./account-addresses.component.scss']
})
export class AccountAddressesComponent implements OnInit {

  public webModule: WebModule = {} as any;
  customer_key = '';
  customer_name = '';
  customer_fname = '';
  show_loader = true;

  rows = [] as any;
  address = {
    key: '',
    email: '',
    name: '',
    fname: '',
    lname: '',
    address1: '',
    address2: '',
    locality: '',
    landmark: '',
    city: '',
    country: '',
    state: '',
    pincode: '',
    mobile: '',
    new_updates: true,
    set_default: false,
    satday_open: 'N',
    sunday_open: 'N',
  } as any;
  countries = [] as any;
  states = [] as any;
  errors = {} as any;
  giftwrap = false;

  //------
  show_popup = false;

  //--remove--
  show_remove_item = false;
  selected_indx = '';
  selected_item = {} as any;

  constructor(
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public auth: AuthenticationService,
    public toastr: ToastrService,
    public addressService: AddressServiceService,
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    this.globals.set_account_active('asm-address');

    this.globals.page_loader_observer.subscribe(state => this.show_loader = state);
    this.globals.web_observer.subscribe(web => this.webModule = web);
    this.globals.set_page_loader(true);

    this.customer_key = await this.globals.get_customer_key();
    this.customer_fname = await this.globals.get_localstore('fname');

    await this.get_data();
  }

  async get_data() {
    const postData = new FormData();
    this.globals.set_page_loader(true);

    postData.append('action', 'account/addresses');
    //postData.append(this.globals.customer_key, this.customer_key);

    const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${atob(this.customer_key)}/addresses`, postData)
      .subscribe((response) => {
        ////console.log(response);
        if (response.msg == 'success') {
          let data = response.data;

          this.rows = response.data;
          this.states = response.states;
          this.countries = response.countries;
          this.globals.set_page_loader(false);
        } else {
          this.globals.set_page_loader(false);
        }
      });
  }

  validate() {
    let customer = this.auth.customer;
    this.errors = {} as any;

    if (!this.check_default_address()) {
      this.errors.set_default = "Please select a shipping address";
      this.toastr.success('Please select a shipping address!', '', { positionClass: 'toast-bottom-center' });
    }

    //console.log([this.errors, this.auth.customer]);
    return (Object.entries(this.errors).length == 0) ? true : false;
  }
  async save() {
    if (this.validate()) {
      let shipping = {} as any;

      for (let kk = 0; kk < this.rows.length; kk++) {
        if (this.rows[kk].set_default) {
          shipping = this.rows[kk];
        }
        //console.log(kk);
      }

      if (this.addressService.address_type = 'shipping')
        localStorage.setItem('shipping_address', JSON.stringify(shipping));

      localStorage.setItem('giftwrap', this.giftwrap.toString());

      //await localStorage.setItem('shipping_address', JSON.stringify(this.addressService.address));
      //await localStorage.setItem('checkout_cart', JSON.stringify(this.cart));
      //await localStorage.setItem('checkout_rows', JSON.stringify(this.ca));
      //await localStorage.setItem('countries', JSON.stringify(this.countries));
      //await localStorage.setItem('states', JSON.stringify(this.states));

      this.router.navigate(['/checkout/payment']);
    }
    //console.log(this.address);
  }

  check_default_address(): boolean {
    let found = false;

    for (let kk = 0; kk < this.rows.length; kk++) {
      if (this.rows[kk].set_default) {
        found = true;
      }
    }

    return found;
  }

  show_address_popup() {
    this.show_popup = true;
  }
  show_update_address_popup(indx: any) {
    //this.addressService.address = this.rows[indx];
    this.addressService.set_address(this.rows[indx]);
    this.addressService.set_mode('edit');
    this.show_address_popup();
  }

  hide_address_popup(state: boolean) {
    this.show_popup = state;
    this.get_data();
  }

  async set_default(indx: number) {
    let results = await this.addressService.set_detault(this.rows[indx].key);

    results.subscribe((response: any) => {
      if (response.msg == 'success') {
        this.get_data();
      } else {
        this.toastr.clear();
        this.toastr.error(response.data, '', { positionClass: 'toast-bottom-center' });
      }
    });
  }



  show_remove_popup(indx: any) {
    this.selected_indx = indx;
    this.selected_item = this.rows[indx];
    this.show_remove_item = true;

    //console.log(this.selected_indx, this.selected_item);
  }
  hide_remove_popup() {
    this.selected_indx = '';
    this.selected_item = {};
    this.show_remove_item = false;
  }
  async delete() {
    let results = await this.addressService.delete(this.rows[this.selected_indx].key);
    this.globals.set_page_loader(true);

    results.subscribe((response: any) => {
      if (response.msg == 'success') {
        this.get_data();
        this.hide_remove_popup();
        this.globals.set_page_loader(false);
      } else {
        this.toastr.clear();
        this.toastr.warning(response.data, '', { positionClass: 'toast-bottom-center' });

        this.hide_remove_popup();
        this.globals.set_page_loader(false);
      }
    });
  }

}
