import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal, NgbActiveModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-size-chart',
  templateUrl: './size-chart.component.html',
  styleUrls: ['./size-chart.component.scss']
})
export class SizeChartComponent implements OnInit {

  constructor(
    
    public modalService: NgbModal,
  ) { }

  szbtntoggle:boolean = true;

  togglesize() {
    this.szbtntoggle = !this.szbtntoggle;
  }

  close_sz_chrt() {
    const offcanvasRef = this.modalService.dismissAll('close modal');
    //console.log(this.offcanvasService.activeInstance);
    //this.offcanvasService.dismiss('Canvas closed');
  }

  ngOnInit(): void {
  }

}
