import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalsService } from './services/globals.service';
import { isPlatformBrowser } from '@angular/common';
import { AuthenticationService } from './services/authentication.service';
import { Observable, combineLatest } from 'rxjs/index';
import { ToastrService } from 'ngx-toastr';
import { response } from 'express';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WebModule } from './modules/web/web.module';
import { CustomersModule } from './modules/customers/customers.module';
import { LsvCommonComponent } from './components/lsv-common/lsv-common.component';
import { LsvComponent } from './components/lsv/lsv.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'OrangeFashion';
  customer_key = '';
  url_params = {} as any;
  url_query_params = {} as any;
  public webModule: WebModule = {} as any;

  public constructor(
    private httpClient: HttpClient,
    public globals: GlobalsService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthenticationService,
    public toastr: ToastrService,
    private modalService: NgbModal,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {

  }

  async ngOnInit() {
    this.globals.web_observer.subscribe(web => this.webModule = web);

    this.globals.set_cart_count(0);
    this.customer_key = await this.globals.get_customer_key();

    this.get_cart_count();
    this.get_wishlist_count();
    await this.check_session();
    //this.auth.openLSV('signup');

    //console.log("AppComponent:OnInit");
  }

  async ngAfterViewInit() {
    this.url_params = await this.activeRoute.params;
    this.url_query_params = await this.activeRoute.queryParams;

    // 2. Call combineLatest operator, inject multiple observables in array
    const joinStream = combineLatest([this.url_params, this.url_query_params]);

    // 3. Subscibe combineLatest observable
    const subscribe = await joinStream.subscribe(async ([params, queryParams]) => {
      //this.product_vkey = queryParams['vkey'];
      await this.extract_params(params, queryParams);

      /**
      * close the search bar if it's open. set it to falses
      */
      this.globals.set_suggestion_bar_state(false);

      //console.log({ params: params, queryParams: typeof queryParams });
    });
  }

  async extract_params(params: any, queryParams: any) {
    let type = (queryParams['type'] != undefined) ? queryParams['type'] : '';
    let status = (queryParams['status'] != undefined) ? queryParams['status'] : '';
    let customer_key = (queryParams['uid'] != undefined) ? queryParams['uid'] : '';
    let token = (queryParams['token'] != undefined) ? queryParams['token'] : '';

    if ((type == 'reset-password')) { //&& (status == 'success')
      let postData = {
        action: "account/personal/info",
        customer_key: customer_key,
      }
      this.globals.set_page_loader(true);

      if ((status == 'success') || (status == 'expired')) {
        let result = this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${atob(customer_key)}/info`, postData)
          .subscribe((response) => {
            if (response.msg == 'success') {
              switch (status) {
                case 'success': {
                  const modalRe = this.modalService.open(LsvComponent, { centered: true, modalDialogClass: 'lsv-modal' });

                  this.auth.signup_type = "email";
                  this.auth.signup_type2 = "reset-password";
                  this.auth.token = token;
                  this.auth.customer.fname = response.data.fname;
                  this.auth.customer.lname = response.data.lname;
                  this.auth.customer.name = response.data.name;
                  this.auth.customer.email = response.data.email;
                  this.auth.customer.mobile = response.data.mobile;
                  this.auth.customer.username = response.data.email;
                  //modalRe.componentInstance.show('password2');
                  this.auth.openLSV('password2');
                  //this.auth.show('password2');

                  this.toastr.clear();
                  this.toastr.success("Please enter your new passwords", '', { positionClass: 'toast-bottom-center' });
                  //this.auth.openLSV('password2');
                  break;
                }
                case 'expired': {
                  const modalRe = this.modalService.open(LsvCommonComponent, { centered: true, modalDialogClass: 'lsv-modal', backdrop: 'static' });
                  modalRe.componentInstance.signup_type = "email";
                  modalRe.componentInstance.signup_type2 = "reset-password-expired";
                  modalRe.componentInstance.token = token;
                  modalRe.componentInstance.auth.customer.fname = response.data.fname;
                  modalRe.componentInstance.auth.customer.fname = response.data.lname;
                  modalRe.componentInstance.auth.customer.name = response.data.name;
                  modalRe.componentInstance.auth.customer.email = response.data.email;
                  modalRe.componentInstance.auth.customer.mobile = response.data.mobile;
                  modalRe.componentInstance.show_login_with_email = false;
                  modalRe.componentInstance.show('password3');

                  this.toastr.clear();
                  this.toastr.error("Token expired", '', { positionClass: 'toast-bottom-center' });
                  break;
                }
              }

              this.globals.set_page_loader(false);
            } else {
              this.globals.set_page_loader(false);
            }
          });
      }

      if (status == 'invalid-token') {
        const modalRe = this.modalService.open(LsvCommonComponent, { centered: true, modalDialogClass: 'lsv-modal', backdrop: 'static' });
        modalRe.componentInstance.signup_type = "email";
        modalRe.componentInstance.signup_type2 = "reset-password-invalid";
        modalRe.componentInstance.show('password3');

        this.toastr.clear();
        this.toastr.error("Invalid link", '', { positionClass: 'toast-bottom-center' });
        this.globals.set_page_loader(false);
      }
    }

    if ((type == 'reset-password') && (status == 'expired1')) {
      let postData = {
        action: "account/personal/info",
        customer_key: atob(customer_key),
      }
      this.globals.set_page_loader(false);

      this.toastr.clear();
      this.toastr.error("Token expired", '', { positionClass: 'toast-bottom-center' });
      //this.auth.openLSV('password2');

      let result = this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${atob(customer_key)}/info`, postData)
        .subscribe((response) => {
          if (response.msg == 'success') {
            this.auth.customer.fname = response.data.fname;
            this.auth.customer.fname = response.data.lname;
            this.auth.customer.name = response.data.name;
            this.auth.customer.email = response.data.email;
            this.auth.customer.mobile = response.data.mobile;

            //localStorage.setItem('signup', JSON.stringify(this.auth.customer));
            //localStorage.setItem('signup_type2', 'reset-password');

            const modalRe = this.modalService.open(LsvCommonComponent, { centered: true, modalDialogClass: 'lsv-modal', backdrop: 'static' });
            modalRe.componentInstance.signup_type = "email";
            modalRe.componentInstance.signup_type2 = "reset-password-expired";
            modalRe.componentInstance.auth.customer.fname = response.data.fname;
            modalRe.componentInstance.auth.customer.fname = response.data.lname;
            modalRe.componentInstance.auth.customer.name = response.data.name;
            modalRe.componentInstance.auth.customer.email = response.data.email;
            modalRe.componentInstance.auth.customer.mobile = response.data.mobile;
            modalRe.componentInstance.show('password3');
          } else {
            this.globals.set_page_loader(true);
          }
        });

    }

    if ((type == 'email_verified') && (status.toLocaleLowerCase() == 'yes')) {
      this.toastr.clear();
      this.toastr.success("Email verified!", '', { positionClass: 'toast-bottom-center' });
      window.history.pushState("", "", '/');
      this.auth.openLSV('common');
    }

    if ((type == 'email_verified') && (status.toLocaleLowerCase() == 'expired')) {
      this.toastr.clear();
      this.toastr.error("Token expired!", '', { positionClass: 'toast-bottom-center' });
      window.history.pushState("", "", '/');
      this.auth.openLSV('common');
    }

    //console.log(params, queryParams);
  }

  async get_cart_count() {
    const postData = {
      action: 'cart/count',
      search: atob(this.customer_key),
    }; //new FormData();

    //postData.append('action', 'cart/count');
    //postData.append('search', atob(this.customer_key));

    if (this.customer_key.length != null && this.customer_key.length > 0) {
      const result = this.httpClient.post<any>(this.globals.get_endpoint() + `/cart/${atob(this.customer_key)}/count`, postData).subscribe((response) => {
        ////console.log(response);
        if (response.msg == 'success') {
          this.globals.set_cart_count(response.data);
        }
      });
    } else {
      if (isPlatformBrowser(this.platformId)) {
        let localCart = [] as any;
        let count = 0;
        localCart = localStorage.getItem('cart');
        localCart = (localCart != null) ? JSON.parse(localCart) : [];

        for (let kk = 0; kk < localCart.length; kk++) {
          count = count + parseInt(localCart[kk].qnty);
        }

        this.globals.set_cart_count(count);
      } else {
        this.globals.set_cart_count(0);
      }
    }

  }

  async get_wishlist_count() {
    if (this.customer_key.length != null && this.customer_key.length > 0) {
      const postData = {
        action: 'wishlist/count',
        search: atob(this.customer_key),
      };

      const result = this.httpClient.post<any>(this.globals.get_endpoint() + `/wishlist/${atob(this.customer_key)}/count`, postData).subscribe((response) => {
        //console.log(response);
        if (response.msg == 'success') {
          this.globals.set_wishlist_count(response.data);
        }
      });
    }
  }

  async check_session() {
    if (isPlatformBrowser(this.globals.platformId) && this.customer_key.length > 0) {
      let postData = {
        action: 'users/check/session',
        customer_key: this.customer_key,
      };

      let results = await this.httpClient.post<any>(this.globals.get_endpoint() + `/users/check/session`, postData)
        .subscribe((response) => {
          if (response.msg == 'success') {
            localStorage.setItem('name', response.data.name);
            localStorage.setItem('fname', response.data.fname);
            this.globals.save_local_cart(response.data.key);

            this.webModule.customer = new CustomersModule;
            this.webModule.customer.key = response.data.key;
            this.webModule.customer.fname = response.data.fname;
            this.webModule.customer.mname = response.data.mname;
            this.webModule.customer.lname = response.data.lname;
            this.webModule.customer.name = response.data.name;
            this.webModule.customer.email = response.data.email;
            this.webModule.customer.mobile = response.data.mobile;
            this.webModule.customer.dob = response.data.dob;
            this.webModule.customer.gender = response.data.gender;
            this.webModule.customer.gender_text = response.data.gender_text;
            this.webModule.customer.status = response.data.status;
            this.webModule.customer.status_text = response.data.status_text;
            //console.log(this.webModule.customer);
            this.globals.set_web(this.webModule);

          } else {
            localStorage.removeItem('customer_key');
            localStorage.removeItem('name');
            localStorage.removeItem('fname');
          }
        });
    } else {
      localStorage.removeItem('customer_key');
      localStorage.removeItem('name');
      localStorage.removeItem('fname');
    }
  }


}
