<div class="single-review-page">
  <div class="flex container" style="max-width:1000px">
      <div class="flex left reviewed-product">
          <div class="flex product">
              <div class="flex column product-content">
                  <div class="flex image">
                      <img class="flex" src="../assets/images/p141.jpg">
                  </div>
                  <div class="flex position-center w-100 name">
                      <span>Griff Black Puffer Jacket</span>
                  </div>
                  <div class="flex position-center w-100 price">
                    <p class="new-price">Rs. <span>1,999</span></p>
                  </div>
                  <div class="flex flex-wrap position-center size">
                      <p class="flex position-center size-chip size-s">XL</p>
                  </div>
              </div>
          </div>
      </div>
      <div class="flex column right all-reviews-container">
          <div class="flex column user-reviews-container">
              <div class="flex flex-column single-review rating-3">
                  <div class="flex single-review-details">
                      <div class="flex allign-middle single-r-r">
                          <span>3</span>
                          <span class="star">★</span>
                      </div>
                      <div class="flex allign-middle single-review-user">
                          <div class="name">Debapriyo Maity</div>
                          <div class="date">13.05.2022</div>
                      </div>
                  </div>
                  <div class="flex flex-column single-r-comments">
                      <div class="text">
                        It’s pretty! It doesn’t have any buttons so you need to make sure the knots are tied properly and
                        tight. I thought the colours would be more poppy and saturated. Was a little disappointed with
                        that. Overall it’s a cute top. Will go well on brown / beige pants 🤎
                      </div>
                  </div> 
                  <div class="flex review-photo">
                      <a (click)="openReviewPopup()">
                          <img src="../assets/images/pz4.jpg">
                      </a>
                      <a (click)="openReviewPopup()">
                          <img src="../assets/images/pz1.jpg">
                      </a>
                      <a (click)="openReviewPopup()">
                          <img src="../assets/images/pz3.jpg">
                      </a>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>