<div class="flex order-details-page">
  <div class="flex flex-column container">
    <div class="flex position-center page-title">Your Order Details</div>
    <section class="flex allign-middle breadcrumbs hide">
      <a>Home</a>
      <span>/</span>
      <a>Account</a>
      <span>/</span>
      <a>Order-details</a>
    </section>
    <div class="flex flex-column right order-detailed-box" style="position:relative;min-height: 120px;">
      <app-section-loader class=" {{(show_loader) ? 'show' :'hide'}} "></app-section-loader>

      <div class="flex order-detailed-box-common" *ngIf="!show_loader">
        <div class="flex flex-column order-details-shiping">
          <span class="head">Delivery Address :</span>
          <span class="name">{{order.customer_name}}</span>
          <span class="address">{{show_address()}}</span>
          <span class="phone">Phone number :</span>
          <span class="number">{{order.customer.mobile}}</span>
        </div>
        <div class="flex flex-column order-details-billing">
          <span class="head">Billing Address :</span>
          <span class="name"> </span>
          <span class="address">
            {{show_address()}}
            <!-- Debapriya Maity, Behind Tamluk ghatal central CO-OPERATIVE BANK ( first white building)
            RadhaMoni hat, near murari kalua primary school, Behind tamluk ghatal co operative bank, Dakshin Baguan -
            721627, West Bengal. 9800622215 -->
          </span>
        </div>
        <div class="flex flex-column order-details-ordrs">
          <span class="head">Order Details :</span>
          <span class="name">#{{order.key}}</span>
          <span class="number">Date : <span class="phone">{{order.create_date}}</span></span>
          <span class="number">Total Items : <span class="phone">{{order.qnty}}</span></span>
          <span class="number">Total Quantity : <span class="phone">{{total_items()}}</span></span>
        </div>
      </div>
    </div>

    <ng-container *ngFor="let item of order.products; let indx=index;">
      <ng-container *ngIf="product_key==item.key">
        <div class="flex w-100 order-detailed-item-box open"
          *ngIf="(item.status=='P') || (item.status=='PKD') || (item.status=='DSP') || (item.status=='CMPLT')">
          <div class="flex item-details">
            <div class="flex w-100 order-item-container">
              <div class="flex position-center order-item-left">
                <img src="{{item.images[0].sm}}" alt="{{item.name}}">
              </div>
              <div class="flex flex-column order-item-right">
                <div class="item-name">{{item.name}}</div>
                <div class="flex allign-middle item-size-Qty">
                  <a class="flex allign-middle item-size" *ngIf="item.is_variant_type== 'Y' ">
                    <span class="">Colour: {{item.color}}</span>
                  </a>
                  <a class="flex allign-middle item-size">
                    <span class="">Size: {{item.size}}</span>
                  </a>
                  <a class="flex allign-middle item-quantity">
                    <span class="">Qty: {{item.qnty}}</span>
                  </a>
                </div>
                <div class="flex allign-middle item-price">
                  <div class="new-price">₹{{price_payable(indx)}}</div>
                  <div class="old-price">₹{{item.price*item.qnty}}</div>
                  <div class="flex discount-price">
                    Saved ₹{{(item.price*item.qnty)-price_payable(indx)}}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex position-center status-details qwe">
            <div
              class="flex flex-column position-center status active confirmed {{(check_traking_status(indx, 'PKD')) ?'active' :''}} ">
              <span class="ttx">Order Confirmed</span>
              <span class="flex w-100 allign-middle bar"><span class="circle"></span></span>
              <span class="ttx2">-{{check_traking_date(indx, 'PKD')}}</span>
            </div>
            <div
              class="flex flex-column position-center status despatched {{(check_traking_status(indx, 'DSP')) ?'active' :''}}">
              <span class="ttx">Shipped</span>
              <span class="flex w-100 allign-middle bar"><span class="circle"></span></span>
              <span class="ttx2">-{{check_traking_date(indx, 'DSP')}}</span>
            </div>
            <!--<div
              class="flex flex-column position-center status out-delivery {{(check_traking_status(indx, 'CMPLT')) ?'active' :''}} ">
              <span class="ttx">Out For Delivery</span>
              <span class="flex w-100 allign-middle bar"><span class="circle"></span></span>
              <span class="ttx2">{{check_traking_date(indx, 'CMPLT')}}-</span>
            </div>-->
            <div
              class="flex flex-column position-center status out-delivery {{(check_traking_status(indx, 'CMPLT')) ?'active' :''}}">
              <span class="ttx">Delivered</span>
              <span class="flex w-100 allign-middle bar"><span class="circle"></span></span>
              <span class="ttx2">-{{check_traking_date(indx, 'CMPLT')}}-</span>
            </div>
          </div>
          <div class="flex flex-column usr-action">
            <span class="ddate">Delivery expected by {{item.delivery_date}}</span>
            <a *ngIf="(item.status=='P') || (item.status=='PKD') || (item.status=='DSP')">Cancel</a>
            <a *ngIf="(item.status=='CMPLT') && (item.return_applicable=='Y')">Return (applicable till
              {{item.return_valid_till | date: 'd MMM, y' }})</a>
            <a *ngIf="item.status!='CMPLT'" href="/contact-us">Need help?</a>
            <a *ngIf="item.status=='CMPLT'"
              href="{{'/account/product/'+item.key+'/review?okey='+order.key+'&pindx='+item.pindx+'&slug='+item.slug+'&vindx='+item.vindx}}"
              target="_blank">Rate &
              Review</a>
          </div>
        </div>

        <div class="flex w-100 order-detailed-item-box return" *ngIf="(item.status=='R')">
          <div class="flex item-details">
            <div class="flex w-100 order-item-container">
              <div class="flex position-center order-item-left">
                <img src="{{item.images[0].sm}}" alt="{{item.name}}">
              </div>
              <div class="flex flex-column order-item-right">
                <div class="item-name">{{item.name}}</div>
                <div class="flex allign-middle item-size-Qty">
                  <a class="flex allign-middle item-size" *ngIf="item.is_variant_type== 'Y' ">
                    <span class="">Colour: {{item.color}}</span>
                  </a>
                  <a class="flex allign-middle item-size">
                    <span class="">Size: {{item.size}}</span>
                  </a>
                  <a class="flex allign-middle item-quantity">
                    <span class="">Qty: {{item.qnty}}</span>
                  </a>
                </div>
                <div class="flex allign-middle item-price">
                  <div class="new-price">₹{{price_payable(indx)}}</div>
                  <div class="old-price">₹{{item.price*item.qnty}}</div>
                  <div class="flex discount-price">
                    Saved ₹{{(item.price*item.qnty)-price_payable(indx)}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex position-center status-details">
            <div class="flex flex-column position-center status return active">
              <span class="ttx">Return</span>
              <span class="flex w-100 allign-middle bar"><span class="circle"></span></span>
              <span class="ttx2">22.11.22</span>
            </div>
            <div class="flex flex-column position-center status return-approved active">
              <span class="ttx">Return Approved</span>
              <span class="flex w-100 allign-middle bar"><span class="circle"></span></span>
              <span class="ttx2">22.11.22</span>
            </div>
            <div class="flex flex-column position-center status return-pickup">
              <span class="ttx">Pickup/Courier</span>
              <span class="flex w-100 allign-middle bar"><span class="circle"></span></span>
              <span class="ttx2">-</span>
            </div>
            <div class="flex flex-column position-center status return-refund">
              <span class="ttx">Refund</span>
              <span class="flex w-100 allign-middle bar"><span class="circle"></span></span>
              <span class="ttx2">-</span>
            </div>
          </div>
          <div class="flex flex-column usr-action">
            <a>Need help?</a>
          </div>
        </div>

        <div class="flex w-100 order-detailed-item-box cancel" *ngIf="(item.status=='CBU') || (item.status=='CBS')">
          <div class="flex item-details">
            <div class="flex w-100 order-item-container">
              <div class="flex position-center order-item-left">
                <img src="{{item.images[0].sm}}" alt="{{item.name}}">
              </div>
              <div class="flex flex-column order-item-right">
                <div class="item-name">{{item.name}}</div>
                <div class="flex allign-middle item-size-Qty">
                  <a class="flex allign-middle item-size" *ngIf="item.is_variant_type== 'Y' ">
                    <span class="">Colour: {{item.color}}</span>
                  </a>
                  <a class="flex allign-middle item-size">
                    <span class="">Size: {{item.size}}</span>
                  </a>
                  <a class="flex allign-middle item-quantity">
                    <span class="">Qty: {{item.qnty}}</span>
                  </a>
                </div>
                <div class="flex allign-middle item-price">
                  <div class="new-price">₹{{price_payable(indx)}}</div>
                  <div class="old-price">₹{{item.price*item.qnty}}</div>
                  <div class="flex discount-price">
                    Saved ₹{{(item.price*item.qnty)-price_payable(indx)}}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex position-center status-details">
            <div
              class="flex flex-column position-center status confirmed {{(check_traking_status(indx, 'P')) ?'active' :''}} ">
              <span class="ttx">Order Confirmed</span>
              <span class="flex w-100 allign-middle bar"><span class="circle"></span></span>
              <span class="ttx2">{{check_traking_date(indx, 'PKD')}}</span>
            </div>
            <div *ngIf="item.status !== 'CMPLT' "
              class="flex flex-column position-center status cancelled {{(check_traking_status(indx, item.status)) ?'active' :''}} ">
              <span class="ttx">Cancelled</span>
              <span class="flex w-100 allign-middle bar"><span class="circle"></span></span>
              <span class="ttx2">{{check_traking_date(indx, item.status)}}</span>
            </div>
          </div>
          <div class="flex flex-column usr-action">
            <a routerLink="/contact-us">Need help?</a>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="order.products!=undefined && order.products.length > 1">
      <div class="flex flex-column other-ordered-items" style="position:relative;">
        <div class="hedd">Other items in this order</div>

        <app-section-loader class=" {{(show_loader) ? 'show' :'hide'}} "></app-section-loader>

        <ng-container *ngFor="let item of order.products; let indx=index;">
          <a class="flex w-100 order-item-container" *ngIf="product_key!=item.key"
            routerLink="{{'/account/order/details/'+item.key}}" [queryParams]="{okey: order.key}">
            <div class="flex position-center order-item-left">
              <img src="{{item.images[0].sm}}" alt="{{item.name}}">
            </div>
            <div class="flex flex-column order-item-right">
              <div class="item-name">{{item.name}}</div>
              <div class="flex allign-middle item-size-Qty">
                <a class="flex allign-middle item-size" *ngIf="item.is_variant_type== 'Y' ">
                  <span class="">Colour: {{item.color}}</span>
                </a>
                <a class="flex allign-middle item-size">
                  <span class="">Size: {{item.size}}</span>
                </a>
                <a class="flex allign-middle item-quantity">
                  <span class="">Qty: {{item.qnty}}</span>
                </a>
              </div>
              <div class="flex allign-middle item-price">
                <div class="new-price">₹{{price_payable(indx)}}</div>
                <div class="old-price">₹{{item.price*item.qnty}}</div>
                <div class="flex discount-price">Saved ₹{{(item.price*item.qnty)-price_payable(indx)}}</div>
              </div>
            </div>
            <div class="flex flex-column order-item-right-action">
              <span class="flex allign-middle od-status">
                <span class="crcl"></span>
                Delivery expected by {{item.delivery_date}}
              </span>
            </div>
          </a>
        </ng-container>

      </div>
    </ng-container>


  </div>
</div>
