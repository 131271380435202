import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { GlobalsService } from './globals.service';

@Injectable({
  providedIn: 'root'
})
export class AddressServiceService {
  mode = 'save';
  private mode_behavior = new BehaviorSubject<any>('');
  public mode_observer = this.mode_behavior.asObservable();

  address_type = "shipping";
  private address_type_behavior = new BehaviorSubject<any>('');
  public address_type_observer = this.address_type_behavior.asObservable();


  rows = [] as any;
  private rows_behavior = new BehaviorSubject<any>([] as any);
  public rows_observer = this.rows_behavior.asObservable();

  address = {
    key: '',
    email: '',
    name: '',
    fname: '',
    lname: '',
    address1: '',
    address2: '',
    locality: '',
    landmark: '',
    city: '',
    country: '',
    state: '',
    pincode: '',
    mobile: '',
    new_updates: true,
    set_default: false,
    satday_open: 'N',
    sunday_open: 'N',
  } as any;

  private address_behavior = new BehaviorSubject<any>({} as any);
  public address_observer = this.address_behavior.asObservable();

  countries = [] as any;
  private countries_behavior = new BehaviorSubject<any>([] as any);
  public countries_observer = this.countries_behavior.asObservable();

  states = [] as any;
  private states_behavior = new BehaviorSubject<any>([] as any);
  public states_observer = this.states_behavior.asObservable();

  errors = {} as any;

  constructor(
    private httpClient: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private globals: GlobalsService,
    @Inject(PLATFORM_ID) public platformId: Object,
  ) { }


  public set_rows(params: any) {
    //console.log(params);
    this.rows_behavior.next(params);
  }

  public set_countries(params: any) {
    //console.log(params);
    this.countries_behavior.next(params);
  }

  public set_states(params: any) {
    //console.log(params);
    this.states_behavior.next(params);
  }

  public set_address(params: any) {
    //console.log(params);
    this.address_behavior.next(params);
    //this.address = params;
  }

  public set_mode(params: any) {
    console.log(params);
    this.mode_behavior.next(params);
  }

  public set_address_type(params: any) {
    console.log(params);
    this.mode_behavior.next(params);
  }

  public async set_detault(key: string): Promise<any> {
    let customer_key = await this.globals.get_customer_key();
    let postData = {
      action: 'account/address/default/set',
    };

    let results = await this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${customer_key}/address/${btoa(key)}/default`, postData);
    //console.log(results);

    return results;
  }

  public async delete(key: string) {
    let customer_key = await this.globals.get_customer_key();
    let postData = {
      action: 'account/address/delete',
      key: btoa(key),
    };

    return await this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${customer_key}/address/delete`, postData);
  }

}
