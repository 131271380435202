<div class="flex position-center user-lsv">
  <div class="flex position-center lsv-box">
    <div class="flex position-center lsv-close">
      <a class="flex position-center modal-close" title="Close" (click)="auth.close()"></a>
    </div>

    <app-section-loader *ngIf="auth.show_loader"></app-section-loader>

    <app-lsv-common class="hide lsv-common authentication"></app-lsv-common>
    <app-lsv-login class="hide login authentication password1"></app-lsv-login>
    <app-lsv-signup class="hide user-registration signup authentication"></app-lsv-signup>
    <app-lsv-social-verify class="hide authentication"></app-lsv-social-verify>
    <app-lsv-otp class="hide ls-otp authentication"></app-lsv-otp>
    <app-lsv-password-reset class="hide password2 authentication"></app-lsv-password-reset>
    <app-lsv-action class="hide authentication lsv-action"></app-lsv-action>
    <app-lsv-google-mob class="hide google-mob authentication"></app-lsv-google-mob>
  </div>
</div>
