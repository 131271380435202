import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { LsvComponent } from '../components/lsv/lsv.component';
import { CustomersModule } from '../modules/customers/customers.module';
import { GlobalsService } from './globals.service';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public customer: CustomersModule;

  //otp: string;
  //showOtpComponent = true;
  showPassword: boolean = false;
  otp = '';
  name = '';
  private customer_key = '';
  errors = {} as any;

  /**
    Username type. Email | Mobile
   */
  signup_type = '';

  /**
    Stores prosess value. signup | signin | reset-password
   */
  signup_type2 = '';

  show_close = true;
  show_login_with_email = true;
  show_change_number = true;
  token = "";
  lsv_action_type = '';

  show_loader = false;

  private signup_popup_behavior = new BehaviorSubject<boolean>(false);
  public signup_popup_observer = this.signup_popup_behavior.asObservable();

  constructor(
    public globals: GlobalsService,
    private modalService: NgbModal,
    private httpClient: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastr: ToastrService,
    private authService: SocialAuthService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.customer = new CustomersModule;
  }

  public set_signup_popup_state(state: boolean) {
    this.signup_popup_behavior.next(state);
  }

  public openLSV(name: any): void {
    let p = new Promise((resolve, reject) => {
      if (this.modalService.hasOpenModals()) {
        this.modalService.dismissAll('close all modals');
      }

      const modalRe = this.modalService.open(LsvComponent, { centered: true, modalDialogClass: 'lsv-modal', backdrop: 'static' });
      //modalRe.componentInstance.name = 'World';

      let elements = document.getElementsByClassName('authentication') as any;
      //console.log(elements);

      for (let kk = 0; kk < elements.length; kk++) {
        elements[kk].classList.add('hide');
      }

      resolve(true);
    });

    let status = p.then((result) => {
      setTimeout(() => {
        switch (name) {
          case 'common': {
            let target = document.getElementsByClassName('lsv-common') as any;
            target[0].classList.remove('hide');
            break;
          }
          case 'signin': {
            let target = document.getElementsByClassName('ls-otp') as any;
            target[0].classList.remove('hide');
            break;
          }
          case 'signup': {
            let target = document.getElementsByClassName('user-registration') as any;
            target[0].classList.remove('hide');
            break;
          }
          case 'password1': {
            let target = document.getElementsByClassName('password1') as any;
            target[0].classList.remove('hide');
            console.log('password1');
            break;
          }
          case 'password2': {
            let target = document.getElementsByClassName('password2') as any;
            //target[0].classList.remove('hide');
            this.show('password2');
            console.log('password2', target, this.customer);
            break;
          }
          case 'password3': {
            let target = document.getElementsByClassName('password3') as any;
            target[0].classList.remove('hide');
            break;
          }
          case 'ls-otp': {
            let target = document.getElementsByClassName('ls-otp') as any;
            target[0].classList.remove('hide');
            break;
          }
        }
      }, 0.5 * 1000);
    });
  }

  async check_user(): Promise<any> {
    if (isPlatformBrowser(this.globals.platformId)) {
      let postData = {
        action: 'users/check',
        username: this.customer.username,
      };

      let result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/users/check`, postData);

      console.log(result);
      return result;
    }

    return false;
  }

  show(name: string) {
    setTimeout(() => {
      if (this.modalService.hasOpenModals()) {
        let elements = document.getElementsByClassName('authentication') as any;
        let target = document.getElementsByClassName(name) as any;
        console.log([elements, target]);

        //--hide all--
        for (let kk = 0; kk < elements.length; kk++) {
          if (!elements[kk].classList.contains('hide'))
            elements[kk].classList.add('hide');
        }

        //--remove hide from target section--
        if (target[0].classList.contains('hide')) {
          target[0].classList.remove('hide');
        }
      }
    }, 0.5 * 1000);
  }

  validate() {
    let customer = this.customer;
    this.errors = {} as any;
    const preg_email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var preg = /^[0-9 ]+$/;
    let isNum = false;

    if (customer.username.length == 0) {
      this.errors.username = "Please enter a valid mobile no or email";
    }

    if ((customer.username.length > 0) && (preg.test(customer.username))) {
      isNum = true;
      //Please enter a valid 10-digit mobile number
    }
    ////console.log(isNum);

    if (isNum) { // validate mobile
      if ((customer.username.length > 0) && (customer.username.length < 10)) {
        this.errors.username = "Please enter a valid 10-digit mobile number";
      }

      if (customer.username.length > 10) {
        this.errors.username = "Please enter a valid 10-digit mobile number";
      }
    }

    if (!isNum) {
      if ((customer.username.length > 0) && !preg_email.test(customer.username)) {
        this.errors.username = "Please enter a valid email address!";
      }
    }

    console.log(this.errors);
    return (Object.entries(this.errors).length == 0) ? true : false;
  }


  async open() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.validate()) {
        let result = await this.check_user();
        this.show_loader = true;

        result.subscribe((response: any) => {
          if (response.msg == 'success') {
            this.customer.username = '';
            //localStorage.setItem('signup_type', response.data.type);

            if (response.type == 'new') {
              if (response.data.type == 'email') {
                this.customer.email = response.data.username;
                this.customer.mobile = '';
                this.signup_type = 'email';
                this.signup_type2 = 'signup';
                //localStorage.setItem('signup_type', 'email');
                //localStorage.setItem('signup_type2', 'signup');
                //localStorage.setItem('signup', JSON.stringify(this.customer));

                //this.openLSV('signup');
                this.show('signup');
              }

              if (response.data.type == 'mobile') {
                this.customer.mobile = response.data.username;
                this.customer.email = '';
                this.signup_type = 'mobile';
                this.signup_type2 = 'signup';
                //localStorage.setItem('signup_type', 'mobile');
                //localStorage.setItem('signup_type2', 'signup');
                //localStorage.setItem('signup', JSON.stringify(this.customer));

                //this.openLSV('signin');
                this.show('ls-otp');
              }

              this.show_loader = false;
            }

            if (response.type == 'registered') {
              this.customer.mobile = response.data.mobile;
              this.customer.email = response.data.email;
              this.customer.name = response.data.name;
              this.customer.fname = response.data.fname;
              this.signup_type2 = '';
              //localStorage.setItem('signup', JSON.stringify(this.customer));

              if (response.data.type == 'email') {
                this.customer.username = response.data.email;
                this.signup_type = 'email';
                //this.openLSV('password1');
                this.show('password1');
              }

              if (response.data.type == 'mobile') {
                //this.openLSV('signin');
                this.customer.username = response.data.mobile;
                this.signup_type = 'mobile';
                this.show('ls-otp');
              }

              this.show_loader = false;
            }

            //console.log(this.customer);
            // this.openLSV('signin');
          } else {
            this.toastr.clear();

            switch (response.code) {
              case '1': {
                this.toastr.error(response.data, '', { positionClass: 'toast-bottom-center' });
                break;
              }
              case '2': {
                this.toastr.error(response.data, '', { positionClass: 'toast-bottom-center' });
                break;
              }
              case "3": {
                //console.log(response);
                this.customer.email = response.data.email;
                this.toastr.warning(response.data, '', { positionClass: 'toast-bottom-center' });
                this.errors.verification_email = response.text;
                break;
              }
            }

            console.log(response);

            this.show_loader = false;
            this.globals.set_page_loader(false);
          }
        });
      }
    }
  }

  async signInWithGoogle(): Promise<void> {
    await this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(async (user) => {
      console.log(user);

      // let postData = new FormData();
      // postData.append('action', 'users/google/signin');
      // postData.append('firstName', user.firstName);
      // postData.append('lastName', user.lastName);
      // postData.append('email', user.email);

      let postData = {
        action: 'users/google/signin',
        fname: (user.firstName != undefined) ? user.firstName : '',
        lname: (user.lastName != undefined) ? user.lastName : '',
        name: '',
        email: (user.email != undefined) ? user.email : '',
        mobile: '',
        image: (user.photoUrl != undefined) ? user.photoUrl : '',
        auth_token: (user.authToken != undefined) ? user.authToken : '',
        token: (user.idToken != undefined) ? user.idToken : '',
      };

      this.show_loader = true;

      const result = await this.httpClient.post<any>(this.globals.get_endpoint() + '/users/google/signin', postData).subscribe((response) => {
        //console.log(response);
        if (response.msg == 'success') {
          let data = response.data;

          this.customer.key = data.key;
          this.customer.fname = data.fname;
          this.customer.lname = data.lname;
          this.customer.name = data.name;
          this.customer.email = data.email;
          this.customer.mobile = (data.mobile !== null) ? data.mobile : '';
          this.customer.username = '';


          if ((data.mobile.length == 0) || (data.mobile_verified == 'N')) {
            this.signup_type = 'email';
            this.signup_type2 = 'google';
            this.show_change_number = false;
            this.show_close = false;
            this.show_login_with_email = false;

            this.show('google-mob');
            this.show_loader = false;
          }

          if ((data.mobile !== null) && (data.mobile.length > 0) && (data.mobile_verified == 'N')) {
            this.signup_type = 'email';
            this.signup_type2 = 'google';
            this.show_change_number = false;
            this.show_close = false;
            this.show_login_with_email = false;
            this.customer.username = this.customer.mobile;
            this.resend_otp();
            this.show('ls-otp');
            this.show_loader = false;
          }

          if ((data.mobile.length > 0) && (data.mobile_verified == 'Y')) {
            localStorage.setItem('name', data.name);
            localStorage.setItem('fname', data.fname);
            localStorage.setItem(this.globals.customer_key, data.key);
            this.globals.save_local_cart(atob(data.key));

            setTimeout(() => {
              window.location.reload();
            }, 1 * 1000);
          }

          /*
          localStorage.setItem('name', data.name);
          localStorage.setItem('fname', data.fname);
          localStorage.setItem(this.globals.customer_key, data.key);
          this.globals.save_local_cart(atob(data.key));

          setTimeout(() => {
            window.location.reload();
          }, 1 * 1000);*/

          //this.router.navigate(['account/profile-info']);
        } else {
          this.toastr.clear();
          this.toastr.warning(response.data, '', { positionClass: 'toast-bottom-center' });
          this.errors.verification_email = response.data;

          this.show_loader = false;
        }
      });
    });
  }

  validate_google_mob() {
    let customer = this.customer;
    this.errors = {} as any;

    if (customer.mobile.length == 0) {
      this.errors.mobile = "Invalid number";
    }

    if ((customer.mobile.length > 0) && (customer.mobile.length < 10)) {
      this.errors.mobile = "Invalid number";
    }

    //console.log(this.errors);
    return (Object.entries(this.errors).length == 0) ? true : false;
  }

  async verify_google_mob() {
    let postData = {
      action: 'users/resend-otp',
      username: this.customer.email,
      mobile: this.customer.mobile,
      type: 'google',
    };

    this.show_loader = true;

    let result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/users/resend-otp`, postData)
      .subscribe((response) => {
        if (response.msg == 'success') {
          this.toastr.clear();
          this.toastr.success("OTP Sent", '', { positionClass: 'toast-bottom-center' });
          this.show('ls-otp');
          this.signup_type2 = 'google';

          this.show_loader = false;
        } else {
          this.show_loader = false;
        }
      });
  }

  async signin1() {
    if (isPlatformBrowser(this.platformId)) {
      let result = await this.check_user();
    }
  }

  validate_otp() {
    let customer = this.customer;
    this.errors = {} as any;

    if (this.otp.length == 0) {
      this.errors.otp = "Please enter OTP";
    }

    if (this.otp.length > 0 && this.otp.length < 6) {
      this.errors.otp = "Invalid OTP";
    }

    //console.log(this.errors);
    return (Object.entries(this.errors).length == 0) ? true : false;
  }

  async submit_otp() {
    let postData = {
      action: 'users/validate-otp',
      username: this.customer.mobile,
      otp: this.otp,
    } as any;

    if (this.signup_type2 == 'google') {
      postData.username = this.customer.email;
      postData.mobile = this.customer.mobile;
      postData.type = this.signup_type2;
    }

    ////console.log(this.customer);
    if (this.validate_otp()) {
      this.show_loader = true;

      let result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/users/validate-otp`, postData)
        .subscribe(async (response) => {
          if (response.msg == 'success') {
            if (isPlatformBrowser(this.platformId)) {
              //localStorage.setItem('signup_type', response.data.type);
            }

            if (response.type == 'new') { // take usere to registration page, collect personal info
              this.customer.mobile = response.data.username;
              this.customer.email = '';
              this.signup_type2 = 'signup';
              //localStorage.setItem('signup', JSON.stringify(this.customer));
              //localStorage.setItem('signup_type2', 'signup');

              //this.openLSV('signup');
              this.show('signup');
              this.show_loader = false;
            }

            /*--Set user if Email, Mobile & Name not null. Complete Signin/Signup -- */
            if (response.type == 'registered') {
              if (isPlatformBrowser(this.platformId)) {
                localStorage.removeItem('signup');
                localStorage.removeItem('signup_type');
                localStorage.removeItem('signup_type2');

                if (this.signup_type == 'email' && this.signup_type2 == 'signup') {// after email-signup > OTP validation
                  this.show('password3');
                  this.globals.save_local_cart(response.data.key);
                  this.show_loader = false;
                } else { //  if user is already registered and both email and mobile is varified
                  localStorage.setItem(this.globals.customer_key, btoa(response.data.key));
                  await this.globals.save_local_cart(response.data.key);
                  window.location.reload();
                }
              }
            }

            if (response.type == 'google') {

            }
          } else {
            this.show_loader = false;
          }
        });
    }
  }

  async resend_otp() {
    let postData = {
      action: 'users/resend-otp',
      username: this.customer.username,
    };

    let result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/users/resend-otp`, postData)
      .subscribe((response) => {
        if (response.msg == 'success') {
          this.toastr.clear();
          this.toastr.success("OTP Sent", '', { positionClass: 'toast-bottom-center' });
        }
      });
  }

  validate_password() {
    let customer = this.customer;
    this.errors = {} as any;

    if (this.customer.password.length == 0) {
      this.errors.password = "Please enter password";
    }

    if (this.customer.password.length > 0 && this.customer.password.length < 8) {
      this.errors.otp = "Invalid Password";
    }

    //console.log(this.errors);
    return (Object.entries(this.errors).length == 0) ? true : false;
  }

  async submit_password() {
    let postData = {
      action: 'users/signin-password',
      username: this.customer.email,
      password: btoa(this.customer.password),
    };

    ////console.log(this.customer);
    if (this.validate_password()) {
      this.globals.set_page_loader(true);
      this.show_loader = true;

      let result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/users/signin-password`, postData)
        .subscribe((response) => {
          if (response.msg == 'success') {
            localStorage.removeItem('signup');
            localStorage.removeItem('signup_type');
            localStorage.setItem(this.globals.customer_key, btoa(response.data.key));
            this.globals.save_local_cart(response.data.key);
            window.location.reload();
          } else {
            this.toastr.clear();

            switch (response.code) {
              case '1': {
                this.toastr.error(response.data, '', { positionClass: 'toast-bottom-center' });
                break;
              }
              case '2': {
                this.toastr.error(response.data, '', { positionClass: 'toast-bottom-center' });
                break;
              }
              case "3": {
                //console.log(response);
                this.toastr.warning(response.data, '', { positionClass: 'toast-bottom-center' });
                this.errors.verification_email = response.data;
                break;
              }
            }

            this.show_loader = false;
            this.globals.set_page_loader(false);
          }
        });
    }
  }

  async resend_verification_email() {
    let customer = this.customer;
    this.errors = {} as any;

    let postData = {
      action: 'users/resend-verification-email',
      email: this.customer.email,
    };

    this.toastr.clear();

    if (this.customer.email.length > 0) {
      let results = await this.httpClient.post<any>(this.globals.get_endpoint() + `/users/resend-verification-email`, postData)
        .subscribe((response) => {
          if (response.msg == 'success') {
            this.toastr.success("Please check your inbox", '', { positionClass: 'toast-bottom-center' });
          }
        });
    } else {
      this.toastr.warning("Invalid username", '', { positionClass: 'toast-bottom-center' });
      this.errors.password = "Invalid username";
    }
  }

  skip_personal_info() {
    if (isPlatformBrowser(this.platformId)) {
      //localStorage.setItem(this.globals.customer_key, btoa(this.customer.key));
      localStorage.removeItem('signup');
      window.location.reload();
    }
  }

  validate_register() {
    let customer = this.customer;
    this.errors = {} as any;
    const preg_email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (customer.name.length == 0) {
      this.errors.name = "Please enter your name";
    }

    if ((this.signup_type == 'email') && customer.mobile.length == 0) {
      this.errors.mobile = "Invalid number";
    }

    if ((this.signup_type == 'email') && (customer.mobile.length > 0) && (customer.mobile.length < 10)) {
      this.errors.mobile = "Invalid number";
    }

    if ((this.signup_type == 'mobile') && customer.email.length == 0) {
      this.errors.email = "Please enter email";
    }

    if ((this.signup_type == 'mobile') && (customer.email.length > 0) && !preg_email.test(customer.email)) {
      this.errors.email = "Please enter a valid email address!";
    }

    if (customer.password.length == 0) {
      this.errors.password = "Please enter your password";
    }

    if ((customer.password.length > 0) && (customer.password.length < 8)) {
      this.errors.password = "Invalid password. Min 8 char. required";
    }

    //console.log([this.errors, this.customer]);
    return (Object.entries(this.errors).length == 0) ? true : false;
  }

  async register() {
    let postData = {
      action: 'users/register',
      username: this.customer.username,
      name: this.customer.name,
      email: this.customer.email,
      mobile: this.customer.mobile,
      password: btoa(this.customer.password),
      otp: this.otp,
      type: this.signup_type,
    };

    //console.log(this.customer);

    if (this.validate_register()) {
      this.globals.set_page_loader(true);
      this.show_loader = true;

      let result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/users/register`, postData)
        .subscribe((response) => {
          if (response.msg == 'success') {
            if (isPlatformBrowser(this.platformId)) {
              //localStorage.removeItem('signup');
              //localStorage.removeItem('signup_type');
              //localStorage.removeItem('signup_type2');

              this.signup_type2 = 'signup';

              if (this.signup_type == 'email') {
                this.customer.username = this.customer.mobile;
                this.resend_otp();
                this.show('ls-otp');

                this.show_close = false;
                this.show_change_number = false;
                this.show_login_with_email = false;
                this.show_loader = false;
              } else {
                let elements = document.getElementsByClassName('authentication') as any;
                let elmPassword3 = document.getElementsByClassName('password3') as any;

                if (elmPassword3[0].classList.contains('hide')) {
                  elmPassword3[0].classList.remove('hide');
                }

                //console.log(elmPassword3);
                for (let kk = 0; kk < elements.length; kk++) {
                  if (!elements[kk].classList.contains('password3'))
                    elements[kk].classList.add('hide');
                }

                this.show('lsv-action');
                this.lsv_action_type = 'registration-email';

                this.show_loader = false;
              }

              ////localStorage.setItem(this.globals.customer_key, response.data.key);
              //window.location.reload();
              this.globals.set_page_loader(false);
            }
          } else {
            switch (response.code.toString()) {
              case "1": {
                this.toastr.clear();
                this.toastr.error(response.data, '', { positionClass: 'toast-bottom-center' });
                break;
              }
              case "2": {
                this.toastr.clear();
                this.toastr.error(response.data, '', { positionClass: 'toast-bottom-center' });
                break;
              }
              case "4": {
                this.toastr.clear();
                this.toastr.error(response.data, '', { positionClass: 'toast-bottom-center' });
                break;
              }
              case "3": {
                if (response.data.email !== undefined) {
                  this.toastr.clear();
                  this.toastr.error(response.data.email[0], '', { positionClass: 'toast-bottom-center' });
                }
                break;
              }
            }

            this.show_loader = false;
            this.globals.set_page_loader(false);
          }
        });
    } else {
      this.show_loader = false;
      this.globals.set_page_loader(false);
    }
  }

  close() {
    const modalRe = this.modalService.dismissAll('close modal');
  }

  login_with_email() {
    this.customer.username = '';
    this.customer.email = '';
    this.customer.mobile = '';

    setTimeout(() => {
      this.show('common');
    }, 0.2 * 1000);
  }

  onOtpChange(otp: any) {
    this.otp = otp;
    //console.log(event);
  }

  remove_error(name: string) {
    let err = this.errors;
    delete err[name];

    var preg = /^[0-9 ]+$/;
    var regx = /[^0-9]+/g;

    if (name == 'mobile') {
      if (this.customer.mobile.length > 0 && !preg.test(this.customer.mobile)) {
        err.phone = 'Invalid contact number';
        let phone = this.customer.mobile;
        phone = phone.replace(regx, '');
        this.customer.mobile = phone;
        ////console.log([phone, this.address]);
      }
    }

    /*if (name == 'otp') {
      if (this.user.otp.length > 0 && !preg.test(this.user.otp)) {
        err.otp = 'Invalid OTP';
        let otp = this.user.otp;
        otp = otp.replace(regx, '');
        this.user.otp = otp;
        ////console.log([phone, this.address]);
      }
      if (this.user.otp.length > 6) {
        let otp = this.user.otp as string;
        otp = otp.substring(0, 6);
        this.user.otp = otp;
      }
    }*/

    this.errors = err;
  }

  goto_signin() {
    localStorage.removeItem('signup');
    localStorage.removeItem('signup_type');
    localStorage.removeItem('signup_type2');

    //this.openLSV('common');
    this.show('common');
  }

  async send_forgot_password_link() {
    this.errors = {} as any;

    let postData = {
      action: 'users/forgot-password-email',
      email: this.customer.email,
    };

    this.toastr.clear();

    if (this.customer.email.length > 0) {
      let results = await this.httpClient.post<any>(this.globals.get_endpoint() + `/users/forgot-password-email`, postData)
        .subscribe((response) => {
          if (response.msg == 'success') {
            //localStorage.setItem('signup_type2', 'reset-password');
            //this.openLSV('password3');
            this.signup_type2 = 'reset-password';
            this.show('password2');
            this.toastr.success("Please check your inbox", '', { positionClass: 'toast-bottom-center' });
          }
        });
    } else {
      this.toastr.warning("Invalid username", '', { positionClass: 'toast-bottom-center' });
      this.errors.password = "Invalid username";
    }
  }

  validate_reset_password() {
    let customer = this.customer;
    this.errors = {} as any;

    if (customer.password.length == 0) {
      this.errors.password = "Please enter your password";
    }

    if ((customer.password.length > 0) && (customer.password.length < 8)) {
      this.errors.password = "Invalid password. Min 8 char. required";
    }

    if (customer.password.length > 0 && customer.confirm_password.length > 0) {
      if (customer.password != customer.confirm_password) {
        this.errors.confirm_password = "Password and Confirm Password mismatch!";
        this.errors.push('confirm_password');
      }
    }

    //console.log([this.errors, this.customer]);
    return (Object.entries(this.errors).length == 0) ? true : false;
  }

  async reset_password() {
    this.errors = {} as any;
    let token = this.token;//await (this.activeRoute.queryParams['token'] !== undefined) ? this.activeRoute.queryParams['token'] : '';

    let postData = {
      action: 'users/reset-password-email',
      email: this.customer.email,
      password: btoa(this.customer.password),
      confirm_password: btoa(this.customer.confirm_password),
      token: token,
    };

    this.toastr.clear();

    if (this.validate_reset_password()) {
      let results = await this.httpClient.post<any>(this.globals.get_endpoint() + `/users/reset-password-email`, postData)
        .subscribe((response) => {
          if (response.msg == 'success') {
            localStorage.removeItem('signup');
            localStorage.removeItem('signup_type');
            localStorage.removeItem('signup_type2');

            this.router.navigate(
              ['.'],
              { relativeTo: this.activeRoute, queryParams: {} }
            );

            //this.openLSV('common');
            this.show('lsv-common');
            this.toastr.success("Password reset successfully", '', { positionClass: 'toast-bottom-center' });
          } else {
            switch (response.code.toString()) {
              case "5": {
                if (response.data.password !== undefined) {
                  this.toastr.clear();
                  this.toastr.error(response.data.password[0], '', { positionClass: 'toast-bottom-center' });
                }
                if (response.data.confirm_password !== undefined) {
                  this.toastr.clear();
                  this.toastr.error(response.data.confirm_password[0], '', { positionClass: 'toast-bottom-center' });
                }
                break;
              }
              default: {
                this.toastr.clear();
                this.toastr.error(response.data, '', { positionClass: 'toast-bottom-center' });
                break;
              }
            }
          }
        });
    } else {
      //this.toastr.warning("Invalid username", '', { positionClass: 'toast-bottom-center' });
    }
  }

  check_enter_pressed(name: string, event: any) {
    ////console.log([name, event]);
    if (event.keyCode == 13) {
      switch (name) {
        case 'open': {
          this.open();
          break;
        }
        case 'signin': {
          this.signin1();
          break;
        }
        case 'signup': {
          this.register();
          break;
        }
        case "password1": {
          this.submit_password();
          break;
        }
        case "password2": {
          this.reset_password();
          break;
        }
        case 'verify-google-mob': {
          this.verify_google_mob();
          break;
        }
        case 'submit_password': {
          this.submit_password();
          break;
        }
      }
    }

    return true;
  }

  show_close_btn(): boolean {
    if (this.signup_type == 'email' && this.signup_type2 == 'signup') {
      return false;
    }

    return true;
  }

}
