import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { isPlatformBrowser } from '@angular/common';
import * as moment from 'moment';
import { NgbModalConfig, NgbModal, NgbActiveModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { parse } from 'path';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  rows = [] as any;

  cart = {
    total: 0,
    price: 0,
    price_payable: 0,
    discount_amount: 0,
    item_qnty: 0,
    shipping: 0,
    coupon_code: '',
    coupon: {} as any,
    maximum_saving: 0,
  } as any;
  customer_key: string = '';
  cart_count = 0;
  giftwrap = false;

  //--remove from cart--
  show_remove_item = false;
  selected_indx = '';
  selected_item = {} as any;

  //--coupons popup--
  show_apply_coupon = false;
  coupon_code = '';
  maximum_saving = 0;
  coupons = {
    rows: [] as any,
    code: '',
    maximum_saving: 0,
  } as any;


  constructor(
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public toastr: ToastrService,
    private offcanvasService: NgbOffcanvas,
    private auth: AuthenticationService,
    public modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {

  }

  async ngAfterContentInit() {
    //console.log("cart:ngAfterViewInit");
    this.customer_key = atob(await this.globals.get_customer_key()) as string;
    this.globals.cart_count_observer.subscribe(x => this.cart_count = x);
    this.globals.set_page_loader(true);
    //this.globals.set_checkout_active('checkout-bag');

    if (this.customer_key == null || this.customer_key.length == 0) {
      //this.router.navigate(['/']);
    }

    await this.get_data();
    this.get_coupons();

    if (isPlatformBrowser(this.globals.platformId)) {
      let giftwrap = await localStorage.getItem('giftwrap') as string;
      this.giftwrap = (giftwrap == 'true') ? true : false;

      this.coupon_code = await localStorage.getItem('coupon_code') as string;

      if (this.coupon_code != null && this.coupon_code.length > 0)
        await this.get_coupon_details();
    }

    /**
     * close the search bar if it's open. set it to falses
     */
    this.globals.set_suggestion_bar_state(false);
  }

  async get_data() {
    let postData = new FormData();

    postData.append('action', 'cart/list');
    this.globals.set_page_loader(true);

    if (this.customer_key !== null && this.customer_key.length > 0) {
      const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${this.customer_key}/cart`, postData).subscribe((response) => {
        //console.log(response);
        this.rows = response.data;
        this.cart = response.cart;

        setTimeout(() => {
          this.globals.set_page_loader(false);
          this.globals.set_cart_count(this.item_count());
          //console.log('setTimeout');
        }, 0.5 * 1000);
      });
    } else {
      this.get_localcart();
    }

  }

  async remove() {
    let product = this.selected_item;
    this.globals.set_page_loader(true);

    if (this.customer_key !== null && this.customer_key.length > 0) { // if logged in
      let postData = new FormData();

      postData.append('action', 'cart/remove');
      postData.append('key', product.cart_key);

      const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/cart/${this.customer_key}/remove/${product.cart_key}`, postData).subscribe((response) => {
        if (response.msg == 'success') {
          this.get_data();
          this.globals.set_cart_count(this.item_count());
          this.globals.set_page_loader(false);
          this.hide_remove_popup();
        }
      });
    } else { // if not logged in
      let cart = (localStorage.getItem('cart') != null) ? JSON.parse(localStorage.getItem('cart') as string) : [];
      let temp = [];

      if (cart.length > 0) {
        for (let kk = 0; kk < cart.length; kk++) {
          ////console.log((cart[kk].pkey == product.key && cart[kk].size == product.size && cart[kk].color == product.color));
          if (!(cart[kk].pkey == product.key && cart[kk].size == product.size && cart[kk].color == product.color)) {
            temp.push(cart[kk]);
          }
        }

        localStorage.setItem('cart', JSON.stringify(temp));
        this.get_data();
        this.hide_remove_popup();
        this.globals.set_cart_count(this.item_count());
        //this.globals.set_page_loader(false);
      } else {
        this.globals.set_page_loader(false);
      }
    }

    //console.log(product); //cart/{customer_key}/remove/{key}
  }

  async update_qnty(product: any) {
    //let product = this.selected_item;
    this.globals.set_page_loader(true);

    if (this.customer_key !== null && this.customer_key.length > 0) { // if logged in
      let postData = new FormData();

      postData.append('action', 'cart/quantity/update');
      postData.append('qnty', product.qnty);

      const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/cart/${this.customer_key}/quantity/update/${product.cart_key}`, postData).subscribe((response) => {
        if (response.msg == 'success') {
          this.get_data();
        }
      });
    } else { // if not logged in
      let cart = (localStorage.getItem('cart') != null) ? JSON.parse(localStorage.getItem('cart') as string) : [];
      let temp = [];

      if (cart.length > 0) {
        for (let kk = 0; kk < cart.length; kk++) {
          ////console.log((cart[kk].pkey == product.key && cart[kk].size == product.size && cart[kk].color == product.color));
          if ((cart[kk].pkey == product.key) && (cart[kk].size == product.size) && (cart[kk].color == product.color)) {
            cart[kk].qnty = product.qnty;
          }
        }

        console.log(cart, product);

        localStorage.setItem('cart', JSON.stringify(cart));
        this.get_data();
        this.hide_remove_popup();
        //this.globals.set_page_loader(false);
      } else {
        this.globals.set_page_loader(false);
      }
    }


  }

  async update_size(indx: any) {
    let product = this.rows[indx];
    this.globals.set_page_loader(true);
    this.toastr.clear();

    if (this.customer_key !== null && this.customer_key.length > 0) { // if logged in
      let postData = new FormData();

      postData.append('action', 'cart/size/update');
      postData.append('key', product.cart_key);
      postData.append('size', product.size);
      postData.append('color', product.color);
      postData.append('product_key', product.key);
      postData.append('vkey', product.variant.vkey);

      const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/cart/${this.customer_key}/size/update/${product.cart_key}`, postData).subscribe((response) => {
        if (response.msg == 'success') {
          this.get_data();
        } else {
          this.toastr.clear();
          this.toastr.warning(response.data, '', { positionClass: 'toast-bottom-center' });
          this.globals.set_page_loader(false);
          this.get_data();
        }
      });

    } else { // if not logged in
      let cart = (localStorage.getItem('cart') != null) ? JSON.parse(localStorage.getItem('cart') as string) : [];
      let temp = [];
      let found = false;

      if (cart.length > 0) {
        for (let kk = 0; kk < cart.length; kk++) {
          ////console.log((cart[kk].pkey == product.key && cart[kk].size == product.size && cart[kk].color == product.color));
          if ((cart[kk].pkey == product.key && cart[kk].size == product.size && cart[kk].color == product.color)) {
            //cart[kk].qnty = product.qnty;
            found = true;
          }
        }

        if (found) {
          this.toastr.warning('Item already exist in cart', '', { positionClass: 'toast-bottom-center' });
          this.globals.set_page_loader(false);
          setTimeout(() => {
            this.rows[indx].size = cart[indx].size;
          }, 0.2 * 1000);
        }

        if (!found) {
          cart[indx].size = product.size;
          localStorage.setItem('cart', JSON.stringify(cart));
          this.get_data();
        }

        //this.globals.set_page_loader(false);
        //console.log('found:', found);
      } else {
        this.globals.set_page_loader(false);
      }
    }
  }

  item_count() {
    let total = 0;

    if (this.rows.length > 0) {
      for (let kk = 0; kk < this.rows.length; kk++) {
        total += parseInt(this.rows[kk].qnty);
      }
    }

    return total;
  }

  round(num: number) {
    return Math.round(num);
  }

  /**
   * name
   */
  async get_localcart() {
    let cart = (localStorage.getItem('cart') != null) ? JSON.parse(localStorage.getItem('cart') as string) : [];
    //console.log(cart);

    if (cart.length > 0) {
      let postData = new FormData();

      postData.append('action', 'checkout/cart');

      for (let kk = 0; kk < cart.length; kk++) {
        postData.append(`cart[${kk}][name]`, cart[kk].name);
        postData.append(`cart[${kk}][slug]`, cart[kk].slug);
        postData.append(`cart[${kk}][color]`, cart[kk].color);
        postData.append(`cart[${kk}][size]`, cart[kk].size);
        postData.append(`cart[${kk}][price_unit]`, cart[kk].price_unit);
        postData.append(`cart[${kk}][price_payable]`, cart[kk].price_payable);
        postData.append(`cart[${kk}][price]`, cart[kk].price);
        postData.append(`cart[${kk}][qnty]`, cart[kk].qnty);
        postData.append(`cart[${kk}][pkey]`, cart[kk].pkey);
        postData.append(`cart[${kk}][pindx]`, cart[kk].pindx);
        postData.append(`cart[${kk}][vkey]`, cart[kk].vkey);
        postData.append(`cart[${kk}][image]`, cart[kk].image);
      }

      const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/checkout/cart`, postData).subscribe((response) => {
        //console.log(response);
        this.rows = response.data;
        this.cart = response.cart;

        setTimeout(() => {
          this.globals.set_page_loader(false);
          //console.log('setTimeout');
        }, 0.5 * 1000);
      });
    } else {
      this.globals.set_page_loader(false);
    }
  }

  show_signin(name: any) {
    this.auth.openLSV(name);
  }

  async place_order() {
    if (this.customer_key != null && this.customer_key.length > 0) {
      localStorage.setItem('giftwrap', this.giftwrap.toString());

      localStorage.removeItem('coupon_code');
      if (this.cart.coupon_code !== undefined && this.cart.coupon_code.length > 0) {
        let postData = {
          action: 'account/coupons/check',
          coupon_code: this.cart.coupon_code,
          //customer_key: this.customer_key,
        };

        let results = await this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${btoa(this.customer_key)}/coupons/${this.cart.coupon_code}/check`, postData)
          .subscribe((response) => {
            if (response.msg == 'success') {
              localStorage.setItem('coupon_code', this.cart.coupon.key.toString());
              this.router.navigate(['checkout/address']);
            } else {
              this.toastr.warning(response.data, '', { positionClass: 'toast-bottom-center' });
            }
          });
      } else {
        this.router.navigate(['checkout/address']);
      }

    } else {
      this.auth.openLSV('common');
    }
  }

  show_remove_popup(indx: any) {
    this.selected_indx = indx;
    this.selected_item = this.rows[indx];
    this.show_remove_item = true;

    //console.log(this.selected_indx, this.selected_item);
  }
  hide_remove_popup() {
    this.selected_indx = '';
    this.selected_item = {};
    this.show_remove_item = false;
  }

  async move_to_wishlist() {
    let product = this.selected_item;

    if (this.customer_key !== null && this.customer_key.length > 0) { // if logged in
      let postData = new FormData();

      postData.append('action', 'cart/move-to-wishlist');
      postData.append('qnty', product.cart_key);

      const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/cart/${btoa(this.customer_key)}/move-to-wishlist/${this.selected_item.cart_key}`, postData)
        .subscribe((response) => {
          if (response.msg == 'success') {
            this.toastr.success('Item moved to wishlist', '', { positionClass: 'toast-bottom-center' });
            this.get_data();
            this.hide_remove_popup();
          }
        });
    } else {// if not logged in
      this.toastr.warning('Signin/Register with us', '', { positionClass: 'toast-bottom-center' });
      this.auth.openLSV('common');
    }
  }

  show_coupon_popup() {
    this.show_apply_coupon = true;
  }
  hide_coupon_popup() {
    this.show_apply_coupon = false;
  }


  giftwrap_change(event: any) {
    console.log(event);

    localStorage.setItem('giftwrap', this.giftwrap.toString());
    this.price_payable();
  }

  change_qnty(type: string, indx: number) {
    switch (type) {
      case 'P': {
        this.rows[indx].qnty = parseInt(this.rows[indx].qnty) + 1;

        if (this.rows[indx].qnty > 10)
          this.rows[indx].qnty = 10;

        break;
      }
      case 'M': {
        this.rows[indx].qnty -= 1;

        if (this.rows[indx].qnty < 1)
          this.rows[indx].qnty = 1;

        break;
      }
    }

    this.update_qnty(this.rows[indx]);
  }

  close_side_cart() {
    const offcanvasRef = this.modalService.dismissAll('close modal');
    console.log(this.offcanvasService.activeInstance);
    this.offcanvasService.dismiss('Canvas closed');
  }

  /*/---COUPONS----*/
  async get_coupon_details() {
    let postData = {
      action: 'account/coupons/view',
      coupon_code: this.coupon_code,
    };
    this.globals.set_page_loader(true);

    if (this.customer_key !== null && this.customer_key.length > 0) {
      const result = await this.httpClient.post<any>(
        this.globals.get_endpoint() + `/account/customer/${btoa(this.customer_key)}/coupons/${this.coupon_code}`
        , postData
      ).subscribe((response) => {
        if (response.msg == 'success') {
          this.cart.coupon = response.data;
          this.cart.coupon_code = response.data.code;

          this.price_payable();


          setTimeout(() => {
            this.globals.set_page_loader(false);
          }, 0.5 * 1000);
        } else {
          this.globals.set_page_loader(false);
        }
      });
    } else {
      this.globals.set_page_loader(false);
    }
  }

  async get_coupons() {
    let postData = new FormData();

    postData.append('action', 'coupons/list');
    this.globals.set_page_loader(true);

    if (this.customer_key !== null && this.customer_key.length > 0) {
      const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${this.customer_key}/coupons`, postData).subscribe((response) => {
        this.coupons.rows = response.data;

        setTimeout(() => {
          this.globals.set_page_loader(false);
        }, 0.5 * 1000);
      });
    } else {
      this.globals.set_page_loader(false);
    }
  }

  coupon_save_amount(indx: number) {
    let coupon = this.coupons.rows[indx];
    let total = 0;

    if (coupon.type == 'flat') {
      total = coupon.discount;
    }

    if (coupon.type == 'percent') {
      let discount = Math.round(Math.abs((this.cart.total * coupon.discount) / 100));
      //console.log(discount, Math.round(Math.abs(discount / 100)));
      total = discount;
    }

    if (coupon.has_min_limit == 'Y' && (parseFloat(coupon.min_purchase) >= parseFloat(this.cart.price_payable))) {
      total = 0
    }


    return total;
  }

  on_change_coupon(indx: number) {
    let coupon = this.coupons.rows[indx];
    let total = 0;

    this.coupon_code = coupon.code;
    this.coupons.maximum_saving = this.check_maximum_savings(coupon);
    //console.log(this.coupons);
  }

  async apply_coupon(): Promise<any> {
    this.toastr.clear();

    if (this.coupon_code == null || this.coupon_code.length == 0) {
      this.toastr.warning("Please enter a coupon code", '', { positionClass: 'toast-bottom-center' });
      return false;
    }

    let postData = {
      action: 'account/coupons/check',
      coupon_code: this.coupon_code,
      //customer_key: this.customer_key,
    };

    let results = await this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${btoa(this.customer_key)}/coupons/${this.cart.coupon_code}/check`, postData)
      .subscribe((response) => {
        if (response.msg == 'success') {
          this.cart.coupon = {} as any;
          this.cart.coupon_code = this.coupons.code;
          this.cart.maximum_saving = 0; //this.coupons.maximum_saving;

          this.coupons.rows.filter((i: any, b: any) => {
            if (i.code == this.coupons.code) {
              if (i.has_min_limit == 'Y' && (parseFloat(this.cart.price_payable) >= parseFloat(i.min_purchase))) {
                this.cart.coupon = i;
              }
              if (i.has_min_limit == 'N') {
                this.cart.coupon = i;
              }
              console.log(i, b, this.cart);
            }
          });

          this.price_payable();
          this.hide_coupon_popup();

          //localStorage.setItem('coupon_code', this.cart.coupon.key.toString());
          //this.router.navigate(['checkout/address']);
        } else {
          this.toastr.warning(response.data, '', { positionClass: 'toast-bottom-center' });
        }
      });

    console.log(this.cart);
  }

  check_maximum_savings(coupon: any) {
    let total = 0;

    if (coupon.type == 'flat') {
      total = coupon.discount;
    }

    if (coupon.type == 'percent') {
      let discount = Math.round(Math.abs((this.cart.total * coupon.discount) / 100));
      //console.log(discount, Math.round(Math.abs(discount / 100)));
      total = discount;
    }

    return total;
  }

  price_payable() {
    let total = 0;

    if (Object.entries(this.cart).length > 0) {
      total = this.cart.total;

      if (this.cart.coupon != undefined && Object.entries(this.cart.coupon).length > 0) {
        let discount = 0;

        if (this.cart.coupon.type == 'flat') {
          discount = this.cart.coupon.discount;
        }

        if (this.cart.coupon.type == 'percent') {
          discount = Math.round(Math.abs((this.cart.total * this.cart.coupon.discount) / 100));
        }

        if (this.cart.coupon.has_min_limit == 'Y' && (this.cart.total >= this.cart.coupon.min_purchase)) {
          total = total - discount;
        } else {
          total = total - discount;
        }

        this.cart.maximum_saving = discount;
        //console.log(discount);
      }

      //console.log(this.giftwrap);
      if (this.giftwrap)
        total = total + 25;

      this.cart.price_payable = total + this.cart.shipping;
    }

    return total;
  }

  item_price_coupon_discount(indx: number) {
    let discount = 0;
    let qnty = 0;

    for (let kk = 0; kk < this.rows.length; kk++) {
      qnty += this.rows[kk].qnty;
    }
    //console.log(qnty);

    if (this.cart.maximum_saving > 0) {
      if (this.cart.coupon.type == 'flat') {
        discount = (this.cart.coupon.discount / qnty);
        discount = discount * this.rows[indx].qnty;
      }

      if (this.cart.coupon.type == 'percent') {
        discount = (Math.abs((this.rows[indx].price_payable * this.cart.coupon.discount) / 100));
        discount = discount * this.rows[indx].qnty;
      }
    }

    return Math.round(discount);
  }

  async check_coupon(): Promise<any> {
    this.toastr.clear();

    if (this.coupon_code == null || this.coupon_code.length == 0) {
      this.toastr.warning("Please enter a coupon code", '', { positionClass: 'toast-bottom-center' });
      return false;
    }

    let postData = {
      action: 'account/coupons/check',
      coupon_code: this.coupon_code,
      //customer_key: this.customer_key,
    };

    let results = await this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${btoa(this.customer_key)}/coupons/${this.cart.coupon_code}/check`, postData)
      .subscribe((response) => {
        if (response.msg == 'success') {
          localStorage.setItem('coupon_code', this.cart.coupon.key.toString());
          //this.router.navigate(['checkout/address']);
        } else {
          this.toastr.warning(response.data, '', { positionClass: 'toast-bottom-center' });
        }
      });
  }
  /*/---./COUPONS----*/

  close_menu() {
    //const offcanvasRef = this.modalService.dismissAll('close modal');
    console.log(this.offcanvasService.activeInstance);
    this.offcanvasService.dismiss('Canvas closed');
  }

  has_min_limit(coupon: any): boolean {
    if (coupon.has_min_limit == 'Y') {
      if (parseFloat(coupon.min_purchase) >= parseFloat(this.cart.price_payable)) {
        return true;
      }
    }

    return false;
  }
}
