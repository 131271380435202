import { NgModule } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { GlobalsService } from 'src/app/services/globals.service';
import { HttpClient } from '@angular/common/http';
import { Router } from 'express';
import { ActivatedRoute } from '@angular/router';
//import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class CustomersModule {
  public key: string;
  public username: string;
  public password: string;
  public confirm_password: string;
  public remember_me: boolean;

  public fname: string;
  public mname: string;
  public lname: string;
  public name: string;
  public email: string;
  public mobile: string;
  public dob: string;
  public gender: string;
  public gender_text: string;
  public status: string;
  public status_text: string;

  public constructor() {
    this.key = '';
    this.username = '';
    this.password = '';
    this.confirm_password = '';
    this.remember_me = false;

    this.fname = '';
    this.mname = '';
    this.lname = '';
    this.name = '';
    this.email = '';
    this.mobile = '';
    this.dob = '';
    this.gender = '';
    this.gender_text = '';
    this.status = '';
    this.status_text = '';
  }

  public full_name() {
    return ((this.fname.length > 0) ? this.fname + ' ' : '')
      + ((this.mname.length > 0) ? this.mname + ' ' : '')
      + ((this.lname.length > 0) ? this.lname + ' ' : '');
  }
}
