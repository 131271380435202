import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

import { SwiperComponent } from "swiper/angular";
import SwiperCore, { SwiperOptions, Zoom, Navigation, Pagination, Lazy, Autoplay, Thumbs } from 'swiper';
SwiperCore.use([Zoom, Navigation, Pagination, Lazy, Autoplay, Thumbs]);


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GalleryComponent implements OnInit {

  constructor() { }

  galleryToggle: boolean = true;
  product_images = [] as any;

  @Input() params = {
    currentIndex: 0,
    images: [] as any,
  };

  closeGallery() {
    //this.galleryToggle = !this.galleryToggle;
    console.log("need to close gallery");
  }


  ngOnInit(): void {
  }

}
