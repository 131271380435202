<app-header-announcement></app-header-announcement>
<div class="flex column header">
  <header class="flex allign-middle site-header">
    <div class="flex w-100 site-header-box">
      <div class="flex position-center container">
        <div class="flex allign-middle header-item left h-100 navigation">
          <button class="flex site-nav" (click)="openMenu()">
            <svg viewBox="0 0 512 512">
              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="48"
                d="M88 152h336M88 256h336M88 360h336" />
            </svg>
          </button>
          <a class="flex position-center header-links-items search" (click)="openSearch()">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none"
                stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
              <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"
                d="M338.29 338.29L448 448" />
            </svg>
          </a>

          <!--<app-menu-top class="flex h-100 header-menu"></app-menu-top> -->
        </div>
        <div class="flex position-center header-item middle logo" style="cursor: pointer;">
          <a class="flex position-center" [routerLink]="['/']" routerLinkActive="router-link-active">
            <svg viewBox="0 0 1908.000000 272.000000" preserveAspectRatio="xMidYMid meet">
              <g transform="translate(0.000000,272.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
                <path d="M7816 2619 c-24 -52 -299 -627 -611 -1279 -312 -652 -569 -1193 -572
              -1202 -5 -17 13 -18 274 -18 l279 0 128 280 129 280 417 0 417 0 128 -280 129
              -280 284 0 c156 0 281 3 279 8 -2 4 -280 585 -617 1292 -337 707 -614 1287
              -616 1289 -2 2 -24 -38 -48 -90z m339 -1530 c6 -18 -2 -19 -294 -19 -166 0
              -301 2 -301 4 0 3 61 146 136 318 75 172 142 329 150 348 l15 35 144 -333 c79
              -184 147 -342 150 -353z" />
                <path d="M10232 1408 l3 -1283 240 0 240 0 3 758 2 758 818 -772 c449 -425
              838 -793 865 -818 l47 -45 0 1287 0 1287 -237 -2 -238 -3 -5 -756 -5 -756
              -860 813 c-473 448 -863 814 -868 814 -4 0 -6 -577 -5 -1282z" />
                <path d="M1090 2605 c-185 -27 -363 -91 -517 -187 -114 -70 -281 -234 -357
              -351 -98 -149 -172 -341 -195 -511 -16 -115 -14 -347 5 -457 55 -324 224 -604
              481 -798 80 -61 257 -151 364 -185 263 -84 630 -80 882 11 426 153 711 487
              808 946 29 134 31 388 5 522 -87 456 -374 794 -804 949 -181 65 -469 91 -672
              61z m455 -488 c285 -93 469 -337 505 -670 47 -437 -196 -811 -580 -892 -92
              -19 -250 -19 -348 1 -282 57 -514 302 -573 604 -16 84 -16 286 0 370 58 298
              288 543 566 604 112 25 327 16 430 -17z" />
                <path d="M14850 2609 c-427 -48 -792 -283 -979 -631 -104 -192 -141 -337 -148
              -584 -6 -208 8 -322 59 -484 140 -439 509 -754 978 -834 287 -49 574 -17 797
              89 340 161 566 456 653 854 21 96 47 369 37 395 -6 15 -60 16 -607 16 l-600 0
              0 -200 0 -200 350 0 c193 0 350 -2 350 -5 0 -2 -11 -34 -25 -71 -80 -213 -249
              -368 -462 -426 -105 -28 -316 -30 -418 -4 -226 58 -416 216 -516 429 -95 205
              -104 516 -20 732 77 198 250 367 447 436 238 83 515 59 698 -60 71 -46 164
              -137 208 -202 16 -24 34 -43 40 -41 9 2 340 194 395 228 l23 14 -37 53 c-63
              89 -222 240 -317 302 -255 166 -566 232 -906 194z" />
                <path d="M3840 1345 l0 -1225 250 0 250 0 2 443 3 442 109 0 109 0 285 -442
              284 -443 295 0 294 0 -60 87 c-33 49 -181 261 -329 473 l-268 384 95 48 c210
              103 334 254 391 473 28 104 30 287 5 395 -73 319 -336 533 -710 579 -54 6
              -275 11 -547 11 l-458 0 0 -1225z m943 780 c116 -30 200 -95 247 -190 22 -44
              25 -63 25 -160 0 -97 -3 -116 -25 -160 -48 -98 -146 -168 -268 -194 -29 -6
              -136 -11 -237 -11 l-185 0 0 365 0 365 193 0 c139 0 209 -4 250 -15z" />
                <path d="M17520 1345 l0 -1225 780 0 780 0 0 210 0 210 -535 0 -535 0 0 335 0
              335 500 0 500 0 0 210 0 210 -500 0 -500 0 0 260 0 260 535 0 535 0 0 210 0
              210 -780 0 -780 0 0 -1225z" />
              </g>
            </svg>
          </a>
        </div>

        <div class="flex header-item right header-links">
          <div class="flex allign-middle header-links-container">
            <!--<a class="flex position-center header-links-items search" (click)="openSearch()">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none"
                  stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32"
                  d="M338.29 338.29L448 448" />
              </svg>
            </a>-->

            <a class="flex position-center header-links-items account" (click)="openLsv()" *ngIf="!is_logged_in()">
              <svg viewBox="0 0 512 512">
                <path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
                <path
                  d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                  fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
              </svg>
            </a>


            <div class="flex position-center header-links-items account item user-account" *ngIf="is_logged_in()">
              <svg viewBox="0 0 512 512">
                <path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none"
                  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
                <path
                  d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                  fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
              </svg>
              <div class="flex flex-column user-account-menu">
                <div class="flex position-center not-loggedin">
                  <span>Welcome! {{customer_fname}}</span>
                </div>
                <div class="flex flex-column header-profile-link">
                  <a class="flex allign-middle" routerLink="/account/orders">
                    <svg viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor"
                        d="M32 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V160H32v288zm160-212c0-6.6 5.4-12 12-12h104c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-8zM480 32H32C14.3 32 0 46.3 0 64v48c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16V64c0-17.7-14.3-32-32-32z">
                      </path>
                    </svg>
                    <span>Orders</span>
                  </a>
                  <a class="flex allign-middle" routerLink="/account/wishlist">
                    <svg viewBox="0 0 512 512">
                      <path fill="currentColor"
                        d="M256 448a32 32 0 01-18-5.57c-78.59-53.35-112.62-89.93-131.39-112.8-40-48.75-59.15-98.8-58.61-153C48.63 114.52 98.46 64 159.08 64c44.08 0 74.61 24.83 92.39 45.51a6 6 0 009.06 0C278.31 88.81 308.84 64 352.92 64c60.62 0 110.45 50.52 111.08 112.64.54 54.21-18.63 104.26-58.61 153-18.77 22.87-52.8 59.45-131.39 112.8a32 32 0 01-18 5.56z" />
                    </svg>
                    <span>Wishlist</span>
                  </a>
                  <a class="flex allign-middle" routerLink="/contact-us">
                    <svg viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor"
                        d="M32 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V160H32v288zm160-212c0-6.6 5.4-12 12-12h104c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-8zM480 32H32C14.3 32 0 46.3 0 64v48c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16V64c0-17.7-14.3-32-32-32z">
                      </path>
                    </svg>
                    <span>Customer Care</span>
                  </a>
                </div>
                <div class="flex flex-column header-profile-logout">
                  <a class="flex allign-middle" (click)="logout()">
                    <svg viewBox="0 0 512 512">
                      <path fill="#000"
                        d="M256 464c-114.69 0-208-93.23-208-207.82a207.44 207.44 0 0174.76-160.13l16.9-14 28.17 33.72-16.9 14A163.72 163.72 0 0092 256.18c0 90.39 73.57 163.93 164 163.93s164-73.54 164-163.93a163.38 163.38 0 00-59.83-126.36l-17-14 28-33.82 17 14A207.13 207.13 0 01464 256.18C464 370.77 370.69 464 256 464z" />
                      <path fill="#000" d="M234 48h44v224h-44z" />
                    </svg>
                    <span>Logout</span>
                  </a>
                </div>
              </div>
            </div>







            <!--<div class="flex position-center item user-account">
              <a class="flex position-center header-links-items account">
                <svg viewBox="0 0 512 512">
                  <path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none"
                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
                  <path
                    d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                    fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
                </svg>
              </a>
              <div class="flex flex-column user-account-menu">

                for logged in users
                <div class="flex flex-column header-profile-link" *ngIf="is_logged_in()">
                  <a class="flex allign-middle" routerLink="/account/orders">
                    <svg viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor"
                        d="M32 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V160H32v288zm160-212c0-6.6 5.4-12 12-12h104c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-8zM480 32H32C14.3 32 0 46.3 0 64v48c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16V64c0-17.7-14.3-32-32-32z">
                      </path>
                    </svg>
                    <span>Orders</span>
                  </a>
                  <a class="flex allign-middle" routerLink="/account/wishlist">
                    <svg viewBox="0 0 512 512">
                      <path fill="currentColor"
                        d="M256 448a32 32 0 01-18-5.57c-78.59-53.35-112.62-89.93-131.39-112.8-40-48.75-59.15-98.8-58.61-153C48.63 114.52 98.46 64 159.08 64c44.08 0 74.61 24.83 92.39 45.51a6 6 0 009.06 0C278.31 88.81 308.84 64 352.92 64c60.62 0 110.45 50.52 111.08 112.64.54 54.21-18.63 104.26-58.61 153-18.77 22.87-52.8 59.45-131.39 112.8a32 32 0 01-18 5.56z" />
                    </svg>
                    <span>Wishlist</span>
                  </a>
                  <a class="flex allign-middle" routerLink="/contact-us">
                    <svg viewBox="0 0 512 512" data-fa-i2svg="">
                      <path fill="currentColor"
                        d="M32 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V160H32v288zm160-212c0-6.6 5.4-12 12-12h104c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-8zM480 32H32C14.3 32 0 46.3 0 64v48c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16V64c0-17.7-14.3-32-32-32z">
                      </path>
                    </svg>
                    <span>Customer Care</span>
                  </a>
                </div>
                ./for logged in users

                <div class="flex flex-column header-profile-logout" *ngIf="is_logged_in()">
                  <a class="flex allign-middle" (click)="logout()">
                    <svg viewBox="0 0 512 512">
                      <path fill="#000"
                        d="M256 464c-114.69 0-208-93.23-208-207.82a207.44 207.44 0 0174.76-160.13l16.9-14 28.17 33.72-16.9 14A163.72 163.72 0 0092 256.18c0 90.39 73.57 163.93 164 163.93s164-73.54 164-163.93a163.38 163.38 0 00-59.83-126.36l-17-14 28-33.82 17 14A207.13 207.13 0 01464 256.18C464 370.77 370.69 464 256 464z" />
                      <path fill="#000" d="M234 48h44v224h-44z" />
                    </svg>
                    <span>Logout</span>
                  </a>
                </div>
              </div>
            </div>-->

            <!--<a class="flex position-center header-links-items wishlist ">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path
                  d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z"
                  fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
              </svg>
            </a>-->
            <a class="flex position-center position-relative header-links-items" (click)="openCart()">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
                  d="M80 176a16 16 0 00-16 16v216c0 30.24 25.76 56 56 56h272c30.24 0 56-24.51 56-54.75V192a16 16 0 00-16-16zM160 176v-32a96 96 0 0196-96h0a96 96 0 0196 96v32" />
              </svg>
              <span class="flex position-center badge" *ngIf="cart_count>0">{{cart_count}}</span>
            </a>
          </div>
        </div>
      </div>

      <div class="search-container">
        <div class="search-entry-box">
          <div class="search-icon"></div>
          <div class="search-input"></div>
          <div class="search-close"></div>
        </div>
      </div>

    </div>
  </header>
</div>
