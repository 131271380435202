<div class="all-reviews-page">
    <div class="flex container" style="max-width:1000px">
        <div class="flex left reviewed-product">
            <div class="flex product">
                <div class="flex column product-content">
                    <div class="flex image">
                        <img class="flex" src="../assets/images/p141.jpg">
                    </div>
                    <div class="flex position-center w-100 name">
                        <span>Griff Black Puffer Jacket</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex column right all-reviews-container">
            <div class="flex position-center w-100 user-rating-box">
                <div class="flex column position-center total-rating">
                    <p class="flex main">
                        <span>4.4</span>
                        <span class="star">★</span>
                    </p>
                    <p class="flex sub-rating">1000 Ratings</p>
                    <p class="flex sub-review">789 Reviews</p>
                </div>
                <div class="flex flex-column detailed-rating">
                  <div class="flex allign-middle rating-brkup rat-5">
                    <div class="flex allign-middle rating-number">
                      <span>5</span>
                      <span class="star">★</span>
                    </div>
                    <div class="flex allign-middle rating-bar">
                      <div class="progress-bar" style="width:50%"></div>
                    </div>
                    <div class="flex allign-middle rating-count">
                      412
                    </div>
                  </div>
                  <div class="flex allign-middle rating-brkup rat-4">
                    <div class="flex allign-middle rating-number">
                      <span>4</span>
                      <span class="star">★</span>
                    </div>
                    <div class="flex allign-middle rating-bar">
                      <div class="progress-bar" style="width:50%"></div>
                    </div>
                    <div class="flex allign-middle rating-count">
                      41
                    </div>
                  </div>
                  <div class="flex allign-middle rating-brkup rat-3">
                    <div class="flex allign-middle rating-number">
                      <span>3</span>
                      <span class="star">★</span>
                    </div>
                    <div class="flex allign-middle rating-bar">
                      <div class="progress-bar" style="width:50%"></div>
                    </div>
                    <div class="flex allign-middle rating-count">
                      126
                    </div>
                  </div>
                  <div class="flex allign-middle rating-brkup rat-2">
                    <div class="flex allign-middle rating-number">
                      <span>2</span>
                      <span class="star">★</span>
                    </div>
                    <div class="flex allign-middle rating-bar">
                      <div class="progress-bar" style="width:50%"></div>
                    </div>
                    <div class="flex allign-middle rating-count">
                      6
                    </div>
                  </div>
                  <div class="flex allign-middle rating-brkup rat-1">
                    <div class="flex allign-middle rating-number">
                      <span>1</span>
                      <span class="star">★</span>
                    </div>
                    <div class="flex allign-middle rating-bar">
                      <div class="progress-bar" style="width:50%"></div>
                    </div>
                    <div class="flex allign-middle rating-count">
                      4126
                    </div>
                  </div>
                </div>
            </div>
            <div class="flex column user-reviews-container">
                <div class="flex flex-column single-review rating-5">
                    <div class="flex single-review-details">
                        <div class="flex allign-middle single-r-r">
                            <span>5</span>
                            <span class="star">★</span>
                        </div>
                        <div class="flex allign-middle single-review-user">
                            <div class="name">Debapriyo Maity</div>
                            <div class="date">13.05.2022</div>
                        </div>
                    </div>
                    <div class="flex flex-column single-r-comments">
                        <div class="text">
                          It’s pretty! It doesn’t have any buttons so you need to make sure the knots are tied properly and
                          tight. I thought the colours would be more poppy and saturated. Was a little disappointed with
                          that. Overall it’s a cute top. Will go well on brown / beige pants 🤎
                        </div>
                    </div> 
                    <div class="flex review-photo">
                      <a (click)="openReviewPopup()">
                          <img src="../assets/images/pz4.jpg">
                      </a>
                      <a (click)="openReviewPopup()">
                          <img src="../assets/images/pz1.jpg">
                      </a>
                      <a (click)="openReviewPopup()">
                          <img src="../assets/images/pz3.jpg">
                      </a>
                  </div>
                </div>
                <div class="flex flex-column single-review rating-3">
                    <div class="flex single-review-details">
                        <div class="flex allign-middle single-r-r">
                            <span>3</span>
                            <span class="star">★</span>
                        </div>
                        <div class="flex allign-middle single-review-user">
                            <div class="name">Debapriyo Maity</div>
                            <div class="date">13.05.2022</div>
                        </div>
                    </div>
                    <div class="flex flex-column single-r-comments">
                        <div class="text">
                          It’s pretty! It doesn’t have any buttons so you need to make sure the knots are tied properly and
                          tight. I thought the colours would be more poppy and saturated. Was a little disappointed with
                          that. Overall it’s a cute top. Will go well on brown / beige pants 🤎
                        </div>
                    </div> 
                    <div class="flex review-photo">
                      <a (click)="openReviewPopup()">
                          <img src="../assets/images/pz4.jpg">
                      </a>
                      <a (click)="openReviewPopup()">
                          <img src="../assets/images/pz1.jpg">
                      </a>
                      <a (click)="openReviewPopup()">
                          <img src="../assets/images/pz3.jpg">
                      </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>