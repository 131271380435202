<div class="flex flex-column left side-menu">
  <div class="flex flex-column position-center user-details">
    <div class="flex position-center user-img">{{get_first_namelatter()}}</div>
    <div class="user-welcome">Hello, {{get_first_namelatter()}}</div>
    <div class="user-email-mobile">{{(webModule.customer!=undefined) ? webModule.customer.email : ''}}</div>
  </div>
  <a class="flex allign-middle profile-item-link asm-profile" routerLink="/account/profile">
    <svg viewBox="0 0 512 512">
      <path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55
                      35-96 88-96s92 42 88 96z" fill="none" stroke="currentColor" stroke-linecap="round"
        stroke-linejoin="round" stroke-width="32" />
      <path
        d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
        fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
    </svg>
    <span>My Profile</span>
  </a>
  <a class="flex allign-middle profile-item-link asm-orders active1" routerLink="/account/orders">
    <svg viewBox="0 0 512 512">
      <path d="M80 176a16 16 0 00-16 16v216c0 30.24 25.76 56
                      56 56h272c30.24 0 56-24.51 56-54.75V192a16 16 0
                      00-16-16zM160 176v-32a96 96 0 0196-96h0a96 96 0 0196 96v32" fill="none" stroke="currentColor"
        stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
      <path d="M160 224v16a96 96 0 0096 96h0a96 96 0 0096-96v-16" fill="none" stroke="currentColor"
        stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
    </svg>
    <span>My Orders</span>
  </a>
  <a class="flex allign-middle profile-item-link asm-wishlist" routerLink="/account/wishlist">
    <svg viewBox="0 0 512 512">
      <path d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54
                      44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16
                      16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z" fill="none"
        stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
    </svg>
    <span>My Wishlist</span>
  </a>
  <a class="flex allign-middle profile-item-link asm-address" routerLink="/account/addresses">
    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
      <title>Location</title>
      <path
        d="M256 48c-79.5 0-144 61.39-144 137 0 87 96 224.87 131.25 272.49a15.77 15.77 0 0025.5 0C304 409.89 400 272.07 400 185c0-75.61-64.5-137-144-137z"
        fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
      <circle cx="256" cy="192" r="48" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
        stroke-width="32" />
    </svg>
    <span>My Addresses</span>
  </a>
  <div class="flex flex-column customer-care">
    <a class="flex allign-middle profile-item-link asm-contactus ">
      <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
        <title>Headset</title>
        <path d="M83 384c-13-33-35-93.37-35-128C48 141.12 149.33 48 256 48s208 93.12 208 208c0 34.63-23 97-35 128"
          fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
        <path
          d="M108.39 270.13l-13.69 8h0c-30.23 17.7-31.7 72.41-3.38 122.2s75.87 75.81 106.1 58.12h0l13.69-8a16.16 16.16 0 005.78-21.87L130 276a15.74 15.74 0 00-21.61-5.87zM403.61 270.13l13.69 8h0c30.23 17.69 31.74 72.4 3.38 122.19s-75.87 75.81-106.1 58.12h0l-13.69-8a16.16 16.16 0 01-5.78-21.87L382 276a15.74 15.74 0 0121.61-5.87z"
          fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
      </svg>
      <span>Contact Us</span>
    </a>
    <a class="flex allign-middle profile-item-link" (click)="logout()">
      <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
        <title>Log Out</title>
        <path
          d="M304 336v40a40 40 0 01-40 40H104a40 40 0 01-40-40V136a40 40 0 0140-40h152c22.09 0 48 17.91 48 40v40M368 336l80-80-80-80M176 256h256"
          fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
      </svg>
      <span>Logout</span>
    </a>
  </div>
</div>
