import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialAuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-lsv-password-reset',
  templateUrl: './lsv-password-reset.component.html',
  styleUrls: ['./lsv-password-reset.component.scss']
})
export class LsvPasswordResetComponent implements OnInit {

  constructor(
    public globals: GlobalsService,
    public toastr: ToastrService,
    public auth: AuthenticationService,
    public modalService: NgbModal,
    private authService: SocialAuthService,
  ) { }

  ngOnInit(): void {

  }

  async ngAfterViewInit() {
    console.log(this.auth);
    /**
     * close the search bar if it's open. set it to falses
     */
    this.globals.set_suggestion_bar_state(false);
  }

}
