import { PlatformLocation } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  email = "";

  constructor(
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private platformLocation: PlatformLocation,
    private offcanvasService: NgbOffcanvas,
    private modalService: NgbModal,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
  }

  subscribe(): any {
    let postData = {} as any;
    postData.action = 'newslatter/subscription';
    postData.email = this.email;

    if (this.email.length == 0) {
      alert('Please enter an email');
      return false;
    }

    const result = this.httpClient.post<any>(this.globals.get_endpoint() + '/newslatter/subscription', postData).subscribe((response) => {
      if (response.msg == 'success') {
        this.email = '';
        alert('You have subscribed to our newslatter!');
      } else {
        alert(response.data);
      }
    });

  }

  onKeyDown(event: any) {
    console.log(event.keyCode);

    if (event.keyCode == 13) {
      this.subscribe();
    }

  }

}
