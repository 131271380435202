<div class="search-box">
  <div class="flex column container">
    <div class="flex allign-middle top">
      <div class="flex position-center search-title hide">Search</div>
      <a class="flex position-center search-close" title="Close" (click)="close_menu()"></a>
    </div>

    <div class="flex position-center middle">
      <!-- Add class " active " when user type something in the input field -->
      <div class="search-button-group position-relative">
        <input type="text" class="search-field" placeholder="Search products…" required pattern="\S+.*"
          (keyup)="on_text_change($event)" (click)="on_text_change($event)" [(ngModel)]="search_text">
        <a class="search-clear remove" title="Clear"></a>
        <span class="search-icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round" class="feather feather-search">
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </span>
      </div>
    </div>

    <div class="flex bottom">

      <div class="flex flex-column suggestion-left">
        <div class="flex allign-middle search-suggestion-heading">
          <span>Popular Searches</span>
        </div>
        <div class="flex flex-column popular-search">
          <!-- <app-section-loader> *ngIf="loading"</app-section-loader> -->
          <ng-container *ngIf="search_results.categories!=undefined && search_results.categories.length>0">
            <ul class="flex flex-column">
              <li class="flex" *ngFor="let item of search_results.categories; let indx=index;">
                <a class="flex allign-middle " routerLink="/search">
                  <svg viewBox="0 0 512 512">
                    <path d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z" fill="none"
                      stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
                    <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10"
                      stroke-width="32" d="M338.29 338.29L448 448" />
                  </svg>
                  <span>{{item.category_name}}</span>
                </a>
              </li>
            </ul>
          </ng-container>

          <ng-container *ngIf="search_results.categories!=undefined && search_results.categories.length==0">
            No Item found!
          </ng-container>
        </div>
      </div>

      <div class="flex flex-column suggestion-right">
        <div class="heading">Products</div>
        <div class="flex flex-wrap top-products">
          <!-- <app-section-loader> *ngIf="loading"</app-section-loader> -->
          <ng-container *ngIf="search_results.products.length>0">
            <a class="flex search-product" *ngFor="let item of search_results.products; let indx=index;"
              routerLink="{{this.globals.get_product_url(item.name_slug,item.indx)}}" [queryParams]="{vkey:item.vindx}">
              <div class="flex product-image">
                <img src="{{item.image[0].sm}}">
              </div>
              <div class="flex column product-content">
                <div class="flex allign-middle w-100 name">
                  <span>{{item.name}}</span>
                </div>
                <div class="flex allign-middle w-100 price">
                  <p class="new-price">Rs. <span>{{item.price_payable}}</span></p>
                  <p class="old-price">Rs. <span>{{item.price}}</span></p>
                  <p class="discount-price">(<span>{{item.discount}}%</span> OFF)</p>
                </div>
              </div>
            </a>
          </ng-container>

          <ng-container *ngIf="search_results.products!=undefined && search_results.products.length==0">
            No Item found!
          </ng-container>

        </div>

        <div class="flex position-center w-100 show-more-results">
          <a class="text" routerLink="/search" (click)="close_menu()">Show more results</a>
        </div>
      </div>

    </div>
  </div>
</div>
