import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { HttpClient } from '@angular/common/http';
import { WebModule } from 'src/app/modules/web/web.module';

@Component({
  selector: 'app-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss']
})
export class AccountProfileComponent implements OnInit {

  public webModule: WebModule = {} as any;
  customer_key = '';
  customer_name = '';
  customer_fname = '';
  customer = {
    indx: '',
    key: "",
    fname: "",
    mname: "",
    lname: "",
    name: "",
    email: "",
    mobile: "",
    uername: "",
    status: '',
    status_text: "",
    gender: '',
    gender_text: '',
    dob: '',
    verified: "",
    create_date: "",
    update_date: ""
  } as any;

  show_loader = true;
  edit_profile = false;
  errors = {} as any;

  constructor(
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public auth: AuthenticationService,
    public toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {

  }

  async ngAfterContentInit() {
    this.globals.page_loader_observer.subscribe(state => this.show_loader = state);
    this.globals.web_observer.subscribe(web => this.webModule = web);
    this.globals.set_page_loader(true);

    this.customer_key = await this.globals.get_customer_key();
    this.customer_fname = await this.globals.get_localstore('fname');

    await this.get_data();
  }

  async get_data() {
    const postData = new FormData();

    postData.append('action', 'account/personal/info');
    postData.append(this.globals.customer_key, this.customer_key);
    //account/customer/CUS20220215055524569/info
    console.log(this.customer_key, this.customer_fname);
    this.globals.set_page_loader(true);
    this.globals.set_account_active('asm-profile');

    const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${this.customer_key}/info`, postData).subscribe((response) => {
      //console.log(response);
      if (response.msg == 'success') {
        let data = response.data;
        this.customer = response.data;
        this.globals.set_page_loader(false);
      } else {
        this.globals.set_page_loader(false);
      }
    });

  }

  async save() {
    const postData = {
      action: 'account/personal/info/save',
      indx: this.customer.indx,
      key: this.customer.key,
      name: this.customer.name,
      email: this.customer.email,
      mobile: this.customer.mob,
      gender: this.customer.gender,
      dob: this.customer.dob,
    };// new FormData();

    this.globals.set_page_loader(true);

    const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${this.customer_key}/info/save`, postData).subscribe((response) => {
      //console.log(response);
      if (response.msg == 'success') {
        let data = response.data;
        this.customer = response.data;
        //this.input_disabled = !this.input_disabled;

        this.toastr.clear();
        this.toastr.success('Personal Info saved!', '', { positionClass: 'toast-bottom-center' });
        this.globals.set_page_loader(false);
      } else {
        this.globals.set_page_loader(false);
      }
    });
  }

  check_enter_pressed(name: string, event: any) {
    ////console.log([name, event]);
    if (event.keyCode == 13) {
      switch (name) {
        case 'open': {
          //this.open();
          break;
        }
        case 'signin': {
          //this.signin1();
          break;
        }
        case 'signup': {
          //this.register();
          break;
        }
        case "password1": {
          //this.submit_password();
          break;
        }
        case "password2": {
          //this.reset_password();
          break;
        }
        case 'verify-google-mob': {
          //his.verify_google_mob();
          break;
        }
      }
    }

    return true;
  }

  remove_error(name: string) {
    let err = this.errors;
    delete err[name];

    var preg = /^[0-9 ]+$/;
    var regx = /[^0-9]+/g;

    if (name == 'mobile') {
      if (this.customer.mobile.length > 0 && !preg.test(this.customer.mobile)) {
        err.mobile = 'Invalid contact number';
        let mobile = this.customer.mobile;
        mobile = mobile.replace(regx, '');
        this.customer.mobile = mobile;
        //console.log([mobile, this.customer]);
      }
    }

    this.errors = err;
  }


}
