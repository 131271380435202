<div class="flex flex-column user-registration google-mob1">
    <div class="flex position-center ls-heading">Verify your Mobile number</div>
    <div class="flex flex-column allign-middle ls-input">
      <!-- this is for email signup auth.customer.mobile.length==0-->
      <div class="single-input">
        <input class="floating-input" type="text" name="mobile" placeholder=" ">
        <label class="floating-label">Mobile Number*</label>
        <span class="ls-warning error text-danger hide">sdsd</span>
      </div>

      <button class="flex position-center ls-submit">Verify Mobile</button>
    </div>
    <div class="flex flex-column position-center forget-pass">
      <a>Skip</a>
    </div>
  </div>
