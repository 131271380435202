<div class="flex flex-column position-center ">
  <app-section-loader *ngIf="auth.show_loader"></app-section-loader>

  <div class="flex allign-middle ls-heading">Verify OTP</div>
  <div class="flex column allign-middle ls-sub-heading">
    <span>A text message with a 6-digit verification code was just sent to</span>
    <span class="mobile">+91-{{auth.customer.mobile}}</span>
  </div>

  <div class="flex flex-column otp-input-box">
    <!-- https://github.com/code-farmz/ng-otp-input -->
    <ng-otp-input #ngOtpInput [config]="config" name="otp" (onInputChange)="onOtpChange($event)"
      (keyup.enter)="auth.submit_otp()"></ng-otp-input>
    <span class="ls-warning error text-danger" *ngIf="auth.errors.otp!==undefined"
      style="display: block !important;">{{auth.errors.otp}}</span>
    <button type="button" class="flex position-center ls-submit ls-otp-submit"
      (click)="auth.submit_otp()">Continue</button>
  </div>

  <div class="flex allign-middle otp-input-resend">
    <span class="not-recd">Not received your OTP?</span>
    <span class="wait hide">Resend in
      <span style="color:#2874f0">01:50</span>
    </span>
    <div class="flex allign-middle after-wait">
      <a (click)="auth.resend_otp()">Resend</a>
      <span class="sep">|</span>
      <a *ngIf="auth.show_change_number">Change number</a>
    </div>
  </div>
  <div class="flex allign-middle login-email" *ngIf="auth.show_login_with_email">
    <a (click)="auth.show('lsv-common')">Login with Email >></a>
  </div>

</div>
