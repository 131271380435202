<div class="flex column position-center coming-soon-page">
    <div class="logo">
        <svg viewBox="0 0 1908.000000 272.000000" preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,272.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
                <path d="M7816 2619 c-24 -52 -299 -627 -611 -1279 -312 -652 -569 -1193 -572
            -1202 -5 -17 13 -18 274 -18 l279 0 128 280 129 280 417 0 417 0 128 -280 129
            -280 284 0 c156 0 281 3 279 8 -2 4 -280 585 -617 1292 -337 707 -614 1287
            -616 1289 -2 2 -24 -38 -48 -90z m339 -1530 c6 -18 -2 -19 -294 -19 -166 0
            -301 2 -301 4 0 3 61 146 136 318 75 172 142 329 150 348 l15 35 144 -333 c79
            -184 147 -342 150 -353z" />
                <path d="M10232 1408 l3 -1283 240 0 240 0 3 758 2 758 818 -772 c449 -425
            838 -793 865 -818 l47 -45 0 1287 0 1287 -237 -2 -238 -3 -5 -756 -5 -756
            -860 813 c-473 448 -863 814 -868 814 -4 0 -6 -577 -5 -1282z" />
                <path d="M1090 2605 c-185 -27 -363 -91 -517 -187 -114 -70 -281 -234 -357
            -351 -98 -149 -172 -341 -195 -511 -16 -115 -14 -347 5 -457 55 -324 224 -604
            481 -798 80 -61 257 -151 364 -185 263 -84 630 -80 882 11 426 153 711 487
            808 946 29 134 31 388 5 522 -87 456 -374 794 -804 949 -181 65 -469 91 -672
            61z m455 -488 c285 -93 469 -337 505 -670 47 -437 -196 -811 -580 -892 -92
            -19 -250 -19 -348 1 -282 57 -514 302 -573 604 -16 84 -16 286 0 370 58 298
            288 543 566 604 112 25 327 16 430 -17z" />
                <path d="M14850 2609 c-427 -48 -792 -283 -979 -631 -104 -192 -141 -337 -148
            -584 -6 -208 8 -322 59 -484 140 -439 509 -754 978 -834 287 -49 574 -17 797
            89 340 161 566 456 653 854 21 96 47 369 37 395 -6 15 -60 16 -607 16 l-600 0
            0 -200 0 -200 350 0 c193 0 350 -2 350 -5 0 -2 -11 -34 -25 -71 -80 -213 -249
            -368 -462 -426 -105 -28 -316 -30 -418 -4 -226 58 -416 216 -516 429 -95 205
            -104 516 -20 732 77 198 250 367 447 436 238 83 515 59 698 -60 71 -46 164
            -137 208 -202 16 -24 34 -43 40 -41 9 2 340 194 395 228 l23 14 -37 53 c-63
            89 -222 240 -317 302 -255 166 -566 232 -906 194z" />
                <path d="M3840 1345 l0 -1225 250 0 250 0 2 443 3 442 109 0 109 0 285 -442
            284 -443 295 0 294 0 -60 87 c-33 49 -181 261 -329 473 l-268 384 95 48 c210
            103 334 254 391 473 28 104 30 287 5 395 -73 319 -336 533 -710 579 -54 6
            -275 11 -547 11 l-458 0 0 -1225z m943 780 c116 -30 200 -95 247 -190 22 -44
            25 -63 25 -160 0 -97 -3 -116 -25 -160 -48 -98 -146 -168 -268 -194 -29 -6
            -136 -11 -237 -11 l-185 0 0 365 0 365 193 0 c139 0 209 -4 250 -15z" />
                <path d="M17520 1345 l0 -1225 780 0 780 0 0 210 0 210 -535 0 -535 0 0 335 0
            335 500 0 500 0 0 210 0 210 -500 0 -500 0 0 260 0 260 535 0 535 0 0 210 0
            210 -780 0 -780 0 0 -1225z" />
            </g>
        </svg>
    </div>
    <div class="heading">We Will be Back <span>Soon!</span></div>
    <div class="text">We are using this time to give our website a revamp! Stay tuned.</div>
    <div class="flex position-center side-menu-social">
        <a href="https://www.instagram.com/orangefashionofficial">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" fill="currentColor" viewBox="0 0 512 512">
            <title>Logo Instagram</title>
            <path
              d="M349.33 69.33a93.62 93.62 0 0193.34 93.34v186.66a93.62 93.62 0 01-93.34 93.34H162.67a93.62 93.62 0 01-93.34-93.34V162.67a93.62 93.62 0 0193.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z" />
            <path
              d="M377.33 162.67a28 28 0 1128-28 27.94 27.94 0 01-28 28zM256 181.33A74.67 74.67 0 11181.33 256 74.75 74.75 0 01256 181.33m0-37.33a112 112 0 10112 112 112 112 0 00-112-112z" />
          </svg>
        </a>
        <a href="https://www.facebook.com/orangefashionofficial">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" fill="currentColor" viewBox="0 0 512 512">
            <title>Logo Facebook</title>
            <path
              d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z"
              fill-rule="evenodd" />
          </svg>
        </a>
        <a href="https://www.youtube.com/channel/UCrPQQV1ok37QP3f_4WNKb0w">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="ionicon" viewBox="0 0 512 512">
            <title>Logo Youtube</title>
            <path
              d="M508.64 148.79c0-45-33.1-81.2-74-81.2C379.24 65 322.74 64 265 64h-18c-57.6 0-114.2 1-169.6 3.6C36.6 67.6 3.5 104 3.5 149 1 184.59-.06 220.19 0 255.79q-.15 53.4 3.4 106.9c0 45 33.1 81.5 73.9 81.5 58.2 2.7 117.9 3.9 178.6 3.8q91.2.3 178.6-3.8c40.9 0 74-36.5 74-81.5 2.4-35.7 3.5-71.3 3.4-107q.34-53.4-3.26-106.9zM207 353.89v-196.5l145 98.2z" />
          </svg>
        </a>
        <a href="https://www.twitter.com/ORANGEO16936891">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon"  fill="currentColor" viewBox="0 0 512 512">
            <title>Logo Twitter</title>
            <path
              d="M496 109.5a201.8 201.8 0 01-56.55 15.3 97.51 97.51 0 0043.33-53.6 197.74 197.74 0 01-62.56 23.5A99.14 99.14 0 00348.31 64c-54.42 0-98.46 43.4-98.46 96.9a93.21 93.21 0 002.54 22.1 280.7 280.7 0 01-203-101.3A95.69 95.69 0 0036 130.4c0 33.6 17.53 63.3 44 80.7A97.5 97.5 0 0135.22 199v1.2c0 47 34 86.1 79 95a100.76 100.76 0 01-25.94 3.4 94.38 94.38 0 01-18.51-1.8c12.51 38.5 48.92 66.5 92.05 67.3A199.59 199.59 0 0139.5 405.6a203 203 0 01-23.5-1.4A278.68 278.68 0 00166.74 448c181.36 0 280.44-147.7 280.44-275.8 0-4.2-.11-8.4-.31-12.5A198.48 198.48 0 00496 109.5z" />
          </svg>
        </a>
        <a href="https://www.pinterest.com/orangefashionofficial">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" fill="currentColor" viewBox="0 0 512 512">
            <title>Logo Pinterest</title>
            <path
              d="M256.05 32c-123.7 0-224 100.3-224 224 0 91.7 55.2 170.5 134.1 205.2-.6-15.6-.1-34.4 3.9-51.4 4.3-18.2 28.8-122.1 28.8-122.1s-7.2-14.3-7.2-35.4c0-33.2 19.2-58 43.2-58 20.4 0 30.2 15.3 30.2 33.6 0 20.5-13.1 51.1-19.8 79.5-5.6 23.8 11.9 43.1 35.4 43.1 42.4 0 71-54.5 71-119.1 0-49.1-33.1-85.8-93.2-85.8-67.9 0-110.3 50.7-110.3 107.3 0 19.5 5.8 33.3 14.8 43.9 4.1 4.9 4.7 6.9 3.2 12.5-1.1 4.1-3.5 14-4.6 18-1.5 5.7-6.1 7.7-11.2 5.6-31.3-12.8-45.9-47-45.9-85.6 0-63.6 53.7-139.9 160.1-139.9 85.5 0 141.8 61.9 141.8 128.3 0 87.9-48.9 153.5-120.9 153.5-24.2 0-46.9-13.1-54.7-27.9 0 0-13 51.6-15.8 61.6-4.7 17.3-14 34.5-22.5 48a225.13 225.13 0 0063.5 9.2c123.7 0 224-100.3 224-224S379.75 32 256.05 32z" />
          </svg>
        </a>
        <a class="hide">
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" fill="currentColor" viewBox="0 0 512 512">
            <title>Logo Linkedin</title>
            <path
              d="M444.17 32H70.28C49.85 32 32 46.7 32 66.89v374.72C32 461.91 49.85 480 70.28 480h373.78c20.54 0 35.94-18.21 35.94-38.39V66.89C480.12 46.7 464.6 32 444.17 32zm-273.3 373.43h-64.18V205.88h64.18zM141 175.54h-.46c-20.54 0-33.84-15.29-33.84-34.43 0-19.49 13.65-34.42 34.65-34.42s33.85 14.82 34.31 34.42c-.01 19.14-13.31 34.43-34.66 34.43zm264.43 229.89h-64.18V296.32c0-26.14-9.34-44-32.56-44-17.74 0-28.24 12-32.91 23.69-1.75 4.2-2.22 9.92-2.22 15.76v113.66h-64.18V205.88h64.18v27.77c9.34-13.3 23.93-32.44 57.88-32.44 42.13 0 74 27.77 74 87.64z" />
          </svg>
        </a>
      </div>
</div>