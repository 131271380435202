import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal, NgbModalConfig, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { GlobalsService } from 'src/app/services/globals.service';
import { BehaviorSubject, combineLatest } from 'rxjs';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class SearchResultComponent implements OnInit {

  toggle: boolean = true;
  toggle2: boolean = true;

  public shouldShow = false;
  search_results = [] as any;

  public filter: boolean = false;
  public quick_shop: boolean = false;

  private sort_by_behavior = new BehaviorSubject<string>('Popularity');
  private sort_by_observer = this.sort_by_behavior.asObservable();
  public sort_by_text = 'Relevance';

  minvalue: number = 0;
  value: number = 5000;

  params = {
    category: '',
    subcategory: '',
  } as any;

  //--search------
  rows = [] as any;
  results_per_page = 20;
  page = 0;
  pages = 0;
  count = 0;

  categories = [] as any;
  colors = [] as any;
  sizes = [] as any;
  discounts = [
    { key: 1, name: 'Under 10%', value: '0-10' },
    { key: 1, name: '10% - 20%', value: '10-20' },
    { key: 1, name: '20% - 30%', value: '20-30' },
    { key: 1, name: '30% - 40%', value: '30-40' },
    { key: 1, name: 'Above 40%', value: '40-100' },
  ] as any;
  breadcrumbs = [] as any;

  search_filters = {
    q: '',
    type: '',
    sort: 'new',
    categories: [],
    colors: [],
    sizes: [],
    price: [],
    discounts: [],
    styles: [],
    category: '',
    subcategory: '',
    //---------
    page: 0,
    pages: 0,
    count: 0,
  } as any;
  search_filter_count = 0;
  search_title = 'Most Popular Items';
  //--./search------

  cart = {
    pkey: '',
    vkey: '',
    color: '',
    size: '',
    qnty: '',
  } as any;
  cart_count = 0;

  listener: any;
  renderer2: any;

  element: any | undefined;
  height: number | undefined;
  httpCall = false;

  loading = false;

  constructor(
    private modalService: NgbModal,
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private offcanvasService: NgbOffcanvas,
  ) { }

  ngOnInit(): void {
    this.globals.header_search_results_observer.subscribe((results) => {
      this.search_results = results;
      //console.log([this.search_results, Object.entries(this.search_results).length]);
    });
  }

  async ngAfterViewInit() {
    console.log('ngAfterViewInit');

    this.search_results.show_results = false;
    this.globals.set_header_search_result(this.search_results);

    this.sort_by_observer.subscribe(text => this.sort_by_text = text);
    this.set_sortby('relevance');

    /*await this.activeRoute.params.subscribe((data) => {
      this.extract_params();
      this.params.category = (data['category'] != undefined) ? data['category'] : '';
      //this.params.subcategory = (params['subcategory'] != undefined) ? params['subcategory'] : '';
      console.log(data['category'], this.params.category);

      this.height = document.getElementById('infinite-scroll')?.offsetHeight;
      this.element = document.getElementById('infinite-scroll') as any;

      this.search_results.show_results = false;
      this.globals.set_suggestion_bar_state(this.search_results);

      //
      // close the search bar if it's open. set it to falses
      //
      //this.globals.set_suggestion_bar_state(false);
    });*/

    // Combine route parameters and query parameters
    const combinedParams = combineLatest([this.activeRoute.params, this.activeRoute.queryParams]);

    // Subscribe to changes in both route parameters and query parameters
    await combinedParams.subscribe(async ([params, queryParams]: [Params, Params]) => {
      // Handle changes to both route parameters and query parameters
      //console.log('Route Params:', params);
      //console.log('Query Params:', queryParams);

      // Call your function or perform actions based on combined changes
      this.params = params;

      await this.extract_params(params, queryParams);

      this.height = document.getElementById('infinite-scroll')?.offsetHeight;
      this.element = document.getElementById('infinite-scroll') as any;

      this.search_results.show_results = false;
      this.globals.set_suggestion_bar_state(this.search_results);

      /**
      * close the search bar if it's open. set it to falses
      */
      this.globals.set_suggestion_bar_state(false);
    });
  }

  async extract_params(params: any, queryParams: any) {
    this.search_filters.q = (params['q'] != undefined) ? params['q'] : '';
    this.search_filters.category = (params['category'] != undefined) ? params['category'] : '';
    this.search_filters.subcategory = (params['subcategory'] != undefined) ? params['subcategory'] : '';

    await this.activeRoute.params.subscribe((params) => {
      this.page = 0;
      this.rows = [];

      this.categories = [];
      this.colors = [];
      this.sizes = [];
      console.log('params change');
    });

    setTimeout(() => {
      this.get_data();
      console.log("extract_params", this.params);
    }, 1 * 1000);

    //console.log("extract_params", this.params);
  }

  async get_data(): Promise<any> {
    if (this.httpCall) {
      return false;
    }

    this.httpCall = true;
    this.loading = true;
    this.page = this.page + 1;

    let postData = new FormData();
    postData.append('action', 'search');
    postData.append('results_per_page', this.results_per_page.toString());
    postData.append('page', this.page.toString());

    postData.append('q', this.search_filters.q);

    if (await this.params.category != undefined)
      postData.append('category_slug', await this.params.category);

    if (await this.params.subcategory != undefined)
      postData.append('subcategory_slug', await this.params.subcategory);

    if (this.search_filters.discounts.length > 0) {
      for (let cc = 0; cc < this.search_filters.discounts.length; cc++) {
        postData.append('discounts[]', this.search_filters.discounts[cc]);
      }
    }

    if (this.search_filters.categories.length > 0) {
      for (let cc = 0; cc < this.search_filters.categories.length; cc++) {
        postData.append('categories[]', this.search_filters.categories[cc]);
      }
    }

    if (this.search_filters.colors.length > 0) {
      for (let cc = 0; cc < this.search_filters.colors.length; cc++) {
        postData.append('colors[]', this.search_filters.colors[cc]);
      }
    }

    if (this.search_filters.sizes.length > 0) {
      for (let cc = 0; cc < this.search_filters.sizes.length; cc++) {
        postData.append('sizes[]', this.search_filters.sizes[cc]);
      }
    }

    console.log(this.search_filters);

    postData.append('type', this.search_filters.type);
    postData.append('sort', this.search_filters.sort);

    const result = this.httpClient.post<any>(this.globals.get_endpoint() + '/products/fetch', postData).subscribe((response) => {
      //console.log(response);
      if (response.msg == 'success') {
        if (this.rows.length == 0) {
          this.rows = (response.data.products);
          this.page = parseInt(response.data.pagination.page);
          console.log(this.page);
        } else {
          let rows = response.data.products;
          this.rows.concat(response.data.products);

          for (let kk = 0; kk < Object.entries(rows).length; kk++) {
            this.rows.push(rows[kk]);
          }
        }

        //this.rows = response.data.products;
        this.categories = response.data.categories;
        this.colors = response.data.colors;
        this.sizes = response.data.sizes;
        this.search_title = response.data.title;
        this.breadcrumbs = response.data.breadcrumbs;

        this.pages = response.data.pagination.pages;
        this.count = response.data.pagination.count;

        this.httpCall = false;
        console.log(this.search_filters);
        //this.config.currentPage = response.pagination.page;
        //this.config.totalItems = response.pagination.count;
        this.loading = false;
      }
    });
  }

  set_sortby(value: string) {
    switch (value) {
      case 'relevance': {
        this.sort_by_behavior.next('Relevance');
        this.search_filters.sort = 'new';
        break;
      }
      case 'popular': {
        this.sort_by_behavior.next('Popularity');
        this.search_filters.sort = value;
        break;
      }
      case 'l2h': {
        this.sort_by_behavior.next('Price: Low to High');
        this.search_filters.sort = value;
        break;
      }
      case 'h2l': {
        this.sort_by_behavior.next('Price: High to Low');
        this.search_filters.sort = value;
        break;
      }
      case 'new': {
        this.sort_by_behavior.next('What\'s New');
        this.search_filters.sort = value;
        break;
      }
      case 'discounts': {
        this.sort_by_behavior.next('Better Discount');
        this.search_filters.sort = value;
        break;
      }
      case 'rating': {
        this.sort_by_behavior.next('Customer Rating');
        this.search_filters.sort = value;
        break;
      }
      default: {
        this.sort_by_behavior.next('');
        this.search_filters.sort = value;
        break;
      }
    }

    this.get_data();
  }

  async onScroll() {
    if (this.page < this.pages) {
      await this.get_data();
    }

    console.log('scrolled!!');
  }

  change() {
    this.toggle = !this.toggle;
  }
  sortToggle() {
    this.toggle2 = !this.toggle2;
  }

  changeColor(name: string, event: any) {
    switch (name) {
      case 'category': {
        this.search_filters.categories = [];

        for (let kk = 0; kk < this.categories.length; kk++) {
          if (this.categories[kk].checked) {
            this.search_filters.categories.push(this.categories[kk].name);
          }
        }

        break;
      }
      case 'discount': {
        this.search_filters.discounts = [];

        for (let kk = 0; kk < this.discounts.length; kk++) {
          if (this.discounts[kk].checked) {
            this.search_filters.discounts.push(this.discounts[kk].value);
          }
        }
        console.log(this.search_filters);

        break;
      }
      case 'color': {
        this.search_filters.colors = [];

        for (let kk = 0; kk < this.colors.length; kk++) {
          if (this.colors[kk].checked) {
            this.search_filters.colors.push(this.colors[kk].name);
          }
        }

        break;
      }
      case 'size': {
        this.search_filters.sizes = [];

        for (let kk = 0; kk < this.sizes.length; kk++) {
          if (this.sizes[kk].checked) {
            this.search_filters.sizes.push(this.sizes[kk].name);
          }
        }

        break;
      }
    }

    //console.log(event);
    console.log(this.search_filters);
    this.rows = [] as any;
    this.page = 0;
    this.pages = 0;
    this.count = 0;
    this.get_data();
  }

  make_sizes_array(data: any) {
    if (data.options != undefined) {
      for (let kk = 0; kk < data.options.length; kk++) {
        if (data.options[kk].name == 'size') {
          //console.log(data.varients[kk].value);
          return (data.options[kk].value).slice(0, 5);
        }
      }
    }

    return [];
  }

  colors_count() {
    let count = 0;

    if (this.colors.length > 0) {
      for (let kk = 0; kk < this.colors.length; kk++) {
        if (this.colors[kk].name != null)
          count++;
      }
    }

    return count;
  }

  sizes_count() {
    let count = 0;

    if (this.sizes.length > 0) {
      for (let kk = 0; kk < this.sizes.length; kk++) {
        if (this.sizes[kk].name != null)
          count++;
      }
    }

    return count;
  }

  soldout_class(product: any) {
    let className = '00';

    if (product.is_variant == 'N') {
      if (product.count == 0)
        className = 'sold-out';
    }

    if (product.is_variant == 'Y') {
      if (product.variant != undefined && product.variant.qnty != undefined) {
        if (product.variant.qnty == 0)
          className = 'sold-out';
      }
    }


    return className;
  }

}
