<div class="flex  position-center track-order-page">
  <div class="tracker-box">

    <div class="item email-phone">
      <div class="flex allign-middle title">Email / Phone #</div>
      <input class="flex allign-middle" id="mobile" name="mobile" [(ngModel)]="username"
        [style.border]=" (errors.username != undefined) ? '1px solid red' :'' " (change)="remove_error('username')"
        (keyup)="remove_error('username');check_enter_pressed($event);" placeholder="" value=""
        placeholder="abc@somesite.com">
      <div class="flex allign-middle info" *ngIf="errors.username!==undefined">{{errors.username}}</div>
    </div>

    <div class="item order-no">
      <div class="flex allign-middle title">Order #</div>
      <input class="flex allign-middle" id="order_key" name="order_key" [(ngModel)]="order_key"
        [style.border]=" (errors.order_key != undefined) ? '1px solid red' :'' " (change)="remove_error('order_key')"
        (keyup)="remove_error('order_key');check_enter_pressed($event);" placeholder="" value=""
        placeholder="ABC123XYZ">
      <div class="flex allign-middle info" *ngIf="errors.order_key!==undefined">{{errors.order_key}}</div>
    </div>

    <button class="flex position-center w-100 track-submit" type="button" (click)="get_data()">FETCH DETAILS</button>
  </div>
</div>