import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-other-shippping-policy',
  templateUrl: './other-shippping-policy.component.html',
  styleUrls: ['./other-shippping-policy.component.scss']
})
export class OtherShipppingPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
