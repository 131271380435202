import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { HttpClient } from '@angular/common/http';
import { Observable, combineLatest } from 'rxjs/index';
import { WebModule } from 'src/app/modules/web/web.module';

@Component({
  selector: 'app-track-order',
  templateUrl: './track-order.component.html',
  styleUrls: ['./track-order.component.scss']
})
export class TrackOrderComponent implements OnInit {

  customer_key = '';
  username = '';
  order_key = '';
  public webModule: WebModule = {} as any;
  show_loader = true;
  errors = {} as any;

  constructor(
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public auth: AuthenticationService,
    public toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    this.globals.page_loader_observer.subscribe(state => this.show_loader = state);
    this.globals.web_observer.subscribe(web => this.webModule = web);
    //this.globals.set_page_loader(false);

    this.customer_key = await this.globals.get_customer_key();
    //await this.get_data();

    /**
    * close the search bar if it's open. set it to falses
    */
    this.globals.set_suggestion_bar_state(false);
  }

  validate() {
    this.errors = {} as any;
    const preg_email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var preg = /^[0-9 ]+$/;
    let isNum = false;

    if (this.username.length > 0) {
      //this.errors.username = "Please enter a valid mobile no or email";
      if ((this.username.length > 0) && (preg.test(this.username))) {
        isNum = true;
        //Please enter a valid 10-digit mobile number
      }
      ////console.log(isNum);

      if (isNum) { // validate mobile
        if ((this.username.length > 0) && (this.username.length < 10)) {
          this.errors.username = "Please enter a valid 10-digit mobile number";
        }

        if (this.username.length > 10) {
          this.errors.username = "Please enter a valid 10-digit mobile number";
        }
      }

      if (!isNum) {
        if ((this.username.length > 0) && !preg_email.test(this.username)) {
          this.errors.username = "Please enter a valid email address!";
        }
      }
    }

    if (this.order_key.length == 0) {
      this.errors.order_key = "Please provide the order number of the order to be tracked.";
    }



    //console.log(this.errors);
    return (Object.entries(this.errors).length == 0) ? true : false;
  }

  async get_data(): Promise<any> {
    const postData = {
      action: 'track-orders/search',
      username: this.username,
      order_key: this.order_key,
      customer_key: this.customer_key,
    };

    if (!this.validate())
      return false;

    this.globals.set_page_loader(true);

    const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/track-orders/search`, postData).subscribe((response) => {
      //console.log(response);
      if (response.msg == 'success') {
        let data = response.data;

        this.router.navigate([`/account/order/details/${data.products[0].key}`], {
          queryParams: {
            okey: data.key,
          }
        });

        this.globals.set_page_loader(false);
        //console.log(Object.entries(this.product).length);
      } else {
        this.globals.set_page_loader(false);
      }
    });
  }

  check_enter_pressed(event: any) {
    //console.log([name, event]);
    if (event.keyCode == 13) {

    }

    return true;
  }

  remove_error(name: string) {
    let err = this.errors;
    delete err[name];

    var preg = /^[0-9 ]+$/;
    var regx = /[^0-9]+/g;



    this.errors = err;
  }

}
