<div class="flex flex-column ls-password password1">
  <div class="flex allign-middle ls-heading">Welcome Back, {{auth.customer.fname}}</div>
  <div class="flex allign-middle ls-sub-heading">{{auth.customer.username}}</div>

  <app-section-loader *ngIf="auth.show_loader"></app-section-loader>

  <div class="flex flex-column allign-middle ls-input">
    <input type="password" autocomplete="password" placeholder="Enter password" name="password"
      class="pincode-floating-input" id="password" value="" [(ngModel)]="auth.customer.password"
      [style.border]=" (auth.errors.password != undefined) ? '1px solid red' :'' "
      (change)="auth.remove_error('password')"
      (keyup)="auth.remove_error('password');auth.check_enter_pressed('submit_password', $event)">
    <span class="ls-warning hide">cz</span>

    <button class="flex position-center ls-submit" (click)="auth.submit_password()">Continue</button>
  </div>
  <div class="flex flex-column forget-pass " *ngIf="auth.customer.email">
    <p>
      Didn't received varification mail? Click
      <a style="color: rgb(49, 130, 236);" (click)="auth.resend_verification_email()">HERE</a> to resend
    </p>
  </div>
  <div class="flex flex-column forget-pass">
    <a (click)="auth.show('lsv-common')">Change email</a>
    <a (click)="auth.send_forgot_password_link()">Forgot password</a>
    <a (click)="auth.customer.username='';auth.show('lsv-common');">Login with Mobile</a>
  </div>
</div>
