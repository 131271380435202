<div class="flex account-page">
  <div class="flex container">
    <!-- account-side-menu -->
    <app-account-sidemenu class="flex account-panel"></app-account-sidemenu>
    <!-- ./account-side-menu -->

    <div class="flex position-center right profile-details" style="position: relative">
      <app-section-loader class="hide"></app-section-loader>

      <div class="flex flex-column profile-overview profile-card" *ngIf="!edit_profile">
        <div class="w-100 head-profile">Profile Details</div>
        <table class="profile-infoTable" *ngIf="webModule.customer!=undefined">
          <tbody>
            <tr>
              <td>Full Name</td>
              <td>{{webModule.customer.name}}</td>
            </tr>
            <tr>
              <td>Mobile Number</td>
              <td>{{webModule.customer.mobile}}</td>
            </tr>
            <tr>
              <td>Email ID</td>
              <td>{{webModule.customer.email}}</td>
            </tr>
            <!--<tr>
              <td>Gender</td>
              <td>{{webModule.customer.gender_text}}</td>
            </tr>-->
            <tr>
              <td>Date of Birth</td>
              <td>{{webModule.customer.dob|date :'dd/MM/yyyy' }}</td>
            </tr>
            <tr *ngIf="0">
              <td>Location</td>
              <td>- not added -</td>
            </tr>
          </tbody>
        </table>
        <button class="flex position-center edit-button" (click)="edit_profile=true;">EDIT DETAILS</button>
      </div>

      <div class="flex flex-column profile-overview edit profile-card " *ngIf="edit_profile">
        <div class="w-100 head-profile">Edit Profile Details</div>
        <div class="flex w-100 flex-column entry-box">
          <div class="flex w-100 single-input">
            <input class="floating-input" type="text" id="mobile" name="mobile" [(ngModel)]="customer.mobile"
              [style.border]=" (errors.mobile != undefined) ? '1px solid red' :'' " (change)="remove_error('mobile')"
              (keyup)="remove_error('mobile');check_enter_pressed('mobile', $event);" placeholder="" value="">
            <label class="floating-label">Mobile Number</label>
          </div>
          <div class="flex w-100 single-input">
            <input class="floating-input" type="text" id="name" name="name" [(ngModel)]="customer.name"
              [style.border]=" (errors.name != undefined) ? '1px solid red' :'' " (change)="remove_error('name')"
              (keyup)="remove_error('name');check_enter_pressed('name', $event);" value="" placeholder=" ">
            <label class="floating-label">Full Name</label>
          </div>
          <div class="single-input">
            <select class="floating-select" id="gender" name="gender" [(ngModel)]="customer.gender"
              [style.border]=" (errors.gender != undefined) ? '1px solid red' :'' " (change)="remove_error('gender')"
              (keyup)="remove_error('gender');check_enter_pressed('gender', $event);"
              onclick="this.setAttribute('value', this.value);" onchange="this.setAttribute('value', this.value);"
              value="">
              <option value=""></option>
              <option value="M">Male</option>
              <option value="F">Female</option>
              <option value="O">Others</option>
            </select>
            <label class="floating-label">Gender</label>
          </div>
          <div class="flex w-100 single-input">
            <input class="floating-input" type="text" id="dob" name="dob" [(ngModel)]="customer.dob"
              [style.border]=" (errors.dob != undefined) ? '1px solid red' :'' " (change)="remove_error('dob')"
              (keyup)="remove_error('dob');check_enter_pressed('dob', $event);" value="" placeholder=" ">
            <label class="floating-label">Birthday (dd/mm/yyyy)</label>
          </div>
          <div class="flex w-100 single-input" *ngIf="0">
            <input class="floating-input" type="text" value="" placeholder=" ">
            <label class="floating-label">Location</label>
          </div>
        </div>
        <button class="flex position-center edit-button" (click)="save()">SAVE DETAILS</button>
        <button class="flex position-center edit-button" (click)="edit_profile=false;">CANCEL</button>
      </div>
    </div>


  </div>
</div>
