import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { SwiperModule } from 'swiper/angular';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { SearchResultComponent } from './pages/search-result/search-result.component';
import { ProductComponent } from './pages/product/product.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderSearchComponent } from './components/header-search/header-search.component';
import { LayoutHomeComponent } from './layouts/layout-home/layout-home.component';
import { LayoutInnerComponent } from './layouts/layout-inner/layout-inner.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './components/header/header.component';
import { CartComponent } from './pages/cart/cart.component';
import { LsvComponent } from './components/lsv/lsv.component';
import { LsvOtpComponent } from './components/lsv-otp/lsv-otp.component';
import { LsvCommonComponent } from './components/lsv-common/lsv-common.component';
import { LsvLoginComponent } from './components/lsv-login/lsv-login.component';
import { LsvSignupComponent } from './components/lsv-signup/lsv-signup.component';
import { LsvSocialVerifyComponent } from './components/lsv-social-verify/lsv-social-verify.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { LsvPasswordResetComponent } from './components/lsv-password-reset/lsv-password-reset.component';
import { LsvActionComponent } from './components/lsv-action/lsv-action.component';
import { AccountProfileComponent } from './pages/account-profile/account-profile.component';
import { SectionLoaderComponent } from './components/section-loader/section-loader.component';
import { AccountOrdersComponent } from './pages/account-orders/account-orders.component';
import { AccountSidemenuComponent } from './components/account-sidemenu/account-sidemenu.component';
import { SizeChartComponent } from './components/size-chart/size-chart.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { TrackOrderComponent } from './pages/track-order/track-order.component';
import { LayoutOtherComponent } from './layouts/layout-other/layout-other.component';
import { HeaderOtherComponent } from './components/header-other/header-other.component';
import { ReturnExchangeComponent } from './pages/return-exchange/return-exchange.component';
import { OtherPrivacyPolicyComponent } from './pages/other-privacy-policy/other-privacy-policy.component';
import { OtherCookiePolicyComponent } from './pages/other-cookie-policy/other-cookie-policy.component';
import { OtherReturnPolicyComponent } from './pages/other-return-policy/other-return-policy.component';
//import { OtherRefundPolicyComponent } from './pages/other-refund-policy/other-refund-policy.component';
//import { OtherCancellationPolicyComponent } from './pages/other-cancellation-policy/other-cancellation-policy.component';
import { OtherShipppingPolicyComponent } from './pages/other-shippping-policy/other-shippping-policy.component';
import { OtherTermsComponent } from './pages/other-terms/other-terms.component';
import { OtherFaqComponent } from './pages/other-faq/other-faq.component';
import { MenuTopComponent } from './components/menu-top/menu-top.component';
import { MenuSideComponent } from './components/menu-side/menu-side.component';
import { ReviewPopupComponent } from './components/review-popup/review-popup.component';
import { ReviewAllComponent } from './pages/review-all/review-all.component';
import { ReviewSingleComponent } from './pages/review-single/review-single.component';
import { ReviewCreateComponent } from './components/review-create/review-create.component';
import { HeaderAnnouncementComponent } from './components/header-announcement/header-announcement.component';
import { ToastrModule, ToastContainerModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxCaptchaModule } from 'ngx-captcha';

//-------------
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { CookieService } from 'ngx-cookie-service';
import { AccountOrderDetailsComponent } from './pages/account-order-details/account-order-details.component';
import { AccountWishlistComponent } from './pages/account-wishlist/account-wishlist.component';
import { AccountAddressesComponent } from './pages/account-addresses/account-addresses.component';
import { AddEditAddressComponent } from './components/add-edit-address/add-edit-address.component';
import { CheckoutAddressComponent } from './pages/checkout-address/checkout-address.component';
import { CheckoutSummaryComponent } from './pages/checkout-summary/checkout-summary.component';
import { WholesaleComponent } from './pages/wholesale/wholesale.component';
import { RouterModule } from '@angular/router';
import { LsvGoogleMobComponent } from './components/lsv-google-mob/lsv-google-mob.component';
import { OtherAboutusComponent } from './pages/other-aboutus/other-aboutus.component';
import { CheckoutStatusComponent } from './pages/checkout-status/checkout-status.component';
import { OrderPaymentPolicyComponent } from './pages/order-payment-policy/order-payment-policy.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { AccountOrderCancelComponent } from './pages/account-order-cancel/account-order-cancel.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SearchResultComponent,
    ProductComponent,
    GalleryComponent,
    FooterComponent,
    HeaderSearchComponent,
    LayoutHomeComponent,
    LayoutInnerComponent,
    HeaderComponent,
    CartComponent,
    LsvComponent,
    LsvOtpComponent,
    LsvCommonComponent,
    LsvLoginComponent,
    LsvSignupComponent,
    LsvSocialVerifyComponent,
    LsvPasswordResetComponent,
    LsvActionComponent,
    AccountProfileComponent,
    SectionLoaderComponent,
    AccountOrdersComponent,
    AccountSidemenuComponent,
    SizeChartComponent,
    ContactUsComponent,
    TrackOrderComponent,
    LayoutOtherComponent,
    HeaderOtherComponent,
    ReturnExchangeComponent,
    OtherPrivacyPolicyComponent,
    OtherCookiePolicyComponent,
    OtherReturnPolicyComponent,
    //OtherRefundPolicyComponent,
    //OtherCancellationPolicyComponent,
    OtherShipppingPolicyComponent,
    OtherTermsComponent,
    OtherFaqComponent,
    MenuTopComponent,
    MenuSideComponent,
    ReviewPopupComponent,
    ReviewAllComponent,
    ReviewSingleComponent,
    ReviewCreateComponent,
    HeaderAnnouncementComponent,
    AccountOrderDetailsComponent,
    AccountWishlistComponent,
    AccountAddressesComponent,
    AddEditAddressComponent,
    CheckoutAddressComponent,
    CheckoutSummaryComponent,
    WholesaleComponent,
    LsvGoogleMobComponent,
    OtherAboutusComponent,
    CheckoutStatusComponent,
    OrderPaymentPolicyComponent,
    ComingSoonComponent,
    AccountOrderCancelComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SwiperModule,
    NgbModule,
    NgOtpInputModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    ToastContainerModule,
    SocialLoginModule,
    BrowserAnimationsModule,
    InfiniteScrollModule,
    NgxCaptchaModule,
    RouterModule,
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '144237304921-48lt490ajito94cpgog8hpipnf54555g.apps.googleusercontent.com'//webgyanee
            )
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    CookieService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
