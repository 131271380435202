import { Component, ViewEncapsulation, ViewChild, OnInit,ElementRef, PLATFORM_ID, Inject } from '@angular/core';
import { SwiperComponent } from "swiper/angular";
// import Swiper core and required components
import SwiperCore, { SwiperOptions, Navigation, Pagination, Lazy, Autoplay, Thumbs } from 'swiper';
SwiperCore.use([Navigation, Pagination, Lazy, Autoplay, Thumbs]);


@Component({
  selector: 'app-review-popup',
  templateUrl: './review-popup.component.html',
  styleUrls: ['./review-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReviewPopupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
