<div class="flex position-center gallery-container">
  <swiper [navigation]="true" [zoom]="true" [pagination]="true" [loop]="true" [lazy]="true" [autoplay]="false"
    class="flex position-center">

    <ng-container>
      <ng-template *ngFor="let image of params.images" swiperSlide>
        <div class="backdrop" (click)="closeGallery()"></div>

        <div class="swiper-zoom-container">
          <img class="" src="{{image.lg}}" alt="{{image.lg}}">
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="false">
      <ng-template swiperSlide>
        <div class="backdrop" (click)="closeGallery()"></div>
        <div class="swiper-zoom-container">
          <img class="" src="../assets/images/ts2.jpg" alt="">
        </div>
      </ng-template>
    </ng-container>
  </swiper>

</div>
