<div class="flex summary-page">
  <div class="flex container">
    <div class="flex flex-column left adress-details">
      <div class="flex flex-column summery-sec">
        <div class="flex allign-middle sum-sec-head">BILLING ADDRESS</div>
        <div class="flex allign-middle sum-sec-head-sub">Select the address that matches your card or payment method
        </div>
        <div class="flex allign-middle summ-sec-deta">
          <label class="flex allign-middle custom-radio">
            <input type="radio" name="aa" class="discount-input" value="same" [(ngModel)]="billing_address_type"
              (change)="reset_billing_address()">Same as delivery address
            <div class="radio-indicator"></div>
          </label>
          <label class="flex allign-middle custom-radio">
            <input type="radio" name="aa" value="diff" class="discount-input" [(ngModel)]="billing_address_type"
              (change)="reset_billing_address()">Use a different billing address
            <div class="radio-indicator"></div>
          </label>
        </div>
        <!-- The below section will be displayed when user wants to enter new billing address-->
        <div class="flex allign-middle summ-sec-deta-add " *ngIf="billing_address_type=='diff'">
          <textarea rows="4" placeholder="Enter a valid address.." class="bill-add"
            [(ngModel)]="address.billing_address"
            [style.border]=" (errors.billing_address != undefined) ? '1px solid red' :'' "
            (change)="remove_error('billing_address')" (keyup)="remove_error('billing_address');"></textarea>
        </div>
        <!-- The above section will be displayed when user wants to enter new billing address-->
      </div>
      <div class="flex flex-column summery-sec">
        <div class="flex allign-middle sum-sec-head">PAYMENT METHOD</div>
        <div class="flex allign-middle sum-sec-head-sub">All transactions are secure and encrypted</div>
        <div class="flex allign-middle summ-sec-deta">
          <label class="flex allign-middle custom-radio">
            <input type="radio" name="pay_type" value="COD" id="pay_1" [(ngModel)]="pay_type" class="discount-input" />
            Cash on delivery
            <div class="radio-indicator"></div>
          </label>

          <label class="flex allign-middle custom-radio">
            <input type="radio" name="pay_type" value="RZPY" id="pay_2" [(ngModel)]="pay_type" class="discount-input">
            <img class="" src="../assets/images/razorpay.png" alt="www.orangefashion.co">
            <div class="radio-indicator"></div>
          </label>
        </div>

        <div class="card_pay razorpay" *ngIf="pay_type=='RZPY'">
          <div class="card_pay_content">
            <p>After clicking “Complete order”, you will be redirected to Razorpay (Cards, UPI, NetBanking,
              Wallets) to complete your purchase securely.</p>
          </div>
        </div>
        <div class="card_pay paytm" *ngIf="pay_type=='PYTM'">
          <div class="card_pay_content ">
            <p>After clicking “Complete order”, you will be redirected to PayTM (Cards, UPI, NetBanking, Wallets)
              to complete your purchase securely.</p>
          </div>
        </div>
      </div>
      <div class="flex flex-column summery-sec shipping">
        <div class="flex allign-middle sum-sec-head">SHIPPING</div>
        <div class="flex allign-middle sum-sec-head-sub">Delivery will be done by our trusted delivery partner</div>
        <div class="flex flex-column allign-middle summ-sec-deta">
          <div class="flex allign-middle w-100 summ-sec-text">
            <span class="ltxt">Address</span>
            <span class="rtxt">{{show_address()}}</span>
            <a class="chng" routerLink="/checkout/address">Change</a>
          </div>
          <div class="flex allign-middle w-100 summ-sec-text">
            <span class="ltxt">Contact</span>
            <span class="rtxt">+91-{{address.mobile}}</span>
            <a class="chng" routerLink="/checkout/address">Change</a>
          </div>
          <div class="flex allign-middle w-100 summ-sec-text">
            <span class="ltxt">Method</span>
            <span class="rtxt">Free shipping</span>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-column right price-details">
      <div class="flex flex-column cart-price">
        <div class="flex allign-middle cart-price-heading">
          PRICE DETAILS (<span>3</span>&nbsp;Items)
        </div>
        <div class="flex justify-content-between price-breakup">
          <span>Total MRP</span>
          <span>₹{{cart.price}}</span>
        </div>
        <div class="flex justify-content-between price-breakup">
          <span>Discount on MRP</span>
          <span class="green">-₹{{cart.discount_amount}}</span>
        </div>
        <div class="flex justify-content-between price-breakup" *ngIf="cart.coupon!==undefined">
          <span>Coupon Discount</span>
          <span class="green">-₹{{cart.maximum_saving}}</span>
        </div>
        <div class="flex justify-content-between price-breakup" *ngIf="giftwrap">
          <span>Gift Wrap</span>
          <span class="green">₹25</span>
        </div>
        <div class="flex justify-content-between price-breakup">
          <span>Delivery Fee</span>
          <span><span style="text-decoration: line-through;">₹{{cart.shipping}}</span>
            <span class="green">FREE</span></span>
        </div>
      </div>
      <div class="flex allign-middle justify-content-between total-cart-amount">
        <span>Total Amount</span>
        <span>₹{{ price_payable() }}</span>
      </div>
      <div class="flex w-100 acction-btn">
        <button (click)="save()" class="flex position-center place-order">COMPLETE ORDER</button>
      </div>
    </div>

    <!-- hiden payment forms -->
    <div class="d-none">
      <form [action]="razorpay_url" method="post" name="razorpay" #razorpay id="razorpay">
        <input type="hidden" name="_token" value="tT3IedryhdSz4vuX4eVWwH690zLQaySDzTas3Hf8">
        <input name="customer_key" type="hidden" id="razorpay_customer_key" [(ngModel)]="customer_key" />
        <input name="order_key" value="" type="hidden" id="razorpay_order_key" [(ngModel)]="order_key" />
        <input name="amount" value="" type="hidden" id="razorpay_amount" [value]="(cart.total+cart.shipping)" />
        <input name="currency" type="hidden" id="razorpay_currency" value="INR">
        <input name="phone" value="" type="hidden" id="razorpay_phone" [(ngModel)]="address.phone" />
      </form>
    </div>
    <div class="d-none">
      <form action="{{payTm.ACTION}}" method="post" name="paytm" id="paytm">
        <input type="hidden" name="_token" value="tT3IedryhdSz4vuX4eVWwH690zLQaySDzTas3Hf8">
        <input id="ORDER_ID" tabindex="-1" maxlength="20" size="20" type="hidden" name="ORDER_ID" autocomplete="off"
          value="{{payTm.ORDER_ID}}">
        <input id="CUST_ID" tabindex="-2" maxlength="12" size="12" name="CUST_ID" autocomplete="off"
          value="{{payTm.CUST_ID}}" type="hidden">
        <input id="INDUSTRY_TYPE_ID" tabindex="-4" maxlength="12" size="12" name="INDUSTRY_TYPE_ID" autocomplete="off"
          value="{{payTm.INDUSTRY_TYPE_ID}}" type="hidden">
        <input id="CHANNEL_ID" tabindex="-4" maxlength="12" size="12" name="CHANNEL_ID" autocomplete="off"
          value="{{payTm.CHANNEL_ID}}" type="hidden">
        <input id="TXN_AMOUNT" tabindex="-10" type="hidden" name="TXN_AMOUNT" value="{{payTm.TXN_AMOUNT}}" title=""
          autocomplete="off">
      </form>
    </div>
    <!-- ./hiden payment forms -->


  </div>
</div>