import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Inject, Injectable, Output, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { WebModule } from '../modules/web/web.module';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CartComponent } from '../pages/cart/cart.component';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  /*private endpoint = 'https://admin.orangefashion.co/api/v1';
  private server_assets_url = 'https://admin.orangefashion.co';
  private server_payment_url = 'https://admin.orangefashion.co';
  private base_url = 'http://localhost:4200'; //window.location.origin;*/
  private endpoint = 'https://admin.orangefashion.thegems.co.in/api/v1';
  private server_assets_url = 'https://admin.orangefashion.thegems.co.in';
  private server_payment_url = 'https://admin.orangefashion.thegems.co.in';
  private base_url = 'http://localhost:4200'; //window.location.origin;
  public active = 1;
  public inactive = 2;
  public results_per_page = 10;

  private suggestion_bar_behavior = new BehaviorSubject<boolean>(false);
  public suggestion_bar_observer = this.suggestion_bar_behavior.asObservable();

  private page_loader_behavior = new BehaviorSubject<boolean>(false);
  public page_loader_observer = this.page_loader_behavior.asObservable();

  private header_search_results_behavior = new BehaviorSubject<any>([] as any);
  public header_search_results_observer = this.header_search_results_behavior.asObservable();

  private search_text_behavior = new BehaviorSubject<string>('');
  public search_text_observer = this.search_text_behavior.asObservable();

  private cart_count_behavior = new BehaviorSubject<any>(0);
  public cart_count_observer = this.cart_count_behavior.asObservable();

  private wishlist_count_behavior = new BehaviorSubject<any>(0);
  public wishlist_count_observer = this.wishlist_count_behavior.asObservable();

  private signup_behavior = new BehaviorSubject<any>({ state: false, type: 'signin' });
  public signup_observer = this.signup_behavior.asObservable();

  private signup_data_behavior = new BehaviorSubject<any>({} as any);
  public signup_data_observer = this.signup_data_behavior.asObservable();

  private web_behavior = new BehaviorSubject<any>({} as WebModule);
  public web_observer = this.web_behavior.asObservable();

  public customer_key = 'customer_key';

  @Output() show_header_search: EventEmitter<any> = new EventEmitter();

  constructor(
    public httpClient: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private offcanvasService: NgbOffcanvas,
    @Inject(PLATFORM_ID) public platformId: Object,
  ) { }

  get_endpoint() {
    return environment.endpoint; //this.endpoint;
  }

  get_base_url() {
    return environment.base_url;// this.base_url;
  }

  get_server_assets_url() {
    return environment.server_assets_url;// this.server_assets_url;
  }

  get_server_payment_url() {
    return environment.server_payment_url;// this.server_payment_url;
  }

  /**
   *
   * @returns boolean
   */
  is_logged_in() {
    return !!localStorage.getItem(this.customer_key);
  }

  get_product_url(slug: any, indx: any) {
    return `/product/${slug}-${indx}`;
  }

  get_category_url(slug: any, indx: any) {
    return `/products/${slug}-${indx}`;
  }

  set_header_search(value: boolean = false) {
    //console.log('change started');
    this.show_header_search.emit(value);

    //console.log(this.show_header_search);
  }

  get_header_search() {
    return this.show_header_search;
  }

  public get_customer_key(): string {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(this.customer_key) !== null) {
        return localStorage.getItem(this.customer_key) as string;
      }
    }

    return '';
  }

  public get_localstore(name: string): string {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem(name) !== null) {
        return localStorage.getItem(name) as string;
      }
    }

    return '';
  }

  public async add_to_cart(order: any) {
    const postData = new FormData();

    postData.append('action', 'cart/add');
    postData.append('product_key', order.pkey);
    postData.append('vkey', order.vkey);
    postData.append('color', order.color);
    postData.append('size', order.size);
    postData.append('qnty', order.qnty);

    let customer_key = await atob(this.get_customer_key());

    const result = await this.httpClient.post<any>(this.get_endpoint() + `/cart/${customer_key}/add`, postData);

    return result;
  }

  public async add_to_wishlist(order: any) {
    const postData = new FormData();

    postData.append('action', 'wishlist/save');
    postData.append('product_key', order.pkey);
    postData.append('vkey', order.vkey);
    postData.append('color', order.color);
    postData.append('size', order.size);
    postData.append('qnty', order.qnty);

    let customer_key = await atob(this.get_customer_key());

    const result = await this.httpClient.post<any>(this.get_endpoint() + `/wishlist/${customer_key}/save`, postData);

    return result;
  }

  public set_suggestion_bar_state(state: boolean) {
    this.suggestion_bar_behavior.next(state);
  }

  public set_page_loader(state: boolean) {
    this.page_loader_behavior.next(state);
  }

  public set_cart_count(count: number) {
    this.cart_count_behavior.next(count);
  }

  public set_search_text(text: string) {
    this.search_text_behavior.next(text);
  }

  public set_signup_state(state: any) {
    //console.log(state);
    this.signup_behavior.next(state);
  }

  public set_header_search_result(params: any) {
    //console.log(params);
    this.header_search_results_behavior.next(params);
  }

  public set_signup_data(params: any) {
    //console.log(params);
    this.signup_data_behavior.next(params);
  }

  public cart_update_count() {
    const postData = new FormData();
    const customer_key = this.get_customer_key();

    postData.append('action', 'cart/count');
    postData.append('search', atob(customer_key));

    if (customer_key.length != null && customer_key.length > 0) {
      const result = this.httpClient.post<any>(this.get_endpoint() + `/cart/${atob(customer_key)}/count`, postData).subscribe((response) => {
        //console.log(response);
        if (response.msg == 'success') {
          this.set_cart_count(response.data);
        }
      });
    } else {
      let localCart = [] as any;
      let count = 0;
      localCart = localStorage.getItem('cart');
      localCart = (localCart != null) ? JSON.parse(localCart) : [];

      for (let kk = 0; kk < localCart.length; kk++) {
        count = count + parseInt(localCart[kk].qnty);
      }

      this.set_cart_count(count);
    }
  }

  public async save_local_cart(customer_key: any) {
    if (isPlatformBrowser(this.platformId)) {
      let localCart = (localStorage.getItem('cart') != null) ? JSON.parse(localStorage.getItem('cart') as string) : [];

      let postData = new FormData;
      postData.append('action', 'checkout/localcart/save');
      postData.append('cart', JSON.stringify(localCart));


      /*{
        action: 'checkout/localcart/save',
          cart: localCart,// JSON.stringify(localCart),
      };*/

      let results = await this.httpClient.post<any>(this.get_endpoint() + `/account/customer/${customer_key}/localcart/save`, postData)
        .subscribe((response) => {
          if (response.msg == 'success') {
            localStorage.removeItem('cart');
            this.cart_update_count();
          }
        });

      console.log(results);
    }
  }

  public set_checkout_active(name: string) {
    let elements = document.getElementsByClassName('checkout-process-step') as any;
    let target = document.getElementsByClassName(name) as any;

    //console.log(elements);

    for (let kk = 0; kk < elements.length; kk++) {
      elements[kk].classList.remove('active');
    }

    setTimeout(() => {
      //target[0].classList.add('active');
    }, 0.2 * 1000);
  }

  public set_account_active(name: string) {
    let elements = document.getElementsByClassName('profile-item-link') as any;
    let target = document.getElementsByClassName(name) as any;

    //console.log(elements);

    for (let kk = 0; kk < elements.length; kk++) {
      elements[kk].classList.remove('active');
    }

    setTimeout(() => {
      target[0].classList.add('active');
    }, 0.2 * 1000);
  }

  public set_web(params: WebModule) {
    //console.log(params);
    this.web_behavior.next(params);
  }

  public slugify(text: string) {
    return text.toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-');
  }

  public set_wishlist_count(count: number) {
    this.wishlist_count_behavior.next(count);
  }

  open_side_cart() {
    if (isPlatformBrowser(this.platformId)) {
      if (window.location.pathname !== '/checkout/cart') {
        const offcanvasRef = this.offcanvasService.open(CartComponent, { panelClass: 'cart-panel', position: 'end' });
        offcanvasRef.componentInstance.name = 'cart';
      }
    }
  }

}
