import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialAuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-lsv',
  templateUrl: './lsv.component.html',
  styleUrls: ['./lsv.component.scss']
})
export class LsvComponent implements OnInit {

  customer_key: string = '';

  constructor(
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public toastr: ToastrService,
    public auth: AuthenticationService,
    public modalService: NgbModal,
    private authService: SocialAuthService,
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    this.customer_key = atob(await this.globals.get_customer_key()) as string;
    this.auth.show('lsv-common');

    /**
     * close the search bar if it's open. set it to falses
     */
    this.globals.set_suggestion_bar_state(false);
  }

  show(name: string) {
    setTimeout(() => {
      if (this.modalService.hasOpenModals()) {
        let elements = document.getElementsByClassName('authentication') as any;
        let target = document.getElementsByClassName(name) as any;
        //console.log(elements);

        //--hide all--
        for (let kk = 0; kk < elements.length; kk++) {
          if (!elements[kk].classList.contains('hide'))
            elements[kk].classList.add('hide');
        }

        //--remove hide from target section--
        if (target[0].classList.contains('hide')) {
          target[0].classList.remove('hide');
        }
      }
    }, 0.5 * 1000);
  }
}
