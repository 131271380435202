import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { GlobalsService } from 'src/app/services/globals.service';
import { Observable, combineLatest } from 'rxjs/index';
import { WebModule } from 'src/app/modules/web/web.module';

@Component({
  selector: 'app-checkout-status',
  templateUrl: './checkout-status.component.html',
  styleUrls: ['./checkout-status.component.scss']
})
export class CheckoutStatusComponent implements OnInit {

  order = [] as any;
  order_key = '';
  customer_key = '';
  url_params = {} as any;
  url_query_params = {} as any;
  public webModule: WebModule = {} as any;
  public search_results = [] as any;
  order_status = '';

  constructor(
    private httpClient: HttpClient,
    public globals: GlobalsService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastr: ToastrService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    this.globals.web_observer.subscribe(web => this.webModule = web);
    this.customer_key = await this.globals.get_customer_key();

    // viewChild is set after the view has been initialized
    this.url_params = await this.activeRoute.params;
    this.url_query_params = await this.activeRoute.queryParams;

    // 2. Call combineLatest operator, inject multiple observables in array
    const joinStream = combineLatest([this.url_params, this.url_query_params]);

    // 3. Subscibe combineLatest observable
    const subscribe = await joinStream.subscribe(async ([params, queryParams]) => {
      //this.product_vkey = queryParams['vkey'];
      await this.extract_params(params, queryParams);

      /**
      * close the search bar if it's open. set it to falses
      */
      this.globals.set_suggestion_bar_state(false);
      this.globals.set_search_text('');

      this.search_results.show_results = false;
      this.globals.set_suggestion_bar_state(this.search_results);
    });

  }

  async extract_params(params: any, queryParams: any) {
    this.order_key = await params['okey'];
    this.get_data();
    //this.product_vkey = await queryParams['vkey'];

    console.log(params, queryParams, window.location);

    let pathname = window.location.pathname;
    const regexSuccess = /\/success/;
    const regexError = /\/error/;

    // Use test() method to check if the string contains '/success'
    const containsSuccess = regexSuccess.test(pathname)
    const containsError = regexError.test(pathname)

    if (containsSuccess)
      this.order_status = 'success';

    if (containsError)
      this.order_status = 'error';

    console.log('T', containsSuccess, this.order_status);
  }

  async get_data(): Promise<any> {
    let postData = {
      action: 'account/orders/details',
    };

    //postData.append('action', 'cart/count');
    //postData.append('search', atob(customer_key));

    const result = this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${this.customer_key}/orders/${atob(this.order_key)}`, postData).subscribe((response) => {
      //console.log(response);
      if (response.msg == 'success') {
        this.order = response.data;
      }
    });
  }


  show_address(): string {
    let addrs = '';
    addrs += (this.order.shipping_address.line1.length > 0) ? this.order.shipping_address.line1 : '';
    addrs += (this.order.shipping_address.landmark.length > 0) ? ', ' + this.order.shipping_address.landmark : '';
    addrs += (this.order.shipping_address.city.length > 0) ? ', ' + this.order.shipping_address.city : '';
    addrs += (this.order.shipping_address.state_name.length > 0) ? ', ' + this.order.shipping_address.state_name : '';
    addrs += (this.order.shipping_address.pincode.length > 0) ? ', ' + this.order.shipping_address.pincode : '';

    return addrs;
  }

}
