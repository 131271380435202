<div class="other-page">
    <div class="flex column container" style="max-width: 1000px">
       <div class="flex position-center other-page-heading">ORDERS & PAYMENTS Policy</div>
       <div class="content">
        

       <h3>ORDERS</h3>
            
            <p>ORANGE has created and published the website with the mission to offer a service exclusively for its own clients. The products on 
                sale on the website www.orangefashion.co are destined to the final customer. By “final customer” www.orangefashion.co intends person or persons who do not 
                operate their own entrepreneurial nor professional activities that may include but not limited to the re-sale of merchandise purchased at www.orangefashion.co 
                therefore, www.orangefashion.co requests users who are not considered a “final customer” to refrain from attempting to establish business relations with www.orangefashion.co 
                nor use accounts of third parties to forward purchase orders relative to the merchandise on sale. In regard to the commercial policy described above, www.orangefashion.co 
                reserves the right to not process orders from persons that are not the final customer and any other orders that are not in conformity with the said commercial policies. www.orangefashion.co
                 reserves the right to decline to process an order and/or offer services to anyone at any time. www.orangefashion.co reserves the right to decline an order in the event that the order does 
                 not receive express verification or approval from the client’s bank. www.orangefashion.co reserves the right to delay a shipment if the order is not able to be shipped for reasons
                 beyond our control. Please note that during promotions and sales there may be delays in immediate shipping. Orders placed separately will be shipped separately.</p>
            
            <p><br />
                Once your order has been placed, you will receive an email acknowledging the details of your order. This email is not an acceptance of your order, just a confirmation that we have received it. Unless you cancel your order, acceptance of your order and completion of the contract between you and www.orangefashion.co will be completed when we email you that the goods have been dispatched. Neither our third party payment processor nor our nominated courier has the authority to accept an order on behalf of www.orangefashion.co. We reserve the right not to accept your order in the event, for example, that we are unable to obtain authorization for payment, that shipping restrictions apply to a particular item, that the item ordered is out of stock or does not satisfy our quality control standards and is withdrawn, or that you do not meet the eligibility criteria set out within the policy.</p>
                <br />
            <p>
                We may also refuse to process and therefore accept a transaction for any reason or refuse service to anyone at any time at our sole discretion. We will not be liable to you or any third party by reason of our withdrawing any merchandise from www.orangefashion.co whether or not that merchandise has been sold, removing, screening or editing any materials or content on www.aarnava.com, refusing to process a transaction or unwinding or suspending any transaction after processing has begun.
            </p>
            
            <p>Whilst every effort is made to make sure details on our website are accurate, we may from time to time discover an error in the pricing of products. If we discover an error in the pricing of a product in your order, we will let you know as soon as possible. We shall be under no obligation to accept or fulfill an order for a product that was advertised at an incorrect price and reserve the right to cancel such an order that has been accepted or is in transit. If you order a product that is priced incorrectly for any reason, we will email or telephone you to inform you that we have not accepted your order and/or that the relevant part of your order has been cancelled. If you have already paid for the goods, we shall refund the full amount as soon as we are able. 
                In the event that products are recalled in transit, we will process your refund once the products have been returned to us.</p>
            
            <h3>Available Items</h3>
            
            <p>Orders made of available items, upon confirmation of their availability and check of the bank transaction, will be processed the next working day of the order’s placement. The amount of the transactions made by credit cards will be settled only when processing the order. 
                Please note that during seasonal sales and promotional sales, delay may occur in the order’s processing.
                </p>
            
           <h3>Pre-Order Items</h3>
            
            <p>A pre-order is the reservation of an item that is not yet available for sale, but will be soon. Pre-order items are shipped upon the arrival of the products in the warehouse within the time that is stated on the item’s purchase page. In case the merchandise in pre-order fails in delivery, www.orangefashion.co will promptly inform and refund customers. www.orangefashion.co will not be held responsible for a delay in shipment caused by circumstances beyond the control of Ambush that cause pre-ordered products to
                 arrive at the warehouse later than the time specified on the product’s purchase page. In pre-order purchases credit cards are debited at the moment the order is placed. <br />
                 Regarding order with available items and pre-orderable items, shipment will happen in 2 different phases:
                 Available merchandise will be shipped with standard shipping methods
                 Pre-orderable items will be shipped right after items are available in stock.
                 </p>
            
         
            <h3>PAYMENT METHODS</h3>
            
            <p>www.orangefashion.co has the following payment methods available: credit cards (we accept visa, MasterCard, rupay and American express ); All UPI payments like BHIM UPI, Paytm, Gpay, Phonepe, amazon pay & Bharat Pe
                Please note that all payment transactions are subject to verification with the card & upi issuer. Your credit card will be charged only when the order is finalized, and we will not be held responsible for any refusal of a card issuer to authorize the transaction. Furthermore, we conduct standard pre-authorization on the card; products shall not be shipped until such checks have been performed. Please note that the card issuer may charge a fee or administrative-processing charge; we are not responsible for such expenses.
                </p>
            
            <p>Please note that it may take your bank additional time to process the funds back to your account.</p>
            
            <p>Your order will be dispatched upon receipt of payment on our account. Refunds from returns will be charged-back to the bank account which the original order was paid from.</p>
            <h3>PRICES AND CURRENCY</h3>
            <p>The product price shall correspond to the list price appearing on the site. Prices include GST and shipping. Shipping costs (at the stated price) will be added automatically to the total amount due once your items appear in the cart and you have selected your desired shipping method. Delivery prices and costs are subject to change at any time; however, such changes shall not affect the orders for which we have already sent a shipping confirmation. Payments as well as refunds for shipments are all invoiced in rupees (Rs).</p>
       </div>
    </div>
</div>
