<div class="flex position-center address-add-edit hside">
  <div class="flex flex-column address-add-edit-box">
    <div class="flex allign-middle heading">
      <span>Add New</span>&nbsp;Address
      <div class="flex position-center dialog-close" (click)="close()">
        <svg _ngcontent-tme-c124="" viewBox="0 0 24 24">
          <path fill="currentColor"
            d="m12 10.586 6.293-6.293a1 1 0 0 1 1.414 1.414l-6.293 6.293 6.293 6.293a1 1 0 0 1 -1.414 1.414l-6.293-6.293-6.293 6.293a1 1 0 1 1 -1.414-1.414l6.293-6.293-6.293-6.293a1 1 0 0 1 1.414-1.414z">
          </path>
        </svg>
      </div>
    </div>
    <div class="flex flex-column allign-middle adress-entry">
      <div class="adress-entry-heading">Contact details</div>
      <div class="flex flex-column entry-input-section">
        <div class="single-input">
          <input class="floating-input" type="text" placeholder=" " [(ngModel)]="address.name"
            [style.border]=" (errors.name != undefined) ? '1px solid red' :'' " (change)="remove_error('name')"
            (keyup)="remove_error('name');check_enter_pressed('name', $event)">
          <label class="floating-label">Name*</label>
        </div>
        <div class="single-input">
          <input class="floating-input" type="text" value="9800622215" placeholder=" " [(ngModel)]="address.mobile"
            [style.border]=" (errors.mobile != undefined) ? '1px solid red' :'' " (change)="remove_error('mobile')"
            (keyup)="remove_error('mobile');check_enter_pressed('mobile', $event)">
          <label class="floating-label">Mobile Number*</label>
        </div>
      </div>
      <div class="adress-entry-heading" style="padding-top: 10px;">Address details</div>
      <div class="flex flex-column entry-input-section">
        <div class="single-input">
          <input class="floating-input" type="text" value="" placeholder=" " [(ngModel)]="address.pincode"
            [style.border]=" (errors.pincode != undefined) ? '1px solid red' :'' " (change)="remove_error('pincode')"
            (keyup)="remove_error('pincode');check_enter_pressed('pincode', $event)">
          <label class="floating-label">Pin Code*</label>
        </div>
        <div class="single-input">
          <input class="floating-input" type="text" value="" placeholder=" " [(ngModel)]="address.address1"
            [style.border]=" (errors.address1 != undefined) ? '1px solid red' :'' " (change)="remove_error('address1')"
            (keyup)="remove_error('address1');check_enter_pressed('address1', $event)">
          <label class="floating-label">Address (House No, Building, Street, Area)*</label>
        </div>
        <div class="single-input">
          <input class="floating-input" type="text" value="" placeholder=" " [(ngModel)]="address.landmark"
            [style.border]=" (errors.landmark != undefined) ? '1px solid red' :'' " (change)="remove_error('landmark')"
            (keyup)="remove_error('landmark');check_enter_pressed('landmark', $event)" />
          <label class="floating-label">Landmark (Optional)</label>
        </div>
        <div class="single-input">
          <input class="floating-input" type="text" value="" placeholder=" " [(ngModel)]="address.locality"
            [style.border]=" (errors.locality != undefined) ? '1px solid red' :'' " (change)="remove_error('locality')"
            (keyup)="remove_error('locality');check_enter_pressed('locality', $event)" />
          <label class="floating-label">Locality / Town*</label>
        </div>
        <div class="single-input">
          <input class="floating-input" type="text" value="" placeholder=" " [(ngModel)]="address.city"
            [style.border]=" (errors.city != undefined) ? '1px solid red' :'' " (change)="remove_error('city')"
            (keyup)="remove_error('city');check_enter_pressed('city', $event)" />
          <label class="floating-label">City / District*</label>
        </div>
        <div class="single-input">
          <!-- onclick="this.setAttribute('value', this.value);" onchange="this.setAttribute('value', this.value);" -->
          <select class="floating-select" value="" [(ngModel)]="address.state"
            [style.border]=" (errors.state != undefined) ? '1px solid red' :'' " (change)="remove_error('state')"
            (keyup)="remove_error('state');check_enter_pressed('state', $event)"
            onchange="this.setAttribute('value', this.value);">
            <option value="">Select a State</option>
            <option *ngFor="let state of states" value="{{state.key}}">{{state.name}}</option>
          </select>
          <label class="floating-label">State*</label>
        </div>
      </div>
      <div class="adress-entry-heading" style="padding-top: 10px;">Save Address As</div>
      <div class="flex flex-column save-address-as">
        <div class="flex address-type switch-field">
          <input type="radio" id="radio-one" name="switch-addres-type" value="home" [(ngModel)]="address.type"
            [style.border]=" (errors.type != undefined) ? '1px solid red' :'' " (change)="remove_error('type')"
            (keyup)="remove_error('type');check_enter_pressed('type', $event)">
          <label for="radio-one">Home</label>
          <input type="radio" id="radio-two" name="switch-addres-type" value="work" [(ngModel)]="address.type"
            [style.border]=" (errors.type != undefined) ? '1px solid red' :'' " (change)="remove_error('type')"
            (keyup)="remove_error('type');check_enter_pressed('type', $event)">
          <label for="radio-two">Work</label>
        </div>
        <div class="flex flex-column work-timing" *ngIf="address.type=='work' ">
          <label class="flex allign-middle custom-checkbox">
            <input type="checkbox" name="satday_open" id="satday_open" [(ngModel)]="address.satday_open"
              [style.border]=" (errors.satday_open != undefined) ? '1px solid red' :'' "
              (change)="remove_error('satday_open')"
              (keyup)="remove_error('satday_open');check_enter_pressed('satday_open', $event)">Open on Saturday
            <div class="checkbox-indicator"></div>
          </label>
          <label class="flex allign-middle custom-checkbox">
            <input type="checkbox" name="sunday_open" id="sunday_open" [(ngModel)]="address.sunday_open"
              [style.border]=" (errors.sunday_open != undefined) ? '1px solid red' :'' "
              (change)="remove_error('sunday_open')"
              (keyup)="remove_error('sunday_open');check_enter_pressed('sunday_open', $event)">Open on Sunday
            <div class="checkbox-indicator"></div>
          </label>
        </div>
        <label class="flex allign-middle custom-checkbox">
          <input type="checkbox" value="default" [(ngModel)]="address.set_default"
            [style.border]=" (errors.type != undefined) ? '1px solid red' :'' " (change)="remove_error('type')"
            (keyup)="remove_error('type');check_enter_pressed('type', $event)" />
          Make this my default address
          <div class="checkbox-indicator"></div>
        </label>
      </div>
    </div>
    <div class="address-footer">
      <!-- (show_popup)="show_popup" -->
      <button class="flex position-center adress-action" (click)="save()">ADD ADDRESS</button>
    </div>
  </div>
</div>