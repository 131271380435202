<div class="flex account-page">
  <div class="flex flex-column container">
    <section class="flex allign-middle breadcrumbs">
      <a>Home</a>
      <span>/</span>
      <a>Account</a>
      <span>/</span>
      <a>Wishlist</a>
    </section>
    <div class="flex w-100">
      <!-- account-side-menu -->
      <app-account-sidemenu></app-account-sidemenu>
      <!-- ./account-side-menu -->

      <div class="flex right profile-details wishlist" style="position: unset">
        <div class="flex flex-column w-100 profile-overview profile-card">
          <div class="flex allign-middle w-100 head-section">
            My Wishlist (<span>{{rows.length}}</span> {{(rows.length>0) ? 'items' :'item' }})
          </div>
          <div class="flex flex-wrap wishlist-item-box" infiniteScroll [infiniteScrollDistance]="1"
            [infiniteScrollThrottle]="50" (scrolled)="onScroll()">

            <ng-container *ngFor="let item of rows; let indx=index;">
              <div class="flex wishlist-item">
                <div class="flex flex-column w-100 wishlist-container">
                  <div class="flex position-center wishlist-item-remove" (click)="remove(indx)">
                    <svg viewBox="0 0 24 24">
                      <path fill="currentColor"
                        d="m12 10.586 6.293-6.293a1 1 0 0 1 1.414 1.414l-6.293 6.293 6.293 6.293a1 1 0 0 1 -1.414 1.414l-6.293-6.293-6.293 6.293a1 1 0 1 1 -1.414-1.414l6.293-6.293-6.293-6.293a1 1 0 0 1 1.414-1.414z">
                      </path>
                    </svg>
                  </div>
                  <div class="flex wishlist-image">
                    <ng-container *ngIf="item.product.is_variant_type=='N'">
                      <img class="" src="{{item.product.images[0].md}}" alt="{{item.product.name}}"
                        routerLink="{{this.globals.get_product_url(item.product.slug,item.product.indx)}}"
                        [queryParams]="{vkey:item.variant.indx}">
                    </ng-container>

                    <ng-container *ngIf="item.product.is_variant_type=='Y'">
                      <img class="" src="{{item.variant.images[0].md}}" alt="{{item.variant.name}}"
                        routerLink="{{this.globals.get_product_url(item.product.slug,item.product.indx)}}"
                        [queryParams]="{vkey:item.variant.indx}">
                    </ng-container>
                  </div>
                  <div class="flex w-100 text-center wishlist-item-name">
                    <span routerLink="{{this.globals.get_product_url(item.product.slug,item.product.indx)}}"
                      [queryParams]="{vkey:item.variant.indx}">{{item.product.name}}</span>
                  </div>
                  <div class="flex w-100 position-center product-price type-1">
                    <div class="flex new-price">₹{{item.product.price_selling}}</div>
                    <div class="flex old-price">₹{{item.product.price_mrp}}</div>
                    <div class="flex discount-price">(50% OFF)</div>
                  </div>
                  <div class="flex w-100 position-center move-order">
                    <!-- <a class="flex position-center" (click)="move_to_cart(indx)">MOVE TO BAG</a> -->
                    <a class="flex position-center" (click)="show_popup(indx)">MOVE TO BAG</a>
                  </div>
                </div>
              </div>
            </ng-container>

          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="flex position-center size-popup" *ngIf="popup_state">
    <div class="size-popup-backdrop"></div>
    <div class="size-popup-box">
      <div class="flex position-center size-popup-close" (click)="hide_popup()">
        <svg viewBox="0 0 24 24">
          <path fill="currentColor"
            d="m12 10.586 6.293-6.293a1 1 0 0 1 1.414 1.414l-6.293 6.293 6.293 6.293a1 1 0 0 1 -1.414 1.414l-6.293-6.293-6.293 6.293a1 1 0 1 1 -1.414-1.414l6.293-6.293-6.293-6.293a1 1 0 0 1 1.414-1.414z">
          </path>
        </svg>
      </div>
      
      <div class="flex w-100 size-popup-product">
        <div class="flex position-center size-popup-product-left">
          <img src="{{product.variant.images[0].sm}}" alt="{{product.product.name}}">
        </div>
        <div class="flex flex-column size-popup-product-right">
          <div class="name" routerLink="{{this.globals.get_product_url(product.product.slug,product.product.indx)}}"
            [queryParams]="{vkey:product.variant.indx}">{{product.product.name}}</div>
          <div class="flex w-100 allign-middle product-price type-1">
            <div class="flex new-price">₹{{product.product.price_selling}}</div>
            <div class="flex old-price">₹{{product.product.price_mrp}}</div>
            <div class="flex discount-price">(50% OFF)</div>
          </div>
        </div>
      </div>
      <div class="flex flex-column select-size">
        <div class="hedd">Select Size</div>
        <div class="flex position-center product-size-palette">

          <ng-container *ngFor="let size of product.variant.sizes">
            <div class="flex size-tile-box">
              <input type="radio" name="sizePalette" class="radio" id="tile_{{size}}" value="{{size}}" />
              <label class="flex tile" for="tile_{{size}}">
                <div class="flex position-center w-100 h-100">{{size}}</div>
              </label>
            </div>
          </ng-container>

          <div class="flex size-tile-box hide">
            <input type="radio" name="sizePalette" class="radio" id="tile_xl" value="xl">
            <label class="flex tile" for="tile_xl">
              <div class="flex position-center w-100 h-100">XL</div>
            </label>
          </div>
        </div>
        <button class="flex position-center size-confirm" (click)="move_to_cart()">Done</button>
      </div>
    </div>
  </div>


</div>
