<div class="flex  position-center track-order-page">
    <div class="tracker-box">
        <div class="item email-phone">
            <div class="flex allign-middle title">Email / Phone #</div>
            <input class="flex allign-middle" placeholder="abc@somesite.com">
            <div class="flex allign-middle info">Please provide your phone number or email id.</div>
        </div>
        <div class="item order-no">
            <div class="flex allign-middle title">Order #</div>
            <input class="flex allign-middle" placeholder="ABC123XYZ">
            <div class="flex allign-middle info">Please provide the order number of the order to be tracked.</div>
        </div>
        <button class="flex position-center w-100 track-submit">FETCH DETAILS</button>
    </div>
</div>