import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { AddressServiceService } from 'src/app/services/address-service.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { isObject } from 'util';

@Component({
  selector: 'app-add-edit-address',
  templateUrl: './add-edit-address.component.html',
  styleUrls: ['./add-edit-address.component.scss']
})
export class AddEditAddressComponent implements OnInit {

  rows = [] as any;
  cart = {
    total: 0,
    price: 0,
    price_payable: 0,
    discount_amount: 0,
    item_qnty: 0,
    shipping: 0,
  } as any;
  customer_key = '';
  cart_count = 0;
  address = {
    key: '',
    email: '',
    name: '',
    fname: '',
    lname: '',
    address1: '',
    address2: '',
    locality: '',
    landmark: '',
    city: '',
    country: '',
    state: '',
    pincode: '',
    mobile: '',
    new_updates: true,
    set_default: false,
    type: 'home',
    satday_open: 'N',
    sunday_open: 'N',
  } as any;
  countries = [] as any;
  states = [] as any;
  errors = {} as any;

  //----
  @Input() show_popup: boolean = false;
  @Output() popupState = new EventEmitter<boolean>();

  private address_behavior = new BehaviorSubject<any>({} as any);
  public address_observer = this.address_behavior.asObservable();

  constructor(
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public toastr: ToastrService,
    private auth: AuthenticationService,
    public addressService: AddressServiceService,
  ) { }

  ngOnInit(): void {
    this.addressService.rows_observer.subscribe((rows) => this.rows = rows);
    this.addressService.mode_observer.subscribe((mode) => this.addressService.mode = mode);
    this.addressService.address_observer.subscribe((address) => { this.addressService.address = address });

    this.customer_key = this.globals.get_customer_key();
    this.get_addreess_details();
    //this.address = this.addressService.address;

    if (this.addressService.mode == 'edit') {
      this.address = this.addressService.address;
    } else { // for Save
      this.addressService.set_address(this.address);

      if (isPlatformBrowser(this.globals.platformId)) {
        let address = (localStorage.getItem('shipping_address') != null) ? JSON.parse(localStorage.getItem('shipping_address') as any) : this.address;
        this.address.name = address.name;
        this.address.mobile = address.mobile;
        this.address.satday_open = false;
        this.address.sunday_open = false;
      }
    }

    //console.log([this.addressService, this.address]);
  }

  async get_addreess_details() {
    if (isPlatformBrowser(this.globals.platformId)) {
      let postData = new FormData();

      postData.append('action', 'account/address/default');
      //postData.append('customer_key', this.customer_key);

      const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${atob(this.customer_key)}/address/default`, postData).subscribe((response) => {
        ////console.log(response);
        //this.address = response.data;
        this.countries = response.countries;
        this.states = response.states;
      });
    }
  }

  validate() {
    let customer = this.auth.customer;
    this.errors = {} as any;
    const preg_email = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    /*if (customer.name.length == 0) {
      this.errors.name = "Please enter your name";
    }

    if ((this.signup_type == 'email') && customer.mobile.length == 0) {
      this.errors.mobile = "Invalid number";
    }

    if ((this.signup_type == 'email') && (customer.mobile.length > 0) && (customer.mobile.length < 10)) {
      this.errors.mobile = "Invalid number";
    }

    if ((this.signup_type == 'mobile') && customer.email.length == 0) {
      this.errors.email = "Please enter email";
    }

    if ((this.signup_type == 'mobile') && (customer.email.length > 0) && !preg_email.test(customer.email)) {
      this.errors.email = "Please enter a valid email address!";
    }

    if (customer.password.length == 0) {
      this.errors.password = "Please enter your password";
    }

    if ((customer.password.length > 0) && (customer.password.length < 8)) {
      this.errors.password = "Invalid password. Min 8 char. required";
    }*/

    //console.log([this.errors, this.auth.customer]);
    return (Object.entries(this.errors).length == 0) ? true : false;
  }

  async save(): Promise<any> {
    if (this.validate()) {
      let postData = new FormData();
      let action = 'account/address/save';
      let url = `/account/customer/${atob(this.customer_key)}/address/save`;

      //console.log(this.address);
      //return false;

      if (this.address.key.length > 0) {
        action = 'account/address/update';
        url = `/account/customer/${atob(this.customer_key)}/address/update`;
      }

      localStorage.removeItem('shipping_address');
      localStorage.removeItem('checkout_cart');
      localStorage.removeItem('checkout_rows');

      postData.append('action', action);
      postData.append(this.globals.customer_key, atob(this.customer_key));
      postData.append('key', this.address.key);
      postData.append('fname', this.address.fname);
      postData.append('lname', this.address.lname);
      postData.append('name', this.address.name);
      postData.append('mobile', this.address.mobile);
      postData.append('state', this.address.state);
      postData.append('city', this.address.city);
      postData.append('country', this.address.country);
      postData.append('pincode', this.address.pincode);
      postData.append('address1', this.address.address1);
      postData.append('address2', this.address.address2);
      postData.append('locality', this.address.locality);
      postData.append('landmark', this.address.landmark);
      postData.append('set_default', this.address.set_default);
      postData.append('satday_open', this.address.satday_open);
      postData.append('sunday_open', this.address.sunday_open);
      postData.append('type', this.address.type);

      this.globals.set_page_loader(true);

      const result = await this.httpClient.post<any>(this.globals.get_endpoint() + url, postData).subscribe((response) => {
        ////console.log(response);
        if (response.msg == 'success') {
          let data = response.data;
          //this.addaddressclose();
          this.toastr.clear();

          if (this.address.key.length == 0) {
            this.toastr.success('Address saved!', '', { positionClass: 'toast-bottom-center' });
          } else {
            this.toastr.success('Address updated!', '', { positionClass: 'toast-bottom-center' });
          }

          this.close();
          this.globals.set_page_loader(false);
        } else {
          this.globals.set_page_loader(false);
        }
      });

      //this.router.navigate(['/checkout-payment']);
    }

    //console.log(['app-add-edit-address', this.address, this.errors]);
  }

  remove_error(name: string) {
    let err = this.errors;
    delete err[name];

    var preg = /^[0-9 ]+$/;
    var regx = /[^0-9]+/g;

    if (name == 'mobile') {
      let phone = this.address.mobile;

      if (this.address.mobile.length > 0 && !preg.test(this.address.mobile)) {
        err.phone = 'Invalid contact number';
        phone = phone.replace(regx, '');
        this.address.mobile = phone;
        ////console.log([phone, this.address]);
      }
      if (phone.length > 10) {
        phone = phone.substring(0, 10);
        this.address.mobile = phone;
      }
    }
    if (name == 'pincode') {
      let pincode = this.address.pincode;

      if (this.address.pincode.length > 0 && !preg.test(this.address.pincode)) {
        err.pincode = 'Invalid contact number';
        //let pincode = this.address.pincode;
        pincode = pincode.replace(regx, '');
        this.address.pincode = pincode;
        //console.log([pincode, this.address]);
      }

      if (pincode.length > 6) {
        pincode = pincode.toString().substring(0, 6);
        this.address.pincode = pincode;
      }
    }

    if (name == 'type') {
      this.address.satday_open = false;
      this.address.sunday_open = false;
    }

    this.errors = err;
  }

  check_enter_pressed(name: string, event: any) {
    ////console.log([name, event]);
    if (event.keyCode == 13) {
      switch (name) {
        case 'open': {
          break;
        }
      }
    }

    return true;
  }

  close() {
    this.show_popup = false;
    this.popupState.emit(this.show_popup);
  }

  set_default() {

  }


}
