<div class="flex address-page">
  <div class="flex container">
    <div class="flex flex-column left adress-details">
      <div class="flex allign-middle justify-content-between address-heading">
        <span>Select Delivery Address</span>
        <button class="flex position-center add-new-address" (click)="show_address_popup()">ADD NEW ADDRESS</button>
      </div>
      <div class="flex flex-wrap address-list">

        <ng-container *ngFor="let item of rows; let indx= index;">

          <div class="flex flex-column address-single default" *ngIf="item.set_default" style="cursor: pointer;">
            <div class="flex flex-column address-single-card">
              <div class="flex flex-column address-single-details">
                <div class="flex name">{{item.name}} <span>{{item.type}}</span></div>
                <div class="flex address1">{{item.address1}}, {{item.locality}}</div>
                <div class="flex address2">{{item.city}}, {{item.state_name}} - {{item.pincode}}</div>
                <div class="flex mobile">Mobile: <span>{{item.mobile}}</span></div>
              </div>
              <div class="flex position-center justify-content-between address-single-details-bottom">
                <!-- <div class="flex position-center default"><a>Deliver here</a></div> -->
                <div class="flex position-center edit"><a (click)="show_update_address_popup(indx)">Edit</a></div>
                <div class="flex position-center delete"><a>Remove</a></div>
              </div>
            </div>
          </div>

          <div class="flex flex-column address-single " *ngIf="!item.set_default" style="cursor: pointer;"
            (click)="select_address(indx)">
            <div class="flex flex-column address-single-card">
              <div class="flex flex-column address-single-details pb-3">
                <div class="flex name">{{item.name}} <span>{{item.type}}</span></div>
                <div class="flex address1">{{item.address1}}, {{item.locality}}</div>
                <div class="flex address2">{{item.city}}, {{item.state_name}} - {{item.pincode}}</div>
                <div class="flex mobile">Mobile: <span>{{item.mobile}}</span></div>
              </div>
              <div class="flex position-center justify-content-between address-single-details-bottom" *ngIf="0">
                <div class="flex position-center default" *ngIf="0"><a>Deliver here</a></div>
                <div class="flex position-center edit" *ngIf="0"><a>Edit</a></div>
                <div class="flex position-center delete" *ngIf="0"><a>Remove</a></div>
              </div>
            </div>
          </div>

        </ng-container>


        <div class="flex address-single add-new">
          <div class="flex w-100 position-center address-single-card">
            <div (click)="show_address_popup()">+ Add New Address</div>
          </div>
        </div>

      </div>
    </div>

    <div class="flex flex-column right price-details">
      <div class="flex flex-column cart-price">
        <div class="flex allign-middle cart-price-heading">
          PRICE DETAILS (<span>3</span>&nbsp;Items)
        </div>
        <div class="flex justify-content-between price-breakup">
          <span>Total MRP</span>
          <span>₹{{cart.price}}</span>
        </div>
        <div class="flex justify-content-between price-breakup">
          <span>Discount on MRP</span>
          <span class="green">-₹{{cart.discount_amount}}</span>
        </div>
        <div class="flex justify-content-between price-breakup" *ngIf="cart.coupon!==undefined">
          <span>Coupon Discount</span>
          <span class="green">-₹{{cart.maximum_saving}}</span>
        </div>
        <div class="flex justify-content-between price-breakup" *ngIf="giftwrap">
          <span>Gift Wrap</span>
          <span>₹25</span>
        </div>
        <div class="flex justify-content-between price-breakup">
          <span>Delivery Fee</span>
          <span><span style="text-decoration: line-through;">₹{{cart.shipping}} </span><span
              class="green">FREE</span></span>
        </div>
      </div>
      <div class="flex allign-middle justify-content-between total-cart-amount">
        <span>Total Amount</span>
        <span>₹{{price_payable()}}</span>
      </div>
      <div class="flex w-100 acction-btn">
        <button routerLink="/checkout/status" class="flex position-center place-order" (click)="save()">
          PLACE ORDER
        </button>
      </div>
    </div>

  </div>
</div>

<app-add-edit-address class="" *ngIf="show_popup" (popupState)="hide_address_popup($event)"></app-add-edit-address>