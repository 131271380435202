import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { HttpClient } from '@angular/common/http';
import { WebModule } from 'src/app/modules/web/web.module';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-account-sidemenu',
  templateUrl: './account-sidemenu.component.html',
  styleUrls: ['./account-sidemenu.component.scss']
})
export class AccountSidemenuComponent implements OnInit {

  public webModule: WebModule = {} as any;

  constructor(
    private httpClient: HttpClient,
    public globals: GlobalsService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthenticationService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {

  }

  ngAfterContentInit() {
    this.globals.web_observer.subscribe(web => this.webModule = web);
    //console.log(this.webModule);
  }

  get_first_namelatter() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.webModule.customer !== undefined && this.webModule.customer.fname.length > 0) {
        let temp_name = (this.webModule.customer.fname).split('');

        if (temp_name.length > 0) {
          return temp_name[0].toLocaleUpperCase();
        }
      }
    }

    return "G"
  }

  logout() {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.removeItem(this.globals.customer_key);
      localStorage.removeItem('name');
      localStorage.removeItem('fname');
      this.router.navigate(['/']);
    }
  }

}
