import { Component, OnInit } from '@angular/core';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-other-faq',
  templateUrl: './other-faq.component.html',
	//standalone: true,
  //imports: [NgbCollapseModule],
  styleUrls: ['./other-faq.component.scss']
})
export class OtherFaqComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
