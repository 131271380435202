<div class="flex position-center flex-column lsv-common common">

  <div class="flex position-center modal-brand">
    <svg viewBox="0 0 1908.000000 272.000000" preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,272.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
        <path d="M7816 2619 c-24 -52 -299 -627 -611 -1279 -312 -652 -569 -1193 -572
      -1202 -5 -17 13 -18 274 -18 l279 0 128 280 129 280 417 0 417 0 128 -280 129
      -280 284 0 c156 0 281 3 279 8 -2 4 -280 585 -617 1292 -337 707 -614 1287
      -616 1289 -2 2 -24 -38 -48 -90z m339 -1530 c6 -18 -2 -19 -294 -19 -166 0
      -301 2 -301 4 0 3 61 146 136 318 75 172 142 329 150 348 l15 35 144 -333 c79
      -184 147 -342 150 -353z" />
        <path d="M10232 1408 l3 -1283 240 0 240 0 3 758 2 758 818 -772 c449 -425
      838 -793 865 -818 l47 -45 0 1287 0 1287 -237 -2 -238 -3 -5 -756 -5 -756
      -860 813 c-473 448 -863 814 -868 814 -4 0 -6 -577 -5 -1282z" />
        <path d="M1090 2605 c-185 -27 -363 -91 -517 -187 -114 -70 -281 -234 -357
      -351 -98 -149 -172 -341 -195 -511 -16 -115 -14 -347 5 -457 55 -324 224 -604
      481 -798 80 -61 257 -151 364 -185 263 -84 630 -80 882 11 426 153 711 487
      808 946 29 134 31 388 5 522 -87 456 -374 794 -804 949 -181 65 -469 91 -672
      61z m455 -488 c285 -93 469 -337 505 -670 47 -437 -196 -811 -580 -892 -92
      -19 -250 -19 -348 1 -282 57 -514 302 -573 604 -16 84 -16 286 0 370 58 298
      288 543 566 604 112 25 327 16 430 -17z" />
        <path d="M14850 2609 c-427 -48 -792 -283 -979 -631 -104 -192 -141 -337 -148
      -584 -6 -208 8 -322 59 -484 140 -439 509 -754 978 -834 287 -49 574 -17 797
      89 340 161 566 456 653 854 21 96 47 369 37 395 -6 15 -60 16 -607 16 l-600 0
      0 -200 0 -200 350 0 c193 0 350 -2 350 -5 0 -2 -11 -34 -25 -71 -80 -213 -249
      -368 -462 -426 -105 -28 -316 -30 -418 -4 -226 58 -416 216 -516 429 -95 205
      -104 516 -20 732 77 198 250 367 447 436 238 83 515 59 698 -60 71 -46 164
      -137 208 -202 16 -24 34 -43 40 -41 9 2 340 194 395 228 l23 14 -37 53 c-63
      89 -222 240 -317 302 -255 166 -566 232 -906 194z" />
        <path d="M3840 1345 l0 -1225 250 0 250 0 2 443 3 442 109 0 109 0 285 -442
      284 -443 295 0 294 0 -60 87 c-33 49 -181 261 -329 473 l-268 384 95 48 c210
      103 334 254 391 473 28 104 30 287 5 395 -73 319 -336 533 -710 579 -54 6
      -275 11 -547 11 l-458 0 0 -1225z m943 780 c116 -30 200 -95 247 -190 22 -44
      25 -63 25 -160 0 -97 -3 -116 -25 -160 -48 -98 -146 -168 -268 -194 -29 -6
      -136 -11 -237 -11 l-185 0 0 365 0 365 193 0 c139 0 209 -4 250 -15z" />
        <path d="M17520 1345 l0 -1225 780 0 780 0 0 210 0 210 -535 0 -535 0 0 335 0
      335 500 0 500 0 0 210 0 210 -500 0 -500 0 0 260 0 260 535 0 535 0 0 210 0
      210 -780 0 -780 0 0 -1225z" />
      </g>
    </svg>
  </div>

  <app-section-loader *ngIf="auth.show_loader"></app-section-loader>

  <div class="flex allign-middle ls-heading">Sign up or Sign in</div>
  <div class="flex allign-middle ls-sub-heading">with us to get latest updates and manage your orders</div>

  <div class="flex flex-column allign-middle ls-input">
    <input type="text" autocomplete="email" placeholder="Mobile Number / Email" name="EmailMobNumberName"
      class="pincode-floating-input" id="common_username" value="" [(ngModel)]="auth.customer.username"
      [style.border]=" (auth.errors.username != undefined) ? '1px solid red' :'' "
      (change)="auth.remove_error('username')"
      (keyup)="auth.remove_error('username');auth.check_enter_pressed('open', $event)">

    <span class="ls-warning" *ngIf="auth.errors.verification_email!==undefined"
      style="display: block !important;">{{auth.errors.verification_email}}</span>
    <span class="ls-warning" *ngIf="auth.errors.username!==undefined"
      style="display: block !important;">{{auth.errors.username}}</span>

    <button type="button" class="flex position-center ls-submit 123" (click)="auth.open()">Continue</button>
    <div class="ls-info hide">By creating or login your account, you agree to our <a>Terms and Conditions</a></div>
  </div>

  <div class="flex flex-column allign-middle ls-input" *ngIf="auth.errors.verification_email!==undefined">
    <p>
      Didn't received varification mail? Click
      <a (click)="auth.resend_verification_email()" style="color: rgb(49, 130, 236);">HERE</a> to resend
    </p>
  </div>

  <div class="flex position-center ls-seperator">
    <span>Or continue with</span>
  </div>

  <div class="flex position-center ls-social">
    <div class="flex facebook" *ngIf="0">
      <a class="flex allign-middle">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
          <path style="fill:#385C8E;" d="M134.941,272.691h56.123v231.051c0,4.562,3.696,8.258,8.258,8.258h95.159
                        c4.562,0,8.258-3.696,8.258-8.258V273.78h64.519c4.195,0,7.725-3.148,8.204-7.315l9.799-85.061c0.269-2.34-0.472-4.684-2.038-6.44
                        c-1.567-1.757-3.81-2.763-6.164-2.763h-74.316V118.88c0-16.073,8.654-24.224,25.726-24.224c2.433,0,48.59,0,48.59,0
                        c4.562,0,8.258-3.698,8.258-8.258V8.319c0-4.562-3.696-8.258-8.258-8.258h-66.965C309.622,0.038,308.573,0,307.027,0
                        c-11.619,0-52.006,2.281-83.909,31.63c-35.348,32.524-30.434,71.465-29.26,78.217v62.352h-58.918c-4.562,0-8.258,3.696-8.258,8.258
            v83.975C126.683,268.993,130.379,272.691,134.941,272.691z" />
        </svg>
        <span>Facebook</span>
      </a>
    </div>
    <div class="flex position-center google">
      <a class="flex allign-middle" (click)="auth.signInWithGoogle()">
        <svg width="18" height="18" xmlns="http://www.w3.org/2000/svg">
          <g fill="#000" fill-rule="evenodd">
            <path
              d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
              fill="#EA4335"></path>
            <path
              d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
              fill="#4285F4"></path>
            <path
              d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
              fill="#FBBC05"></path>
            <path
              d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
              fill="#34A853"></path>
            <path fill="none" d="M0 0h18v18H0z"></path>
          </g>
        </svg>
        <span>Google</span>
      </a>
    </div>
  </div>

</div>
