<nav class="flex navigation side-navigation side-menu">
  <div class="flex user-links">
    <a class="flex position-center side-menu-close" title="Close" (click)="close_menu()"></a>

    <div class="flex user-side-menu-action" *ngIf="this.globals.is_logged_in()">
      <a class="flex position-center user-links-items account" [routerLink]="['/account/profile']"
        (click)="close_menu()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none"
            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
          <path
            d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
            fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
        </svg>
      </a>
      <a class="flex position-center user-links-items wishlist" [routerLink]="['/account/wishlist']"
        (click)="categoryHide()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z"
            fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
        </svg>
      </a>
      <a class="flex position-center user-links-items wishlist" (click)="openCart()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
            d="M80 176a16 16 0 00-16 16v216c0 30.24 25.76 56 56 56h272c30.24 0 56-24.51 56-54.75V192a16 16 0 00-16-16zM160 176v-32a96 96 0 0196-96h0a96 96 0 0196 96v32" />
        </svg>
      </a>
    </div>

    <div class="flex user-side-menu-action" *ngIf="!this.globals.is_logged_in()">
      <a class="flex position-center user-links-items account" (click)="openLsv(); close_menu();">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none"
            stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
          <path
            d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
            fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
        </svg>
      </a>
      <a class="flex position-center user-links-items wishlist" (click)="openLsv(); close_menu();">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0018 0c96.26-65.34 184.09-143.09 183-252.42-.54-52.67-42.32-96.81-95.08-96.81z"
            fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
        </svg>
      </a>
      <a class="flex position-center user-links-items wishlist" (click)="openCart(); close_menu();">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
            d="M80 176a16 16 0 00-16 16v216c0 30.24 25.76 56 56 56h272c30.24 0 56-24.51 56-54.75V192a16 16 0 00-16-16zM160 176v-32a96 96 0 0196-96h0a96 96 0 0196 96v32" />
        </svg>
      </a>
    </div>
  </div>

  <div class="flex w-100 menu">
    <div class="flex column side-menu-box">
      <div class="flex side-menu-top" [ngClass]="{'panel-hidden': toggle, 'panel-displayed': !toggle}">
        <div class="flex column side-menu-links">

          <ng-container *ngIf="webModule.menus!=undefined && (webModule.menus.length>0)">
            <ng-container *ngFor="let lavel1 of webModule.menus; let indx1=index">
              <a class="flex allign-middle side-menu-links-items has-child" *ngIf="lavel1.child != undefined"
                (click)="catToggle(lavel1.name)">{{lavel1.name}}
              </a>
              <a class="flex allign-middle side-menu-links-items" *ngIf="lavel1.child==undefined" href="{{lavel1.link}}"
                (click)="close_menu()" [attr.data-key]="lavel1.name+'/'">{{lavel1.name}}
              </a>
            </ng-container>
          </ng-container>
          <a class="flex allign-middle side-menu-links-items" target="_blank" href="https://blog.orangefashion.co">Blog</a>
          <!--<a class="flex allign-middle side-menu-links-items" [routerLink]="['/track-order']">Track Order</a>
          <a class="flex allign-middle side-menu-links-items" [routerLink]="['/contact-us']">Customer Support</a>-->
        </div>

        <ng-container *ngFor="let lavel1 of webModule.menus; let indx1=index">
          <ng-container *ngIf="(lavel1.child !== undefined && lavel1.child.length>0)">
            <div class="flex column w-100 side-menu-categories" *ngIf="selectedCategory==lavel1.name ">
              <a class="flex allign-middle w-100  side-menu-links-items panel-close" (click)="categoryHide()">Back</a>

              <ng-container *ngFor="let lavel2 of lavel1.child">
                <a class="flex allign-middle w-100  side-menu-links-items" *ngFor="let lavel3 of lavel2.child"
                  [routerLink]="[lavel3.link]" [attr.data-key]="lavel3 | json" (click)="close_menu()">{{lavel3.name}}
                </a>
              </ng-container>

              <a class="flex allign-middle w-100  side-menu-links-items" *ngIf="false">----</a>
            </div>
          </ng-container>
        </ng-container>

      </div>
      <div class="flex column side-menu-bottom">
        <div class="flex column side-menu-links">
          <a class="flex allign-middle side-menu-links-items login hide">
            <span>Login</span>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M344 144c-3.92 52.87-44 96-88 96s-84.15-43.12-88-96c-4-55 35-96 88-96s92 42 88 96z" fill="none"
                stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
              <path
                d="M256 304c-87 0-175.3 48-191.64 138.6C62.39 453.52 68.57 464 80 464h352c11.44 0 17.62-10.48 15.65-21.4C431.3 352 343 304 256 304z"
                fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
            </svg>
          </a>
          <a class="flex allign-middle side-menu-links-items" href="/privacy-policy">Privacy Policy</a>
          <a class="flex allign-middle side-menu-links-items" href="/terms-conditions">Terms & Conditions</a>
          <a class="flex allign-middle side-menu-links-items" href="/shipping-return-policy">Shipping & Return Policy</a>
          <a class="flex allign-middle side-menu-links-items" href="/order-payment-policy">Orders & Payment Policy</a>
          <ng-container *ngIf="this.globals.is_logged_in()">
            <a class="flex allign-middle side-menu-links-items logout" (click)="logout()">
              <span>Logout</span>
              <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
                <title>Log Out</title>
                <path
                  d="M304 336v40a40 40 0 01-40 40H104a40 40 0 01-40-40V136a40 40 0 0140-40h152c22.09 0 48 17.91 48 40v40M368 336l80-80-80-80M176 256h256"
                  fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
              </svg>
            </a>
          </ng-container>
        </div>

        <div class="flex position-center side-menu-social">
          <a href="https://www.instagram.com/orangefashionofficial">
            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
              <title>Logo Instagram</title>
              <path
                d="M349.33 69.33a93.62 93.62 0 0193.34 93.34v186.66a93.62 93.62 0 01-93.34 93.34H162.67a93.62 93.62 0 01-93.34-93.34V162.67a93.62 93.62 0 0193.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z" />
              <path
                d="M377.33 162.67a28 28 0 1128-28 27.94 27.94 0 01-28 28zM256 181.33A74.67 74.67 0 11181.33 256 74.75 74.75 0 01256 181.33m0-37.33a112 112 0 10112 112 112 112 0 00-112-112z" />
            </svg>
          </a>
          <a href="https://www.facebook.com/orangefashionofficial">
            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
              <title>Logo Facebook</title>
              <path
                d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z"
                fill-rule="evenodd" />
            </svg>
          </a>
          <a href="https://www.youtube.com/@orangefashionofficial">
            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
              <title>Logo Youtube</title>
              <path
                d="M508.64 148.79c0-45-33.1-81.2-74-81.2C379.24 65 322.74 64 265 64h-18c-57.6 0-114.2 1-169.6 3.6C36.6 67.6 3.5 104 3.5 149 1 184.59-.06 220.19 0 255.79q-.15 53.4 3.4 106.9c0 45 33.1 81.5 73.9 81.5 58.2 2.7 117.9 3.9 178.6 3.8q91.2.3 178.6-3.8c40.9 0 74-36.5 74-81.5 2.4-35.7 3.5-71.3 3.4-107q.34-53.4-3.26-106.9zM207 353.89v-196.5l145 98.2z" />
            </svg>
          </a>
          <a href="https://www.twitter.com/ORANGEO16936891">
            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
              <title>Logo Twitter</title>
              <path
                d="M496 109.5a201.8 201.8 0 01-56.55 15.3 97.51 97.51 0 0043.33-53.6 197.74 197.74 0 01-62.56 23.5A99.14 99.14 0 00348.31 64c-54.42 0-98.46 43.4-98.46 96.9a93.21 93.21 0 002.54 22.1 280.7 280.7 0 01-203-101.3A95.69 95.69 0 0036 130.4c0 33.6 17.53 63.3 44 80.7A97.5 97.5 0 0135.22 199v1.2c0 47 34 86.1 79 95a100.76 100.76 0 01-25.94 3.4 94.38 94.38 0 01-18.51-1.8c12.51 38.5 48.92 66.5 92.05 67.3A199.59 199.59 0 0139.5 405.6a203 203 0 01-23.5-1.4A278.68 278.68 0 00166.74 448c181.36 0 280.44-147.7 280.44-275.8 0-4.2-.11-8.4-.31-12.5A198.48 198.48 0 00496 109.5z" />
            </svg>
          </a>
          <a href="https://www.pinterest.com/orangefashionofficial">
            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
              <title>Logo Pinterest</title>
              <path
                d="M256.05 32c-123.7 0-224 100.3-224 224 0 91.7 55.2 170.5 134.1 205.2-.6-15.6-.1-34.4 3.9-51.4 4.3-18.2 28.8-122.1 28.8-122.1s-7.2-14.3-7.2-35.4c0-33.2 19.2-58 43.2-58 20.4 0 30.2 15.3 30.2 33.6 0 20.5-13.1 51.1-19.8 79.5-5.6 23.8 11.9 43.1 35.4 43.1 42.4 0 71-54.5 71-119.1 0-49.1-33.1-85.8-93.2-85.8-67.9 0-110.3 50.7-110.3 107.3 0 19.5 5.8 33.3 14.8 43.9 4.1 4.9 4.7 6.9 3.2 12.5-1.1 4.1-3.5 14-4.6 18-1.5 5.7-6.1 7.7-11.2 5.6-31.3-12.8-45.9-47-45.9-85.6 0-63.6 53.7-139.9 160.1-139.9 85.5 0 141.8 61.9 141.8 128.3 0 87.9-48.9 153.5-120.9 153.5-24.2 0-46.9-13.1-54.7-27.9 0 0-13 51.6-15.8 61.6-4.7 17.3-14 34.5-22.5 48a225.13 225.13 0 0063.5 9.2c123.7 0 224-100.3 224-224S379.75 32 256.05 32z" />
            </svg>
          </a>
          <a class="">
            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
              <title>Logo Linkedin</title>
              <path
                d="M444.17 32H70.28C49.85 32 32 46.7 32 66.89v374.72C32 461.91 49.85 480 70.28 480h373.78c20.54 0 35.94-18.21 35.94-38.39V66.89C480.12 46.7 464.6 32 444.17 32zm-273.3 373.43h-64.18V205.88h64.18zM141 175.54h-.46c-20.54 0-33.84-15.29-33.84-34.43 0-19.49 13.65-34.42 34.65-34.42s33.85 14.82 34.31 34.42c-.01 19.14-13.31 34.43-34.66 34.43zm264.43 229.89h-64.18V296.32c0-26.14-9.34-44-32.56-44-17.74 0-28.24 12-32.91 23.69-1.75 4.2-2.22 9.92-2.22 15.76v113.66h-64.18V205.88h64.18v27.77c9.34-13.3 23.93-32.44 57.88-32.44 42.13 0 74 27.77 74 87.64z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>
