import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { HttpClient } from '@angular/common/http';
import { WebModule } from 'src/app/modules/web/web.module';

@Component({
  selector: 'app-account-orders',
  templateUrl: './account-orders.component.html',
  styleUrls: ['./account-orders.component.scss']
})
export class AccountOrdersComponent implements OnInit {

  customer_key = '';
  orders = [] as any;
  results_per_page = 10;
  page = 0;
  pages = 0;
  count = 0;
  httpCall = false;

  public webModule: WebModule = {} as any;
  show_price_popup = false;
  show_cancel_popup = false;
  show_return_popup = false;
  product = [] as any;
  show_loader = true;

  constructor(
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public auth: AuthenticationService,
    public toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    this.globals.page_loader_observer.subscribe(state => this.show_loader = state);
    this.globals.set_page_loader(true);
    this.customer_key = await this.globals.get_customer_key();
    this.globals.set_account_active('asm-orders');

    await this.get_data();

    /**
    * close the search bar if it's open. set it to falses
    */
    this.globals.set_suggestion_bar_state(false);

    console.log('account.sidebar:ngAfterViewInit');
  }

  async get_data(): Promise<any> {
    if (this.httpCall) {
      return false;
    }

    this.httpCall = true;
    this.page = parseInt(this.page.toString()) + 1;

    const postData = new FormData();
    this.globals.set_page_loader(true);

    postData.append('action', 'account/orders/product-wise');
    postData.append(this.globals.customer_key, this.customer_key);
    postData.append('results_per_page', this.results_per_page.toString());
    postData.append('page', this.page.toString());

    const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${this.customer_key}/orders/product-wise`, postData).subscribe((response) => {
      //console.log(response);
      if (response.msg == 'success') {
        if (this.orders.length == 0) {
          this.orders = response.data;
          //console.log('1st')
        } else {
          let rows = response.data;
          //this.orders.concat(response.data);

          for (let kk = 0; kk < Object.entries(rows).length; kk++) {
            this.orders.push(rows[kk]);
          }
        }

        this.pages = response.pagination.pages;
        this.count = response.pagination.count;

        this.httpCall = false;
        this.globals.set_page_loader(false);
      } else {
        this.globals.set_page_loader(false);
      }
    });
  }

  show_price_breakup(indx: number) {
    this.product = this.orders[indx];
    this.show_price_popup = true;
  }

  hide_price_breakup() {
    this.product = [] as any;
    this.show_price_popup = false;
  }

  show_cancel(indx: number) {
    this.product = this.orders[indx];
    this.show_cancel_popup = true;
  }

  hide_cancel() {
    this.product = [] as any;
    this.show_cancel_popup = false;
  }

  show_return(indx: number) {
    this.product = this.orders[indx];
    this.show_return_popup = true;
  }

  hide_return() {
    this.product = [] as any;
    this.show_return_popup = false;
  }

  cancel_status(indx: number) {
    let product = this.orders[indx];
    if ((product.status == 'CBS' || product.status == 'CBU' || product.status == 'R') && product.cancel_key.length > 0) {
      if (product.cancel.type == null || product.cancel.type == 'cancel') {
        switch (product.cancel_status) {
          case 'P': {
            return "Cancelled, Waiting for approval";
            break;
          }
          default: {
            return product.cancel_status_text;
            break;
          }
        }
      }

      if (product.cancel.type == 'refund') {
        switch (product.cancel_status) {
          case 'P': {
            return `Refund requested for ${product.cancel.qnty} ${(product.cancel.qnty > 1) ? 'items' : 'item'} `;
            break;
          }
          default: {
            return product.cancel_status_text;
            break;
          }
        }
      }


      //return product.cancel_status_text;
    }

    return "Unknown";
  }

  order_details_link() {
    this.router.navigate([`/account/orders/cancel/${this.product.key}`]);

    //`/account/orders/cancel/'+product.key}}" [queryParams]="{okey: product.order.key}"`;
  }

  async onScroll() {
    if (this.page < this.pages) {
      await this.get_data();
    }

    //console.log('scrolled!!');
  }

}
