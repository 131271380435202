import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-order-cancel',
  templateUrl: './account-order-cancel.component.html',
  styleUrls: ['./account-order-cancel.component.scss']
})
export class AccountOrderCancelComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
