<div class="other-page">
    <div class="flex column container" style="max-width: 1000px">
       <div class="flex position-center other-page-heading">Frequently Asked Questions</div>
       <div class="flex column content">
            <div class="faq-box">
                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
                    <ngb-panel id="faq-1" title="DO I NEED TO CREATE AN ACCOUNT TO PLACE AN ORDER?">
                        <ng-template ngbPanelContent>
                            Before proceeding to the checkout you should login using your credentials, otherwise you can register and create an account.
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="faq-2" title="DO I NEED TO SIGN IN TO SEE MY ORDER DETAILS?">
                        <ng-template ngbPanelContent>
                            Yes. In order to see your orders, you should sign in to the Site and go to Account, and click on the 'Orders' section.
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="faq-3" 
                    title="IS MY PERSONAL INFORMATION KEPT PRIVATE?">
                        <ng-template ngbPanelContent>
                            For more information, please visit our <a href="/privacy-policy">Privacy Policy</a> section.
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="faq-4" 
                    title="IS IT SAFE TO USE MY CARD ON ORANGEFASHION.COM?">
                        <ng-template ngbPanelContent>
                            All online transactions performed on this site are secured payments. For more information, please visit our <a href="/terms-conditions">Terms and Conditions</a>.
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="faq-5" 
                    title="HOW MUCH WILL I BE CHARGED FOR SHIPPING?">
                        <ng-template ngbPanelContent>
                            Shipping cost is completely free for pan India. Before the time of placing order customer will get the fee amount in order details. From above for minimum order value, shipping cost is completely free for pan India.  For more information, please visit our Shipping Policy.
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="faq-6" 
                    title="WHAT ONLINE PAYMENT METHODS DO WE ACCEPT?">
                        <ng-template ngbPanelContent>
                            <p>- Credit cards (we accept Visa, Rupay &  Mastercard,);</p>
                            <p>- Bhim UPI & Other available UPI</p>
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="faq-7" 
                    title="WHICH CURRENCIES CAN I SHOP IN?">
                        <ng-template ngbPanelContent>
                            Payments as well as refunds for shipments are all invoiced in Rupees (Rs).
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="faq-8" 
                    title="HOW CAN I RETURN MY ORDER?">
                        <ng-template ngbPanelContent>
                            We offer a free collection service to all customers for Product(s) you wish to return except in relation to certain types of Products as set out in the Returns Policy.
                            You have 14 days from receiving your order to return the Product to us. We strongly recommend that you book your free returns pick-up within 7 days of receiving your order to ensure that it arrives back in time.
                            Do not seal your package until the courier person has checked the contents. Should youhave any doubt, do not hesitate to contact us at <a>support@orangefashion.co</a>.
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="faq-9" 
                    title="HOW ARE REFUNDS PROCESSED?">
                        <ng-template ngbPanelContent>
                            Once the return has been received at our warehouse and accepted by the Quality and Control team, the refund will be processed via the original payment method. The refund includes the full price for the Product(s) with the delivery charges.
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="faq-10" 
                    title="WHAT IS A PRE-ORDER?">
                        <ng-template ngbPanelContent>
                            A pre-order is the reservation of an item that is not yet available for sale, but will be soon. Pre-order items are shipped upon the arrival of the products in the warehouse within the time that is stated on the item’s purchase page.
                            In pre-order purchases credit cards are debited at the moment the order is placed. Regarding order with available items and pre-orderable items, shipment will happen in 2 different phases: available merchandise will be shipped with standard shipping methods. Pre-orderable items will be shipped right after items are available in stock.
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="faq-11" 
                    title="CAN I CHANGE MY SHIPPING ADDRESS AFTER MY ORDER HAS BEEN DISPATCHED?">
                        <ng-template ngbPanelContent>
                            No, the shipping address cannot be modified. For any doubts, please contact us at <b>support@orangefashion.co</b>.
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="faq-12" 
                    title="HOW CAN I TRACK MY  ORDER?">
                        <ng-template ngbPanelContent>
                            When the order will be tendered to the courier, you will receive an email/sms from the courier providing the tracking number (AWB) related to the order. To monitor the status of your order, you may visit the courier website and insert the AWB number in the “Track Your Shipment” area. 
                        </ng-template>
                    </ngb-panel>
                    <ngb-panel id="faq-13" 
                    title="WHERE CAN I FIND SIZE AND FIT INFORMATION?">
                        <ng-template ngbPanelContent>
                            Click on ‘Size Chart’, then ' View Size Chart' on each item’s page to view our conversion chart. We also provide model measurements. For more information, do not hesitate to contact us at <b>support@orangefashion.com</b>.
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
       </div>
    </div>
</div>