<ng-container *ngIf="!webModule.show_mobile_menu">

  <!-- onpage loade: this is for skeleton loading -->
  <ng-container *ngIf="webModule.menus==undefined || (webModule.menus.length==0)">
    <nav class="flex navigation main-navigation top-menu">
      <ul class="flex top-menu-container">
        <li class="flex position-center top-menu-item home" *ngFor="let lavel1 of [1,2,3,4]">
          <a class="flex position-center top-menu-item-link" href="/product"
            style="background: grey; color: grey;">Home</a>
        </li>

        <li class="flex position-center top-menu-item">
          <a class="flex position-center top-menu-item-link" href="/track-order">Order Tracking</a>
        </li>
      </ul>
    </nav>
  </ng-container>
  <!-- ./onpage loade -->


  <!-- top menu -->
  <ng-container *ngIf="webModule.menus!=undefined && (webModule.menus.length>0)">
    <nav class="flex navigation main-navigation top-menu _9844">
      <ul class="flex top-menu-container">
        <ng-container *ngFor="let lavel1 of webModule.menus; let indx1=index">
          <li class="flex position-center top-menu-item {{(indx1>2) ? 'hide' :''}}">
            <a class="flex position-center top-menu-item-link" routerLink="{{lavel1.link}}"
              routerLinkActive="active">{{lavel1.name}}</a>

            <ul class="sub-menu-container" *ngIf="(lavel1.child !== undefined && lavel1.child.length>0)">
              <ng-container *ngFor="let lavel2 of lavel1.child">
                <li class="flex top-menu-item-sub">
                  <a class="flex" [routerLink]="[lavel2.link]" [attr.data-key]="lavel2 | json">{{lavel2.name}}</a>
                </li>
              </ng-container>
            </ul>
          </li>
        </ng-container>
      </ul>
    </nav>
  </ng-container>
  <!-- top menu -->


</ng-container>
