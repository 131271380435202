import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { HttpClient } from '@angular/common/http';
import { WebModule } from 'src/app/modules/web/web.module';
import { Observable, combineLatest } from 'rxjs/index';

@Component({
  selector: 'app-account-order-details',
  templateUrl: './account-order-details.component.html',
  styleUrls: ['./account-order-details.component.scss']
})
export class AccountOrderDetailsComponent implements OnInit {

  customer_key = '';
  product_key = '';
  order_key = '';
  order = {} as any;
  public webModule: WebModule = {} as any;
  url_params = {} as any;
  url_query_params = {} as any;
  show_loader = true;

  constructor(
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public auth: AuthenticationService,
    public toastr: ToastrService,
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    this.customer_key = await this.globals.get_customer_key();

    this.url_params = await this.activeRoute.params;
    this.url_query_params = await this.activeRoute.queryParams;

    // 2. Call combineLatest operator, inject multiple observables in array
    const joinStream = combineLatest([this.url_params, this.url_query_params]);

    // 3. Subscibe combineLatest observable
    const subscribe = await joinStream.subscribe(async ([params, queryParams]) => {
      //this.product_vkey = queryParams['vkey'];
      await this.extract_params(params, queryParams);

      await this.get_data();

      /**
      * close the search bar if it's open. set it to falses
      */
      this.globals.set_suggestion_bar_state(false);
      this.globals.set_search_text('');

      this.globals.page_loader_observer.subscribe((state) => { this.show_loader = state });
      //console.log({ params: params, queryParams: typeof queryParams });
    });


    /**
    * close the search bar if it's open. set it to falses
    */
    this.globals.set_suggestion_bar_state(false);
  }

  async extract_params(params: any, queryParams: any) {
    this.product_key = await params['key'];
    this.order_key = await queryParams['okey'];

    console.log(this.product_key, this.order_key);
  }

  async get_data() {
    const postData = {
      action: 'account/orders/details',
      product_key: this.product_key,
      order_key: this.order_key,
      customer_key: this.customer_key,
    };
    this.globals.set_page_loader(true);


    const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${this.customer_key}/orders/${this.order_key}`, postData).subscribe((response) => {
      //console.log(response);
      if (response.msg == 'success') {
        let data = response.data;

        this.order = response.data;
        this.globals.set_page_loader(false);
        //console.log(Object.entries(this.product).length);
      } else {
        this.globals.set_page_loader(false);
      }
    });
  }

  show_address(): string {
    let addrs = '';
    addrs += (this.order.shipping_address.line1.length > 0) ? this.order.shipping_address.line1 : '';
    addrs += (this.order.shipping_address.landmark.length > 0) ? ', ' + this.order.shipping_address.landmark : '';
    addrs += (this.order.shipping_address.city.length > 0) ? ', ' + this.order.shipping_address.city : '';
    addrs += (this.order.shipping_address.state_name.length > 0) ? ', ' + this.order.shipping_address.state_name : '';
    addrs += (this.order.shipping_address.pincode.length > 0) ? ', ' + this.order.shipping_address.pincode : '';

    return addrs;
  }

  total_items(): number {
    let total = 0;

    for (let kk = 0; kk < this.order.products.length; kk++) {
      //console.log(parseInt(this.order.products[kk].qnty));
      total += parseInt(this.order.products[kk].qnty);
    }

    return total;
  }

  price_payable(indx: number) {
    let total = 0;
    let product = this.order.products[indx];

    total = (product.price_payable * product.qnty) - (product.coupon_price_discount * product.qnty);

    return total;
  }

  check_traking_status(indx: number, type: string) {
    let status_history = this.order.products[indx].status_history;
    let status = false;

    for (let kk = 0; kk < status_history.length; kk++) {
      if (status_history[kk].status == type) {
        status = true;
        break;
      }
      //console.log(status_history[kk]);
    }

    return status;
  }

  check_traking_date(indx: number, type: string) {
    let status_history = this.order.products[indx].status_history;
    let date = '';

    for (let kk = 0; kk < status_history.length; kk++) {
      if (status_history[kk].status == type) {
        date = status_history[kk].date;
        break;
      }
      //console.log(status_history[kk]);
    }

    return date;
  }

}
