<div class="flex flex-column ls-password reset ">
  <div class="flex allign-middle ls-heading">
    Welcome Back, {{auth.customer.fname}}
  </div>
  <div class="flex allign-middle ls-sub-heading">{{auth.customer.email}}</div>

  <div class="flex flex-column allign-middle ls-input">
    <input type="password" autocomplete="password" placeholder="Type New password" name="password"
      class="pincode-floating-input" id="password" value="" [(ngModel)]="auth.customer.password"
      [style.border]=" (auth.errors.password != undefined) ? '1px solid red' :'' "
      (change)="auth.remove_error('password')"
      (keyup)="auth.remove_error('password');auth.check_enter_pressed('password2', $event)" />
    <span class="ls-warning" *ngIf="auth.errors.password!==undefined">{{auth.errors.password}}</span>

    <input type="password" autocomplete="password" placeholder="Re-type password" name="password"
      class="pincode-floating-input" id="confirm_password" value="" [(ngModel)]="auth.customer.confirm_password"
      [style.border]=" (auth.errors.confirm_password != undefined) ? '1px solid red' :'' "
      (change)="auth.remove_error('confirm_password')"
      (keyup)="auth.remove_error('confirm_password');auth.check_enter_pressed('password2', $event)" />
    <span class="ls-warning " *ngIf="auth.errors.confirm_password!==undefined">{{auth.errors.confirm_password}}</span>

    <button class="flex position-center ls-submit" (click)="auth.reset_password()">Reset Password</button>
  </div>
  <div class="flex flex-column forget-pass">
    <a style="color: #5e5e5e;" (click)="auth.show('lsv-common')">Go back to Sign-in</a>
  </div>
</div>
