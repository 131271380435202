<div class="flex flex-column user-registration signup">
  <app-section-loader *ngIf="auth.show_loader"></app-section-loader>

  <div class="flex position-center ls-heading">New User Registration</div>
  <div class="flex flex-column allign-middle ls-input">
    <div class="single-input">
      <input class="floating-input" type="text" name="name" [(ngModel)]="auth.customer.name"
        [style.border]=" (auth.errors.name != undefined) ? '1px solid red' :'' " (change)="auth.remove_error('name')"
        (keyup)="auth.remove_error('name');auth.check_enter_pressed('signup', $event);" placeholder=" ">
      <label class="floating-label">Full Name*</label>
      <span class="ls-warning error text-danger" *ngIf="auth.errors.name!==undefined"
        style="display: block !important;">{{auth.errors.name}}</span>
    </div>
    <!-- this is for email signup auth.customer.mobile.length==0-->
    <div class="single-input" *ngIf="1">
      <input class="floating-input" type="text" name="mobile" [(ngModel)]="auth.customer.mobile"
        [style.border]=" (auth.errors.mobile != undefined) ? '1px solid red' :'' "
        (change)="auth.remove_error('mobile')"
        (keyup)="auth.remove_error('mobile');auth.check_enter_pressed('signup', $event);" placeholder=""
        value="9800622215" [disabled]="(auth.signup_type=='mobile')" />
      <label class="floating-label">Mobile*</label>
      <span class="ls-warning error text-danger" *ngIf="auth.errors.mobile!==undefined"
        style="display: block !important;">{{auth.errors.mobile}}</span>
    </div>
    <!-- this is for Mobile signup auth.customer.email.length==0-->
    <div class="single-input" *ngIf="1">
      <input class="floating-input" type="text" name="name" [(ngModel)]="auth.customer.email" placeholder=" "
        [style.border]=" (auth.errors.email != undefined) ? '1px solid red' :'' " (change)="auth.remove_error('email')"
        (keyup)="auth.remove_error('email');auth.check_enter_pressed('signup', $event);" value=""
        [disabled]="(auth.signup_type=='email')">
      <label class="floating-label">Email*</label>
      <span class="ls-warning error text-danger" *ngIf="auth.errors.email!==undefined"
        style="display: block !important;">{{auth.errors.email}}</span>
    </div>
    <div class="flex allign-middle single-input">
      <input id="passVisiblity" [(ngModel)]="auth.customer.password" class="floating-input"
        [style.border]=" (auth.errors.password != undefined) ? '1px solid red' :'' "
        (change)="auth.remove_error('password')"
        (keyup)="auth.remove_error('password'); auth.check_enter_pressed('signup', $event);"
        [type]=" showPassword ? 'text' : 'password' " placeholder=" ">
      <label class="floating-label">Enter Password*</label>
      <button class="sh-pass  {{ auth.showPassword ? 'visible' : 'not-visible' }}" (click)='showHidePassword()'>
        <svg class="open" viewBox="0 0 512 512">
          <path
            d="M255.66 112c-77.94 0-157.89 45.11-220.83 135.33a16 16 0 00-.27 17.77C82.92 340.8 161.8 400 255.66 400c92.84 0 173.34-59.38 221.79-135.25a16.14 16.14 0 000-17.47C428.89 172.28 347.8 112 255.66 112z"
            fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
          <circle cx="256" cy="256" r="80" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
        </svg>
        <svg class="close" viewBox="0 0 512 512">
          <path
            d="M432 448a15.92 15.92 0 01-11.31-4.69l-352-352a16 16 0 0122.62-22.62l352 352A16 16 0 01432 448zM255.66 384c-41.49 0-81.5-12.28-118.92-36.5-34.07-22-64.74-53.51-88.7-91v-.08c19.94-28.57 41.78-52.73 65.24-72.21a2 2 0 00.14-2.94L93.5 161.38a2 2 0 00-2.71-.12c-24.92 21-48.05 46.76-69.08 76.92a31.92 31.92 0 00-.64 35.54c26.41 41.33 60.4 76.14 98.28 100.65C162 402 207.9 416 255.66 416a239.13 239.13 0 0075.8-12.58 2 2 0 00.77-3.31l-21.58-21.58a4 4 0 00-3.83-1 204.8 204.8 0 01-51.16 6.47zM490.84 238.6c-26.46-40.92-60.79-75.68-99.27-100.53C349 110.55 302 96 255.66 96a227.34 227.34 0 00-74.89 12.83 2 2 0 00-.75 3.31l21.55 21.55a4 4 0 003.88 1 192.82 192.82 0 0150.21-6.69c40.69 0 80.58 12.43 118.55 37 34.71 22.4 65.74 53.88 89.76 91a.13.13 0 010 .16 310.72 310.72 0 01-64.12 72.73 2 2 0 00-.15 2.95l19.9 19.89a2 2 0 002.7.13 343.49 343.49 0 0068.64-78.48 32.2 32.2 0 00-.1-34.78z" />
          <path
            d="M256 160a95.88 95.88 0 00-21.37 2.4 2 2 0 00-1 3.38l112.59 112.56a2 2 0 003.38-1A96 96 0 00256 160zM165.78 233.66a2 2 0 00-3.38 1 96 96 0 00115 115 2 2 0 001-3.38z" />
        </svg>
      </button>
    </div>
    <span class="error text-danger" *ngIf="auth.errors.password!==undefined"
      style="display: block !important;">{{auth.errors.password}}</span>

    <button class="flex position-center ls-submit" (click)="auth.register()">Register</button>
  </div>
  <!--<div class="flex flex-column position-center forget-pass" *ngIf="0">
      <a (click)="skip_personal_info()">Skip</a>
    </div>-->
</div>
