import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SocialAuthService } from 'angularx-social-login';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { HttpClient } from '@angular/common/http'

@Component({
  selector: 'app-lsv-common',
  templateUrl: './lsv-common.component.html',
  styleUrls: ['./lsv-common.component.scss']
})
export class LsvCommonComponent implements OnInit {

  constructor(
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private router: Router,
    private activeRoute: ActivatedRoute,
    public toastr: ToastrService,
    public auth: AuthenticationService,
    public modalService: NgbModal,
    private authService: SocialAuthService,
  ) { }

  ngOnInit(): void {
  }

}
