<div class="other-page">
    <div class="flex column container" style="max-width: 1000px">
       <div class="flex position-center other-page-heading">Cookie Policy</div>
       <div class="content">

        <h2><strong>What are Cookies</strong></h2>

        <p>Most websites you visit will use cookies to improve your user experience by enabling that website to remember you, either for the duration of your visit (using a &#39;session cookie&#39;) or for future visits (using a &#39;persistent cookie&#39;).<br />
        A cookie is a simple text file that is issued to your computer when you visit a website and which stores and sometimes tracks information about your use of a website. Each cookie is unique to your browser. It will contain some anonymous information such as a unique identifier, the website name and some digits and numbers. It allows a website to remember things like your preferences or what&#39;s in your shopping basket.<br />
        Although they do identify a user&#39;s computer, cookies do not personally identify users, and passwords and credit card information are not stored in cookies.<br />
        Cookies are not programs and therefore cannot contain viruses or other malicious software.</p>
        
        <h3>How We Use Cookies</h3>
        
        <p>You can find a summary of the various types of cookies we use below. Our cookies are used for 5 types of purpose:<br />
        &nbsp;We use the following categories of cookies on our Site.</p>
        
        <h3>CATEGORY 1: STRICTLY NECESSARY COOKIES</h3>
        
        <p>These cookies are essential in order to enable you to move around the Site and use its features. Without these cookies, services you have asked for such as remembering your login details or shopping basket items cannot be provided. These cookies are passed under HTTPS in an encrypted format. This provides the most security when identifying you as a user.<br />
        We will also use cookies in order to protect the brand and our customers from fraudulent activities during the checkout operations. In this context, we may engage third parties, &nbsp;that will help us to conduct this activity. All the data collected throughout these cookies will be encrypted and stored for a period of years and will be processed only in the case that a customer checks out any of our products.</p>
        
        <h3>CATEGORY 2: PERFORMANCE COOKIES</h3>
        
        <p>These cookies collect anonymous information on how people use our Site. For example, we use Google Analytics cookies to help us understand how customers arrive at our Site, browse or use our Site and highlight areas where we can improve areas such as navigation, user experience, shopping experience and marketing campaigns. The data stored by these cookies never shows personal details from which your individual identity can be established.<br />
        Google Analytics is a system used by many websites to record information about who visits a website. Google sets five different cookies with expiry dates ranging from 1 day to 2 years. These cookies are used mainly to differentiate between first time visitors to a website and repeat visitors. They do not contain any personally identifiable information.&nbsp;</p>
        
        <h3>CATEGORY 3: FUNCTIONALITY COOKIES</h3>
        
        <p>These cookies allow us to:<br />
        Remember choices you make such as the country you visit our Site from, language and search parameters such as size, colour or product line. These can then be used to provide you with an experience more appropriate to your selections and to make the visits more tailored and pleasant. The information these cookies collect may be anonymised and they cannot track your browsing activity on other websites.<br />
        Remember that you have visited us before; this means we can identify the number of unique visitors we receive. This allows us to make sure we have enough capacity for the number of users that we get.</p>
        
        <h3>CATEGORY 4: TARGETING COOKIES OR ADVERTISING COOKIES</h3>
        
        <p>These cookies collect information about your browsing habits in order to make advertising more relevant to you and your interests. They are also used to limit the number of times you see an advert as well as help measure the effectiveness of an advertising campaign. The cookies are usually placed by third party advertising networks. They remember the websites you visit and that information is shared with other parties such as advertisers.&nbsp;</p>
        
        <h3>CATEGORY 5: SOCIAL MEDIA COOKIES</h3>
        
        <p>These cookies allow you to share what you&rsquo;ve been doing on the Site on social media such as Facebook. These cookies are not within our control. Please refer to the respective privacy policies for how their cookies work.</p>
        
        <h3>Controlling or deleting cookies</h3>
        
        <p>Most web and mobile device browsers automatically accept cookies but, if you prefer, you can change your browser to prevent that or to notify you each time a cookie is set.<br />
        If you want to delete any cookies that are already on your computer, please refer to the help and support area on your internet browser for instructions on how to locate the file or directory that stores cookies.<br />
        Please note that by deleting or restricting cookies or by disabling future cookies you may not be able to access certain areas or features of our Site or take full advantage of the Site.<br />
        To opt out of being tracked by Google Analytics across all websites visit Google Analytics Opt-out Browser Add-on.<br />
        If you&#39;d like to opt out of other third party cookies relating to behavioural advertising, Opting out does not mean you will no longer receive online advertising. It does mean that the company or companies from which you opted out will no longer deliver ads tailored to your web preferences and usage patterns.<br />
        Uses made of your information</p>
        
        <p>We will use the information you provide only for the following purposes:</p>
        
        <h3>Information you give to us</h3>
        
        <p>We will use this information:<br />
        to carry out our obligations arising from any contracts entered into between you and us and to provide you with the information, products and services that you request from us;<br />
        to provide you, with information about goods or services we feel may interest you, if you have ticked the relevant box situated on the form on which we collect your data (the order form or registration form to create an account with the Site) indicating your consent to receiving marketing material from us;<br />
        verify and carry out financial transactions in relation to payments you make online;<br />
        to notify you about changes to our service provided by the Site;&nbsp;&nbsp; &nbsp;<br />
        to ensure in our legitimate interests that content from our Site is presented in the most effective.<br />
        &nbsp;</p>
        


       </div>
    </div>
</div>
