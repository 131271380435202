import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';
import { HttpClient } from '@angular/common/http';
import { WebModule } from 'src/app/modules/web/web.module';

@Component({
  selector: 'app-account-wishlist',
  templateUrl: './account-wishlist.component.html',
  styleUrls: ['./account-wishlist.component.scss']
})

export class AccountWishlistComponent implements OnInit {

  public webModule: WebModule = {} as any;
  //--search------
  rows = [] as any;
  results_per_page = 9;
  page = 0;
  pages = 0;
  count = 0;

  element: any | undefined;
  height: number | undefined;
  httpCall = false;
  popup_state = false;
  selected_indx: number | undefined;
  product = {} as any;

  constructor(
    private modalService: NgbModal,
    private offcanvasService: NgbOffcanvas,
    private httpClient: HttpClient,
    public globals: GlobalsService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    public toastr: ToastrService,
    public auth: AuthenticationService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit(): Promise<any> {
    this.globals.web_observer.subscribe(web => this.webModule = web);
    this.globals.set_account_active('asm-wishlist');

    await this.get_data();
  }

  async get_data(): Promise<any> {
    if (this.httpCall) {
      return false;
    }

    this.httpCall = true;
    this.page = this.page + 1;

    let postData = {
      action: 'account/wishlist',
      results_per_page: this.results_per_page,
      page: this.page,
    };

    const result = this.httpClient.post<any>(this.globals.get_endpoint() + `/account/customer/${this.globals.get_customer_key()}/wishlist`, postData).subscribe((response) => {
      //console.log(response);
      if (response.msg == 'success') {
        if (this.rows.length == 0) {
          this.rows = (response.data);
          //console.log('1st')
        } else {
          let rows = response.data;
          this.rows.concat(response.data);

          for (let kk = 0; kk < Object.entries(rows).length; kk++) {
            this.rows.push(rows[kk]);
          }
        }

        this.pages = response.pagination.pages;
        this.count = response.pagination.count;

        this.httpCall = false;
        //this.config.currentPage = response.pagination.page;
        //this.config.totalItems = response.pagination.count;
      }
    });

  }

  async onScroll() {
    if (this.page < this.pages) {
      await this.get_data();
    }

    //console.log('scrolled!!');
  }

  async remove(indx: number) {
    let product = this.rows[indx];
    let customer_key = await this.globals.get_customer_key();
    this.globals.set_page_loader(true);

    if (customer_key !== null && customer_key.length > 0) { // if logged in
      let postData = new FormData();

      postData.append('action', 'cart/remove');
      postData.append('key', product.key);

      const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/cart/${atob(customer_key)}/remove/${product.key}`, postData).subscribe((response) => {
        if (response.msg == 'success') {
          //this.get_data();
          let temp = this.rows.filter((v: any, k: any) => {
            if (k != indx)
              return v;
          });

          this.rows = temp;
          //console.log(temp);
          this.globals.set_page_loader(false);
        } else {
          this.globals.set_page_loader(false);
        }
      });
    }
  }

  async move_to_cart(): Promise<any> {
    if (this.selected_indx == undefined)
      return false;

    let product = this.rows[this.selected_indx];
    let customer_key = await this.globals.get_customer_key();
    this.globals.set_page_loader(true);

    if (customer_key !== null && customer_key.length > 0) { // if logged in
      let postData = new FormData();

      postData.append('action', 'wishlist/move-to-cart');
      postData.append('key', product.key);
      postData.append('product_key', product.product_key);
      postData.append('color', product.color);
      postData.append('size', product.size);
      postData.append('vkey', product.varieant_key);

      const result = await this.httpClient.post<any>(this.globals.get_endpoint() + `/wishlist/${customer_key}/move-to-cart/${product.key}`, postData).subscribe((response) => {
        if (response.msg == 'success') {
          //this.get_data();
          let temp = this.rows.filter((v: any, k: any) => {
            if (k != this.selected_indx)
              return v;
          });

          this.rows = temp;
          this.globals.set_page_loader(false);

          this.toastr.clear();
          this.toastr.success(response.data, '', { positionClass: 'toast-bottom-center' });
          this.hide_popup();
        } else {
          this.globals.set_page_loader(false);
        }
      });
    }

  }

  show_popup(indx: number) {
    this.selected_indx = indx;
    this.product = this.rows[indx];
    console.log(this.product);
    this.popup_state = true;
  }

  hide_popup() {
    this.selected_indx = undefined;
    this.product = {} as any;
    this.popup_state = false;
  }

}
