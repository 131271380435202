import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';
import { WebModule } from 'src/app/modules/web/web.module';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-menu-top',
  templateUrl: './menu-top.component.html',
  styleUrls: ['./menu-top.component.scss']
})
export class MenuTopComponent implements OnInit {

  public searchComponentLoaded: boolean = false;
  public shouldShow = false;

  public search: boolean = false;
  public mobileMenu: boolean = false;
  public menus = [] as any;
  public announcement = {
    show: false,
    data: {} as any,
  } as any;
  announcement_state = true;
  search_text = '';
  customer_key = '';
  customer_name = '';
  customer_fname = '';

  search_results = {
    products: [] as any,
    categories: [] as any,
    show_results: false,
  } as any;

  cart_count = 0;
  wishlist_count = 0;

  public webModule: WebModule;

  constructor(
    private modalService: NgbModal,
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    public auth: AuthenticationService,
    public cookies: CookieService,
  ) { }

  ngOnInit(): void {
    this.globals.header_search_results_observer.subscribe(results => this.search_results = results);
    this.globals.cart_count_observer.subscribe(count => this.cart_count = count);
    this.globals.wishlist_count_observer.subscribe(count => this.wishlist_count = count);
    this.globals.search_text_observer.subscribe(text => this.search_text = text);
    this.globals.web_observer.subscribe(web => this.webModule = web);


    // if (window.localStorage.getItem('menus') != null) {
    //   this.menus = JSON.parse(window.localStorage.getItem('menus') as string);
    // }

    if (isPlatformBrowser(this.globals.platformId)) {
      if (this.cookies.check('anmt')) {
        //let st = this.cookies.get('anmt') as string;  //(localStorage.getItem('announcement_state') as string);
        this.announcement_state = false;
      }
    }

    //this.get_menus();
  }

  async ngAfterViewInit() {
    //console.log('top-bar', this.webModule);
  }

  async ngAfterContentInit() {
    //console.log('ngAfterViewInit');
    //await this.get_search_results();
    this.customer_key = await this.globals.get_customer_key();
    this.customer_fname = await this.globals.get_localstore('fname');
    this.search_results.show_results = false;
    //this.globals.set_header_search_result(this.search_results);
    //this.globals.set_search_text(this.search_text);
    this.globals.web_observer.subscribe(web => this.webModule = web);

    let page = this;
    if (isPlatformBrowser(this.platformId)) {
      document.addEventListener('mouseup', function (e) {
        let container = document.getElementById('search_input_container') as any;

        if (container != null && (!container.contains(e.target))) {
          //container.style.display = 'none';
          page.search_results.show_results = false;
          page.globals.set_header_search_result(page.search_results);
          ////console.log(container);
        }
        ////console.log(e);
      });
    }

    if (isPlatformBrowser(this.globals.platformId)) {
      let tempMenu = localStorage.getItem('menu');

      if (tempMenu != null && tempMenu.length > 0) {
        this.webModule.menus = JSON.parse(tempMenu);
      }
    }
  }

  async get_menus() {
    const postData = {
      action: 'home/menus',
    }; //new FormData();

    //postData.append('action', 'home/menus');

    const result = this.httpClient.post<any>(this.globals.get_endpoint() + '/home/menus', postData).subscribe((response) => {
      ////console.log(response);
      if (response.msg == 'success') {
        this.menus = response.data.menus;
        this.announcement = response.announcement;
        this.webModule.menus = this.menus;
        this.globals.set_web(this.webModule);

        localStorage.setItem('menu', JSON.stringify(this.webModule.menus));
        //console.log(this.webModule, this.globals.slugify('df ssf---sdf dsfsf'));
      }
    });
  }

}
