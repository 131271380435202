<div class="flex account-page">
  <div class="flex flex-column container">
    <div class="flex w-100">

      <!-- account-side-menu -->
      <app-account-sidemenu class=""></app-account-sidemenu>
      <!-- ./account-side-menu -->

      <div class="flex right profile-details" style="position: relative">

        <!-- page loader -->
        <app-section-loader class="{{(show_loader) ? 'show' :'hide'}}"></app-section-loader>
        <!-- ./page loader -->

        <div class="flex flex-column w-100 profile-overview profile-card">
          <div class="flex allign-middle w-100 head-section">My Addresses
            <button class="flex position-center add-new-address" (click)="show_address_popup()">ADD NEW ADDRESS</button>
          </div>
          <div class="flex flex-wrap address-list">

            <ng-container *ngFor="let item of rows; let indx= index;">

              <div class="flex flex-column address-single default" *ngIf="item.set_default" style="cursor: pointer;">
                <div class="flex flex-column address-single-card">
                  <div class="flex flex-column address-single-details">
                    <div class="flex name">{{item.name}} <span>{{item.type}}</span></div>
                    <div class="flex address1">{{item.address1}}, {{item.locality}}</div>
                    <div class="flex address2">{{item.city}}, {{item.state_name}} - {{item.pincode}}</div>
                    <div class="flex mobile">Mobile: <span>{{item.mobile}}</span></div>
                  </div>
                  <div class="flex position-center justify-content-between address-single-details-bottom">
                    <!-- <div class="flex position-center default"><a>Deliver here</a></div> -->
                    <div class="flex position-center edit"><a (click)="show_update_address_popup(indx)">Edit</a></div>
                    <div class="flex position-center delete"><a (click)="show_remove_popup(indx)">Remove</a></div>
                  </div>
                </div>
              </div>

            </ng-container>

            <ng-container *ngFor="let item of rows; let indx= index;">

              <div class="flex flex-column address-single" *ngIf="!item.set_default">
                <div class="flex flex-column address-single-card">
                  <div class="flex flex-column address-single-details">
                    <div class="flex name">{{item.name}} <span>{{item.type}}</span></div>
                    <div class="flex address1">{{item.address1}}, {{item.locality}}</div>
                    <div class="flex address2">{{item.city}}, {{item.state_name}} - {{item.pincode}}</div>
                    <div class="flex mobile">Mobile: <span>{{item.mobile}}</span></div>
                  </div>
                  <div class="flex position-center justify-content-between address-single-details-bottom">
                    <div class="flex position-center default" (click)="set_default(indx)"><a>Make Default</a></div>
                    <div class="flex position-center edit" (click)="show_update_address_popup(indx)"><a>Edit</a></div>
                    <div class="flex position-center delete"><a (click)="show_remove_popup(indx)">Remove</a></div>
                  </div>
                </div>
              </div>

            </ng-container>

            <div class="flex address-single add-new">
              <div class="flex w-100 position-center address-single-card" (click)="show_address_popup()">
                <div (click)="show_address_popup()">+ Add New Address</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- remove item alert/prompt -->
<div class="flex position-center remove-order-item-dialog" *ngIf="show_remove_item">
  <div class="remove-order-item-backdrp"></div>
  <div class="flex flex-column remove-box">
    <div class="flex w-100 remove-box-top">
      <div class="flex allign-middle image">
        <img src="" alt="" style="width:48px; height: 64px;">
      </div>
      <div class="flex flex-column details">
        <div class="head1">
          Remove Address
          <div class="flex position-center dialog-close" (click)="hide_remove_popup()">
            <svg viewBox="0 0 24 24">
              <path fill="currentColor"
                d="m12 10.586 6.293-6.293a1 1 0 0 1 1.414 1.414l-6.293 6.293 6.293 6.293a1 1 0 0 1 -1.414 1.414l-6.293-6.293-6.293 6.293a1 1 0 1 1 -1.414-1.414l6.293-6.293-6.293-6.293a1 1 0 0 1 1.414-1.414z">
              </path>
            </svg>
          </div>
        </div>
        <div class="txt">Are you sure you want to delete this address?</div>
      </div>
    </div>
    <div class="flex position-center justify-content-between remove-item-bottom">
      <div class="flex position-center remove" (click)="hide_remove_popup()"><a>CLOSE</a></div>
      <div class="flex position-center wish" (click)="delete()"><a>YES, REMOVE</a></div>
    </div>
  </div>
</div>

<app-add-edit-address *ngIf="show_popup" (popupState)="hide_address_popup($event)"></app-add-edit-address>