import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModalConfig, NgbModal, NgbActiveModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { GlobalsService } from 'src/app/services/globals.service';

@Component({
  selector: 'app-header-search',
  templateUrl: './header-search.component.html',
  styleUrls: ['./header-search.component.scss'],
  providers: [NgbModalConfig, NgbModal]
})
export class HeaderSearchComponent implements OnInit {

  public show_results = false;
  public search_text = '';
  public customer_key = '';
  public search_results = [] as any;
  public modal = [] as any;

  public loading = true;

  constructor(
    private modalService: NgbModal,
    public globals: GlobalsService,
    private httpClient: HttpClient,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private offcanvasService: NgbOffcanvas,
  ) { }

  ngOnInit(): void {
    this.search_results.show_results = false;
    this.globals.set_header_search_result(this.search_results);
  }

  async ngAfterViewInit() {

  }

  async ngAfterContentInit() {
    //console.log('ngAfterViewInit');
    this.globals.search_text_observer.subscribe(text => this.search_text = text);
    this.globals.header_search_results_observer.subscribe((results) => {
      this.search_results = results;
      //console.log([this.search_results, Object.entries(this.search_results).length]);
    });

    this.customer_key = await this.globals.get_customer_key();
  }

  on_text_change(event: any): any {
    if (event.keyCode == 13) {
      //console.log(this.router.navigated);
      this.offcanvasService.dismiss('On enter click');

      this.router.navigate(['/search'], {
        queryParams: { q: this.search_text },
      });
      return false;
    }

    this.globals.set_search_text(this.search_text);
    this.get_search_results();
  }

  get_search_results() {
    const postData = {
      action: 'products/suggestions',
      search: this.search_text,
    }; //new FormData();

    this.loading = true;

    const result = this.httpClient.post<any>(this.globals.get_endpoint() + '/products/suggestions', postData).subscribe((response) => {
      ////console.log(response);
      if (response.msg == 'success') {
        this.search_results.products = response.data.products;
        this.search_results.categories = response.data.categories;
        this.globals.set_header_search_result(this.search_results);

        setTimeout(() => {
          this.loading = false;
          console.log(this.loading)
        }, 1 * 1000);
      } else {
        setTimeout(() => {
          this.loading = false;
        }, 1 * 1000);
      }
    });
  }

  close_menu() {
    const offcanvasRef = this.modalService.dismissAll('close modal');
    console.log(this.offcanvasService.activeInstance);
    this.offcanvasService.dismiss('Canvas closed');
    //this.modal.dismissAll('close')
    //console.log(this.modal);
  }

}
